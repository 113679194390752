import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { selectRecentlyPlayedItems } from '../selectors/client';
import * as analyticsActions from '../actions/analytics';

import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';

import Head from '../components/chrome/Head';
import List from '../components/util/List';
import PlayableItemPreview from '../components/common/PlayableItemPreview';

import styles from './RecentlyPlayed.css';
import protectedComponent from '../hoc/protectedComponent';
import { loadRecentlyPlayed } from '../actions/recentlyPlayed';
import dataComponent from '../hoc/dataComponent';

const messages = defineMessages({
  metaTitle: {
    id: 'recently-played.meta.title',
    defaultMessage: 'Recently Played',
  },
  metaDescription: {
    id: 'recently-played.meta.description',
    defaultMessage: 'Recently Played',
  },
});

function fetchData(store) {
  return store.dispatch(loadRecentlyPlayed());
}

class RecentlyPlayed extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    trackPlayPausePressed: PropTypes.func.isRequired,
    analyticsTrack: PropTypes.func.isRequired,
    intl: intlShape,
  };

  onItemClick = ({ content }) => {
    this.props.analyticsTrack('Selected Play History Entry', {
      itemId: content.id,
      itemType: content.type,
    });
  };

  renderItem = item => {
    return (
      <li key={item.content.id} className={styles.item}>
        <PlayableItemPreview
          item={item}
          onClick={this.onItemClick}
          beforeTogglePlay={this.trackPlayPausePressed}
        />
      </li>
    );
  };

  renderEmpty() {
    return (
      <FormattedMessage
        id="recently-played.empty"
        defaultMessage="Your recently played tracks will appear here."
      />
    );
  }

  render() {
    const { intl, items } = this.props;
    const { formatMessage } = intl;
    return (
      <div className="u-page-container">
        <Head
          title={formatMessage(messages.metaTitle)}
          description={formatMessage(messages.metaDescription)}
        />
        <div>
          <div className={styles.header}>
            {items.length === 0 ? (
              this.renderEmpty()
            ) : (
              <h1 className="fz--beta">
                <FormattedMessage id="recently-played.header" defaultMessage="Recently Played" />
              </h1>
            )}
          </div>
          <List className={styles.list} items={items} renderItem={this.renderItem} />
        </div>
      </div>
    );
  }

  trackPlayPausePressed = (item, playing) => {
    this.props.trackPlayPausePressed(
      {
        contextId: item.content.id,
        contextType: item.content.type,
        contextFacet: 'Recently Played',
      },
      playing
    );
  };
}

function mapStateToProps(state) {
  return {
    items: selectRecentlyPlayedItems(state),
  };
}

export default compose(
  protectedComponent({ authenticatedOnly: true }),
  dataComponent(fetchData),
  connect(mapStateToProps, {
    analyticsTrack: analyticsActions.track,
    trackPlayPausePressed: analyticsActions.trackPlayPausePressed,
  }),
  injectIntl
)(RecentlyPlayed);
