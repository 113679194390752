import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import * as Shapes from '../../shapes';
import Head from '../chrome/Head';
import dataComponent from '../../hoc/dataComponent';
import AboutGroup from './AboutGroup';
import BlurFilterSvg from '../livestream-event/BlurFilterSvg';

import { messages } from './messages';

import { loadProfileAbout } from '../../actions/profile';
import { selectArtistIdFromSlug, selectProfile } from '../../selectors/profile';

import styles from './ProfileAbout.css';
import Expandable from '../common/Expandable';

class ProfileAbout extends PureComponent {
  static propTypes = {
    profile: Shapes.Profile.isRequired,

    intl: intlShape,
  };

  static getCanonicalRoute(slug) {
    return `/profiles/${slug}/about`;
  }

  render() {
    const { profile, intl } = this.props;

    const { name, slug } = profile;
    const { formatMessage } = intl;

    return (
      <div>
        <Head
          canonicalRoute={ProfileAbout.getCanonicalRoute(slug)}
          title={formatMessage(messages.profileAboutMetaTitle, { name })}
          description={formatMessage(messages.profileAboutMetaTitle, { name })}
          keywords={formatMessage(messages.profileAboutMetaKeywords, { name })}
        />

        {profile.biography && (
          <Expandable>
            <p className={styles.artistBio}>{profile.biography}</p>
          </Expandable>
        )}

        {profile.latestReleases && (
          <AboutGroup
            id="latest-releases"
            title={formatMessage(messages.profileAboutLatestReleases)}
            items={profile.latestReleases}
            slug={slug}
          />
        )}

        {profile.featuredOn && (
          <AboutGroup
            id="featured-on"
            title={formatMessage(messages.profileAboutFeaturedOn)}
            items={profile.featuredOn}
            slug={slug}
          />
        )}

        {profile.recommendedAlbums && (
          <AboutGroup
            id="recommended-albums"
            title={formatMessage(messages.profileAboutRecommendedAlbums)}
            items={profile.recommendedAlbums}
            slug={slug}
          />
        )}

        {profile.concerts && (
          <React.Fragment>
            <AboutGroup
              id="concerts"
              title={formatMessage(messages.profileAboutConcerts)}
              items={profile.concerts}
              slug={slug}
              isConcertGroup
            />
            <BlurFilterSvg />
          </React.Fragment>
        )}
      </div>
    );
  }
}

function fetchData(store, { slug }) {
  const state = store.getState();
  const id = selectArtistIdFromSlug(state, slug);
  return store.dispatch(loadProfileAbout(id));
}

function mapStateToProps(state, ownProps) {
  const { slug } = ownProps.params;

  const profile = selectProfile(state, slug);

  return {
    profile,
  };
}

export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, {}),
  injectIntl
)(ProfileAbout);
