export default {
  conductors: [
    { id: 3515, name: 'Leonard Bernstein' },
    { id: 10544215, name: 'Andris Nelsons' },
    { id: 77957, name: 'Herbert von Karajan' },
    { id: 6372212, name: 'Nikolaus Harnoncourt' },
    { id: 11727176, name: 'Marin Alsop' },
    { id: 10979445, name: 'Carlos Kleiber' },
    { id: 10544479, name: 'Simon Rattle' },
    { id: 11212852, name: 'Claudio Abbado ' },
  ],
  pianists: [
    { id: 73382, name: 'Martha Argerich' },
    { id: 73415, name: 'Vladimir Horowitz' },
    { id: 18703349, name: 'Murray Perahia' },
    { id: 16665076, name: 'Maria João Pires' },
    { id: 17923426, name: 'Yuja Wang' },
    { id: 72131, name: 'Alfred Brendel' },
    { id: 9433542, name: 'Maurizio Pollini' },
    { id: 72289, name: 'Artur Rubinstein' },
  ],
  violinists: [
    { id: 11112301, name: 'Lisa Batiashvili' },
    { id: 11063952, name: 'Janine Jansen' },
    { id: 86084, name: 'Jascha Heifetz' },
    { id: 11238852, name: 'Isabelle Faust' },
    { id: 19235367, name: 'Maxim Vengerov' },
    { id: 24928, name: 'Yehudi Menuhin' },
    { id: 18085990, name: 'Hilary Hahn' },
    { id: 11174711, name: 'Thomas Zehetmair' },
  ],
  cellists: [
    { id: 12778226, name: 'Yo-Yo Ma' },
    { id: 4356, name: 'Mstislav Rostropovich' },
    { id: 16892166, name: 'Jacqueline du Pré' },
    { id: 3628, name: 'Pablo Casals' },
    { id: 10954040, name: 'Gautier Capuçon' },
    { id: 16505216, name: 'Alisa Weilerstein' },
    { id: 12585586, name: 'Jean-Guihen Queyras' },
    { id: 18231983, name: 'Sol Gabetta' },
  ],
  vocalists: [
    { id: 3607, name: 'Maria Callas' },
    { id: 11212857, name: 'Luciano Pavarotti' },
    { id: 12769867, name: 'Placido Domingo' },
    { id: 11309355, name: 'Joyce DiDonato' },
    { id: 11174406, name: 'Jonas Kaufmann' },
    { id: 12078860, name: 'Bryn Terfel' },
    { id: 18057049, name: 'Anna Netrebko' },
    { id: 10544474, name: 'Christian Gerhaher' },
  ],
  orchestras: [
    { id: 766, name: 'Wiener Philharmoniker' },
    { id: 2953, name: 'Berliner Philharmoniker' },
    { id: 2989, name: 'Chicago Symphony Orchestra' },
    { id: 3052, name: 'Royal Concertgebouw Orchestra' },
    { id: 17913918, name: 'Mariinsky Orchestra' },
    { id: 3103, name: 'New York Philharmonic' },
    { id: 93661, name: 'London Symphony Orchestra' },
    { id: 93672, name: 'Czech Philharmonic Orchestra' },
  ],
  choirs: [
    { id: 11662581, name: 'Monteverdi Choir' },
    { id: 11737762, name: 'Tenebrae' },
    { id: 3368581, name: 'Choir of King"s College, Cambridge' },
    { id: 93938, name: 'RIAS Kammerchor' },
    { id: 11400604, name: 'Swedish Radio Choir' },
    { id: 12769999, name: 'Vox Luminis' },
    { id: 13985071, name: 'Tallis Scholars' },
    { id: 11213185, name: 'The Sixteen' },
  ],
  quartets: [
    { id: 12050499, name: 'The Lindsays' },
    { id: 5110428, name: 'Borodin Quartet' },
    { id: 12938072, name: 'Takács Quartet' },
    { id: 93897, name: 'Juillard String Quartet' },
    { id: 11438430, name: 'Belcea Quartet' },
    { id: 13811696, name: 'Arditti Quartet' },
    { id: 17921921, name: 'Emerson Quartet' },
    { id: 11089275, name: 'Quatuor Ebène' },
  ],
  ensembles: [
    { id: 10953928, name: 'Concentus Musicus Wien' },
    { id: 8708770, name: 'Concerto Köln' },
    { id: 11662547, name: 'English Baroque Soloists' },
    { id: 14894196, name: 'Academy of Ancient Music' },
    { id: 10928452, name: 'Akademie für Alte Musik Berlin' },
    { id: 12455505, name: 'Tafelmusik' },
    { id: 18526803, name: 'Les Arts Florissants' },
    { id: 17772040, name: 'Freiburger Barockorchester' },
  ],
};
