import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { NavigationPlugin } from '../../capacitor-connector';
import IconLabel from '../util/IconLabel';
import styles from './OfflineScreen.css';

const OfflineScreen = () => {
  const handleGoToCollection = () => {
    NavigationPlugin.androidShouldOpenCollection();
  };

  return (
    <div className={styles.screenWrapper}>
      <IconLabel name="disconnected" size="4rem" className={styles.iconDisconnected} />
      <p>
        <FormattedMessage id="offline-message.title" defaultMessage="No network connection." />
        <br />
        <FormattedMessage
          id="offline-message.paragraph"
          defaultMessage="Please reconnect to the internet."
        />
      </p>
      <div className={styles.buttonContainer}>
        <button className={classnames('c-btn', styles.button)} onClick={handleGoToCollection}>
          <FormattedMessage
            id="offline-message.collection-button"
            defaultMessage="Listen to your music offline"
          />
        </button>
      </div>
    </div>
  );
};

export default OfflineScreen;
