// @flow
import { loaded } from '../middleware/apiCacheValidators';
import { MoodList } from '../schema';

import type { Request } from './types';

export type LoadMoodsAction = { type: 'FETCH_MOODS' } & Request;
export type MoodAction = LoadMoodsAction;

export function loadMoods(): LoadMoodsAction {
  return {
    type: 'FETCH_MOODS',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v1.8/mood',
    },
    meta: {
      schema: MoodList,
    },
    cache: {
      fetch: state => state.lists.moods,
      validate: loaded,
    },
  };
}
