import GenericTrackListView from '../components/common/GenericTrackListView';
import { loadMix } from '../actions/mix';
import { showAddToPlaylistModal } from '../actions/ui';
import {
  getMixQueueOrigin,
  selectMix,
  selectMixImageAnnotation,
  selectMixIsPlaying,
  selectMixIsQueued,
} from '../selectors/mix';

import * as playerActions from '../actions/player';

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import collectibleEntity from '../hoc/collectibleEntity';
import playlistableEntity from '../hoc/playlistableEntity';
import queueOriginComponent from '../hoc/queueOriginComponent';

import { selectCollectionIds } from '../selectors/collection';

import { defineMessages, injectIntl } from 'react-intl';
import metaInjector from '../hoc/metaInjector';
import { ENTITY_TYPE_MIX } from '../constants';
import protectedComponent from '../hoc/protectedComponent';

function fetchData(store, { slug }) {
  return store.dispatch(loadMix(slug));
}

const messages = defineMessages({
  playAllButtonPausedText: {
    id: 'mix.play-all.paused.title',
    defaultMessage: 'Play mix',
  },
  playAllButtonPlayingText: {
    id: 'mix.play-all.playing.title',
    defaultMessage: 'Pause mix',
  },
});

function mapStateToProps(state, ownProps) {
  const slug = ownProps.params.slug;
  return {
    playlist: selectMix(state, slug),
    mix: selectMix(state, slug),

    collectionIds: selectCollectionIds(state),
    imageAnnotation: selectMixImageAnnotation(state, slug),
    pathname: ownProps.location.pathname,
    messages,
  };
}

function getCollectibleEntityDescription(props) {
  return {
    active: false,
    id: props.mix.id,
    trackingSource: 'Mix',
  };
}

function mapQueueOriginStateToProps(state, ownProps) {
  const { slug, tracks, trackIds } = ownProps.mix;
  return {
    originId: slug,

    tracks,
    trackIds,

    isPlaying: selectMixIsPlaying(state, slug),
    isQueued: selectMixIsQueued(state, slug),
  };
}

function getTrackingContext(props) {
  return {
    contextType: 'mix',
    contextId: props.mix.slug,
  };
}

function getEntityTrackingData(props) {
  return {
    itemType: 'Album',
    itemId: props.mix.slug,
  };
}

function getMetaInformation(props) {
  return {
    'og:image': `${props.playlist.imageUrl}?auto=format&dpr=1&crop=faces&fit=crop&w=1200&h=630`,
  };
}

// Adds a proper displayName used for tracking
const Mix = props => (
  <GenericTrackListView entityType={ENTITY_TYPE_MIX} hideDownloadButton {...props} />
);

Mix.onDataFetch = (store, params, location) => {
  if (!__CAPACITOR__ || !location.query || !location.query.trackId) {
    return;
  }
  // In capacitor we may have deeplinks that request a track to be played upon startup.
  const state = store.getState();
  const mixSlug = params.slug;
  const requestedTrack = location.query && location.query.trackId;

  const playlist = selectMix(state, mixSlug);

  store.dispatch(playerActions.rewind());

  const tracks = playlist.trackIds.map(playerActions.createItem);

  store.dispatch(
    playerActions.setQueueAndPlay(
      getMixQueueOrigin(mixSlug),
      tracks,
      playerActions.getItemById(requestedTrack, tracks)
    )
  );
};

export default compose(
  protectedComponent({ authenticatedOnly: true }, true),
  dataComponent(fetchData),
  connect(mapStateToProps, {
    showAddToPlaylistModal,
  }),
  collectibleEntity(ENTITY_TYPE_MIX, getCollectibleEntityDescription),
  playlistableEntity({
    getEntityTrackingData,
    getTrackingContext,
    entityType: ENTITY_TYPE_MIX,
  }),
  queueOriginComponent(getMixQueueOrigin, mapQueueOriginStateToProps, getTrackingContext),
  injectIntl,
  metaInjector(getMetaInformation)
)(Mix);
