export const selectSoloistsIsLoading = state => state.lists.soloists.loading;

export const selectSoloistsCount = state => state.lists.soloists.meta.count;

export const selectNextSoloistsCursor = state => {
  const cursor = state.lists.soloists.meta.cursor;
  if (cursor) {
    return cursor.next;
  }
  return null;
};

export const selectPrevSoloistsCursor = state => {
  const cursor = state.lists.soloists.meta.cursor;
  if (cursor) {
    return cursor.prev;
  }
  return null;
};
