import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import { compact } from 'lodash';

import Separated from '../util/Separated';

const messages = defineMessages({
  'composer': {
    id: 'profile.functions.composer',
    defaultMessage: 'composer',
  },
  'soloist': {
    id: 'profile.functions.soloist',
    defaultMessage: 'soloist',
  },
  'conductor': {
    id: 'profile.functions.conductor',
    defaultMessage: 'conductor',
  },
  'librettist': {
    id: 'profile.functions.librettist',
    defaultMessage: 'librettist',
  },
  'transcription': {
    id: 'profile.functions.transcription',
    defaultMessage: 'transcription',
  },
  'text': {
    id: 'profile.functions.text',
    defaultMessage: 'text',
  },
  'translation': {
    id: 'profile.functions.translation',
    defaultMessage: 'translation',
  },
  'compilation': {
    id: 'profile.functions.compilation',
    defaultMessage: 'compilation',
  },
  'arrangement': {
    id: 'profile.functions.arrangement',
    defaultMessage: 'arrangement',
  },
  'completion': {
    id: 'profile.functions.completion',
    defaultMessage: 'completion',
  },
  'choir': {
    id: 'profile.functions.choir',
    defaultMessage: 'choir',
  },
  'orchestra': {
    id: 'profile.functions.orchestra',
    defaultMessage: 'orchestra',
  },
  'instrumental-ensemble': {
    id: 'profile.functions.instrumental-ensemble',
    defaultMessage: 'instrumental ensemble',
  },
  'chamber-music-ensemble': {
    id: 'profile.functions.chamber-music-ensemble',
    defaultMessage: 'chamber music ensemble',
  },
  'string-quartet': {
    id: 'profile.functions.string-quartet',
    defaultMessage: 'string quartet',
  },
  'piano-trio': {
    id: 'profile.functions.piano-trio',
    defaultMessage: 'piano trio',
  },
  'string-trio': {
    id: 'profile.functions.string-trio',
    defaultMessage: 'string trio',
  },
  'brass-ensemble': {
    id: 'profile.functions.brass-ensemble',
    defaultMessage: 'brass ensemble',
  },
  'vocal-ensemble': {
    id: 'profile.functions.vocal-ensemble',
    defaultMessage: 'vocal ensemble',
  },
});

class ArtistFunctions extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    functions: PropTypes.array,
    intl: intlShape.isRequired,
  };

  getFormattedMessage = key => {
    const { formatMessage } = this.props.intl;
    return messages[key] ? formatMessage(messages[key]) : key;
  };

  render() {
    const { functions, className } = this.props;
    if (!functions || !(functions instanceof Array)) {
      return null;
    }

    return (
      <div className={className}>
        <Separated>{compact(functions).map(this.getFormattedMessage)}</Separated>
      </div>
    );
  }
}

export default compose(injectIntl)(ArtistFunctions);
