export default {
  2476: [
    { id: 11075621, name: 'Hildegard von Bingen' },
    { id: 19443034, name: 'Léonin' },
    { id: 16104085, name: 'Walther von der Vogelweide' },
    { id: 4291682, name: 'Guillaume de Machaut' },
    { id: 10512415, name: 'Gilles Binchois' },
    { id: 66977, name: 'Guillaume Dufay' },
    { id: 14928112, name: 'John Dunstable' },
    { id: 15695406, name: 'Oswald von Wolkenstein' },
  ],
  2482: [
    { id: 66866, name: 'Johannes Ockeghem' },
    { id: 86862, name: 'Josquin des Prez' },
    { id: 86919, name: 'Giovanni Palestrina' },
    { id: 67957, name: 'Thomas Tallis' },
    { id: 86802, name: 'Tómas Luis de Victoria' },
    { id: 86897, name: 'Orlando di Lasso' },
    { id: 67085, name: 'William Byrd' },
    { id: 86885, name: 'Carlo Gesualdo' },
  ],
  2488: [
    { id: 61217, name: 'Johann Sebastian Bach' },
    { id: 62314, name: 'Antonio Vivaldi' },
    { id: 61214, name: 'George Frideric Handel' },
    { id: 63122, name: 'Claudio Monteverdi' },
    { id: 63147, name: 'Georg Pachelbel' },
    { id: 66939, name: 'Barbara Strozzi ' },
    { id: 63281, name: 'Domenico Scarlatti' },
    { id: 61210, name: 'Jean-Philippe Rameau' },
  ],
  2494: [
    { id: 61259, name: 'Ludwig van Beethoven' },
    { id: 61251, name: 'Wolfgang Amadeus Mozart ' },
    { id: 61240, name: 'Joseph Haydn ' },
    { id: 61590, name: 'Luigi Boccherini' },
    { id: 64322, name: 'Antonio Salieri' },
    { id: 61233, name: 'Christoph Willibald Gluck' },
    { id: 61230, name: 'Carl Philipp Emanuel Bach' },
    { id: 62902, name: 'Johann Nepomuk Hummel' },
  ],
  802: [
    { id: 61329, name: 'Richard Wagner' },
    { id: 61312, name: 'Frédéric Chopin' },
    { id: 86375, name: 'Robert Schumann' },
    { id: 86371, name: 'Franz Schubert' },
    { id: 86378, name: 'Giuseppe Verdi' },
    { id: 61378, name: 'Pyotr Ilyich Tchaikovsky' },
    { id: 61385, name: 'Antonín Dvořák' },
    { id: 61368, name: 'Johannes Brahms' },
    { id: 61389, name: 'Edvard Grieg' },
    { id: 61305, name: 'Hector Berlioz' },
    { id: 61309, name: 'Felix Mendelssohn Bartholdy' },
    { id: 64389, name: 'Clara Schumann (Wieck)' },
  ],
  2512: [
    { id: 1165227, name: 'Igor Stravinsky' },
    { id: 61437, name: 'Arnold Schoenberg' },
    { id: 86973, name: 'Dmitri Shostakovich' },
    { id: 62437, name: 'Grażyna Bacewicz' },
    { id: 11762442, name: 'Rebecca Clarke' },
    { id: 61631, name: 'Benjamin Britten ' },
    { id: 86462, name: 'Leoš Janáček' },
    { id: 86963, name: 'Sergei Rachmaninoff' },
    { id: 61727, name: 'Edward Elgar' },
    { id: 86451, name: 'George Gershwin' },
    { id: 61400, name: 'Gustav Mahler' },
    { id: 1167527, name: 'Richard Strauss' },
  ],
  2518: [
    { id: 86806, name: 'John Adams' },
    { id: 64289, name: 'Wolfgang Rihm' },
    { id: 67540, name: 'Kaija Saariaho' },
    { id: 66579, name: 'Jörg Widmann' },
    { id: 87036, name: 'Sofia Gubaidulina' },
    { id: 66539, name: 'George Benjamin' },
    { id: 64233, name: 'Arvo Pärt' },
    { id: 12627202, name: 'Judith Weir' },
  ],
};
