import React, { Component } from 'react';
import Helmet from 'react-helmet';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

export default function metaInjector(getMetaInformation, options = {}) {
  const { withRef = false } = options;

  return WrappedComponent => {
    class MetaInjector extends Component {
      static renderMetaTag(attribute, value) {
        if (attribute === 'description') {
          return [<meta key="description" property="description" content={value} />];
        }

        if (attribute === 'title') {
          return (
            <title key="title" property="title" content={value}>
              {value}
            </title>
          );
        }

        if (attribute === 'og:image') {
          return [
            <meta key="og:image" property="og:image" content={value} />,
            <meta key="og:image:width" property="og:image:width" content="1200" />,
            <meta key="og:image:height" property="og:image:height" content="630" />,
            <meta key="twitter:image" name="twitter:image" content={value} />,
          ];
        }

        if (attribute === 'og:title') {
          return [
            <meta key="og:title" property="og:title" content={value} />,
            <meta key="twitter:title" name="twitter:title" content={value} />,
          ];
        }

        if (attribute === 'og:description') {
          return [
            <meta key="og:description" property="og:description" content={value} />,
            <meta key="twitter:description" name="twitter:description" content={value} />,
          ];
        }

        return [<meta key={attribute} property={attribute} content={value} />];
      }

      renderMetaTags() {
        const metaInformation = getMetaInformation(this.props);
        // Fallback image for sharing
        if (!metaInformation['og:image']) {
          metaInformation['og:image'] = MetaInjector.ogImageDefaultUrl;
        }

        if (!metaInformation['og:title'] && metaInformation.title) {
          metaInformation['og:title'] = metaInformation.title;
        }

        if (!metaInformation['og:description'] && metaInformation.description) {
          metaInformation['og:description'] = metaInformation.description;
        }

        let metaTags = [];
        Object.entries(metaInformation).forEach(pair => {
          metaTags = metaTags.concat(MetaInjector.renderMetaTag(...pair));
        });

        return <Helmet>{metaTags}</Helmet>;
      }

      render() {
        return (
          <React.Fragment>
            {this.renderMetaTags()}
            <WrappedComponent {...this.props} ref={withRef ? 'wrappedInstance' : null} />
          </React.Fragment>
        );
      }
      static ogImageDefaultUrl =
        'https://d21as4hugkzy6u.cloudfront.net/images/opengraph/og-image.jpg?r=1';
    }

    MetaInjector.displayName = `MetaInjector(${getDisplayName(WrappedComponent)}`;

    MetaInjector.WrappedComponent = WrappedComponent;

    return MetaInjector;
  };
}
