export const selectConductorsIsLoading = state => state.lists.conductors.loading;

export const selectConductorsCount = state => state.lists.conductors.meta.count;

export const selectNextConductorsCursor = state => {
  const cursor = state.lists.conductors.meta.cursor;
  if (cursor) {
    return cursor.next;
  }
  return null;
};

export const selectPrevConductorsCursor = state => {
  const cursor = state.lists.conductors.meta.cursor;
  if (cursor) {
    return cursor.prev;
  }
  return null;
};
