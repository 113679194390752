import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import CapacitorRipple from '../capacitor/Ripple';
import StickyNavigation from '../common/StickyNavigation';
import styles from '../common/StickyNavigation.css';

export default class ProfileNavigation extends PureComponent {
  static propTypes = {
    slug: PropTypes.string.isRequired,
    profileIsComposer: PropTypes.bool.isRequired,
    profileHasPlaylists: PropTypes.bool.isRequired,
    shouldShowAboutTab: PropTypes.bool.isRequired,
  };

  render() {
    const { slug, profileIsComposer, profileHasPlaylists, shouldShowAboutTab } = this.props;
    return (
      <StickyNavigation>
        {shouldShowAboutTab && (
          <Link
            to={`/profiles/${slug}/about`}
            className={styles.link}
            activeClassName={styles.linkIsActive}
            onlyActiveOnIndex
          >
            <CapacitorRipple />
            <FormattedMessage id="profile.nav.about" defaultMessage="About" />
          </Link>
        )}
        <Link
          to={`/profiles/${slug}/albums`}
          className={styles.link}
          activeClassName={styles.linkIsActive}
          onlyActiveOnIndex={false}
          data-test="profile.albums"
        >
          <CapacitorRipple />
          <FormattedMessage id="profile.nav.albums" defaultMessage="Albums" />
        </Link>
        {profileHasPlaylists && (
          <Link
            to={`/profiles/${slug}/playlists`}
            className={styles.link}
            activeClassName={styles.linkIsActive}
            onlyActiveOnIndex={false}
            data-test="profile.playlists"
          >
            <CapacitorRipple />
            <FormattedMessage id="profile.nav.playlists" defaultMessage="Playlists" />
          </Link>
        )}
        {profileIsComposer && (
          <Link
            to={`/profiles/${slug}/works`}
            className={styles.link}
            activeClassName={styles.linkIsActive}
            onlyActiveOnIndex={false}
          >
            <CapacitorRipple />
            <FormattedMessage id="profile.nav.works" defaultMessage="Works" />
          </Link>
        )}
        <Link
          to={`/profiles/${slug}/recordings`}
          className={styles.link}
          activeClassName={styles.linkIsActive}
          onlyActiveOnIndex={false}
          data-test="profile.recordings"
        >
          <CapacitorRipple />
          <FormattedMessage id="profile.nav.recordings" defaultMessage="Recordings" />
        </Link>
      </StickyNavigation>
    );
  }
}
