import React, { Component } from 'react';
import { defineMessages, intlShape, injectIntl } from 'react-intl';

const messages = defineMessages({
  geoblockedTitle: {
    id: 'error.geoblocked-content.title',
    defaultMessage: 'Content not available',
  },
  geoblockedBody: {
    id: 'error.geoblocked-content.body',
    defaultMessage:
      'Due to legal constraints, this content is unfortunately not available in your region.',
  },
});

class GeoblockedContent extends Component {
  static propTypes = {
    intl: intlShape,
  };

  render() {
    const { intl } = this.props;
    const title = intl.formatMessage(messages.geoblockedTitle);

    return (
      <div className="u-page-container" role="main" aria-label={title}>
        <h1>{title}</h1>
        <p>{intl.formatMessage(messages.geoblockedBody)}</p>
      </div>
    );
  }
}

export default injectIntl(GeoblockedContent);
