// @flow
import React from 'react';
import styles from './Slider.css';
import classnames from 'classnames';
import IconLabel from '../util/IconLabel';

type OwnProps = {
  onNextClick: Function,
  onPrevClick: Function,
  canGoBack: boolean,
  canGoForward: boolean,
};

type Props = OwnProps;

const SliderControls = ({ onNextClick, onPrevClick, canGoBack, canGoForward }: Props) => (
  <div className={styles.sliderControls}>
    <button
      className={classnames(styles.prevSliderButton, !canGoBack && styles.inactive)}
      onClick={onPrevClick}
    >
      <IconLabel name="chevron-left" className={styles.buttonIcon} title="Previous" />
    </button>
    <button
      className={classnames(styles.nextSliderButton, !canGoForward && styles.inactive)}
      onClick={onNextClick}
    >
      <IconLabel name="chevron-right" className={styles.buttonIcon} title="Next" />
    </button>
  </div>
);

export default SliderControls;
