// @flow
import { loaded } from '../middleware/apiCacheValidators';
import { normalizeMetaListToItem } from '../schema/normalizeMeta';
import type { Request } from './types';

export type LoadRecentlyPlayedAction = {
  type: 'FETCH_RECENTLY_PLAYED',
} & Request;
export type RecentlyPlayedAction = LoadRecentlyPlayedAction;

export function loadRecentlyPlayed(): LoadRecentlyPlayedAction {
  return {
    type: 'FETCH_RECENTLY_PLAYED',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/recently-played/items.v1',
    },
    meta: {
      restricted: true,
      normalize: normalizeMetaListToItem,
    },
    cache: {
      fetch: state => state.recentlyPlayed.entities,
      validate: loaded,
    },
  };
}
