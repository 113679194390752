// @flow
declare var __CAPACITOR__: boolean;
import { motion } from 'framer-motion';
import React from 'react';
import CapacitorRipple from '../capacitor/Ripple';
import IconLabel from '../util/IconLabel';

import styles from './FacetGroupMenuTitle.css';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

type OwnProps = {
  getTriggerText: Function,
  isActive?: boolean,
  isDesktop?: boolean,
  type: string,
};

type Props = OwnProps & { intl: IntlShape };

const FacetGroupMenuTitle = ({ getTriggerText, isActive, isDesktop, intl, type }: Props) => {
  const triggerClassNames = classNames(styles.trigger, {
    [styles.isActive]: isActive,
  });

  return (
    <div className={triggerClassNames}>
      <CapacitorRipple version="center" />
      {getTriggerText(intl, type)}
      {isDesktop ? (
        <motion.div animate={{ scaleY: isActive ? -1 : 1 }} transition={{ duration: 0.3 }}>
          <IconLabel size="default" name="chevron-down" />
        </motion.div>
      ) : (
        <IconLabel size="default" name="chevron-right" />
      )}
    </div>
  );
};

export default injectIntl(FacetGroupMenuTitle);
