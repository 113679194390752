import React, { Component } from 'react';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

export default function chromeComponent(chrome, options = {}) {
  const { withRef = false } = options;

  return WrappedComponent => {
    class ChromeComponent extends Component {
      render() {
        return <WrappedComponent {...this.props} ref={withRef ? 'wrappedInstance' : null} />;
      }
    }

    ChromeComponent.displayName = `ChromeComponent(${getDisplayName(WrappedComponent)}`;

    ChromeComponent.WrappedComponent = WrappedComponent;

    ChromeComponent.chrome = chrome;

    return ChromeComponent;
  };
}
