// @flow
declare var __ELECTRON__: boolean; // eslint-disable-line

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import enhanceWithClickOutside from 'react-click-outside';
import classnames from 'classnames';
import type { intlShape } from 'react-intl';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import List from '../util/List';
import IconLabel from '../util/IconLabel';
import Feature from '../util/Feature';
import Group from './SonosGroup';

import { closePlayerExternalDeviceDialog, openPlayerExternalDeviceDialog } from '../../actions/ui';
import { selectPlayerExternalDeviceDialogIsOpen } from '../../selectors/client';

import type { SonosGroup } from './SonosGroup';
import type { PickSonosGroup } from '../../actions/sonos';

import {
  LANGUAGE_SUPPORT_NO_DEVICES_LINKS as supportLinks,
  CONSTANT_MESSAGES,
} from '../../constants';

import styles from './SonosGroups.css';

export type SonosGroupsArray = Array<SonosGroup>;
type Props = {
  sonosGroups: SonosGroupsArray,
  pickGroup: PickSonosGroup,
  connectedSonosGroup: string,
  intl: intlShape,

  isOpen: Boolean,
  open: Function,
  close: Function,
};

type State = { sonosIsOpen: boolean };

function getSonosSupportLink(locale) {
  const language = locale.split('-')[0];
  return supportLinks[language] ? supportLinks[language] : supportLinks.en;
}

const messages = defineMessages({
  learnMore: {
    id: 'sonos.groups.learn-more',
    defaultMessage: 'Learn More',
  },
});

class SonosGroupsComponent extends PureComponent<Props, State> {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    open: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (__ELECTRON__) {
      window.require('electron').ipcRenderer.send('sonos-discover');
    }
  }

  componentDidUpdate(prevProps) {
    if (__ELECTRON__) {
      if (!prevProps.isOpen && this.props.isOpen) {
        window.require('electron').ipcRenderer.send('sonos-force-discover');
      }
    }
  }

  onClick = () => {
    if (this.props.isOpen) {
      this.props.close();
    } else {
      this.props.open();
    }
  };

  renderNotAllowedGroup = (group: SonosGroup) => {
    const { connectedSonosGroup, pickGroup } = this.props;
    return (
      <Group
        key={group.groupId + group.groupName}
        onClick={pickGroup}
        group={group}
        isConnected={connectedSonosGroup === group.groupId}
        allowed={false}
      />
    );
  };

  renderGroup = (group: SonosGroup) => {
    const { connectedSonosGroup, pickGroup } = this.props;
    return (
      <Group
        key={group.groupId + group.groupName}
        onClick={pickGroup}
        group={group}
        isConnected={connectedSonosGroup === group.groupId}
      />
    );
  };

  renderHeader() {
    return (
      <div className={styles.header}>
        {this.props.sonosGroups.length > 1 ? (
          <FormattedMessage
            id="sonos.groups.connect-to-device"
            defaultMessage="Connect to Device"
          />
        ) : (
          <FormattedMessage id="sonos.groups.no-devices" defaultMessage="No devices available" />
        )}
      </div>
    );
  }

  renderBody(allowed) {
    const { sonosGroups, intl } = this.props;

    if (sonosGroups.length > 1) {
      return (
        <List
          items={sonosGroups}
          renderItem={allowed ? this.renderGroup : this.renderNotAllowedGroup}
        />
      );
    }

    const text = intl.formatMessage(messages.learnMore);
    const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

    return (
      <div className={styles.emptyBox}>
        <p className={styles.emptyParagraph}>
          <FormattedMessage
            id="sonos.groups.no-devices-info"
            defaultMessage="There are currently no Sonos speakers available to connect to."
          />
        </p>
        <p className={styles.emptyParagraph}>
          <FormattedMessage
            id="sonos.groups.dont-have-sonos"
            defaultMessage="Don't have Sonos? We're working on support for other devices."
          />
        </p>
        <a
          className="c-btn c-btn--has-outline-is-white"
          href={getSonosSupportLink(this.props.intl.locale)}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={ariaLink}
        >
          {text}
        </a>
      </div>
    );
  }

  render() {
    const componentClassNames = classnames(styles.component, {
      [styles.isVisible]: this.props.isOpen,
    });

    return (
      <div>
        <div className={componentClassNames}>
          <Feature id="streaming_to_all_external_players">
            {allowed => (
              <React.Fragment>
                {this.renderHeader()}
                <hr className={styles.hr} />
                {this.renderBody(allowed)}
              </React.Fragment>
            )}
          </Feature>
        </div>
        <IconLabel
          name="devices"
          className={styles.button}
          title="Remote devices"
          onClick={this.onClick}
        />
      </div>
    );
  }

  handleClickOutside = () => {
    if (this.props.isOpen) {
      this.props.close();
    }
  };
}

function mapStateToProps(state) {
  return {
    isOpen: selectPlayerExternalDeviceDialogIsOpen(state),
  };
}

export default compose(
  connect(mapStateToProps, {
    open: openPlayerExternalDeviceDialog,
    close: closePlayerExternalDeviceDialog,
  }),
  injectIntl,
  enhanceWithClickOutside
)(SonosGroupsComponent);
