import PropTypes from 'prop-types';

import { Location, RecordingVenue, RecordingDate } from './index';

export const LiveCompareRecordingShape = PropTypes.shape({
  albumSlug: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  performerSummary: PropTypes.string.isRequired,
  recordingId: PropTypes.number.isRequired,
  trackId: PropTypes.number.isRequired,
  location: Location,
  venue: RecordingVenue,
  recordingDate: RecordingDate,
});

export const LiveCompareComposerShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
});

export const LiveCompareWorkShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
});

export const LiveComparePieceShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
});
