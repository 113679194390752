import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeAtIndex } from '../utils/array';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

const defaultGetEntity = props => props.playlist;

function defaultGetTrackIds(props) {
  return props.playlist.tracks.map(({ id }) => id);
}

export const editableEntityPropTypes = {
  isEditable: PropTypes.bool,
  playlist: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDeleteTrack: PropTypes.func,
  onReorder: PropTypes.func,
};

export default function editableEntity(
  actions,
  getIsEditable,
  getEntity = defaultGetEntity,
  getTrackIds = defaultGetTrackIds,
  options = {}
) {
  const { withRef = false } = options;

  return WrappedComponent => {
    class Editable extends Component {
      static propTypes = editableEntityPropTypes;

      onEdit = () => {
        this.props.onEdit(defaultGetEntity(this.props));
      };

      onDelete = () => {
        this.props.onDelete(defaultGetEntity(this.props));
      };

      onDeleteTrack = (track, index) => {
        const playlist = getEntity(this.props);
        const trackIds = getTrackIds(this.props);
        const updatedTrackIds = removeAtIndex(trackIds, index);
        const action = {
          type: 'remove',
          trackId: track.id,
          index,
          trackIds: updatedTrackIds,
        };

        this.props.onDeleteTrack(playlist, action);
      };

      onReorder = (updatedTrackIds, change) => {
        const playlist = getEntity(this.props);
        const action = {
          type: 'move',
          trackIds: updatedTrackIds,
          ...change, // from, to, trackId
        };
        this.props.onReorder(playlist, action);
      };

      render() {
        // only is editable if has all actions and has `isEditable`
        // from mapStateToProps
        const isEditable =
          !!actions.onEdit &&
          !!actions.onDelete &&
          !!actions.onDeleteTrack &&
          !!actions.onReorder &&
          this.props.isEditable; // <- comes from mapStateToProps

        return (
          <WrappedComponent
            {...this.props}
            ref={withRef ? 'wrappedInstance' : null}
            isEditable={isEditable}
            onEdit={this.onEdit}
            onDelete={this.onDelete}
            onDeleteTrack={this.onDeleteTrack}
            onReorder={this.onReorder}
          />
        );
      }
    }

    Editable.displayName = `Editable(${getDisplayName(WrappedComponent)}`;

    Editable.WrappedComponent = WrappedComponent;

    return connect(
      (state, ownProps) => ({
        isEditable: getIsEditable(state, getEntity(ownProps)),
      }),
      {
        onEdit: actions.onEdit,
        onDelete: actions.onDelete,
        onDeleteTrack: actions.onDeleteTrack,
        onReorder: actions.onReorder,
      }
    )(Editable);
  };
}
