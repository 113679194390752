// @flow
import React from 'react';

import CourseSession from './CourseSession';
import type { CourseSession as CourseSessionProps } from '../../shapes/types';
import { courseSessionDecorator } from '../../selectors/livestreamEvents';
import styles from './CourseSessions.css';

type OwnProps = {
  sessions: Array<CourseSessionProps>,
  slug: string,
  userHasAccess: boolean,
  location: Object,
};

type Props = OwnProps;

const CourseSessions = ({ sessions, slug, userHasAccess, location }: Props) => {
  const courseSessions = sessions || [];
  if (!courseSessions.length) {
    return null;
  }

  return (
    <section className={styles.wrapper}>
      {courseSessions.map((session, index) => {
        return (
          <CourseSession
            key={index}
            session={courseSessionDecorator(session, index)}
            slug={slug}
            userHasAccess={userHasAccess}
            location={location}
          />
        );
      })}
    </section>
  );
};

export default CourseSessions;
