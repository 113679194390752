import React, { PureComponent } from 'react';
import styles from './PlayerInfo.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, routerShape, Link } from 'react-router';
import { injectIntl, intlShape, defineMessages } from 'react-intl';

import * as uiActions from '../../actions/ui';
import * as adActions from '../../actions/ads';

import { CONSTANT_MESSAGES } from '../../constants';

const IDAGIO_PROTOCOL = 'idagio:';
const IDAGIO_HOSTNAME = 'app.idagio.com';

const messages = defineMessages({
  adInfoLink: {
    id: 'aria-labels-ad-info',
    defaultMessage: 'Advertisement info',
  },
});

class PlayerInfoAd extends PureComponent {
  static propTypes = {
    ad: PropTypes.object.isRequired,
    clickedAd: PropTypes.func.isRequired,

    showModal: PropTypes.func.isRequired,
    router: routerShape.isRequired,

    intl: intlShape,
  };

  onClickedAd = () => {
    this.props.clickedAd(this.props.ad);
  };

  onClickedUniversalLink = () => {
    const { ad } = this.props;

    // track it
    this.onClickedAd(ad);

    // open it
    const url = new URL(ad.link);
    this.handleInternalUrl(url);
  };

  renderInfo(ad) {
    const { description, title } = ad;
    return (
      <React.Fragment>
        <div className={styles.infoEl}>
          {title} · {description}
        </div>
        <div className={styles.recordingInfo}>{ad.subtitle}</div>
      </React.Fragment>
    );
  }

  render() {
    const { ad, intl } = this.props;
    const { description, link } = ad;

    const text = intl.formatMessage(messages.adInfoLink);
    const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

    const url = new URL(link);
    if (url.protocol === IDAGIO_PROTOCOL) {
      return (
        <a className={styles.info} title={description} onClick={this.onClickedUniversalLink}>
          {this.renderInfo(ad)}
        </a>
      );
    }

    if (url.hostname === IDAGIO_HOSTNAME) {
      return (
        <Link
          className={styles.info}
          onlyActiveOnIndex={false}
          to={url.pathname}
          onClick={this.onClickedAd}
        >
          {this.renderInfo(ad)}
        </Link>
      );
    }

    return (
      <a
        className={styles.info}
        href={link}
        title={description}
        target="_blank"
        rel="noopener noreferrer"
        onClick={this.onClickedAd}
        aria-label={ariaLink}
      >
        {this.renderInfo(ad)}
      </a>
    );
  }

  handleInternalUrl = url => {
    const { showModal, router } = this.props;
    const pathname = url.pathname;
    const split = pathname.split('/').filter(i => i);
    if (!split.length) {
      throw new Error('Bad pathname');
    }
    const base = split[0];
    switch (base) {
      case 'subscribe':
        showModal('SUBSCRIBE_MODAL', { trigger: 'universalLinkFromAd' });
        break;

      case 'playlists': {
        if (!base[1]) {
          throw new Error('No id provided for playlists');
        }
        const playlistId = split[1];
        router.push(`/playlists/${playlistId}`);
        break;
      }

      default:
        throw new Error('Unhandled internal url');
    }
  };
}

export default compose(
  withRouter,
  connect(() => ({}), {
    showModal: uiActions.showModal,
    clickedAd: adActions.clickedAd,
  }),
  injectIntl
)(PlayerInfoAd);
