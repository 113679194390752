import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { getSDK } from '../../../utils/dom';
import classNames from 'classnames';
import styles from './VimeoVideoAdapter.css';

const SDK_URL = 'https://player.vimeo.com/api/player.js';
const SDK_GLOBAL = 'Vimeo';

class VimeoVideoAdaptor extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    showControls: PropTypes.bool.isRequired,
    autoPlay: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,

    onPlay: PropTypes.func.isRequired,
    onPause: PropTypes.func.isRequired,
    onEnded: PropTypes.func.isRequired,
    onProgressUpdate: PropTypes.func.isRequired,
  };

  componentDidMount = async () => {
    this.Vimeo = await getSDK(SDK_URL, SDK_GLOBAL);
    if (this.container) {
      this.attachPlayer();
    }
  };

  componentDidUpdate = prevProps => {
    if (!this.player) return;
    if (this.props.id !== prevProps.id) {
      this.attachPlayer();
    }
  };

  onPlay = async ({ seconds }) => {
    // A live stream might not start from zero
    this.currentTime = seconds;
    const data = await this.getEventData();
    this.props.onPlay(data, this.paused);
    this.paused = false;
  };

  onPause = async () => {
    this.paused = true;
    const data = await this.getEventData();
    this.props.onPause({
      ...data,
      accumulatedTime: this.accumulatedTime,
    });
  };

  onEnded = async () => {
    const data = await this.getEventData();
    this.props.onEnded({
      ...data,
      accumulatedTime: this.accumulatedTime,
    });
    this.paused = false;
  };

  onTimeupdate = ({ seconds, duration }) => {
    const delta = Math.abs(this.currentTime - seconds);
    this.currentTime = seconds;
    this.accumulatedTime += delta;
    this.props.onProgressUpdate({
      delta,
      currentTime: this.currentTime,
      duration,
      videoProvider: 'vimeo',
    });
  };

  onSeeked = ({ seconds }) => {
    this.currentTime = seconds;
  };

  getEventData = async () => {
    return {
      currentTime: this.currentTime,
      duration: await this.player.getDuration(),
    };
  };

  render() {
    const { className, id } = this.props;
    return <div ref={this.ref} className={classNames(className, styles.video)} key={id} />;
  }

  async attachPlayer() {
    const { id, autoPlay, showControls } = this.props;
    const player = new this.Vimeo.Player(this.container, {
      id,
      autoplay: autoPlay,
      controls: showControls,
      responsive: true,
    });

    // Unfortunately because the Vimeo player internally relies on window.location.origin being 'https://app.idagio.com'
    // to send events with Window.postMessage, these events will never fire in electron where the origin is 'file://'
    player.on('play', this.onPlay);
    player.on('pause', this.onPause);
    player.on('ended', this.onEnded);
    player.on('timeupdate', this.onTimeupdate);
    player.on('seeked', this.onSeeked);
    this.player = player;
  }

  ref = container => {
    this.container = container;
  };

  // Relative to start of video
  currentTime = 0;

  // Relative to when in time user started playback
  accumulatedTime = 0;

  // Used to decide if resume or not first play
  paused = false;
}

export default VimeoVideoAdaptor;
