// @flow

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import trackedViewComponent from '../hoc/trackedViewComponent';
import { loadDiscoverGroup } from '../actions/discover';
import * as analyticsActions from '../actions/analytics';
import CapacitorHeaderBar from '../components/capacitor/HeaderBar';

import { selectDiscoverGroupItems } from '../selectors/discover';

import List from '../components/util/List';

import styles from './DiscoverGroup.css';

import dataComponent from '../hoc/dataComponent';
import PlayableItemPreview from '../components/common/PlayableItemPreview';
import DiscoverCompactItem from '../components/discover/DiscoverCompactItem';
import {
  DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP,
  ENTITY_TYPE_DISCOVER_GROUP,
  LIVESTREAM_EVENT_DEFAULT_FILTER,
} from '../constants';
import DiscoverLivestreamEventItem from '../components/discover/DiscoverLivestreamEventItem';
import LivestreamEventsTitle from '../components/messages/LivestreamEventsTitle';
import type { DiscoverGroup as DiscoverGroupProps } from '../shapes/types';
import shareableEntity from '../hoc/shareableEntity';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  params: Object,
  location: Object,
};

type MapStateToProps = {
  discoverGroup: DiscoverGroupProps,
};

type DispatchProps = {
  analyticsTrack: Function,
  trackPlayPausePressed: Function,
};

type Props = MapStateToProps & DispatchProps & { showShareModal: Function };

const DiscoverGroup = ({
  discoverGroup,
  analyticsTrack,
  trackPlayPausePressed,
  showShareModal,
}: Props) => {
  const { name, subtitle, type, items } = discoverGroup;

  const onItemClick = ({ content }) => {
    analyticsTrack('Selected Content From Category', {
      id: content.id,
      type: content.type,
      category: discoverGroup.id,
      pageName: 'discover-category',
    });
  };

  const beforeTogglePlay = (item, playing) => {
    trackPlayPausePressed(
      {
        contextType: item.content.type,
        contextId: item.content.id,
        contextCategory: discoverGroup.id,
      },
      playing
    );
  };

  const renderDiscoverGroupItem = (item, index) => {
    return (
      <li className={styles.item} key={item.content.id + index}>
        <div className={styles.hiddenDesktop}>
          <DiscoverCompactItem
            item={item}
            onClick={onItemClick}
            beforeTogglePlay={beforeTogglePlay}
            showPlayButton
          />
        </div>
        <div className={styles.hiddenMobile}>
          <PlayableItemPreview
            item={item}
            onClick={onItemClick}
            beforeTogglePlay={beforeTogglePlay}
            showPlayButton
          />
        </div>
      </li>
    );
  };

  const renderLivestreamEventGroupItem = (item, index) => (
    <li className={styles.item} key={index}>
      <DiscoverLivestreamEventItem key={index} item={item} onClick={onItemClick} fullWidth />
    </li>
  );

  const isLivestreamEventGroup = type === DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP;

  return (
    <div>
      {__CAPACITOR__ && (
        <CapacitorHeaderBar
          title={isLivestreamEventGroup && <LivestreamEventsTitle />}
          showShareModal={showShareModal}
        />
      )}
      <div className={styles.header}>
        <h1 className="fz--beta">{name}</h1>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <List
        className={styles.list}
        items={items}
        renderItem={
          isLivestreamEventGroup ? renderLivestreamEventGroupItem : renderDiscoverGroupItem
        }
      />
    </div>
  );
};

function fetchData(store, { type, slug }, location) {
  const { query } = location;
  let filter;
  if (query) {
    filter = query.filter;
  }
  return store.dispatch(loadDiscoverGroup(type, slug, filter));
}

const getDiscoverGroup = (state: Object, params: Object, location: Object): DiscoverGroupProps => {
  const { type, slug } = params;
  const query = location.query;
  let filter = LIVESTREAM_EVENT_DEFAULT_FILTER;
  if (query && query.filter) {
    filter = query.filter;
  }

  return selectDiscoverGroupItems(state, type, slug, filter);
};

function mapStateToProps(state: Object, ownProps: OwnProps): MapStateToProps {
  const { params, location } = ownProps;
  return {
    discoverGroup: getDiscoverGroup(state, params, location),
  };
}

export const trackingProps = (state: Object, params: Object, location: Object): Object => {
  const { name, type, id } = getDiscoverGroup(state, params, location);
  return {
    group_name: name,
    group_type: type,
    group_id: id,
  };
};

const dispatchProps: DispatchProps = {
  trackPlayPausePressed: analyticsActions.trackPlayPausePressed,
  analyticsTrack: analyticsActions.track,
};

function getShareTrackingContext(props) {
  return {
    sharedContentType: 'DiscoverCategory',
    sharedContentId: props.discoverGroup.id,
    contextType: 'DiscoverCategory',
    contentId: props.discoverGroup.id,
  };
}

export default compose(
  trackedViewComponent('Discover Group', trackingProps),
  dataComponent(fetchData),
  connect(mapStateToProps, dispatchProps),
  shareableEntity(ENTITY_TYPE_DISCOVER_GROUP, getShareTrackingContext),
  withRouter // needs to be at end
)(DiscoverGroup);
