import React, { Component } from 'react';
import BrowseElementList from './BrowseElementList';
import BrowseListContainer from './BrowseListContainer';
import BrowseVerticalElementList from './BrowseVerticalElementList';
import stylesListGrid from './BrowseListGrid.css';
import soloists from './data/soloists';

import { defineMessages, injectIntl, intlShape } from 'react-intl';

const messages = defineMessages({
  conductors: {
    id: 'browse.performers.conductors',
    defaultMessage: 'Conductors',
  },
  soloists: {
    id: 'browse.performers.soloists',
    defaultMessage: 'Soloists',
  },
  ensembles: {
    id: 'browse.performers.ensembles',
    defaultMessage: 'Ensembles',
  },
  pianists: {
    id: 'browse.performers.pianists',
    defaultMessage: 'Pianists',
  },
  violinists: {
    id: 'browse.performers.violinists',
    defaultMessage: 'Violinists',
  },
  cellists: {
    id: 'browse.performers.cellists',
    defaultMessage: 'Cellists',
  },
  vocalists: {
    id: 'browse.performers.vocalists',
    defaultMessage: 'Vocalists',
  },
  orchestras: {
    id: 'browse.performers.orchestras',
    defaultMessage: 'Orchestras',
  },
  choirs: {
    id: 'browse.performers.choirs',
    defaultMessage: 'Choirs',
  },
  stringQuartets: {
    id: 'browse.performers.string-quartets',
    defaultMessage: 'String Quartets',
  },
  periodInstrumentEnsembles: {
    id: 'browse.performers.period-instrument-ensembles',
    defaultMessage: 'Period Instrument Ensembles',
  },
});

class BrowsePerformers extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  render() {
    const { formatMessage } = this.props.intl;

    const conductorsLink = '/conductors';
    const soloistsLink = '/soloists';
    const ensemblesLink = '/ensembles';

    return (
      <div>
        <BrowseListContainer
          title={formatMessage(messages.conductors)}
          link={conductorsLink}
          category="Performers"
        >
          <BrowseElementList items={soloists.conductors} />
        </BrowseListContainer>

        <BrowseListContainer
          title={formatMessage(messages.soloists)}
          link={soloistsLink}
          category="Performers"
        >
          <ul className={stylesListGrid.list}>
            <BrowseVerticalElementList
              items={soloists.pianists}
              title={formatMessage(messages.pianists)}
            />
            <BrowseVerticalElementList
              items={soloists.violinists}
              title={formatMessage(messages.violinists)}
            />
            <BrowseVerticalElementList
              items={soloists.cellists}
              title={formatMessage(messages.cellists)}
            />
            <BrowseVerticalElementList
              items={soloists.vocalists}
              title={formatMessage(messages.vocalists)}
            />
          </ul>
        </BrowseListContainer>

        <BrowseListContainer
          title={formatMessage(messages.ensembles)}
          link={ensemblesLink}
          category="Performers"
        >
          <ul className={stylesListGrid.list}>
            <BrowseVerticalElementList
              items={soloists.orchestras}
              title={formatMessage(messages.orchestras)}
            />
            <BrowseVerticalElementList
              items={soloists.choirs}
              title={formatMessage(messages.choirs)}
            />
            <BrowseVerticalElementList
              items={soloists.quartets}
              title={formatMessage(messages.stringQuartets)}
            />
            <BrowseVerticalElementList
              items={soloists.ensembles}
              title={formatMessage(messages.periodInstrumentEnsembles)}
            />
          </ul>
        </BrowseListContainer>
      </div>
    );
  }
}

export default injectIntl(BrowsePerformers);
