// @flow
import React from 'react';

import FacetGroupsOverlay from '../modals/FacetGroupsOverlay';
import FacetGroupsContainer from './FacetGroupsContainer';
import { useDesktopMediaQuery } from '../../utils/useMediaQuery';
import { getPluralMessage } from '../messages/FilterMessages';

type OwnProps = {
  facets: Array<any>,
  isVisible: boolean,
  onClose: Function,
  activeFacets: Array<any>,
  loadAndShowAllFacets: Function,
  location: Object,
  shouldRenderFacetGroup: Function,
  handleMultipleComposers?: boolean,
  isInApp: boolean,
};

const FacetGroups = ({
  facets,
  isVisible,
  onClose,
  activeFacets,
  loadAndShowAllFacets,
  location,
  shouldRenderFacetGroup,
  handleMultipleComposers,
  isInApp,
}: OwnProps) => {
  const showMobileView = !useDesktopMediaQuery() || isInApp;
  const Wrapper = showMobileView ? FacetGroupsOverlay : FacetGroupsContainer;

  const getTriggerText = (intl, type) => {
    const activeFacetsCount = activeFacets.filter(facet => facet.type === type).length;
    return `${getPluralMessage(intl, type)}${activeFacetsCount ? ` (${activeFacetsCount})` : ''}`;
  };

  return (
    <Wrapper
      isVisible={isVisible}
      onClose={onClose}
      activeFacets={activeFacets}
      location={location}
      onClickClear={onClose}
      facets={facets}
      loadAndShowAllFacets={loadAndShowAllFacets}
      getTriggerText={getTriggerText}
      shouldRenderFacetGroup={shouldRenderFacetGroup}
      handleMultipleComposers={handleMultipleComposers}
    />
  );
};

export default FacetGroups;
