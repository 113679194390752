import React, { Component } from 'react';
import styles from './SonosAuth.css';
import classnames from 'classnames';
import { PropTypes } from 'prop-types';

import SubscribeModalStepNumberText from '../messages/SubscribeModalStepNumberText';
import SubscribeModalContinueButtonText from '../messages/SubscribeModalContinueButtonText';

export default class SonosStep extends Component {
  static propTypes = {
    currentStep: PropTypes.number.isRequired,
    totalSteps: PropTypes.number.isRequired,
    children: PropTypes.array.isRequired,
    onButtonClick: PropTypes.func,
    shouldRenderButton: PropTypes.bool,
    iconName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    shouldRenderButton: true,
  };

  getIconClassName(iconName) {
    switch (iconName) {
      case 'connected':
        return styles.iconConnected;
      case 'platforms':
        return styles.iconPlatforms;
      case 'select-box':
        return styles.iconSelectBox;
      case 'subscribe-lock':
        return styles.iconSubscribeLock;
      default:
        return styles.iconSubscribeLock;
    }
  }

  renderIcon() {
    const { iconName } = this.props;
    const className = classnames(styles.stepIcon, this.getIconClassName(iconName));
    return (
      <div className={className}>
        <svg
          className={styles.iconSelectBoxIcon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 56 57"
        >
          <path d="M53.879 25.022v29.697H12.303v-7.637h33.09V15.688h-1.696v29.697H2.121V3.81h29.697V2.113H.424v44.97h10.182v9.333h44.97V25.022z" />
          <path d="M9.842 15.918l-1.154 1.238 12.456 11.625L45.25 1.544 43.985.424l-22.96 25.93z" />
        </svg>
        <svg
          className={styles.iconPlatformsIcon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 117 58"
        >
          <path d="M95.222 18A2.227 2.227 0 0 0 93 20.222V54.89c0 1.223.999 2.222 2.222 2.222h19.556A2.227 2.227 0 0 0 117 54.89V20.222A2.227 2.227 0 0 0 114.778 18H95.222zm.88 1.889h17.794c.679 0 1.213.367 1.213.835v.835h-20.22v-.835c0-.468.534-.835 1.213-.835zm-1.213 3.555h20.22v26.44h-20.22v-26.44zm0 28.334h20.22v2.618c0 .628-.534 1.122-1.213 1.122H96.102c-.679 0-1.213-.494-1.213-1.122v-2.618zM105 53.11a.889.889 0 1 0 0 1.778.889.889 0 0 0 0-1.778zM5.429 0A5.44 5.44 0 0 0 0 5.429v36.914a5.44 5.44 0 0 0 5.429 5.428h31.485v6.515H17.371a1.09 1.09 0 1 0 0 2.171H58.63a1.086 1.086 0 1 0 0-2.171H39.086V47.77H70.57A5.44 5.44 0 0 0 76 42.343V5.429A5.44 5.44 0 0 0 70.571 0H5.43zm0 2.171H70.57A3.226 3.226 0 0 1 73.83 5.43v36.914A3.226 3.226 0 0 1 70.57 45.6H5.43a3.226 3.226 0 0 1-3.258-3.257V5.429A3.226 3.226 0 0 1 5.43 2.17z" />
        </svg>
        <svg
          className={styles.iconSubscribeLockIcon}
          viewBox="0 0 43 55"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m43 19.92h-7.588v-5.692c0-7.683-6.229-13.912-13.912-13.912s-13.912 6.229-13.912 13.912v5.691h-7.588v34.147h43zm-33.882-6.029c0-6.653 5.542-12.046 12.38-12.046 6.837 0 12.38 5.393 12.38 12.046v6.024h-24.76zm32.351 38.649h-39.939v-31.09h39.94v31.09z" />
          <path d="m21.5 43.316a6.324 6.324 0 1 0 0-12.647 6.324 6.324 0 0 0 0 12.647zm0-11.117a4.795 4.795 0 1 1 0 9.59 4.795 4.795 0 0 1 0-9.59z" />
        </svg>
        <svg
          className={styles.iconConnectedIcon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 68 53"
        >
          <path d="M11.444 26.269c-3.43 1.98-4.434 6.492-2.566 9.728l-3.776 2.18a.883.883 0 0 0-.309 1.21l4.464 7.73a.883.883 0 0 0 1.201.337l3.777-2.18c1.868 3.235 6.276 4.623 9.707 2.642l12.745-7.358a.883.883 0 0 0 .309-1.21l-1.786-3.092 11.33-6.54a.868.868 0 0 0 .44-.767.911.911 0 0 0-.45-.779.868.868 0 0 0-.883 0l-11.33 6.54-6.248-10.824 11.328-6.54a.868.868 0 0 0 .442-.766.911.911 0 0 0-.45-.78.868.868 0 0 0-.884 0l-11.329 6.54-1.785-3.092a.883.883 0 0 0-1.202-.337l-12.745 7.358zm20.73-11.742a.888.888 0 0 0-.168 1.128l11.605 20.1a.883.883 0 0 0 1.202.338l12.745-7.359c3.43-1.98 4.433-6.492 2.566-9.727l3.776-2.18a.883.883 0 0 0 .309-1.21l-4.464-7.73a.883.883 0 0 0-1.201-.338l-3.777 2.18C52.9 6.494 48.49 5.107 45.06 7.087l-12.745 7.359a.852.852 0 0 0-.142.081zM12.336 27.815l11.99-6.922 1.743 3.02a.914.914 0 0 0 .042.072l7.142 12.37a.914.914 0 0 0 .055.097l1.73 2.996-11.99 6.922c-2.568 1.483-5.957.365-7.304-1.968l-.446-.773a.883.883 0 0 0-1.202-.337l-3.776 2.18-3.57-6.185 3.775-2.18a.883.883 0 0 0 .31-1.21l-.447-.772c-1.347-2.333-.621-5.827 1.948-7.31zm21.626-12.26l11.99-6.922c2.568-1.483 5.957-.364 7.304 1.968l.446.773a.883.883 0 0 0 1.202.338l3.776-2.18 3.57 6.184-3.775 2.18a.883.883 0 0 0-.31 1.21l.447.773c1.347 2.332.621 5.826-1.948 7.31l-11.99 6.921-10.712-18.554z" />
        </svg>
      </div>
    );
  }

  renderContinueButton() {
    const { shouldRenderButton, onButtonClick } = this.props;
    if (!shouldRenderButton) {
      return null;
    }
    const buttonClassName = classnames(styles.continueButton, 'c-btn c-btn--is-purple');
    return (
      <button className={buttonClassName} onClick={onButtonClick} data-test="sonos-step.continue">
        <span className="payment-form__button-text">
          <SubscribeModalContinueButtonText />
        </span>
      </button>
    );
  }

  renderStepText(step, totalSteps) {
    return (
      <div className={styles.stepText}>
        <SubscribeModalStepNumberText step={step} totalSteps={totalSteps} />
      </div>
    );
  }

  render() {
    const { currentStep, totalSteps } = this.props;

    return (
      <div className={styles.stepContainer} data-test={`sonos-step.${this.props.currentStep}`}>
        <div className={styles.iconContainer}>
          {this.renderIcon()}
          {this.renderStepText(currentStep, totalSteps)}
        </div>
        <div className={styles.heading}>{this.props.children}</div>
        {this.renderContinueButton()}
      </div>
    );
  }
}
