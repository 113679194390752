import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from '../util/Select';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import qs from 'qs';

const messages = defineMessages({
  alphabetical: {
    id: 'recording-sort.alphabetical',
    defaultMessage: 'a-z',
  },
  chronological: {
    id: 'recording-sort.chronological',
    defaultMessage: 'Date',
  },
  popular: {
    id: 'recording-sort.popularity',
    defaultMessage: 'popularity',
  },
  recent: {
    id: 'recording-sort.recent',
    defaultMessage: 'recently added',
  },
  relevance: {
    id: 'recording-sort.relevance',
    defaultMessage: 'relevance',
  },
  sort: {
    id: 'recording-sort.sort',
    defaultMessage: 'sort',
  },
});

class SortSelect extends Component {
  static propTypes = {
    sort: PropTypes.string,
    location: PropTypes.object,
    currentCursor: PropTypes.string,
    optionsContext: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    intl: intlShape,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const { optionsContext, sort } = this.props;
    const { formatMessage } = this.props.intl;
    const recordingsSortOptions = [
      {
        text: formatMessage(messages.popular),
        value: 'popular',
      },
      {
        text: formatMessage(messages.recent),
        value: 'recent',
      },
      {
        text: formatMessage(messages.chronological),
        value: 'chronological',
      },
    ];

    const albumsSortOptions = [
      {
        text: formatMessage(messages.relevance),
        value: 'relevance',
      },
      {
        text: formatMessage(messages.alphabetical),
        value: 'alphabetical',
      },
      {
        text: formatMessage(messages.chronological),
        value: 'publishDate',
      },
    ];
    const options = {
      recordings: recordingsSortOptions,
      albums: albumsSortOptions,
      works: [
        {
          text: formatMessage(messages.popular),
          value: 'popular',
        },
        {
          text: formatMessage(messages.alphabetical),
          value: 'alphabetical',
        },
      ],
    };
    return (
      <Select
        optionsDescriptor={formatMessage(messages.sort)}
        defaultOptionValue="chronological"
        currentOptionValue={sort}
        options={options[optionsContext]}
        onSelect={this.applySorting}
        isDisabled={this.props.isDisabled}
      />
    );
  }

  applySorting = sort => {
    const { currentCursor, location } = this.props;
    const query = {
      ...location.query,
      sort,
    };

    let { pathname } = location;
    if (currentCursor) {
      // Always make sure to remove the cursor when changing sorting
      pathname = pathname.replace(`/pages/${currentCursor}`, '');
    }
    const target = pathname + '?' + qs.stringify(query);
    this.context.router.push(target);
  };
}

export default injectIntl(SortSelect);
