// @flow
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { UI_THEME_DARK, UI_THEME_LIGHT, UI_THEME_AUTOMATIC, DEFAULT_THEME } from '../../constants';

import * as uiActions from '../../actions/ui';
import { selectTheme } from '../../selectors/client';
import SectionItem from './SectionItem';
import styles from './SectionItem.css';
import Select from '../util/Select';
import { compose } from 'redux';

const messages = defineMessages({
  automatic: {
    id: 'settings.app.theme.automatic.label-2',
    defaultMessage: 'Automatic',
  },
  light: {
    id: 'settings.app.theme.light.label-2',
    defaultMessage: 'Light Mode',
  },
  dark: {
    id: 'settings.app.theme.dark.label-2',
    defaultMessage: 'Dark Mode',
  },
});

const SUPPORTED_THEMES = [
  {
    title: messages.automatic,
    value: UI_THEME_AUTOMATIC,
  },
  {
    title: messages.light,
    value: UI_THEME_LIGHT,
  },
  {
    title: messages.dark,
    value: UI_THEME_DARK,
  },
];

type MapStateToProps = {
  currentTheme: string,
};

type DispatchProps = {
  setTheme: Function,
};

type Props = MapStateToProps & DispatchProps & { intl: IntlShape };

const Theme = ({ currentTheme, setTheme, intl }: Props) => {
  const options = SUPPORTED_THEMES.map(({ title, value }) => ({
    text: intl.formatMessage(title),
    value,
  }));

  return (
    <SectionItem
      title={<FormattedMessage id="settings.app.theme.title" defaultMessage="Appearance:" />}
    >
      <div className={styles.form}>
        <Select
          defaultOptionValue={DEFAULT_THEME}
          currentOptionValue={currentTheme}
          options={options}
          onSelect={setTheme}
          className={styles.select}
        />
      </div>
    </SectionItem>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    currentTheme: selectTheme(state),
  };
}

const dispatchProps: DispatchProps = {
  setTheme: uiActions.setTheme,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(Theme);
