import React from 'react';
import CapacitorRipple from '../capacitor/Ripple';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import styles from './PriceInfoTooltip.css';

const PriceInfoIcon = () => {
  return (
    <svg>
      <use xlinkHref="#icon-price-info" />
    </svg>
  );
};

const PriceInfoTooltip = () => {
  const id = 'price-info';
  const ref = React.useRef(null);
  const timeoutRef = React.useRef(null);

  const handleTriggerClick = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (__CAPACITOR__) {
      // For some reason, after we auto-hide the plugin, we need to manually
      // call the show method to actually show the tooltip again.
      ReactTooltip.show(ref.current);

      timeoutRef.current = setTimeout(() => {
        ReactTooltip.hide(ref.current);
      }, 5000);
    }
  };

  return (
    <span>
      <a className={styles.main} data-tip data-for={id} ref={ref} onClick={handleTriggerClick}>
        <CapacitorRipple />
        <PriceInfoIcon />
      </a>
      <ReactTooltip className={styles.tooltip} id={id} effect="solid">
        <FormattedMessage
          id="livestream-events.price-info-tooltip.text"
          defaultMessage="This event is pay-as-you-wish: you can choose to pay any price within the given price range."
        />
      </ReactTooltip>
    </span>
  );
};

export default PriceInfoTooltip;
