// @flow
import React from 'react';
import styles from './ActiveFacets.css';
import stylesFacet from './Facet.css';
import ClearAllActiveFacetsButton from './ClearAllActiveFacetsButton';
import { selectIsBoolean } from '../../selectors/facet';
import Facet from './Facet';

type OwnProps = {
  activeFacets: Array<any>,
  location: Object,
  onClickClear?: Function,
};

type Props = OwnProps;

const ActiveFacets = ({ activeFacets, location, onClickClear }: Props) => {
  if (!activeFacets.length) {
    return null;
  }

  const { pathname, query } = location;

  const FacetLineItem = ({ facet, type }: { facet: Object, type: string }) => {
    const isBoolean = selectIsBoolean(type);

    return (
      <li key={isBoolean ? type + facet.value : facet.id}>
        <Facet
          facet={facet}
          type={type}
          pathname={pathname}
          query={query}
          className={stylesFacet.isActiveFacetGrp}
          isBoolean={isBoolean}
        />
      </li>
    );
  };

  return (
    <ul className={styles.list} data-test="active-facets.list.container">
      {activeFacets.map(FacetLineItem)}
      <li>
        <ClearAllActiveFacetsButton pathname={pathname} query={query} onClickClear={onClickClear} />
      </li>
    </ul>
  );
};

export default ActiveFacets;
