// @flow
import React from 'react';
import CapacitorRipple from '../capacitor/Ripple';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import styles from './CourseSessionRecording.css';
import { CONSTANT_MESSAGES } from '../../constants';

const messages = defineMessages({
  accessButton: {
    id: 'course.session.access-recording',
    defaultMessage: 'Access Session Recording',
  },
});

type OwnProps = {
  url: string,
  password?: string,
  className?: string,
  sessionNo: number,
};

type Props = OwnProps & { intl: IntlShape };

const CourseSessionRecording = ({ url, password, className, sessionNo, intl }: Props) => {
  const text = intl.formatMessage(messages.accessButton);
  const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

  return (
    <div>
      <a
        className={className}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={ariaLink}
        data-test={`livestream-event.course-session.access-recording-btn-${sessionNo}`}
      >
        <CapacitorRipple />
        {text}
      </a>
      {password && (
        <div className={styles.password}>
          <FormattedMessage
            id="course.session.access-recording.password"
            defaultMessage="Zoom password: {password}"
            values={{ password }}
          />
        </div>
      )}
    </div>
  );
};

export default injectIntl(CourseSessionRecording);
