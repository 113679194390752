import React, { PureComponent } from 'react';
import { DataFetcherLoadingContext } from '../../lib/routing/DataFetcher';

import styles from './ProgressBar.css';

export default class ProgressBar extends PureComponent {
  render() {
    const classNames = this.context ? styles.active : styles.idle;
    return <div className={classNames} />;
  }

  static contextType = DataFetcherLoadingContext;
}
