// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DiscoverGroupSlider from '../discover/DiscoverGroupSlider';
import {
  DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP,
  DISCOVER_GROUP_TYPE_MIX,
} from '../../constants';
import { normalizeDiscoverGroup } from '../../schema/normalizeDiscover';
import * as analyticsActions from '../../actions/analytics';
import { connect } from 'react-redux';

type OwnProps = {
  items: Array<Object>,
  trackingScreen: string,
  slug: string,
};

type DispatchProps = {
  analyticsTrack: Function,
  trackPlayPausePressed: Function,
};

type Props = OwnProps & DispatchProps;

const groupName = group => {
  if (group.type === DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP) {
    return (
      <FormattedMessage
        id="livestream-events.featured-events.group.title"
        defaultMessage="Events"
      />
    );
  }

  if (group.type === DISCOVER_GROUP_TYPE_MIX) {
    return (
      <FormattedMessage
        id="livestream-events.featured-audios.group.title"
        defaultMessage="Audios"
      />
    );
  }

  return group.name;
};

const FeaturedEventsAndAudios = ({
  items,
  trackingScreen,
  slug,
  analyticsTrack,
  trackPlayPausePressed,
}: Props) => {
  if (!items || !items.length) {
    return null;
  }

  const onItemClick = ({ content }, groupId) => {
    analyticsTrack('Selected Content From Category', {
      id: content.id,
      type: content.type,
      category: groupId,
      pageName: trackingScreen,
      slug,
    });
  };

  const onItemBeforeTogglePlay = (item, playing, groupId) => {
    trackPlayPausePressed(
      {
        contextType: item.content.type,
        contextId: item.content.id,
        contextCategory: groupId,
      },
      playing
    );
  };

  return (
    <section data-test="livestream-event.featured-events-and-audios">
      <h2>
        <FormattedMessage
          id="livestream-events.featured-events-and-audios.title"
          defaultMessage="You might also enjoy..."
        />
      </h2>
      {items.map((item, index) => {
        const group = {
          ...normalizeDiscoverGroup(item),
          name: groupName(item),
        };
        return (
          <DiscoverGroupSlider
            key={index}
            group={group}
            trackingScreen={trackingScreen}
            slug={slug}
            onItemClick={onItemClick}
            onItemBeforeTogglePlay={onItemBeforeTogglePlay}
          />
        );
      })}
    </section>
  );
};

const dispatchProps: DispatchProps = {
  analyticsTrack: analyticsActions.track,
  trackPlayPausePressed: analyticsActions.trackPlayPausePressed,
};

export default connect(null, dispatchProps)(FeaturedEventsAndAudios);
