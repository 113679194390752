import React, { Component } from 'react';
import { pusher } from '../client/pusher';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

export default function pusherComponent(options = {}) {
  const {
    getJitterValue = msg => msg.jitter_in_ms || 0,
    getPusherChannel,
    onPusherMessage,
    withRef = false,
  } = options;

  return WrappedComponent => {
    class PusherComponent extends Component {
      componentDidMount() {
        const props = this.props;
        if (pusher && getPusherChannel) {
          const pusherChannel = getPusherChannel(props);
          if (getPusherChannel(props)) {
            pusher.subscribe(pusherChannel);
            pusher.bind('IEP-1', this.onPusherMessageWrapper);
          }
        }
      }

      componentWillUnmount() {
        const props = this.props;
        if (pusher && getPusherChannel) {
          const pusherChannel = getPusherChannel(props);
          if (getPusherChannel(props)) {
            pusher.unsubscribe(pusherChannel);
            pusher.unbind('IEP-1', this.onPusherMessageWrapper);
          }
        }
      }

      onPusherMessageWrapper = msg => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        const delay = Math.random() * getJitterValue(msg, this.props);
        this.timeout = setTimeout(() => {
          onPusherMessage(msg, this.props);
        }, delay);
      };

      render() {
        return <WrappedComponent {...this.props} ref={withRef ? 'wrappedInstance' : null} />;
      }
    }

    PusherComponent.displayName = `PusherComponent(${getDisplayName(WrappedComponent)}`;

    PusherComponent.WrappedComponent = WrappedComponent;

    return PusherComponent;
  };
}
