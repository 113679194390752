// @flow
import React from 'react';
import type { ChildrenArray, Element } from 'react';
import styles from './SectionItem.css';
import { FormattedMessage } from 'react-intl';

type OwnProps = {
  title: Element<typeof FormattedMessage>,
  children: ChildrenArray<Element<any>>,
};

const SectionItem = ({ title, children }: OwnProps) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{title}</span>
      {children}
    </div>
  );
};

export default SectionItem;
