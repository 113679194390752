import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const SonosAuthLoginStep1HeaderSonosDeal = ({ trialDurationInMonths }) => (
  <FormattedMessage
    id="sonos-auth.login-step-1.header-sonos-deal"
    defaultMessage="Enjoy {trialDurationInMonths} months for free"
    values={{ trialDurationInMonths }}
  />
);

SonosAuthLoginStep1HeaderSonosDeal.propTypes = {
  trialDurationInMonths: PropTypes.number.isRequired,
};

export default SonosAuthLoginStep1HeaderSonosDeal;
