export default {
  popular: [
    {
      id: 63122,
      name: 'Claudio Monteverdi',
    },
    {
      id: 61217,
      name: 'Johann Sebastian Bach',
    },
    {
      id: 62314,
      name: 'Antonio Vivaldi',
    },
    {
      id: 61259,
      name: 'Ludwig van Beethoven',
    },
    {
      id: 61251,
      name: 'Wolfgang Amadeus Mozart',
    },
    {
      id: 61240,
      name: 'Joseph Haydn',
    },
    {
      id: 86371,
      name: 'Franz Schubert',
    },
    {
      id: 61312,
      name: 'Fryderyk Chopin',
    },
    {
      id: 86378,
      name: 'Giuseppe Verdi',
    },
    {
      id: 61329,
      name: 'Richard Wagner',
    },
    {
      id: 61368,
      name: 'Johannes Brahms',
    },
    {
      id: 61378,
      name: {
        en: 'Pyotr Ilyich Tchaikovsky',
        de: 'Pjotr Iljitsch Tschaikowski',
      },
    },
    {
      id: 61385,
      name: 'Antonín Dvořák',
    },
    {
      id: 61400,
      name: 'Gustav Mahler',
    },
    {
      id: 1167527,
      name: 'Richard Strauss',
    },
    {
      id: 61404,
      name: 'Claude Debussy',
    },
    {
      id: 86386,
      name: 'Jean Sibelius',
    },
    {
      id: 86963,
      name: {
        en: 'Sergei Rachmaninoff',
        de: 'Sergei Rachmaninow',
      },
    },
    {
      id: 1165227,
      name: {
        en: 'Igor Stravinsky',
        de: 'Igor Strawinski',
      },
    },
    {
      id: 86973,
      name: {
        en: 'Dmitri Shostakovich',
        de: 'Dmitri Schostakowitsch',
      },
    },
  ],
  unpopular: [
    {
      id: 67957,
      name: 'Thomas Tallis',
    },
    {
      id: 86885,
      name: 'Carlo Gesualdo',
    },
    {
      id: 61195,
      name: 'Arcangelo Corelli',
    },
    {
      id: 66935,
      name: 'Francesco Cavalli ',
    },
    {
      id: 66939,
      name: 'Barbara Strozzi',
    },
    {
      id: 61985,
      name: 'Jean-Baptiste Lully',
    },
    {
      id: 66602,
      name: 'Marin Marais',
    },
    {
      id: 86356,
      name: 'Henry Purcell',
    },
    {
      id: 61210,
      name: 'Jean-Philippe Rameau ',
    },
    {
      id: 66532,
      name: 'Fanny Mendelssohn-Hensel',
    },
    {
      id: 86983,
      name: {
        en: 'Modest Mussorgsky',
        de: 'Modest Mussorgski',
      },
    },
    {
      id: 86462,
      name: 'Leoš Janáček',
    },
    {
      id: 12138144,
      name: 'Amy Beach',
    },
    {
      id: 61441,
      name: 'Maurice Ravel',
    },
    {
      id: 63423,
      name: 'Edgar Varèse',
    },
    {
      id: 62555,
      name: 'John Cage',
    },
    {
      id: 61631,
      name: 'Benjamin Britten',
    },
    {
      id: 66559,
      name: 'György Kurtág',
    },
    {
      id: 67540,
      name: 'Kaija Saariaho',
    },
    {
      id: 15852460,
      name: 'Anna Þorvaldsdóttir',
    },
  ],
};
