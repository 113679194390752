import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import IconLabel from './../util/IconLabel';
import styles from './ExpandButton.css';
import CapacitorRipple from '../capacitor/Ripple';
import { defineMessages, intlShape, injectIntl } from 'react-intl';

const messages = defineMessages({
  ariaButtonExpand: {
    id: 'aria-button-expand',
    defaultMessage: 'Expand',
  },

  ariaButtonCondense: {
    id: 'aria-button-condense',
    defaultMessage: 'Condense',
  },
});

class ExpandButton extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    isExpanded: PropTypes.bool.isRequired,
    intl: intlShape,
  };

  render() {
    const { isExpanded, intl } = this.props;
    const ariaButtonExpand = intl.formatMessage(messages.ariaButtonExpand);
    const ariaButtonCondense = intl.formatMessage(messages.ariaButtonCondense);

    return (
      <button
        onClick={this.props.onClick}
        className={styles.button}
        aria-label={isExpanded ? ariaButtonCondense : ariaButtonExpand}
      >
        <CapacitorRipple version="center" />
        <IconLabel name={isExpanded ? 'caret-up' : 'caret-down'} size="medium" />
      </button>
    );
  }
}

export default injectIntl(ExpandButton);
