// @flow

import { loadedCursor } from '../middleware/apiCacheValidators';
import { PersonList } from '../schema';
import { normalizeMetaList } from '../schema/normalizeMeta';

import type { Request } from './types';

export type LoadSoloistsAction = { type: 'FETCH_SOLOISTS' } & Request;

export type SoloistAction = LoadSoloistsAction;

export function loadSoloists(cursor?: string, limit: number = 50): LoadSoloistsAction {
  let params = { limit };

  if (cursor) {
    params = {
      ...params,
      cursor,
    };
  }

  return {
    type: 'FETCH_SOLOISTS',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/metadata/soloists',
      params,
    },
    meta: {
      schema: PersonList,
      normalize: normalizeMetaList,
    },
    cache: {
      fetch: state => state.lists.soloists,
      validate: loadedCursor(cursor),
    },
  };
}
