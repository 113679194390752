import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as Shapes from '../../shapes';
import Separated from '../util/Separated';
import RecordingVenue from './RecordingVenue';
import RecordingDate from './RecordingDate';
import RecordingLocation from './RecordingLocation';

export default class RecordingInfo extends PureComponent {
  static propTypes = {
    venue: Shapes.RecordingVenue,
    date: Shapes.RecordingDate,
    location: Shapes.Location,
    className: PropTypes.string,
  };

  render() {
    const { venue, date, location, className } = this.props;
    if (!venue && !date.from && !date.to) {
      return null;
    }

    return (
      <div className={className}>
        <Separated>
          {(date.from || date.to) && <RecordingDate recordingDate={date} />}
          {location && <RecordingLocation location={location} />}
          {venue && <RecordingVenue venue={venue} />}
        </Separated>
      </div>
    );
  }
}
