import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { CHROME_CLEAN } from '../constants';
import BookletViewer from '../components/common/BookletViewer';
import chromeComponent from '../hoc/chromeComponent';
import styles from './Booklet.css';

const Booklet = props => {
  const bookletUrl = props.location.query.bookletUrl;
  if (!bookletUrl || !bookletUrl.startsWith('https://booklets.idagio.com/')) {
    return <div>Invalid booklet URL</div>;
  }

  return (
    <div className={styles.bookletContainer}>
      <BookletViewer bookletUrl={bookletUrl} />
    </div>
  );
};

Booklet.propTypes = {
  location: PropTypes.object,
};

export default compose(chromeComponent({ type: CHROME_CLEAN }))(Booklet);
