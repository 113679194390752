import { compose } from 'redux';
import { connect } from 'react-redux';

import dataComponent from '../hoc/dataComponent';
import trackedViewComponent from '../hoc/trackedViewComponent';

import { loadRecordingsByFilter } from '../actions/recording';

import { getCategoryFetchData, getCategoryMapStateToProps } from '../utils/category-page';

import CategoryPage from '../components/common/CategoryPage';

import { selectPeriod } from '../selectors/categories';
import { loadPeriod } from '../actions/period';

const contextFacet = 'Epoch';
const facetType = 'epochs';

const fetchData = getCategoryFetchData(loadPeriod, facetType);
const mapStateToProps = getCategoryMapStateToProps(selectPeriod, facetType, contextFacet);
const trackingProps = (state, params) => ({ id: params.id });

export default compose(
  trackedViewComponent(contextFacet, trackingProps),
  dataComponent(fetchData),
  connect(mapStateToProps, { loadMore: loadRecordingsByFilter }, (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
  }))
)(CategoryPage);
