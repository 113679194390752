import { get } from 'lodash';
import { getTitleWithPopularTitle } from './recording';
import { getEntityTypeFromQueueType } from './queue';
import {
  IMAGES_BASE,
  QUEUE_TYPE_FILTERED_RECORDING_LIST,
  QUEUE_TYPE_PERSONAL_PLAYLIST,
  QUEUE_TYPE_RECORDING,
  QUEUE_TYPE_TRACK_COLLECTION,
} from '../constants';

export function recordingToPlayableItemPreview(recording, contentType) {
  const imageUrl = `${IMAGES_BASE}/artists/${recording.work.composer.id}/main.jpg?w=256&h=256&fit=crop&crop=faces`;

  return {
    title: getTitleWithPopularTitle(recording.work),
    subtitle: recording.summary,
    imageUrl: imageUrl,
    content: {
      type: contentType,
      id: recording.id,
      composerName: get(recording, 'work.composer.name', null),
    },
  };
}

export function personalPlaylistToPlayableItemPreview(playlist, getSubtitle) {
  const { title, imageUrl } = playlist;
  const subtitle = getSubtitle ? getSubtitle(playlist) : '';

  return {
    title,
    imageUrl,
    subtitle,
    content: {
      type: 'personalPlaylist',
      ...playlist,
    },
  };
}

export function playlistToPlayableItemPreview(entity, contentType) {
  return {
    title: entity.title,
    imageUrl: entity.imageUrl,
    content: {
      type: contentType,
      id: entity.id,
      slug: entity.slug,
      curator: entity.curator,
    },
  };
}

export function queueEntityToPlayableItemPreview(item, queueType) {
  const contentType = getEntityTypeFromQueueType(queueType);

  if (
    queueType === QUEUE_TYPE_RECORDING ||
    queueType === QUEUE_TYPE_TRACK_COLLECTION ||
    queueType === QUEUE_TYPE_FILTERED_RECORDING_LIST
  ) {
    return recordingToPlayableItemPreview(item, contentType);
  }

  if (queueType === QUEUE_TYPE_PERSONAL_PLAYLIST) {
    return personalPlaylistToPlayableItemPreview(item);
  }

  return playlistToPlayableItemPreview(item, contentType);
}
