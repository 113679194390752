import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import styles from './style.css';

class HeaderSection extends React.PureComponent {
  render() {
    const classesTitle = classNames('fz--beta', styles.title);

    return (
      <div className={styles.section}>
        <h1 className={classesTitle}>
          <FormattedMessage id="live-compare.title" defaultMessage="Live Compare" />
        </h1>
        <p className={styles.paragraph}>
          <FormattedMessage
            id="live-compare.subtitle"
            defaultMessage="Find your perfect recording faster: Tap between performances to hear the exact same moment interpreted by a specially curated selection of your favourite performers."
          />
        </p>
      </div>
    );
  }
}

export default HeaderSection;
