// @flow
import React from 'react';
import type { IntlShape } from 'react-intl';
import SectionItem from './SectionItem';
import { FormattedDate, FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import styles from './SectionItem.css';
import classNames from 'classnames';
import {
  ANDROID_ACCOUNT_MANAGEMENT_LINK,
  CONSTANT_MESSAGES,
  ITUNES_ACCOUNT_MANAGEMENT_LINK,
} from '../../constants';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  selectSubscriptionIsCanceled,
  selectSubscriptionRenewalDate,
  selectSubscriptionRenews,
  selectSubscriptionTypeIsAndroid,
  selectSubscriptionTypeIsApple,
  selectUserCanCancelSubscription,
} from '../../selectors/subscription';

const messages = defineMessages({
  appleManageLink: {
    id: 'settings.subs.cancel-subscription.apple-id',
    defaultMessage: 'your Apple ID',
  },
  androidManageLink: {
    id: 'settings.subs.cancel-subscription.google-play',
    defaultMessage: 'your Google Play account',
  },
});

type OwnProps = {
  cancelSubscriptionDialogIsOpen: boolean,
  onCancelSubscription: Function,
  onKeepPremiumSubscription: Function,
  onConfirmCancelSubscription: Function,
};

type MapStateToProps = {
  subscriptionRenews: boolean,
  renewalDate: string,
  userCanCancel: boolean,
  subscriptionCanceled: boolean,
  isAppleSub: boolean,
  isAndroidSub: boolean,
};

type Props = OwnProps & MapStateToProps & { intl: IntlShape };

const NextBilling = ({
  subscriptionRenews,
  renewalDate,
  userCanCancel,
  subscriptionCanceled,
  intl,
  cancelSubscriptionDialogIsOpen,
  onCancelSubscription,
  onKeepPremiumSubscription,
  onConfirmCancelSubscription,
  isAppleSub,
  isAndroidSub,
}: Props) => {
  if (!subscriptionRenews) {
    return null;
  }

  const CancelSubscription = () => {
    if (isAppleSub || isAndroidSub) {
      const text = intl.formatMessage(
        isAppleSub ? messages.appleManageLink : messages.androidManageLink
      );
      const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

      return (
        <div className={classNames(styles.action, styles.support)}>
          <FormattedMessage
            id="settings.subs.cancel-subscription.mobile"
            defaultMessage="You are subscribed via {provider}. Please use {link} to manage your subscription."
            values={{
              provider: isAppleSub ? 'Apple' : 'Google',
              link: (
                <a
                  href={
                    isAppleSub ? ITUNES_ACCOUNT_MANAGEMENT_LINK : ANDROID_ACCOUNT_MANAGEMENT_LINK
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="c-text-link--is-visible"
                  aria-label={ariaLink}
                >
                  {text}
                </a>
              ),
            }}
          />
        </div>
      );
    }

    if (!cancelSubscriptionDialogIsOpen) {
      return (
        <div className={classNames(styles.action, styles.btns)}>
          <button className={classNames('c-btn', styles.primaryBtn)} onClick={onCancelSubscription}>
            <FormattedMessage id="settings.subs.cancel-now.cta" defaultMessage="Cancel now" />
          </button>
        </div>
      );
    }

    return (
      <div className={styles.action}>
        <p>
          <FormattedMessage
            id="settings.subs.cancel-subscription.confirmation"
            defaultMessage="Are you sure you want to end your IDAGIO subscription?"
          />
        </p>
        <div className={styles.btns}>
          <button className="c-btn" onClick={onKeepPremiumSubscription}>
            <FormattedMessage
              id="settings.subs.cancel-subscription.keep"
              defaultMessage="Keep Subscription"
            />
          </button>
          <button className="c-btn c-btn--is-red" onClick={onConfirmCancelSubscription}>
            <FormattedMessage
              id="settings.subs.cancel-subscription.confirm"
              defaultMessage="End Subscription"
            />
          </button>
        </div>
      </div>
    );
  };

  return (
    <SectionItem
      title={
        <FormattedMessage
          id="settings.account.subscription.next-billing.title"
          defaultMessage="Next Billing:"
        />
      }
    >
      <span className={styles.value}>
        {renewalDate && (
          <FormattedDate year="numeric" month="2-digit" day="2-digit" value={renewalDate} />
        )}
      </span>
      {userCanCancel && !subscriptionCanceled && <CancelSubscription />}
    </SectionItem>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    subscriptionRenews: selectSubscriptionRenews(state),
    renewalDate: selectSubscriptionRenewalDate(state),
    userCanCancel: selectUserCanCancelSubscription(state),
    subscriptionCanceled: selectSubscriptionIsCanceled(state),
    isAppleSub: selectSubscriptionTypeIsApple(state),
    isAndroidSub: selectSubscriptionTypeIsAndroid(state),
  };
}

export default compose(connect(mapStateToProps), injectIntl)(NextBilling);
