// @flow
// $FlowFixMe
import React, { useCallback } from 'react';
import { getEntityUrl } from '../../lib/entities';
import { ENTITY_TYPE_LIVESTREAM_EVENT } from '../../constants';
import PlayableHeroSliderItem from './PlayableHeroSliderItem';
import LivestreamEventHeroSliderItem from './LivestreamEventHeroSliderItem';

type OwnProps = {
  item: Object,
  isActive: boolean,
  onClick: Function,
  beforeTogglePlay: Function,
  cancelAutoSlide: Function,
  isLivestreamEventGroup: boolean,
  groupId: string,
};

type Props = OwnProps;

export default function HeroSliderItem({
  item,
  isActive,
  onClick,
  beforeTogglePlay,
  cancelAutoSlide,
  isLivestreamEventGroup,
  groupId,
}: Props) {
  const { content } = item;
  const contentType = isLivestreamEventGroup ? ENTITY_TYPE_LIVESTREAM_EVENT : content.type;
  const url = getEntityUrl(contentType, content);
  const wrappedOnClick = useCallback(() => onClick(item), [item, onClick]);

  if (isLivestreamEventGroup) {
    return (
      <LivestreamEventHeroSliderItem
        isActive={isActive}
        url={url}
        item={item}
        onClick={wrappedOnClick}
      />
    );
  }

  return (
    <PlayableHeroSliderItem
      isActive={isActive}
      item={item}
      url={url}
      onClick={wrappedOnClick}
      beforeTogglePlay={beforeTogglePlay}
      cancelAutoSlide={cancelAutoSlide}
      groupId={groupId}
    />
  );
}
