import { defineMessages } from 'react-intl';
import { startCase } from 'lodash';

export const booleanMessages = defineMessages({
  isExclusive: {
    id: 'filtered-list.type.isExclusive',
    defaultMessage: 'Exclusive',
  },
  isOriginalWork: {
    id: 'filtered-list.type.arrangements',
    defaultMessage: 'Arrangements',
  },
  isCompleteWork: {
    id: 'filtered-list.type.operaExcerpts',
    defaultMessage: 'Opera Excerpts',
  },
});

const singularMessages = defineMessages({
  works: {
    id: 'filtered-list.type.work',
    defaultMessage: 'Work',
  },
  composers: {
    id: 'filtered-list.type.composer',
    defaultMessage: 'Composer',
  },
  ensembles: {
    id: 'filtered-list.type.ensemble',
    defaultMessage: 'Ensemble',
  },
  conductors: {
    id: 'filtered-list.type.conductor',
    defaultMessage: 'Conductor',
  },
  soloists: {
    id: 'filtered-list.type.soloist',
    defaultMessage: 'Soloist',
  },
  recordingTypes: {
    id: 'filtered-list.type.recordingType',
    defaultMessage: 'Recording Type',
  },
  instruments: {
    id: 'filtered-list.type.instrument',
    defaultMessage: 'Instrument',
  },
  genres: {
    id: 'filtered-list.type.genre',
    defaultMessage: 'Genre',
  },
  epochs: {
    id: 'filtered-list.type.epoch',
    defaultMessage: 'Period',
  },
});

const singularMessagesWithArticle = defineMessages({
  works: {
    id: 'filtered-list.type.a-work',
    defaultMessage: 'a Work',
  },
  composers: {
    id: 'filtered-list.type.a-composer',
    defaultMessage: 'a Composer',
  },
  ensembles: {
    id: 'filtered-list.type.an-ensemble',
    defaultMessage: 'an Ensemble',
  },
  conductors: {
    id: 'filtered-list.type.a-conductor',
    defaultMessage: 'a Conductor',
  },
  soloists: {
    id: 'filtered-list.type.a-soloist',
    defaultMessage: 'a Soloist',
  },
  recordingTypes: {
    id: 'filtered-list.type.a-recording-type',
    defaultMessage: 'a Recording Type',
  },
  instruments: {
    id: 'filtered-list.type.an-instrument',
    defaultMessage: 'an Instrument',
  },
  genres: {
    id: 'filtered-list.type.a-genre',
    defaultMessage: 'a Genre',
  },
  epochs: {
    id: 'filtered-list.type.an-epoch',
    defaultMessage: 'a Period',
  },
});

const pluralMessages = defineMessages({
  works: {
    id: 'filtered-list.type.works',
    defaultMessage: 'Works',
  },
  composers: {
    id: 'filtered-list.type.composers',
    defaultMessage: 'Composers',
  },
  ensembles: {
    id: 'filtered-list.type.ensembles',
    defaultMessage: 'Ensembles',
  },
  conductors: {
    id: 'filtered-list.type.conductors',
    defaultMessage: 'Conductors',
  },
  soloists: {
    id: 'filtered-list.type.soloists',
    defaultMessage: 'Soloists',
  },
  recordingTypes: {
    id: 'filtered-list.type.recordingTypes',
    defaultMessage: 'Recording Types',
  },
  instruments: {
    id: 'filtered-list.type.instruments',
    defaultMessage: 'Instruments',
  },
  genres: {
    id: 'filtered-list.type.genres',
    defaultMessage: 'Genres',
  },
  epochs: {
    id: 'filtered-list.type.epochs',
    defaultMessage: 'Periods',
  },
});

export const getSingularMessage = (intl, type) => {
  const message = singularMessages[type] ? singularMessages[type] : booleanMessages[type];
  return message ? intl.formatMessage(message) : startCase(type);
};

export const getSingularMessageWithArticle = (intl, type) => {
  const message = singularMessagesWithArticle[type]
    ? singularMessagesWithArticle[type]
    : booleanMessages[type];
  return message ? intl.formatMessage(message) : startCase(type);
};

export const getPluralMessage = (intl, type) => {
  const message = pluralMessages[type] ? pluralMessages[type] : booleanMessages[type];
  return message ? intl.formatMessage(message) : startCase(type);
};
