import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import classnames from 'classnames';
import { loadMoods } from '../actions/mood';

import { Link } from 'react-router';
import Head from '../components/chrome/Head';
import List from '../components/util/List';

import styles from './Moods.css';

const messages = defineMessages({
  metaTitle: {
    id: 'moods.meta.title',
    defaultMessage: 'Moods',
  },
  metaDescription: {
    id: 'moods.meta.description',
    defaultMessage:
      'Discover new classical music with our unique mood feature. We have the perfect soundtrack for you, hand-picked by our team of music experts.',
  },
});

class Moods extends Component {
  static propTypes = {
    moods: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    intl: intlShape,
  };

  renderListItem = item => {
    const itemClasses = classnames(styles.listItem, styles[`listItem--${item.id}`]);

    return (
      <li className={styles.listElement} key={item.id}>
        <Link to={`/moods/${item.id}`} className={itemClasses}>
          <span className={styles.name}>{item.name}</span>
        </Link>
      </li>
    );
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className={classnames(styles.moods, 'u-page-container')}>
        <Head
          title={formatMessage(messages.metaTitle)}
          description={formatMessage(messages.metaDescription)}
        />
        <h1 className="sr-only">Moods</h1>
        <List className={styles.list} items={this.props.moods} renderItem={this.renderListItem} />
      </div>
    );
  }
}

function fetchData(store) {
  return store.dispatch(loadMoods());
}

function mapStateToProps(state) {
  return {
    entities: state.entities,
    listOfMoods: state.lists.moods,
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { entities, listOfMoods } = stateProps;

  return Object.assign({}, ownProps, dispatchProps, {
    moods: listOfMoods.entities.map(id => entities.moods[id]),
  });
}

export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, {}, mergeProps),
  injectIntl
)(Moods);
