import React, { Component } from 'react';

import { CHROME_GLASS } from '../constants';

export default class Geoblocked extends Component {
  static chrome = CHROME_GLASS;

  render() {
    return (
      <section className="u-page-container">
        <h1>IDAGIO is currently unavailable in your region.</h1>
        <p>
          Due to copyright restrictions we&#8217;ve unfortunately had to temporarily delay the
          release of IDAGIO in your region.
        </p>
        <a
          className="c-text-link--is-visible"
          href="https://support.idagio.com/en/articles/3478724-is-idagio-available-in-my-country"
        >
          Find out more
        </a>
      </section>
    );
  }
}
