// @flow
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import CapacitorRipple from '../capacitor/Ripple';
import classNames from 'classnames';
import styles from './FacetShowAllBtn.css';
import { getPluralMessage } from '../messages/FilterMessages';

type OwnProps = {
  facetLength: number,
  loadAndShowAllFacets: Function,
  type: string,
  count: number,
};

type Props = OwnProps & { intl: IntlShape };

const messages = defineMessages({
  ariaButton: {
    id: 'filtered-list.show-all',
    defaultMessage: 'Show all {count} {type}',
  },
});

const FacetShowAllBtn = ({ facetLength, loadAndShowAllFacets, type, count, intl }: Props) => {
  if (!facetLength || count - facetLength < 1) {
    return null;
  }

  const showAllBtnText = intl.formatMessage(messages.ariaButton, {
    count,
    type: getPluralMessage(intl, type),
  });
  return (
    <button
      onClick={() => loadAndShowAllFacets(type)}
      className={classNames('c-btn', 'c-btn--is-red', styles.btn)}
      aria-label={showAllBtnText}
      data-test="facet.group.show-all-btn"
    >
      <CapacitorRipple />
      {showAllBtnText}
    </button>
  );
};

export default injectIntl(FacetShowAllBtn);
