/* This file is pretty similar to SonosLoginFlow. Changes in either may be needed to be reflected in the other */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import styles from './SonosAuth.css';
import Price from '../common/Price';
import List from '../util/List';
import addMonths from 'date-fns/add_months';
import IconLabel from '../util/IconLabel';
import classnames from 'classnames';

import { compose } from 'redux';
import StripePaymentForm from '../premium/StripePaymentForm';
import BackButton from '../common/BackButton';
import SonosStep from './SonosStep';
import Signup from '../common/Signup';
import {
  FROM_ID_CAMPAIGNS,
  PLAN_ID_PREMIUM,
  SUBSCRIPTION_SONOS_TRIAL_DURATION_DAYS,
} from '../../constants';
import { selectUserIsAllowedOptOutTrial } from '../../selectors/user';

import SubscribeModalStep2Header from '../messages/SubscribeModalStep2Header';
import SonosAuthLoginStep1HeaderSonosDeal from '../messages/SonosAuthLoginStep1HeaderSonosDeal';
import SonosAuthLoginStep1TextSonosDeal from '../messages/SonosAuthLoginStep1TextSonosDeal';
import SonosAuthLoginStep1PriceTextSonosDeal from '../messages/SonosAuthLoginStep1PriceTextSonosDeal';

const JOIN_STEP_INITIAL = 1;
const JOIN_STEP_CREATE = 2;
const PAYMENT_BENEFITS = 3;
const PAYMENT_FORM = 4;

const TOTAL_STEPS = 4;

const messages = defineMessages({
  newPremiumUserBenefit1: {
    id: 'subscribe-modal.argument.cancel-before',
    defaultMessage: 'Cancel before {endDate, date, medium} to not be charged.',
  },
  newPremiumUserBenefit2: {
    id: 'subscribe-modal.argument.no-commitments-new',
    defaultMessage: 'No commitments. Cancel online anytime.',
  },
});

export const benefitsList = [messages.newPremiumUserBenefit1, messages.newPremiumUserBenefit2];

const getAnalyticsStepName = stepNumber => {
  switch (stepNumber) {
    case 1:
      return 'TrialOptOutInformation';
    case 2:
      return 'AccountCreation';
    case 3:
      return 'Subscribe';
    case 4:
      return 'PaymentDetails';
    case 5:
      return 'BackToSonosLogin';
    default:
      return 'TrialOptOutInformation';
  }
};

class SonosSignup extends Component {
  static propTypes = {
    linkCode: PropTypes.string,
    authorizeSonos: PropTypes.func.isRequired,
    trackStepCompletion: PropTypes.func.isRequired,
    trialDurationInMonths: PropTypes.number,
    intl: intlShape.isRequired,
  };

  static contextTypes = {
    router: PropTypes.object,
    intl: PropTypes.object,
  };

  static defaultProps = {
    trialDurationInMonths: 2,
  };

  state = {
    step: JOIN_STEP_INITIAL,
  };

  renderBenefitItem = (message, i) => {
    const { trialDurationInMonths } = this.props;
    const endDate = addMonths(new Date(), trialDurationInMonths);
    return (
      <li className={styles.argument} key={i}>
        <IconLabel name="check" className={styles.iconCheck} size="32px" />
        <span>
          <FormattedMessage
            {...message}
            values={{
              endDate,
              trialDuration: SUBSCRIPTION_SONOS_TRIAL_DURATION_DAYS,
            }}
          />
        </span>
      </li>
    );
  };

  renderFirstStep() {
    const { trialDurationInMonths } = this.props;
    return (
      <SonosStep
        currentStep={1}
        totalSteps={TOTAL_STEPS}
        onButtonClick={this.increaseStep}
        iconName={'select-box'}
      >
        <div className={styles.heading}>
          <h2>
            <SonosAuthLoginStep1HeaderSonosDeal trialDurationInMonths={trialDurationInMonths} />
          </h2>
          <p className={styles.subheading}>
            <SonosAuthLoginStep1TextSonosDeal />
          </p>
        </div>
        <div className={styles.priceBox}>
          <div className={styles.priceText}>
            <SonosAuthLoginStep1PriceTextSonosDeal trialDurationInMonths={trialDurationInMonths} />
          </div>
          <div>
            <span className={styles.price}>
              <Price price={0.0} />
            </span>
          </div>
        </div>
      </SonosStep>
    );
  }

  renderSecondStep() {
    return (
      <SonosStep
        currentStep={2}
        totalSteps={TOTAL_STEPS}
        shouldRenderButton={false}
        iconName={'platforms'}
      >
        <Signup
          onAuthComplete={this.increaseStep}
          fromId={FROM_ID_CAMPAIGNS.sonos.fromId}
          signupSource="Sonos"
          subheader={
            <FormattedMessage
              id="sonos-auth.signup-form.description"
              defaultMessage="Welcome to the world’s best curated collection of classical music"
            />
          }
          hideSocialOptions
        />
      </SonosStep>
    );
  }

  renderThirdStep() {
    return (
      <SonosStep
        currentStep={3}
        totalSteps={TOTAL_STEPS}
        onButtonClick={this.increaseStep}
        iconName={'subscribe-lock'}
      >
        <h2>
          <SubscribeModalStep2Header />
        </h2>
        <List
          className={styles.listArguments}
          items={benefitsList}
          renderItem={this.renderBenefitItem}
        />
      </SonosStep>
    );
  }

  renderPaymentForm() {
    return (
      <SonosStep
        currentStep={4}
        totalSteps={TOTAL_STEPS}
        shouldRenderButton={false}
        iconName={'subscribe-lock'}
      >
        <StripePaymentForm
          isInModal={false}
          trialDuration={SUBSCRIPTION_SONOS_TRIAL_DURATION_DAYS}
          price={0.0}
          onSuccess={this.routeToSuccessPage}
          selectedPlan={PLAN_ID_PREMIUM}
          userIsAllowedOptOutTrial={this.props.userIsAllowedOptOutTrial}
        />
      </SonosStep>
    );
  }

  render() {
    const { step } = this.state;

    const backButtonVisible = step > JOIN_STEP_INITIAL && step < PAYMENT_FORM;
    const classesContainer = classnames(styles.container, 'u-page-container');

    return (
      <div className={classesContainer}>
        <BackButton
          onClick={this.decreaseStep}
          isActive={backButtonVisible}
          isVisible={backButtonVisible}
        />
        {this.state.step === JOIN_STEP_INITIAL && this.renderFirstStep()}
        {this.state.step === JOIN_STEP_CREATE && this.renderSecondStep()}
        {this.state.step === PAYMENT_BENEFITS && this.renderThirdStep()}
        {this.state.step === PAYMENT_FORM && this.renderPaymentForm()}
      </div>
    );
  }

  routeToSuccessPage = () => {
    this.context.router.push('/sonos/success');
  };

  increaseStep = () => {
    this.props.trackStepCompletion(getAnalyticsStepName(this.state.step));
    this.setState({ step: this.state.step + 1 });
  };

  decreaseStep = () => {
    this.setState({ step: this.state.step > 1 ? this.state.step - 1 : 1 });
  };
}

function mapStateToProps(state) {
  return {
    userIsAllowedOptOutTrial: selectUserIsAllowedOptOutTrial(state),
  };
}

export default compose(injectIntl, connect(mapStateToProps, {}))(SonosSignup);
