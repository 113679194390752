// @flow
import React from 'react';
import styles from './ClearActiveFacetsByGroupButton.css';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import ClearActiveFacetsByGroup from './ClearActiveFacetsByGroup';

type OwnProps = {
  type: string,
  pathname: string,
  query: Object,
  className?: string,
  onClickClear?: Function,
};

const ClearActiveFacetsByGroupButton = ({
  type,
  pathname,
  query,
  onClickClear,
  className,
}: OwnProps) => {
  const linkClassNames = classNames(
    'c-btn',
    'c-btn--has-outline',
    'c-btn--is-small',
    styles.component,
    className
  );

  return (
    <ClearActiveFacetsByGroup
      type={type}
      pathname={pathname}
      query={query}
      onClickClear={onClickClear}
      className={linkClassNames}
      title={<FormattedMessage id="filtered-list.active-facets.clear" defaultMessage="Clear" />}
    />
  );
};

export default ClearActiveFacetsByGroupButton;
