// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ClearAllActiveFacets from './ClearAllActiveFacets';

type OwnProps = {
  pathname: string,
  query: Object,
  onClickClear?: Function,
};

const ResetAllActiveFacetsButton = ({ pathname, query, onClickClear }: OwnProps) => {
  return (
    <ClearAllActiveFacets
      pathname={pathname}
      query={query}
      title={<FormattedMessage id="filtered-list.active-facets.reset-all" defaultMessage="Reset" />}
      onClickClear={onClickClear}
    />
  );
};

export default ResetAllActiveFacetsButton;
