// @flow
import { values } from 'lodash';

import * as cacheValidators from '../middleware/apiCacheValidators';

import getSerializedFilterId from '../utils/getSerializedFilterId';
import singulariseParamObject from '../utils/singulariseParamObject';
import filterAndSortParams from '../utils/filterAndSortParams';

import {
  BOOLEAN_FACET_PARAMS,
  VALID_ALBUM_FACET_PARAMS,
  VALID_RECORDING_FACET_PARAMS,
  VALID_WORK_FACET_PARAMS,
} from '../constants';

import type { Request } from './types';
import { normalizeAllFacets, normalizeTopFacetsResult } from '../schema/normalizeFacet';

const ignoredTypes = [...BOOLEAN_FACET_PARAMS];

// The facet types have been written in plural in our web code.
// This function is a helper to convert them to singular to be used in the API calls.
function singulariseFacetType(facetType) {
  if (!ignoredTypes.includes(facetType)) {
    return facetType.slice(0, -1);
  }
  return facetType;
}

type LoadAllFacetsActionTypes =
  | 'FETCH_ALL_WORK_FACETS'
  | 'FETCH_ALL_RECORDING_FACETS'
  | 'FETCH_ALL_ALBUM_FACETS';
type LoadTopFacetsActionTypes =
  | 'FETCH_TOP_WORK_FACETS'
  | 'FETCH_TOP_RECORDING_FACETS'
  | 'FETCH_TOP_ALBUM_FACETS';
export type LoadTopFacetsAction = {
  type: LoadTopFacetsActionTypes,
} & Request;
export type LoadAllFacetsAction = {
  type: LoadAllFacetsActionTypes,
} & Request;

export type FacetAction = LoadTopFacetsAction | LoadAllFacetsAction;

function loadAllFacets(
  mapKey: string,
  filterType: string,
  facetType: string,
  validParams: Array<string>,
  params: Object,
  actionType: LoadAllFacetsActionTypes
): LoadAllFacetsAction {
  const validAndSortedParams = filterAndSortParams(params, validParams);
  const cacheId = [facetType, ...values(validAndSortedParams)].join('-');

  return {
    type: actionType,
    cacheId,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: `/v2.0/metadata/${filterType}/facets/${singulariseFacetType(facetType)}`,
      params: singulariseParamObject(validAndSortedParams),
    },
    meta: {
      normalize: response => normalizeAllFacets(response, facetType),
    },
    cache: {
      fetch: state => state.maps[mapKey][cacheId],
      validate: cacheValidators.loaded,
    },
  };
}

export function loadAllWorkFacets(facetType: string, params: Object): LoadAllFacetsAction {
  return loadAllFacets(
    'workFacets',
    'works',
    facetType,
    VALID_WORK_FACET_PARAMS,
    params,
    'FETCH_ALL_WORK_FACETS'
  );
}

export function loadAllRecordingFacets(facetType: string, params: Object): LoadAllFacetsAction {
  return loadAllFacets(
    'recordingFacets',
    'recordings',
    facetType,
    VALID_RECORDING_FACET_PARAMS,
    params,
    'FETCH_ALL_RECORDING_FACETS'
  );
}

export function loadAllAlbumFacets(facetType: string, params: Object): LoadAllFacetsAction {
  return loadAllFacets(
    'albumFacets',
    'albums',
    facetType,
    VALID_ALBUM_FACET_PARAMS,
    params,
    'FETCH_ALL_ALBUM_FACETS'
  );
}

function loadTopFacets(
  filterType: string,
  mapKey: string,
  actionType: LoadTopFacetsActionTypes,
  validParams: Array<string>,
  params: Object
): LoadTopFacetsAction {
  const validAndSortedParams = filterAndSortParams(params, validParams);
  const cacheId = getSerializedFilterId(validAndSortedParams, validParams);
  return {
    type: actionType,
    cacheId,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: `/v2.0/metadata/${filterType}/facets-top`,
      params: singulariseParamObject(validAndSortedParams),
    },
    meta: {
      normalize: response => normalizeTopFacetsResult(response, validParams),
    },
    cache: {
      fetch: state => state.maps[mapKey][cacheId],
      validate: cacheValidators.loaded,
    },
  };
}

export function loadTopAlbumFacets(params: Object): LoadTopFacetsAction {
  return loadTopFacets(
    'albums',
    'topAlbumFacetsByFilter',
    'FETCH_TOP_ALBUM_FACETS',
    VALID_ALBUM_FACET_PARAMS,
    params
  );
}

export function loadTopWorkFacets(params: Object): LoadTopFacetsAction {
  return loadTopFacets(
    'works',
    'topWorkFacetsByFilter',
    'FETCH_TOP_WORK_FACETS',
    VALID_WORK_FACET_PARAMS,
    params
  );
}

export function loadTopRecordingFacets(params: Object): LoadTopFacetsAction {
  return loadTopFacets(
    'recordings',
    'topRecordingFacetsByFilter',
    'FETCH_TOP_RECORDING_FACETS',
    VALID_RECORDING_FACET_PARAMS,
    params
  );
}
