import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  profileMetaDescription: {
    id: 'profile.meta.description',
    defaultMessage:
      'Listen to works, recordings and albums by {name} in stunning, lossless sound quality. Stream now on IDAGIO',
  },
  profileMetaTitle: {
    id: 'profile.meta.title',
    defaultMessage: '{name} | Stream on IDAGIO',
  },
  profileMetaKeywords: {
    id: 'profile.meta.keywords',
    defaultMessage: '{name} classical music',
  },
  profileOgTitle: {
    id: 'profile.og.title',
    defaultMessage: '{name} on IDAGIO',
  },
  profileWorksMetaTitle: {
    id: 'profile-works.meta.title',
    defaultMessage: 'Works by {name} | Stream on IDAGIO',
  },
  profileWorksMetaDescription: {
    id: 'profile-works.meta.description',
    defaultMessage:
      'Listen to all works by {name} in stunning, lossless sound quality. Stream now on IDAGIO',
  },
  profileWorksMetaKeywords: {
    id: 'profile-works.meta.keywords',
    defaultMessage: '{name} classical works,',
  },
  profileAlbumsMetaTitle: {
    id: 'profile-albums.meta.title',
    defaultMessage: 'Albums by {name} | Stream on IDAGIO',
  },
  profileAlbumsOgDescription: {
    id: 'profile-albums.og.description',
    defaultMessage: 'Stream {name} albums on IDAGIO',
  },
  profileAlbumsMetaDescription: {
    id: 'profile-albums.meta.description',
    defaultMessage: '{name}: Discography | Stream on IDAGIO',
  },
  profileAlbumsMetaKeywords: {
    id: 'profile-albums.meta.keywords',
    defaultMessage: '{name} classical albums,',
  },
  profileRecordingsMetaTitle: {
    id: 'profile-recordings.meta.title',
    defaultMessage: 'Recordings by {name} | Stream on IDAGIO',
  },
  profileRecordingsOgDescription: {
    id: 'profile-recordings.og.description',
    defaultMessage: 'Stream {name} recordings on IDAGIO',
  },
  profileRecordingsMetaDescription: {
    id: 'profile-recordings.meta.description',
    defaultMessage:
      'Listen to all recordings by {name} in stunning, lossless sound quality. Stream now on IDAGIO',
  },
  profileRecordingsMetaKeywords: {
    id: 'profile-recordings.meta.keywords',
    defaultMessage: '{name} classical recordings,',
  },
  profilePlaylistsMetaTitle: {
    id: 'profile-playlists.meta.title',
    defaultMessage: 'Playlists by {name} | Stream on IDAGIO',
  },
  profilePlaylistsMetaDescription: {
    id: 'profile-playlists.meta.description',
    defaultMessage:
      'Listen to all playlists by {name} in stunning, lossless sound quality. Stream now on IDAGIO',
  },
  profilePlaylistsMetaKeywords: {
    id: 'profile-playlists.meta.keywords',
    defaultMessage: '{name} playlists,',
  },
  share: {
    id: 'profile-head.share',
    defaultMessage: 'Share artist',
  },
  profileAboutMetaTitle: {
    id: 'profile-about.meta.title',
    defaultMessage: '{name} | Stream on IDAGIO',
  },
  profileAboutMetaDescription: {
    id: 'profile-about.meta.description',
    defaultMessage:
      'Listen to works, recordings and albums by {name} in stunning, lossless sound quality. Stream now on IDAGIO',
  },
  profileAboutMetaKeywords: {
    id: 'profile-about.meta.keywords',
    defaultMessage: '{name} classical music,',
  },
  profileAboutRecommendedAlbums: {
    id: 'profile-about.recommended-albums',
    defaultMessage: 'Recommended Albums',
  },
  profileAboutLatestReleases: {
    id: 'profile-about.latest-releases',
    defaultMessage: 'Latest Releases',
  },
  profileAboutFeaturedOn: {
    id: 'profile-about.featured-on',
    defaultMessage: 'Featured On',
  },
  profileAboutConcerts: {
    id: 'profile-about.concerts-events',
    defaultMessage: 'Concerts and Events',
  },
});
