import classNames from 'classnames';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './style.css';
import typography from '../common/typography.css';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

// until we decide how to update works in general, we might just keep this as a hardcoded value.
// later on, we could integrate this somehow in the get work endpoint.
const LIVE_COMPARE_PARTICIPATING_WORK_ID = 6739525;

class LiveCompareInvitationOverlay extends PureComponent {
  static propTypes = {
    workId: PropTypes.number.isRequired,
  };

  // currently, we show this on every subsequent viewing of the work, so the local state should work.
  state = {
    dismissed: false,
  };

  render() {
    if (this.state.dismissed || this.props.workId !== LIVE_COMPARE_PARTICIPATING_WORK_ID) {
      return null;
    }

    return (
      <div className={styles.Container}>
        <div className={classNames(typography.Large, styles.Title)}>
          <FormattedMessage
            id={'live-compare.work-page-tooltip.title'}
            defaultMessage={'Find your perfect recording faster'}
          />
        </div>
        <div>
          <FormattedMessage
            id={'live-compare.work-page-tooltip.body'}
            defaultMessage={'This work is currently being showcased on our {featureName} feature.'}
            values={{
              featureName: (
                <b>
                  <FormattedMessage
                    id={'live-compare.work-page-tooltip.featureName'}
                    defaultMessage={'Live Compare'}
                  />
                </b>
              ),
            }}
          />
        </div>
        <div className={styles.ActionBlock}>
          <span
            className={classNames(
              typography.SmallText,
              typography.Underline,
              typography.Pointer,
              styles.Dismiss
            )}
            onClick={this.handleDismiss}
          >
            <FormattedMessage
              id={'live-compare.work-page-tooltip.dismiss'}
              defaultMessage={'Dismiss'}
            />
          </span>
          <Link to="/live-compare" className={classNames(typography.Base, styles.Button)}>
            <FormattedMessage
              id={'live-compare.work-page-tooltip.action'}
              defaultMessage={'Go to Live Compare'}
            />
          </Link>
        </div>
      </div>
    );
  }

  handleDismiss = () => this.setState({ dismissed: true });
}

export default LiveCompareInvitationOverlay;
