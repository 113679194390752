// @flow

import React from 'react';
import { Link } from 'react-router';

import Feature from '../util/Feature';

import CollectionTracks from '../messages/CollectionTracks';
import CollectionRecordings from '../messages/CollectionRecordings';
import CollectionPlaylists from '../messages/CollectionPlaylists';
import CollectionAlbums from '../messages/CollectionAlbums';
import CollectionArtists from '../messages/CollectionArtists';
import CollectionLivestreamEvents from '../messages/CollectionLivestreamEvents';

type CollectionLinksProps = {
  linkClassName: string,
  onClick: () => void,
};

const CollectionLinks = (props: CollectionLinksProps) => (
  <ul>
    <li>
      <Link
        to="/collection/tracks"
        className={props.linkClassName}
        activeClassName="is-active"
        onClick={props.onClick}
      >
        <CollectionTracks />
      </Link>
    </li>
    <li>
      <Link
        to="/collection/recordings"
        className={props.linkClassName}
        activeClassName="is-active"
        onClick={props.onClick}
      >
        <CollectionRecordings />
      </Link>
    </li>
    <li>
      <Feature id="user_playlists">
        {allowed => {
          return (
            <Link
              to={allowed ? '/collection/playlists/personal' : 'collection/playlists'}
              className={props.linkClassName}
              activeClassName="is-active"
              onClick={props.onClick}
            >
              <CollectionPlaylists />
            </Link>
          );
        }}
      </Feature>
    </li>
    <li>
      <Link
        to="/collection/albums"
        className={props.linkClassName}
        activeClassName="is-active"
        onClick={props.onClick}
      >
        <CollectionAlbums />
      </Link>
    </li>
    <li>
      <Link
        to="/collection/artists"
        className={props.linkClassName}
        activeClassName="is-active"
        onClick={props.onClick}
      >
        <CollectionArtists />
      </Link>
    </li>
    <li>
      <Link
        to="/collection/events"
        className={props.linkClassName}
        activeClassName="is-active"
        onClick={props.onClick}
      >
        <CollectionLivestreamEvents />
      </Link>
    </li>
  </ul>
);

export default CollectionLinks;
