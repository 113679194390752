// @flow
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import CapacitorRipple from '../capacitor/Ripple';
import classNames from 'classnames';
import styles from './FacetShowAllBtn.css';
import qs from 'qs';
import { Link, withRouter } from 'react-router';
import { compose } from 'redux';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  type: string,
  selectedFacetIds: Array<number | string | boolean>,
  pathname: string,
  query: Object,
  onClick?: Function,
  router: Object,
};

type Props = OwnProps & { intl: IntlShape };

const messages = defineMessages({
  ariaButton: {
    id: 'filtered-list.apply-filters',
    defaultMessage: 'Apply filters',
  },
});

function generateLinkTo(
  filterKey: string,
  ids: Array<number | string | boolean>,
  pathname: string,
  query: Object
): string {
  const alteredQuery = { ...query };
  alteredQuery[filterKey] = ids.join(',');
  if (alteredQuery[filterKey] === '') {
    delete alteredQuery[filterKey];
  }
  return pathname + '?' + qs.stringify(alteredQuery);
}

const FacetApplyFiltersBtn = ({
  selectedFacetIds,
  type,
  pathname,
  query,
  onClick,
  intl,
  router,
}: Props) => {
  const applyFiltersBtnText = intl.formatMessage(messages.ariaButton);
  const linkTo = generateLinkTo(type, selectedFacetIds, pathname, query);

  const onClickBtn = event => {
    if (__CAPACITOR__) {
      event.preventDefault();
      router.replace(linkTo);
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Link
      to={linkTo}
      className={classNames('c-btn', 'c-btn--is-red', styles.btn)}
      aria-label={applyFiltersBtnText}
      onClick={onClickBtn}
      data-test="facet.group.apply-filters-btn"
    >
      <CapacitorRipple />
      {applyFiltersBtnText}
    </Link>
  );
};

export default compose(withRouter, injectIntl)(FacetApplyFiltersBtn);
