// @flow
declare var __CAPACITOR__: boolean;
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { motion } from 'framer-motion';
// $FlowFixMe
import React, { useState, useEffect } from 'react';
import type { ChildrenArray, Element } from 'react';
import CapacitorRipple from '../capacitor/Ripple';
import IconLabel from './IconLabel';

import styles from './ContextMenu.css';

type OwnProps = {
  'children'?: ChildrenArray<Element<any>>,
  'data-test'?: string,
};

const ContextMenu = ({ children, 'data-test': dataTest }: OwnProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const scrollingElement = __CAPACITOR__ ? window : document.querySelector('.content');
    if (scrollingElement) {
      scrollingElement.addEventListener('scroll', handleScroll);
      return () => {
        scrollingElement.removeEventListener('scroll', handleScroll);
      };
    }
    return () => {};
  }, []);

  return (
    <DropdownMenu.Root modal={false} onOpenChange={setIsOpen} open={isOpen}>
      <DropdownMenu.Trigger className={styles.button} data-test={dataTest}>
        <CapacitorRipple version="center" />
        <IconLabel size="default" name="more-horizontal" />
      </DropdownMenu.Trigger>
      {isOpen && (
        <DropdownMenu.Portal container={document.getElementById('radix-portal')}>
          <DropdownMenu.Content
            asChild
            side="top"
            align="start"
            alignOffset={50}
            sideOffset={0}
            forceMount
          >
            <motion.div
              key="dropdown"
              className={styles.menuItems}
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.15 }}
            >
              {children}
            </motion.div>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      )}
    </DropdownMenu.Root>
  );
};

export default ContextMenu;
