// @flow
import React from 'react';
import styles from './FacetGroupMenuTrigger.css';
import FacetGroupMenuTitle from './FacetGroupMenuTitle';
import CapacitorRipple from '../capacitor/Ripple';

type OwnProps = {
  getTriggerText: Function,
  loadAndShowAllFacets: Function,
  type: string,
};

type Props = OwnProps;

const FacetGroupMenuTrigger = ({ getTriggerText, loadAndShowAllFacets, type }: Props) => {
  return (
    <button className={styles.trigger} onClick={() => loadAndShowAllFacets(type)}>
      <CapacitorRipple />
      <FacetGroupMenuTitle getTriggerText={getTriggerText} type={type} />
    </button>
  );
};

export default FacetGroupMenuTrigger;
