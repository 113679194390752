// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';

import * as analyticsActions from '../../actions/analytics';

import styles from './DiscoverFooter.css';
import CapacitorRipple from '../capacitor/Ripple';

type OwnProps = {
  originPath: string,
};

type DispatchProps = {
  track: Function,
};

type Props = OwnProps & DispatchProps;

const DiscoverFooter = ({ originPath, track }: Props) => {
  const destinationPath = 'browse';

  const onBrowseClick = () => {
    track('Clicked CTA Button', {
      from: 'Discover',
      originPath,
      destinationPath,
    });
  };

  return (
    <div className={styles.container}>
      <p>
        <FormattedMessage
          id="discover.browse.text"
          defaultMessage="Didn’t find what you were looking for? Browse the catalogue by composers, genres, periods and more."
        />
      </p>
      <Link
        to={`/${destinationPath}`}
        className={classNames('c-btn', styles.btn)}
        onClick={onBrowseClick}
        onlyActiveOnIndex
      >
        <CapacitorRipple />
        <FormattedMessage id="discover.browse.cta" defaultMessage="Browse" />
      </Link>
    </div>
  );
};

const dispatchProps: DispatchProps = {
  track: analyticsActions.trackIdagioLive,
};

export default connect(null, dispatchProps)(DiscoverFooter);
