import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './A11ySkipLink.css';

export default class A11ySkipLink extends PureComponent {
  static propTypes = {
    target: PropTypes.string.isRequired,
  };

  render() {
    const { target } = this.props;

    return (
      <a href={target} className={styles.hide}>
        <FormattedMessage id="link-text" defaultMessage="Skip this section" />
      </a>
    );
  }
}
