// @flow
import React from 'react';
import classnames from 'classnames';
import styles from './style.css';
import IconLabel from '../../util/IconLabel';

type OwnProps = {
  className?: string,
  dark?: boolean,
  light?: boolean,
  direction: 'forward' | 'back',
  onClick?: Function,
};

const CircularSkipButton = ({ className, dark, light, direction, onClick }: OwnProps) => {
  const componentClassName = classnames(className, {
    [styles.component]: !dark && !light, // only use the default one if these are not specified
    [styles.darkComponent]: dark,
    [styles.lightComponent]: light,
  });
  return (
    <IconLabel
      size="18"
      name={`skip-${direction}`}
      className={componentClassName}
      onClick={onClick}
    />
  );
};

export default CircularSkipButton;
