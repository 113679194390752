import React, { Component } from 'react';
import { compose } from 'redux';
import { Link } from 'react-router';
import { FormattedMessage, injectIntl, intlShape, defineMessages } from 'react-intl';
import classnames from 'classnames';
import chromeComponent from '../hoc/chromeComponent';
import { CHROME_CLEAN, CONSTANT_MESSAGES } from '../constants';
import Head from '../components/chrome/Head';
import styles from './SuccessSonos.css';
import Footer from '../components/common/Footer';

const classesBtnIdagio = classnames('c-btn', 'c-btn--is-purple', styles.stageSonosSuccessIdagio);
const classesBtnSonos = classnames('c-btn', 'c-btn--has-outline', styles.stageSonosSuccessSonos);

const classesStage = classnames(styles.stageSonosSuccessParagraph, styles.stageSonosParagraph);

const classesStageInner = classnames(styles.ctaSectionInner, styles.section);

const messages = defineMessages({
  metaTitle: {
    id: 'sonos.meta-title',
    defaultMessage: 'Enjoy classical music with IDAGIO on your Sonos speakers',
  },
  metaDescription: {
    id: 'sonos.meta-description',
    defaultMessage:
      'IDAGIO has partnered up with Sonos to deliver high fidelity sound quality to your Sonos speakers. Simply connect your account in the Sonos app and start listening.',
  },
  faqLink: {
    id: 'sonos.successpage.question-cta',
    defaultMessage: 'See FAQs',
  },
  downloadLink: {
    id: 'sonos.successpage.stage-cta-sonos',
    defaultMessage: 'Download SONOS',
  },
});

class SuccessPageSonos extends Component {
  static propTypes = {
    intl: intlShape,
  };

  renderCTABanner() {
    const { intl } = this.props;
    const text = intl.formatMessage(messages.downloadLink);
    const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

    return (
      <section className={styles.stageSonosSuccess} data-test="back-to-sonos-step.success">
        <div className={classesStageInner}>
          <div className={styles.stageText}>
            <img
              className={styles.logoSonos}
              src="https://idagio-images.global.ssl.fastly.net/desktop-app/promo/sonos-logo.jpg"
              alt="Sonos Logo"
            />
            <h1 className={styles.ctaSuccessHeadline}>
              <FormattedMessage
                id="sonos.successpage.stage-heading"
                defaultMessage="You’re almost done"
              />
            </h1>
            <p className={classesStage}>
              <FormattedMessage
                id="sonos.successpage.stage-paragraph"
                defaultMessage="Please return to the Sonos app to authorize your IDAGIO account."
              />
            </p>
            <div className={styles.sonosSuccessCtaWrapper}>
              <Link className={classesBtnIdagio} to="/download">
                <FormattedMessage
                  id="sonos.successpage.stage-cta-idagio"
                  defaultMessage="Download IDAGIO"
                />
              </Link>
              <a
                className={classesBtnSonos}
                href="https://www.sonos.com/controller-app"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={ariaLink}
              >
                {text}
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderQuestion() {
    const sectionJoin = classnames(styles.sectionCenterColored, styles.gradient, styles.question);

    const { intl } = this.props;
    const text = intl.formatMessage(messages.faqLink);
    const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

    return (
      <section className={sectionJoin}>
        <h1>
          <FormattedMessage id="sonos.successpage.question-heading" defaultMessage="Questions?" />
        </h1>
        <p className={styles.questionParagraph}>
          <FormattedMessage
            id="sonos.successpage.question-paragraph"
            defaultMessage="If you need help at any stage along the way, check out our FAQs for more information, or get in touch with our team."
          />
        </p>
        <a
          href="https://support.idagio.com/"
          target="_blank"
          className="c-btn--is-purple c-btn"
          rel="noopener noreferrer"
          aria-label={ariaLink}
        >
          {text}
        </a>
      </section>
    );
  }

  render() {
    const { intl } = this.props;

    return (
      <div className={styles.bodySonos}>
        <Head
          title={intl.formatMessage(messages.metaTitle)}
          description={intl.formatMessage(messages.metaDescription)}
        />
        {this.renderCTABanner()}
        {this.renderQuestion()}
        <Footer />
      </div>
    );
  }
}

export default compose(chromeComponent({ type: CHROME_CLEAN }), injectIntl)(SuccessPageSonos);
