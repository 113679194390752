// @flow

import * as React from 'react';
import { DEFAULT_FEATURE_FLAGS } from '../../constants';

import type { AudioQuality } from '../../actions/client';

export const FeatureContext = React.createContext(DEFAULT_FEATURE_FLAGS);

type FeatureFlag = boolean | Array<AudioQuality>;

type Props = {
  id: string | Array<string>,
  children: (featureFlag: FeatureFlag) => React.Node,
};

export default class Feature extends React.PureComponent<Props> {
  render() {
    const { children, id } = this.props;
    return (
      <FeatureContext.Consumer>
        {featureFlags => {
          if (Array.isArray(id)) {
            return children(...id.map(flagId => featureFlags[flagId]));
          }
          return children(featureFlags[id]);
        }}
      </FeatureContext.Consumer>
    );
  }
}
