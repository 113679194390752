import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import classnames from 'classnames';
import stylesBrowseListContainer from './BrowselistContainer.css';
import { connect } from 'react-redux';
import * as analyticsActions from '../../actions/analytics';
import CapacitorRipple from '../capacitor/Ripple';
import { FormattedMessage } from 'react-intl';

class BrowseListContainer extends PureComponent {
  static propTypes = {
    children: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    link: PropTypes.string,
    overTitle: PropTypes.string,
    track: PropTypes.func.isRequired,
    category: PropTypes.string.isRequired,
  };

  onLinkClick = () => {
    const { track, title, category } = this.props;
    track('Selected View All', { subCategory: title, category, screen: 'browse' });
  };

  render() {
    const { children, title, link, overTitle } = this.props;
    const classesLink = classnames('c-text-link--is-visible', stylesBrowseListContainer.link);

    return (
      <React.Fragment>
        {overTitle && <p className="fz--delta">{overTitle}</p>}
        <h2 className={`${stylesBrowseListContainer.headline}`} data-test="browse.title">
          {title}
        </h2>
        {link && (
          <Link className={classesLink} onClick={this.onLinkClick} to={link}>
            <CapacitorRipple />
            <FormattedMessage id="browse.explore.link" defaultMessage="Explore" />
          </Link>
        )}
        {children}
      </React.Fragment>
    );
  }
}

export default connect(null, {
  track: analyticsActions.track,
})(BrowseListContainer);
