import React, { Component } from 'react';
import { QUEUE_TYPE_RECORDING } from '../../constants';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as playerActions from '../../actions/player';
import {
  selectRecordingIsLoadingToPlay,
  selectRecordingIsPlaying,
  selectRecordingIsQueued,
} from '../../selectors/recording';
import queueOriginComponent, {
  queueOriginComponentPropTypes,
} from '../../hoc/queueOriginComponent';
import * as analyticsActions from '../../actions/analytics';
import CapacitorRipple from '../capacitor/Ripple';
import stylesRecordingItem from './RecordingItem.css';
import { selectLocale } from '../../selectors/client';
import { splitLocale } from '../../lib/locale-utils';
import { Link } from 'react-router';
import { CircularPlayButton } from '../common/CircularPlayButton';

function getTrackingContext(props) {
  return {
    contextType: 'Category',
    contextTitle: props.contextTitle,
    contextId: props.recording.id.toString(),
  };
}

class RecordingItem extends Component {
  static propTypes = {
    recording: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.shape({
        de: PropTypes.string.isRequired,
        en: PropTypes.string.isRequired,
      }).isRequired,
      workId: PropTypes.number.isRequired,
      composerName: PropTypes.string.isRequired,
    }),
    language: PropTypes.string.isRequired,
    contextTitle: PropTypes.string.isRequired,
    togglePlayRecording: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    ...queueOriginComponentPropTypes,
  };

  render() {
    const { recording, isPlaying, language, isLoading } = this.props;
    const title = `${recording.composerName} • ${recording.title[language] || recording.title.en}`;

    return (
      <li>
        <div className={stylesRecordingItem.recordingItem}>
          <CircularPlayButton
            className={stylesRecordingItem.playButton}
            size="1.5rem"
            loading={isLoading}
            playing={isPlaying}
            onClick={this.togglePlay}
          />
          <Link to={`/works/${recording.workId}`} className={stylesRecordingItem.link}>
            <CapacitorRipple />
            {title}
          </Link>
        </div>
      </li>
    );
  }

  togglePlay = () => {
    if (this.props.isPlaying) {
      this.props.analyticsTrack('Pause Pressed', {
        source: 'Browse',
      });
    } else {
      this.props.analyticsTrack('Play Pressed', {
        source: 'Browse',
      });
    }
    this.props.setPlaybackTrackingContext(getTrackingContext(this.props));
    this.props.togglePlayRecording(this.props.recording, Date.now());
  };
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.recording;
  const locale = selectLocale(state);
  return {
    ...ownProps,
    isPlaying: selectRecordingIsPlaying(state, id, QUEUE_TYPE_RECORDING),
    isLoading: selectRecordingIsLoadingToPlay(state, id),
    language: splitLocale(locale).language,
  };
}

function getQueueOrigin(originId) {
  return {
    type: QUEUE_TYPE_RECORDING,
    id: originId,
  };
}

function mapQueueOriginStateToProps(state, ownProps) {
  const { id } = ownProps.recording;

  return {
    originId: id.toString(),
    tracks: [],
    trackIds: [],
    isPlaying: selectRecordingIsPlaying(state, id, QUEUE_TYPE_RECORDING),
    isQueued: selectRecordingIsQueued(state, id, QUEUE_TYPE_RECORDING),
  };
}

export default compose(
  connect(mapStateToProps, {
    setQueueAndPlay: playerActions.setQueueAndPlay,
    setPlaybackTrackingContext: analyticsActions.setPlaybackTrackingContext,
    analyticsTrack: analyticsActions.track,
    togglePlayRecording: playerActions.togglePlayRecording,
  }),
  queueOriginComponent(getQueueOrigin, mapQueueOriginStateToProps, getTrackingContext)
)(RecordingItem);
