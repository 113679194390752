// @flow
import React from 'react';
import SearchBox from './SearchBox';
import { debounce } from 'lodash';
import { MIN_SEARCH_QUERY_LENGTH, SEARCH_CHANGE_DEBOUNCE_INTERVAL } from '../../constants';
import { defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import { getPluralMessage, getSingularMessage } from '../messages/FilterMessages';

type OwnProps = {
  id: string,
  query?: string,
  onSearchChange: Function,
  count?: number,
  type: string,
  onSearchBlur?: Function,
  className?: string,
};

type Props = OwnProps & { intl: IntlShape };

const messages = defineMessages({
  placeholder: {
    id: 'local-list-search.placeholder',
    defaultMessage: 'Search in {pluralType}',
  },
  placeholderWithCount: {
    id: 'local-list-search.placeholder-with-count',
    defaultMessage: 'Search in {count, plural, one {# {singularType}} other {# {pluralType}}}',
  },
  title: {
    id: 'local-list-search.title',
    defaultMessage: '{pluralType} search',
  },
});

const LocalListSearch = ({
  query,
  onSearchChange,
  onSearchBlur,
  id,
  className,
  count,
  type,
  intl,
}: Props) => {
  const singularMessage = getSingularMessage(intl, type);
  const pluralMessage = getPluralMessage(intl, type);

  // don't search immediately after typing
  const debouncedOnChange = debounce(searchQuery => {
    onSearchChange(searchQuery);
  }, SEARCH_CHANGE_DEBOUNCE_INTERVAL);

  const onChange = searchQuery => {
    if (searchQuery.length >= MIN_SEARCH_QUERY_LENGTH || searchQuery.length === 0) {
      debouncedOnChange(searchQuery);
    }
  };

  const placeholder = count
    ? intl.formatMessage(messages.placeholderWithCount, {
        count,
        singularType: singularMessage,
        pluralType: pluralMessage,
      })
    : intl.formatMessage(messages.placeholder, { pluralType: pluralMessage });

  return (
    <div className={className} data-test="local-list-search">
      <SearchBox
        query={query}
        onChange={onChange}
        onBlur={onSearchBlur}
        titleSearch={intl.formatMessage(messages.title, { pluralType: pluralMessage })}
        id={id}
        placeholder={placeholder}
      />
    </div>
  );
};

export default injectIntl(LocalListSearch);
