// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as collectionActions from '../../actions/collection';
import * as analyticsActions from '../../actions/analytics';
import * as livestreamEventActions from '../../actions/livestreamEvents';

import {
  selectLivestreamEventCollection,
  selectCollectionIds,
  selectNextLivestreamEventCollectionCursor,
} from '../../selectors/collection';

import stylesEventCollection from '../../views/MyEvents.css';
import CollectionItem from './CollectionItem';

import dataComponent from '../../hoc/dataComponent';

import EmptyCollection from './EmptyCollection';
import PaginatedCollection from './PaginatedCollection';

import CollectionLivestreamEvents from '../messages/CollectionLivestreamEvents';

import { ENTITY_TYPE_LIVESTREAM_EVENT, ENTITY_TYPE_LIVESTREAM_EVENTS } from '../../constants';
import protectedComponent from '../../hoc/protectedComponent';
import EventCollectionFooter from '../livestream-event/EventCollectionFooter';
import { FormattedMessage } from 'react-intl';
import { selectMyEvents } from '../../selectors/myEvents';
import { Link } from 'react-router';
import IconLabel from '../util/IconLabel';
import CapacitorRipple from '../capacitor/Ripple';
import type { LivestreamEvent } from '../../shapes/types';
import shareableEntity from '../../hoc/shareableEntity';

type OwnProps = {
  showShareModal: Function,
};

type MapStateToProps = {
  isLoading: boolean,
  livestreamEventCollection: Array<LivestreamEvent>,
  livestreamEventIds: Array<string>,
  bookedEvents: Array<LivestreamEvent>,
  nextCursor: string,
};

type DispatchProps = {
  loadLivestreamEventCollection: Function,
  analyticsTrack: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const LivestreamEventCollection = ({
  livestreamEventCollection,
  livestreamEventIds,
  loadLivestreamEventCollection,
  analyticsTrack,
  bookedEvents,
  nextCursor,
  isLoading,
  showShareModal,
}: Props) => {
  const trackSelectedEvent = ({ content }) => {
    if (!content) {
      return;
    }
    analyticsTrack('Selected Event From Collection', {
      id: content.id,
    });
  };

  const LivestreamEventDisplayItem = event => {
    return (
      <CollectionItem
        collectionItem={event}
        entityType={ENTITY_TYPE_LIVESTREAM_EVENT}
        onItemClick={trackSelectedEvent}
        key={event.id}
      />
    );
  };

  const PurchasedEventsLink = () => {
    if (bookedEvents.length < 1) {
      return null;
    }

    return (
      <Link
        className={stylesEventCollection.link}
        to="/my-events"
        data-test="livestream-event-collection.link-to-purchased-events"
      >
        <CapacitorRipple />
        <div className={stylesEventCollection.icon}>
          <IconLabel name="tag" size="small" />
        </div>
        <FormattedMessage
          id="livestream-event.view-purchased-tickets"
          defaultMessage="View my purchased tickets"
        />
      </Link>
    );
  };

  const loadMore = () => {
    loadLivestreamEventCollection(nextCursor);
  };

  const hasLivestreamEvents = livestreamEventIds && livestreamEventIds.length !== 0;
  const hasMore = !!nextCursor;

  return (
    <React.Fragment>
      {hasLivestreamEvents ? (
        <div data-test="livestream-event-collection.container">
          <PaginatedCollection
            loadMore={loadMore}
            hasMore={hasMore}
            items={livestreamEventCollection}
            renderItem={LivestreamEventDisplayItem}
            isLoading={isLoading}
            styles={stylesEventCollection.list}
            title={<CollectionLivestreamEvents />}
            showShareModal={showShareModal}
          >
            <PurchasedEventsLink />
          </PaginatedCollection>
        </div>
      ) : (
        <React.Fragment>
          <EmptyCollection
            entityType={ENTITY_TYPE_LIVESTREAM_EVENT}
            showShareModal={showShareModal}
          />
          <PurchasedEventsLink />
        </React.Fragment>
      )}
      {!hasMore && <EventCollectionFooter trackingSource="eventCollectionPage" />}
    </React.Fragment>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  const livestreamEventCollection = selectLivestreamEventCollection(state);
  return {
    isLoading: state.lists.collection.livestreamEvents.loading,
    livestreamEventIds: selectCollectionIds(state).entities.livestreamEventIds,
    livestreamEventCollection,
    nextCursor: selectNextLivestreamEventCollectionCursor(state),
    bookedEvents: selectMyEvents(state),
  };
}

const dispatchProps: DispatchProps = {
  loadLivestreamEventCollection: collectionActions.loadLivestreamEventCollection,
  analyticsTrack: analyticsActions.track,
};

function fetchData(store) {
  return [
    store.dispatch(collectionActions.loadLivestreamEventCollection()),
    store.dispatch(livestreamEventActions.loadMyEvents()),
  ];
}

function getShareTrackingContext() {
  return {
    sharedContentType: 'Livestream Event',
    sharedContentId: null,
    contextType: 'favouriteLivestreamEvents',
    contentId: null,
  };
}

export default compose(
  protectedComponent({ authenticatedOnly: true }),
  dataComponent(fetchData),
  connect(mapStateToProps, dispatchProps),
  shareableEntity(ENTITY_TYPE_LIVESTREAM_EVENTS, getShareTrackingContext)
)(LivestreamEventCollection);
