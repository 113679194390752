// @flow
import React from 'react';
import PlayableItemPreview from '../common/PlayableItemPreview';

type OwnProps = {
  item: Object,
  onClick: Function,
  beforeTogglePlay: Function,
  groupId: string,
};

type Props = OwnProps;

const DiscoverLiveCompareItem = ({ item, onClick, beforeTogglePlay, groupId }: Props) => {
  return (
    <PlayableItemPreview
      item={item}
      onClick={onClick}
      beforeTogglePlay={beforeTogglePlay}
      showPlayButton={false}
      groupId={groupId}
    />
  );
};

export default DiscoverLiveCompareItem;
