// @flow
// $FlowFixMe
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { Link } from 'react-router';
import { motion, useAnimation, useMotionValue } from 'framer-motion';
import { connect } from 'react-redux';
import * as clientActions from '../../actions/client';

import CapacitorRipple from '../capacitor/Ripple';
import IconLabel from '../util/IconLabel';
import SliderControls from './SliderControls';

import styles from './Slider.css';
import { DEFAULT_SLIDER_TRANSITION } from '../../constants';
import { FormattedMessage } from 'react-intl';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  items: Array<Object>,
  title: string | React$Element<typeof FormattedMessage>,
  subtitle: string | React$Element<typeof FormattedMessage>,
  viewAllLabel: React$Element<any>,
  viewAllLink?: string,
};

type MapStateToProps = {
  hasInteractedWithSlider: boolean,
};

type DispatchProps = {
  interactWithSlider: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const Slider = ({
  items,
  title,
  subtitle,
  viewAllLabel,
  viewAllLink,
  hasInteractedWithSlider,
  interactWithSlider,
}: Props) => {
  const sliderRef = useRef();
  const timeoutRef = useRef();
  const [slideWidth, setSlideWidth] = useState(400);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [containerWidth, setContainerWidth] = useState(1200);
  const [allowClick, setAllowClick] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const hasInteractedWithSliderRef = useRef();
  // We need to use a ref so the value is up to date in the timeout
  hasInteractedWithSliderRef.current = hasInteractedWithSlider;

  const controls = useAnimation();
  const x = useMotionValue(0);

  const getLeftDragConstraint = useCallback(() => {
    // Make sure we can only drag until the right edge of the last slide lines
    // up with the right edge of the slider container.
    // We need to account for container padding and spacing between slides,
    // which is different on mobile
    return containerWidth - slideWidth * items.length + (isMobile ? -16 : 32);
  }, [containerWidth, slideWidth, items.length, isMobile]);

  function updateSliderState() {
    const currentX = x.get();
    if (currentX >= 0 && !isAtBeginning) {
      setIsAtBeginning(true);
    } else if (currentX <= getLeftDragConstraint() && !isAtEnd) {
      setIsAtEnd(true);
    } else if (currentX < 0 && currentX > getLeftDragConstraint() && (isAtBeginning || isAtEnd)) {
      setIsAtEnd(false);
      setIsAtBeginning(false);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => x.onChange(updateSliderState), [updateSliderState]);

  // Only actually listen for a drag if there is something to drag. If
  // the drag constraint would be larger than 0, that means the total
  // slide width is less than the container width.
  const canDrag = getLeftDragConstraint() < 0;

  // If the user enters a slider with their mouse and stays longer than a
  // second without dragging, we show a nudge animation that reveals that
  // there is more to the right of the viewport edge, hopefully reinforcing the
  // idea that the slider can be dragged.
  const handleMouseEnter = () => {
    if (!hasInteractedWithSlider && canDrag) {
      timeoutRef.current = setTimeout(async () => {
        // Check again after the timeout since the user might have dragged in the meantime
        if (!hasInteractedWithSliderRef.current) {
          await controls.start({
            x: [0, -100, 0],
            transition: {
              duration: 1,
            },
          });
          if (!hasInteractedWithSliderRef.current) {
            interactWithSlider();
          }
        }
      }, 1000);
    }
  };

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    debounce(() => {
      const newSlideWidth = sliderRef.current.querySelector(`.${styles.slide}`).clientWidth;
      setSlideWidth(newSlideWidth);
      setContainerWidth(sliderRef.current.clientWidth);
      setIsMobile(window.innerWidth < 720);
    }, 200),
    []
  );

  useEffect(() => {
    setSlideWidth(sliderRef.current.querySelector(`.${styles.slide}`).clientWidth);
    setContainerWidth(sliderRef.current.clientWidth);
    setIsMobile(window.innerWidth < 720);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const handleDragStart = () => {
    setAllowClick(false);
    if (!hasInteractedWithSlider) {
      interactWithSlider();
    }
  };

  const handleDragEnd = () => {
    setTimeout(() => setAllowClick(true), 200);
  };

  const slidesPerPage = containerWidth / slideWidth >= 2 ? 2 : 1;

  const handleNextClick = () => {
    interactWithSlider();
    const target = Math.max(x.get() - slideWidth * slidesPerPage, getLeftDragConstraint());
    controls.start({
      x: target,
      transition: DEFAULT_SLIDER_TRANSITION,
    });
  };

  const handlePrevClick = () => {
    interactWithSlider();
    const target = Math.min(x.get() + slideWidth * slidesPerPage, 0);
    controls.start({
      x: target,
      transition: DEFAULT_SLIDER_TRANSITION,
    });
  };

  const handleWheel = useCallback(
    event => {
      const { deltaX, deltaY } = event;
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        event.preventDefault();
        const target = Math.min(0, Math.max(x.get() - deltaX, getLeftDragConstraint()));
        controls.start({
          x: target,
          transition: { duration: 0.015, ease: 'linear' },
        });
      }
    },
    [controls, x, getLeftDragConstraint]
  );

  useEffect(() => {
    if (!__CAPACITOR__) {
      const slider = sliderRef.current;
      slider.addEventListener('wheel', handleWheel, { passive: false });

      return () => {
        slider.removeEventListener('wheel', handleWheel);
      };
    }
    return () => {};
  }, [handleWheel]);

  return (
    <div
      className={styles.container}
      ref={sliderRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-test="slider"
    >
      <div className={styles.header}>
        <div className={styles.leftColumn}>
          {title && (
            <h2 className={styles.title}>
              {viewAllLink ? (
                <Link to={viewAllLink} onlyActiveOnIndex className={styles.headerViewAllLink}>
                  <CapacitorRipple />
                  {title}
                </Link>
              ) : (
                title
              )}
              {canDrag && (
                <SliderControls
                  onNextClick={handleNextClick}
                  onPrevClick={handlePrevClick}
                  canGoBack={!isAtBeginning}
                  canGoForward={!isAtEnd}
                />
              )}
            </h2>
          )}
          {subtitle && <p>{subtitle}</p>}
        </div>
        <div>
          {viewAllLink && (
            <Link className={styles.viewAllLink} to={viewAllLink} onlyActiveOnIndex>
              <CapacitorRipple />
              {viewAllLabel}
              <IconLabel name="chevron-right" className={styles.viewAllIcon} />
            </Link>
          )}
        </div>
      </div>
      <motion.div
        animate={controls}
        style={{ x }}
        drag="x"
        dragConstraints={{
          right: 0,
          left: getLeftDragConstraint(),
        }}
        className={styles.slider}
        dragElastic={0.1}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        dragListener={canDrag}
        dragTransition={{
          power: isMobile ? 0.6 : 0.2,
          timeConstant: 150,
          modifyTarget: target => {
            const targetIndex = Math.max(
              0,
              Math.min(items.length - 1, Math.round(-target / slideWidth))
            );
            return -targetIndex * slideWidth;
          },
        }}
      >
        {items.map((item, index) => (
          <div
            className={styles.slide}
            key={index}
            style={allowClick ? {} : { pointerEvents: 'none' }}
          >
            {item}
          </div>
        ))}
      </motion.div>
    </div>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    hasInteractedWithSlider: state.client.hasInteractedWithSlider,
  };
}

const dispatchProps: DispatchProps = {
  interactWithSlider: clientActions.interactWithSlider,
};

export default connect(mapStateToProps, dispatchProps)(Slider);
