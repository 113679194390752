import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const SubscribeModalStepNumberText = ({ step, totalSteps }) => (
  <FormattedMessage
    id="subscribe-modal.step-number.text"
    defaultMessage="Step {step} of {totalSteps}"
    values={{ step, totalSteps }}
  />
);

SubscribeModalStepNumberText.propTypes = {
  step: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default SubscribeModalStepNumberText;
