// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import styles from './ActiveFacetsByGroup.css';
import ClearActiveFacetsByGroupButton from './ClearActiveFacetsByGroupButton';
import {
  getSingularMessage,
  getPluralMessage,
  getSingularMessageWithArticle,
} from '../messages/FilterMessages';

type OwnProps = {
  activeFacets: Array<any>,
  location: Object,
  type: string,
  onClickClear?: Function,
};

type Props = OwnProps & { intl: IntlShape };

const FacetGroupContentHeader = ({ activeFacets, type, onClickClear, location, intl }: Props) => {
  const singularMessage = getSingularMessage(intl, type);
  const singularMessageWithArticle = getSingularMessageWithArticle(intl, type);
  const pluralMessage = getPluralMessage(intl, type);
  const count = activeFacets.length;

  if (!!count) {
    const { pathname, query } = location;

    return (
      <React.Fragment>
        <span data-test="facet.group.selected" data-test-length={count}>
          <FormattedMessage
            id="facet-group.num-selected"
            defaultMessage="{count, plural, one {# {singularType}} other {# {pluralType}}} selected"
            values={{
              count,
              singularType: singularMessage,
              pluralType: pluralMessage,
            }}
          />
        </span>
        <ClearActiveFacetsByGroupButton
          type={type}
          pathname={pathname}
          query={query}
          className={styles.clearButton}
          onClickClear={onClickClear}
        />
      </React.Fragment>
    );
  }

  return (
    <span data-test="facet.group.please-select">
      <FormattedMessage
        id="facet-group.please-select"
        defaultMessage="Please select {type}"
        values={{ type: singularMessageWithArticle }}
      />
    </span>
  );
};

export default injectIntl(FacetGroupContentHeader);
