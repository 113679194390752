import React from 'react';
import PropTypes from 'prop-types';
import Search from '../search/QuickSearch';

import styles from './BrowseCapacitorNavigation.css';

const BrowseCapacitorNavigation = ({ children }) => {
  return (
    <React.Fragment>
      <div className={styles.spacer} />
      <div className={styles.navigation}>
        <Search />
        <div className={styles.nav}>{children}</div>
      </div>
    </React.Fragment>
  );
};

BrowseCapacitorNavigation.propTypes = {
  children: PropTypes.any.isRequired,
};

export default BrowseCapacitorNavigation;
