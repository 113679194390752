// @flow
import React from 'react';
import IconLabel from '../util/IconLabel';

import CapacitorRipple from '../capacitor/Ripple';
import styles from './FacetFilterButton.css';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

type OwnProps = {
  toggleFacetFilterDrawer: Function,
  isActive: boolean,
  isDisabled: boolean,
};

const FacetFilterButton = ({ toggleFacetFilterDrawer, isActive, isDisabled }: OwnProps) => {
  const buttonClassNames = classNames(styles.component, {
    [styles.isActive]: isActive,
    [styles.isDisabled]: isDisabled,
  });

  return (
    <button
      className={buttonClassNames}
      onClick={toggleFacetFilterDrawer}
      data-test="filtered-list.btn.facet-filter"
      disabled={isDisabled}
    >
      <CapacitorRipple />
      <IconLabel name="filter" className={styles.icon} size="small" />
      <span className={styles.text}>
        <FormattedMessage id="facet.button.filter" defaultMessage="Filter" />
      </span>
    </button>
  );
};

export default FacetFilterButton;
