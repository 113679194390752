import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router';
import Head from '../components/chrome/Head';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Qs from 'qs';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { keyBy, intersection } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import InfiniteScroll from '../vendor/InfiniteScroll';
import dataComponent from '../hoc/dataComponent';
import collectibleEntity, { collectibleEntityPropTypes } from '../hoc/collectibleEntity';
import shareableEntity, { shareableEntityPropTypes } from '../hoc/shareableEntity';
import queueOriginComponent, { queueOriginComponentPropTypes } from '../hoc/queueOriginComponent';
import trackedViewComponent from '../hoc/trackedViewComponent';
import { selectUserIsPatron } from '../selectors/user';
import { selectAlbumsByRecording, selectAlbumsAreLoadingForRecording } from '../selectors/album';
import RecordingAlbums from '../components/recording/RecordingAlbums';
import CapacitorHeaderBar from '../components/capacitor/HeaderBar';
import CapacitorDownloadButton from '../components/capacitor/DownloadButton';

import List from '../components/util/List';
import RecordingInfo from '../components/recording/RecordingInfo';
import RecordingTags from '../components/recording/RecordingTags';
import PlayButton from '../components/common/PlayButton';
import { messages as radioButtonMessages } from '../components/common/PlayRadioButton';
import RecordingTrackListItem from '../components/recording/RecordingTrackListItem';
import InterpreterList from '../components/common/InterpreterList';
import TitleWithPopularTitle from '../components/common/TitleWithPopularTitle';
import CollectionButton from '../components/common/CollectionButton';
import ShareButton from '../components/common/ShareButton';
import ComposerList from '../components/common/ComposerList';
import AuthorList from '../components/common/AuthorList';
import AddToPlaylistButton from '../components/common/AddToPlaylistButton';
import CapacitorSkeletonIfLoading from '../components/capacitor/SkeletonIfLoading';

import getRecordingIsPopular from '../utils/getRecordingIsPopular';

import * as Shapes from '../shapes';

import { loadRecordingsByWork, loadRecordingWithAlbums } from '../actions/recording';
import * as uiActions from '../actions/ui';
import * as playerActions from '../actions/player';

import { QUEUE_TYPE_RECORDING, ENTITY_TYPE_RECORDING } from '../constants';

import {
  selectRecording,
  selectRecordingIsPlaying,
  selectRecordingIsQueued,
  selectRecordingsByWork,
} from '../selectors/recording';

import { formatGoogleMusicAlbum } from '../lib/googleMusicActions';

import styles from './Recording.css';
import stylesItem from '../components/recording/RecordingItem.css';
import playlistableEntity, { playlistableEntityPropTypes } from '../hoc/playlistableEntity';
import * as analyticsActions from '../actions/analytics';
import OriginalContentVideo from './OriginalContentVideo';

import { recordingVideos } from '../discover-videos';
import {
  getSessionValueFromSessionStorage,
  isReload,
  saveLastStateInSessionStorage,
  SCROLL_CONTEXT_KEYS,
} from '../utils/sessionStorage';
import RecordingItemLink from '../components/recording/RecordingItemLink';
import ShareContextMenuItem from '../components/common/ShareContextMenuItem';
import AddToPlaylistContextMenuItem from '../components/common/AddToPlaylistContextMenuItem';
import ContextMenu from '../components/util/ContextMenu';

const messages = defineMessages({
  metaDescription: {
    id: 'recording.meta.description',
    defaultMessage:
      'Listen to {composerName}’s {title}, performed by {summary}. Discover and compare alternative recordings.',
  },
  playAllButtonPausedText: {
    id: 'recording.play-all.paused.title',
    defaultMessage: 'Play recording',
  },
  playAllButtonPlayingText: {
    id: 'recording.play-all.playing.title',
    defaultMessage: 'Pause recording',
  },
  shareButton: {
    id: 'recording.share-recording',
    defaultMessage: 'Share recording',
  },
});

function getQueueOrigin(originId) {
  return {
    type: QUEUE_TYPE_RECORDING,
    id: originId,
  };
}

function getCanonicalRoute(id) {
  return `/recordings/${id}`;
}

const DEFAULT_NUM_WORK_RECORDINGS_TO_SHOW = 20;

class Recording extends PureComponent {
  static propTypes = {
    recording: Shapes.Recording.isRequired,
    work: Shapes.Work.isRequired,
    workRecordings: PropTypes.array.isRequired,

    trackIds: PropTypes.array.isRequired,
    mapPiecesToTracks: PropTypes.object.isRequired,

    isPlaying: PropTypes.bool.isRequired,

    pause: PropTypes.func.isRequired,
    setShuffledQueue: PropTypes.func.isRequired,

    loadRecordingsByWork: PropTypes.func.isRequired,
    isPopular: PropTypes.bool.isRequired,
    showAddToPlaylistModal: PropTypes.func.isRequired,

    albumsAreLoading: PropTypes.bool.isRequired,

    ...shareableEntityPropTypes,
    ...queueOriginComponentPropTypes,
    ...collectibleEntityPropTypes,
    ...playlistableEntityPropTypes,
    intl: intlShape,
  };

  state = {
    shownWorkRecordings: DEFAULT_NUM_WORK_RECORDINGS_TO_SHOW,
  };

  constructor(props) {
    super(props);
    this.prevLocation = props.location;
  }

  static onDataFetch(store, params, location) {
    const state = store.getState();
    const recordingId = params.recording_id;
    const queuedTrack = location.query && location.query.trackId;

    const recording = selectRecording(state, recordingId);

    if (queuedTrack) {
      store.dispatch(playerActions.rewind());

      const tracks = recording.trackIds.map(playerActions.createItem);
      store.dispatch(
        playerActions.setShuffledQueue(
          getQueueOrigin(recordingId),
          tracks,
          // In capacitor, we can immediately start the playback because with
          // the android navigate player, we don't have to wait for user
          // interaction.
          __CAPACITOR__,
          undefined,
          true,
          undefined,
          true,
          playerActions.getItemById(queuedTrack, tracks)
        )
      );
    }

    return store.dispatch(loadRecordingsByWork(recording.work.id));
  }

  componentDidMount() {
    const hashObj = Qs.parse(window.location.hash.slice(1));
    if (hashObj.t) {
      const { trackIds, setShuffledQueue, originId } = this.props;
      const tracks = trackIds.map(playerActions.createItem);
      setShuffledQueue(
        getQueueOrigin(originId),
        trackIds.map(playerActions.createItem),
        false,
        null,
        true,
        undefined,
        true,
        playerActions.getItemById(hashObj.t, tracks)
      );
    }

    // if we are navigating back/forward without reloading the page, restore the last shown work recordings
    if (!isReload() && this.props.location.action === 'POP') {
      this.retrieveLastShownWorkRecordings();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.prevLocation = prevProps.location;
      this.saveLastScrollPositionAndShownWorkRecordings();
      this.retrieveLastShownWorkRecordings();
    }
  }

  componentWillUnmount() {
    this.saveLastScrollPositionAndShownWorkRecordings();
  }

  onClickTrack = (trackId, index) => {
    this.props.playFromTrack(trackId, index);
  };

  renderAllWorkRecordings() {
    const { work, workRecordings } = this.props;
    if (workRecordings.length > 1) {
      const hasMore = workRecordings.length > this.state.shownWorkRecordings;
      return (
        <div className={styles.recordings}>
          <p className={`${styles.heading} ${__CAPACITOR__ ? 'fz--gamma' : 'fz--delta'}`}>
            <Link to={`/works/${work.id}`}>
              <FormattedMessage
                id="recording.all-recordings-of"
                defaultMessage={'All recordings of "{workTitle}"'}
                values={{
                  workTitle: work.title,
                }}
              />
            </Link>
          </p>

          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMoreWorkRecordings}
            hasMore={hasMore}
            scrollableSelector={__CAPACITOR__ ? undefined : '.content'}
            useWindow={__CAPACITOR__}
            loader={null}
          >
            <List
              items={workRecordings.slice(0, this.state.shownWorkRecordings)}
              renderItem={this.renderWorkRecording}
            />
          </InfiniteScroll>
        </div>
      );
    }

    return null;
  }

  renderWorkPart = workPart => {
    if (!workPart.pieces.length) {
      return null;
    }

    return (
      <li className={styles.list} key={workPart.id}>
        {workPart.isOverture || !workPart.title ? null : (
          <h2 className="fz--theta">{workPart.title}</h2>
        )}
        <List items={workPart.pieces} renderItem={this.renderPiece} />
      </li>
    );
  };

  renderPiece = (piece, index) => {
    const { mapPiecesToTracks } = this.props;
    const track = mapPiecesToTracks[piece.id];
    return this.renderTrack(track, index);
  };

  // Second argument of `renderTrack`, `index`, in this case refers to the
  // index inside the work-part, not inside the queue
  renderTrack = track => {
    const { onAddTrackToPlaylistClick, isQueuedTrack, trackIds } = this.props;
    const indexInQueue = trackIds.indexOf(track.id.toString());
    const isCurrentTrack = isQueuedTrack(track.id, indexInQueue);
    return (
      <RecordingTrackListItem
        key={track.id}
        track={track}
        index={indexInQueue}
        duration={track.duration}
        isCurrentTrack={isCurrentTrack}
        onClick={this.onClickTrack}
        onAddToPlaylistButtonClick={onAddTrackToPlaylistClick}
        recordingId={this.props.recording.id}
        isPlaying={this.props.isPlaying}
      />
    );
  };

  renderWorkRecording = recording => {
    const isCurrentRecording = recording.id === this.props.recording.id;
    const classesItem = classnames(stylesItem.item, {
      [stylesItem.isCurrentRecording]: isCurrentRecording,
    });

    return (
      <li key={recording.id} className={classesItem} data-module-variant="sidebar">
        <RecordingItemLink recording={recording} hideWorkTitle />
      </li>
    );
  };

  renderVideo = () => {
    const { recording } = this.props;

    if (recordingVideos.find(x => x.id === recording.id)) {
      return <OriginalContentVideo id={recording.id} type="recording" />;
    }

    return null;
  };

  renderHeader() {
    const { recording, isPlaying, isInCollection, isPopular, intl } = this.props;
    const { formatMessage } = intl;
    const { recordingDate, location, venue, work, tags } = recording;
    const { togglePlayAll, toggleIsInCollection, showShareModal, onAddToPlaylistClick } =
      this.props;

    const isOnlyRadioAvailable = __CAPACITOR__ && !this.props.userIsPatron;
    const pausedTitle = isOnlyRadioAvailable
      ? intl.formatMessage(radioButtonMessages.playAllButtonPausedText)
      : intl.formatMessage(messages.playAllButtonPausedText);
    const playingTitle = isOnlyRadioAvailable
      ? intl.formatMessage(radioButtonMessages.playAllButtonPlayingText)
      : intl.formatMessage(messages.playAllButtonPlayingText);

    const showAddToPlaylistButton = onAddToPlaylistClick && recording.tracks.length > 0;
    const showShareButton = showShareModal;
    const shareButtonText = formatMessage(messages.shareButton);

    return (
      <React.Fragment>
        <div className={styles.header}>
          <div className={styles.headerName}>
            <ComposerList authors={work.authors} hyperlinks />
          </div>
          <h1 className={`${styles.workTitle} fz--beta`}>
            <Link to={`/works/${work.id}`}>
              <TitleWithPopularTitle {...work} />
            </Link>
          </h1>
          <AuthorList authors={work.authors} className={styles.authorList} />
          <div className={styles.recording}>
            <InterpreterList
              hyperlinks
              recording={recording}
              linkClassNames="work-header__name-link"
            />
          </div>
          <RecordingInfo
            className={styles.recording}
            location={location}
            venue={venue}
            date={recordingDate}
          />
          {tags && (
            <div className={styles.tags}>
              <RecordingTags tags={tags} isPopular={isPopular} />
            </div>
          )}
        </div>
        <div className={styles.headerBtns} data-test="recording.header.actions">
          <PlayButton
            playing={isPlaying}
            onClick={togglePlayAll}
            pausedTitle={pausedTitle}
            playingTitle={playingTitle}
            moduleVariant="header"
            size="small"
            labelVisible
            isRadio={__CAPACITOR__ && !this.props.userIsPatron}
          />
          <div className={styles.actionBtns}>
            <CollectionButton active={isInCollection} onClick={toggleIsInCollection} />
            {__CAPACITOR__ && (
              <CapacitorDownloadButton
                entityType={ENTITY_TYPE_RECORDING}
                entityIdOrSlug={recording.id}
              />
            )}
            {!__CAPACITOR__ ? (
              <React.Fragment>
                <AddToPlaylistButton onClick={onAddToPlaylistClick} />
                <ShareButton onClick={showShareModal} text={shareButtonText} />
              </React.Fragment>
            ) : (
              <ContextMenu>
                {showAddToPlaylistButton && (
                  <AddToPlaylistContextMenuItem onClick={onAddToPlaylistClick} />
                )}
                {showShareButton && (
                  <ShareContextMenuItem onClick={showShareModal} text={shareButtonText} />
                )}
              </ContextMenu>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { intl } = this.props;
    const { formatMessage, locale } = intl;
    const { recording, work } = this.props;
    const { summary } = recording;
    const { title, composer, workparts } = work;
    const classesDetails = classnames(styles.details, styles.sectionTwoThirds);
    const classesSections = classnames(styles.sections, 'u-page-container');
    const hasAlbums = this.props.albums.length > 0;

    return (
      <div className={classesSections}>
        <CapacitorSkeletonIfLoading>
          <Head
            title={title}
            description={formatMessage(messages.metaDescription, {
              composerName: composer.name,
              title: title,
              summary: summary,
            })}
            opengraph={[{ property: 'og:type', content: 'music.album' }]}
            googleMusicAction={{
              type: 'application/ld+json',
              innerHTML: `${formatGoogleMusicAlbum(recording, locale)}`,
            }}
            canonicalRoute={getCanonicalRoute(recording.id)}
          />
          <div className={classesDetails}>
            {__CAPACITOR__ && (
              <CapacitorHeaderBar
                title={<TitleWithPopularTitle {...this.props.work} />}
                onlyShowTitleOnScroll
              />
            )}
            {this.renderVideo()}
            {this.renderHeader()}
            <div className={styles.workParts}>
              <List items={workparts} renderItem={this.renderWorkPart} />
            </div>
            {hasAlbums && (
              <p className={classnames('fz--gamma', styles.albumsTitle)}>
                <FormattedMessage
                  id="recording-albums-heading"
                  defaultMessage="Recording appears on:"
                />
              </p>
            )}
            <RecordingAlbums loading={this.props.albumsAreLoading} albums={this.props.albums} />
          </div>
          <div className={styles.sectionThird}>{this.renderAllWorkRecordings()}</div>
        </CapacitorSkeletonIfLoading>
      </div>
    );
  }

  setShownWorkRecordings = count => {
    this.setState({ shownWorkRecordings: count });
  };

  retrieveLastShownWorkRecordings = () => {
    const sessionValue = getSessionValueFromSessionStorage(
      this.props.location,
      SCROLL_CONTEXT_KEYS.RECORDING
    );
    if (sessionValue && sessionValue.workRecordingsCount) {
      this.setShownWorkRecordings(sessionValue.workRecordingsCount);
    }
  };

  saveLastScrollPositionAndShownWorkRecordings = () => {
    return saveLastStateInSessionStorage(this.prevLocation, true, SCROLL_CONTEXT_KEYS.RECORDING, {
      workRecordingsCount: this.state.shownWorkRecordings,
    });
  };

  loadMoreWorkRecordings = () => {
    this.setState({
      shownWorkRecordings:
        DEFAULT_NUM_WORK_RECORDINGS_TO_SHOW + this.state.shownWorkRecordings <=
        this.props.workRecordings.length
          ? DEFAULT_NUM_WORK_RECORDINGS_TO_SHOW + this.state.shownWorkRecordings
          : this.props.workRecordings.length,
    });
  };
}

function fetchData(store, { recording_id: recordingId }) {
  return [store.dispatch(loadRecordingWithAlbums(recordingId))];
}

function mapStateToProps(state, ownProps) {
  const id = parseInt(ownProps.params.recording_id, 10);
  const recording = selectRecording(state, id);
  const albums = selectAlbumsByRecording(state, id);
  return {
    recording,
    albums,
    userIsPatron: selectUserIsPatron(state),
    workRecordings: selectRecordingsByWork(state, recording.work.id),
    isPopular: getRecordingIsPopular(recording),
    albumsAreLoading: selectAlbumsAreLoadingForRecording(state, id),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { work, tracks } = stateProps.recording;

  // Remove pieces without tracks:
  const mapPiecesToTracks = keyBy(tracks, track => track.piece.id);

  const workPieces = work.workparts.reduce(
    (acc, workpart) => [...acc, ...(workpart.pieces || []).map(item => item.id.toString())],
    []
  );

  const trackPieces = Object.keys(mapPiecesToTracks);
  const validPieces = intersection(workPieces, trackPieces);

  if (validPieces.length) {
    work.workparts = work.workparts.map(workPart => ({
      ...workPart,
      pieces: workPart.pieces.filter(piece => !!mapPiecesToTracks[piece.id]),
    }));
  } else {
    work.workparts = [];
    stateProps.recording.tracks = [];
  }

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,

    work,
    mapPiecesToTracks,
  };
}

function getCollectibleEntityDescription(props) {
  return {
    id: props.recording.id,
    trackingSource: 'Recording',
  };
}

function mapQueueOriginStateToProps(state, ownProps) {
  const { id, tracks, trackIds } = ownProps.recording;
  return {
    originId: id.toString(),

    tracks,
    trackIds,
    isPlaying: selectRecordingIsPlaying(state, id, QUEUE_TYPE_RECORDING),
    isQueued: selectRecordingIsQueued(state, id, QUEUE_TYPE_RECORDING),
  };
}

function getShareTrackingContext(props) {
  return {
    sharedContentType: 'Recording',
    sharedContentId: props.recording.id,
    contextType: 'Recording',
    contentId: props.recording.id,
  };
}

function getTrackingContext(props) {
  return {
    contextType: 'recording',
    contextId: props.recording.id.toString(),
  };
}

function getEntityTrackingData(props) {
  return {
    itemType: 'recording',
    itemId: props.recording.id.toString(),
  };
}

const trackingProps = (state, params) => {
  return {
    contextType: 'Recording',
    contextId: params.recording_id,
  };
};

export default compose(
  trackedViewComponent('Recording', trackingProps),
  dataComponent(fetchData),
  connect(
    mapStateToProps,
    {
      loadRecordingsByWork,
      showAddToPlaylistModal: uiActions.showAddToPlaylistModal,
      setShuffledQueue: playerActions.setShuffledQueue,
      analyticsTrack: analyticsActions.track,
    },
    mergeProps
  ),
  shareableEntity(ENTITY_TYPE_RECORDING, getShareTrackingContext),
  collectibleEntity(ENTITY_TYPE_RECORDING, getCollectibleEntityDescription),
  playlistableEntity({
    getEntityTrackingData,
    getTrackingContext,
    entityType: ENTITY_TYPE_RECORDING,
  }),
  queueOriginComponent(getQueueOrigin, mapQueueOriginStateToProps, getTrackingContext),
  injectIntl,
  withRouter
)(Recording);
