import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NoScript from 'react-noscript';
import classnames from 'classnames';

import LoadingIndicator from '../common/LoadingIndicator';
import InfiniteScroll from '../../vendor/InfiniteScroll';

import styles from './PaginatedList.css';

export default class PaginatedList extends Component {
  static propTypes = {
    children: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    currentCursor: PropTypes.string,
    nextCursor: PropTypes.string,
    prevCursor: PropTypes.string,
    location: PropTypes.object.isRequired,
    loadMore: PropTypes.func.isRequired,
  };

  renderStaticPaginationControls() {
    const { currentCursor, nextCursor, prevCursor, location } = this.props;
    const { pathname, search } = location;
    const encodedNextCursor = encodeURIComponent(nextCursor);
    const encodedPrevCursor = encodeURIComponent(prevCursor);
    let nextPathname = `/${pathname}/pages/${encodedNextCursor}`;
    let prevPathname = `/${pathname}/pages/${encodedPrevCursor}`;
    if (currentCursor) {
      const base = pathname.split(`/${encodeURIComponent(currentCursor)}`)[0];
      nextPathname = `/${base}/${encodedNextCursor}`;
      prevPathname = `/${base}/${encodedPrevCursor}`;
    }
    const toNext = nextPathname + search;
    const toPrev = prevPathname + search;
    const classesstaticPaginationNext = classnames(
      styles.staticPaginationNext,
      'c-text-link--is-visible'
    );
    return (
      <div className={styles.staticPagination}>
        {prevCursor && (
          <a className="c-text-link--is-visible" href={toPrev}>
            Previous
          </a>
        )}
        {nextCursor && (
          <a className={classesstaticPaginationNext} href={toNext}>
            Next
          </a>
        )}
      </div>
    );
  }

  render() {
    const { children, loadMore, nextCursor, isLoading } = this.props;

    return (
      <div>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={!!nextCursor}
          scrollableSelector={__CAPACITOR__ ? undefined : '.content'}
          useWindow={__CAPACITOR__}
          loader={null}
        >
          {children}
        </InfiniteScroll>
        <NoScript>{this.renderStaticPaginationControls()}</NoScript>
        <div className={styles.loadingIndicator}>
          <LoadingIndicator isLoading={isLoading} />
        </div>
      </div>
    );
  }
}
