import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Price from '../common/Price';

const SonosAuthLoginStep1PriceTextSonosDeal = ({ trialDurationInMonths }) => (
  <FormattedMessage
    id="sonos-auth.login-step-1.price-text-sonos-deal"
    defaultMessage="After {trialDurationInMonths} months {price} / month"
    values={{ trialDurationInMonths, price: <Price /> }}
  />
);

SonosAuthLoginStep1PriceTextSonosDeal.propTypes = {
  trialDurationInMonths: PropTypes.number.isRequired,
};

export default SonosAuthLoginStep1PriceTextSonosDeal;
