// @flow
import React from 'react';
import classnames from 'classnames';
import { compose } from 'redux';

import LoginComponent from '../components/common/Login';
import Head from '../components/chrome/Head';

import chromeComponent from '../hoc/chromeComponent';

import styles from './Login.css';
import { CHROME_CLEAN_LINKS } from '../constants';
import protectedComponent from '../hoc/protectedComponent';
import { defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

const messages = defineMessages({
  metaTitle: {
    id: 'login.meta-title',
    defaultMessage: 'Login',
  },
});

type OwnProps = {};

type Props = OwnProps & { intl: IntlShape };

const Login = ({ intl }: Props) => {
  const classesView = classnames(styles.view, 'u-page-container');

  return (
    <div className={classesView}>
      <Head title={intl.formatMessage(messages.metaTitle)} />
      <LoginComponent showFacebookLogin />
    </div>
  );
};

export default compose(
  protectedComponent({ anonymousOnly: true }),
  chromeComponent({ type: CHROME_CLEAN_LINKS })
)(injectIntl(Login));
