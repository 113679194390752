import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './style.css';
import PlayButton from '../../common/PlayButton';

export default class CircularPlayButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    dark: PropTypes.bool,
    light: PropTypes.bool,
  };

  render() {
    const { className, dark, light, ...rest } = this.props;
    const componentClassName = classnames(className, {
      [styles.component]: !dark && !light, // only use the default one if these are not specified
      [styles.darkComponent]: dark,
      [styles.lightComponent]: light,
    });
    return <PlayButton className={componentClassName} {...rest} />;
  }
}
