// @flow
import React from 'react';
import qs from 'qs';
import CapacitorRipple from '../capacitor/Ripple';
import { Link, withRouter } from 'react-router';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  type: string,
  pathname: string,
  query: Object,
  className?: string,
  onClickClear?: Function,
  title: React$Element<any> | string,
  router: Object,
};

function generateLinkTo(type, pathname, query) {
  const alteredQuery = { ...query };
  delete alteredQuery[type];
  return pathname + '?' + qs.stringify(alteredQuery);
}

const ClearActiveFacetsByGroup = ({
  type,
  pathname,
  query,
  onClickClear,
  className,
  title,
  router,
}: OwnProps) => {
  const linkTo = generateLinkTo(type, pathname, query);

  const onClick = event => {
    if (__CAPACITOR__) {
      event.preventDefault();
      router.replace(linkTo);
    }
    if (onClickClear) {
      onClickClear(event);
    }
  };

  return (
    <Link
      to={linkTo}
      className={className}
      onClick={onClick}
      data-test="facet.group.clear-active-facets"
    >
      <CapacitorRipple />
      {title}
    </Link>
  );
};

export default withRouter(ClearActiveFacetsByGroup);
