// @flow
import * as cacheValidators from '../middleware/apiCacheValidators';
import { Work, WorkList } from '../schema';
import { normalizeMetaItem, normalizeMetaList } from '../schema/normalizeMeta';

import singulariseParamObject from '../utils/singulariseParamObject';
import filterAndSortParams from '../utils/filterAndSortParams';
import getSerializedFilterId from '../utils/getSerializedFilterId';

import { VALID_WORK_FILTER_PARAMS } from '../constants';

import type { Request } from './types';

export type LoadWorksByFilterAction = {
  type: 'FETCH_WORKS_BY_FILTER',
} & Request;
export type LoadWorkAction = {
  type: 'FETCH_WORK',
} & Request;
export type WorkAction = LoadWorksByFilterAction | LoadWorkAction;

export function loadWorksByFilter(
  params: Object,
  cursor?: string,
  limit?: number = 50
): LoadWorksByFilterAction {
  const validAndSortedParams = filterAndSortParams(params, VALID_WORK_FILTER_PARAMS);
  const cacheId = getSerializedFilterId(validAndSortedParams, VALID_WORK_FILTER_PARAMS);

  let paginatedParams = {
    ...singulariseParamObject(validAndSortedParams),
    limit,
  };

  if (cursor) {
    paginatedParams = {
      ...paginatedParams,
      cursor,
    };
  }

  return {
    type: 'FETCH_WORKS_BY_FILTER',
    cacheId,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/metadata/works/filter',
      params: paginatedParams,
    },
    meta: {
      schema: WorkList,
      normalize: normalizeMetaList,
    },
    cache: {
      fetch: state => state.maps.worksByFilter[cacheId],
      validate: cacheValidators.loadedCursor(cursor),
    },
  };
}

export function loadWork(id: string): LoadWorkAction {
  return {
    type: 'FETCH_WORK',
    cacheId: id,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/metadata/works/{id}',
      params: { id },
    },
    meta: {
      schema: Work,
      normalize: normalizeMetaItem,
    },
    cache: {
      fetch: state => state.maps.works[id],
      validate: cacheValidators.loaded,
    },
  };
}
