// @flow
import React from 'react';
import SectionItem from './SectionItem';
import { FormattedMessage } from 'react-intl';
import VoucherCodeForm from './VoucherCodeForm';
import SubscriptionDiscountStatus from './SubscriptionDiscountStatus';
import styles from './SectionItem.css';
import {
  selectUserCanApplyVoucher,
  selectUserHasStripeDiscount,
} from '../../selectors/subscription';
import { connect } from 'react-redux';

type MapStateToProps = {
  userCanApplyVoucher: boolean,
  discountActive: boolean,
};

const Voucher = ({ userCanApplyVoucher, discountActive }: MapStateToProps) => {
  if (!userCanApplyVoucher && !discountActive) {
    return null;
  }

  return (
    <SectionItem
      title={
        <FormattedMessage
          id="settings.account.subscription.voucher.title"
          defaultMessage="Voucher:"
        />
      }
    >
      {userCanApplyVoucher && <VoucherCodeForm />}
      {discountActive && (
        <span className={styles.value}>
          <SubscriptionDiscountStatus />
        </span>
      )}
    </SectionItem>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    userCanApplyVoucher: selectUserCanApplyVoucher(state),
    discountActive: selectUserHasStripeDiscount(state),
  };
}

export default connect(mapStateToProps)(Voucher);
