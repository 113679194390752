import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const PlaylistTrackCount = ({ trackCount }) => (
  <FormattedMessage
    id="personal-playlists.track-count"
    defaultMessage="{trackCount, plural, one {# Track} other {# Tracks}}"
    values={{
      trackCount,
    }}
  />
);

PlaylistTrackCount.propTypes = {
  trackCount: PropTypes.number.isRequired,
};

export default PlaylistTrackCount;
