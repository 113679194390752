import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

import styles from './RadioButton.css';

export default class RadioButton extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    allowed: PropTypes.bool,
    // ^ allowed: triggers the onChange, but looks grey if it set to false
    checked: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  };

  static defaultProps = {
    allowed: true,
  };

  render() {
    const { disabled, checked, id, onChange, name, value, description, allowed } = this.props;

    const style = classnames(styles.component, { [styles.notAllowed]: !allowed });

    return (
      <div className={style}>
        <label className={styles.label} htmlFor={id}>
          {description}
        </label>
        <input
          className={styles.input}
          disabled={disabled}
          type="radio"
          checked={checked}
          id={id}
          onChange={onChange}
          name={name}
          value={value}
        />
        <div className={styles.visual} aria-hidden="true" />
      </div>
    );
  }
}
