import { createSelector } from 'reselect';
import assembleEntity from '../schema/assembleEntity';
import * as Schema from '../schema';
import { head, sortBy, isEmpty } from 'lodash';

export const selectArtistIdFromSlug = (state, slugOrArtistId) =>
  state.indexes.artistIdBySlug[slugOrArtistId] || slugOrArtistId;

function getStringSlug(slugs) {
  /*
    We want the shortest slug that is not a stringified integer id.
    parseInt won't work here since slugs starting with a leading digit
    will be false positives (e.g 2cellos).
  */
  const stringSlugs = slugs.filter(slug => !/^\d+$/.test(slug));
  return head(sortBy(stringSlugs, slug => slug.length));
}

export const selectProfile = createSelector(
  [state => state.entities, selectArtistIdFromSlug],
  (entities, id) => {
    const profile = assembleEntity(Schema.Profile, entities, id);

    if (isEmpty(profile)) {
      return profile;
    }

    return {
      ...profile,
      slug: getStringSlug(profile.slugs),
    };
  }
);

export const selectProfileImageAnnotation = (state, slug) => {
  const artistId = selectArtistIdFromSlug(state, slug);
  const annotationId = `artists-${artistId}-main.jpg`;
  return state.client.annotations.image ? state.client.annotations.image[annotationId] : null;
};
