// @flow

export type SetLocalListSearchQueryAction = {
  type: 'SET_LOCAL_LIST_SEARCH_QUERY',
  id: string,
  query: string,
};

export function setSearchQuery(id: string, query: string): SetLocalListSearchQueryAction {
  return {
    type: 'SET_LOCAL_LIST_SEARCH_QUERY',
    id,
    query,
  };
}
