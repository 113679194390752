import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectGenresLinks } from '../../selectors/categories';
import PropTypes from 'prop-types';
import GenreContainer from './Genre';
import genreRecordings from './data/genre-recordings';

class BrowseGenres extends Component {
  static propTypes = {
    genreLinks: PropTypes.array.isRequired,
  };

  render() {
    const { genreLinks } = this.props;
    return (
      <div>
        {Object.keys(genreRecordings)
          .map(x => parseInt(x, 10))
          .map(id => {
            const genre = genreLinks.find(x => x.id === id);
            // We must check if there is an intersection between hardcoded data
            // and backend response selected below so that we will not try to
            // view either browse items that don’t exist on backend, or will not
            // try to render items that are not in the hardcoded lists.
            return genre ? (
              <GenreContainer
                key={`genre-${id}`}
                title={genre.title}
                genre={genreRecordings[id]}
                href={genre.href}
              />
            ) : null;
          })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    genreLinks: selectGenresLinks(state),
  };
}

export default connect(mapStateToProps)(BrowseGenres);
