import React, { PureComponent } from 'react';
import * as Shapes from '../../shapes';

export default class RecordingLocation extends PureComponent {
  static propTypes = {
    location: Shapes.Location,
  };

  render() {
    const { location } = this.props;

    if (!location || !location.name) {
      return null;
    }

    return <span>{location.name}</span>;
  }
}
