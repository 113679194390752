import React from 'react';
import Skeleton from '../components/util/Skeleton';
import styles from './Skeleton.css';

const SkeletonPage = () => {
  return (
    <div className={styles.skeletonPage}>
      <Skeleton />
    </div>
  );
};

export default SkeletonPage;
