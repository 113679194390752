import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { LOGIN_INTENT, SIGNUP_INTENT } from '../../constants';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectUserIsPatron } from '../../selectors/user';
import { getSonosAccessToken } from '../../actions/auth';
import { sendSonosAuthorizationDetails } from '../../actions/sonosAuth';
import * as analyticsActions from '../../actions/analytics';
import SonosSignup from './SonosSignupFlow';
import SonosLogin from './SonosLoginFlow';

class SonosAuthentication extends Component {
  static propTypes = {
    getSonosAccessToken: PropTypes.func.isRequired,
    sendSonosAuthorizationDetails: PropTypes.func.isRequired,
    linkCode: PropTypes.string,
    shouldShowPaymentInfo: PropTypes.bool.isRequired,
    intent: PropTypes.string.isRequired,
    analyticsTrack: PropTypes.func.isRequired,
  };

  getSonosAccessToken = async (email, password) => {
    const userData = await this.props.getSonosAccessToken(email, password);

    return userData.normalized.accessToken;
  };

  renderLoginView() {
    const { linkCode, shouldShowPaymentInfo } = this.props;

    return (
      <SonosLogin
        linkCode={linkCode}
        shouldShowPaymentInfo={shouldShowPaymentInfo}
        getSonosAccessToken={this.getSonosAccessToken}
        authorizeSonos={this.authorizeSonos}
        trackStepCompletion={this.trackStepCompletion}
        trackSuccessfulAuthorization={this.trackSuccessfulDeviceAuthorization}
      />
    );
  }

  renderSignupView() {
    const { linkCode } = this.props;

    return (
      <SonosSignup
        linkCode={linkCode}
        authorizeSonos={this.authorizeSonos}
        trackStepCompletion={this.trackStepCompletion}
      />
    );
  }

  render() {
    const { intent } = this.props;

    return (
      <div>
        {intent === SIGNUP_INTENT && this.renderSignupView()}
        {intent === LOGIN_INTENT && this.renderLoginView()}
      </div>
    );
  }

  authorizeSonos = async (accessToken, linkCode) => {
    return await this.props.sendSonosAuthorizationDetails(accessToken, linkCode);
  };

  trackStepCompletion = step => {
    const { intent, analyticsTrack } = this.props;
    const funnel = intent === SIGNUP_INTENT ? 'join' : 'login';

    analyticsTrack('Completed Sonos Step', {
      funnel,
      step,
    });
  };

  trackSuccessfulDeviceAuthorization = () => {
    this.props.analyticsTrack('Authorized Sonos Device');
  };
}

function mapStateToProps(state) {
  return {
    shouldShowPaymentInfo: !selectUserIsPatron(state),
  };
}

export default compose(
  connect(mapStateToProps, {
    getSonosAccessToken,
    sendSonosAuthorizationDetails,
    analyticsTrack: analyticsActions.track,
  }),
  injectIntl
)(SonosAuthentication);
