// @flow
import { motion } from 'framer-motion';
import React from 'react';
import styles from './FacetGroups.css';
import ActiveFacets from './ActiveFacets';
import FacetGroupTopFacets from './FacetGroupTopFacets';
import List from '../util/List';
import { selectIsBoolean } from '../../selectors/facet';

type OwnProps = {
  isVisible: boolean,
  facets: Array<any>,
  loadAndShowAllFacets: Function,
  shouldRenderFacetGroup: Function,
  activeFacets: Array<any>,
  onClose: Function,
  handleMultipleComposers?: boolean,
  getTriggerText: Function,
  location: Object,
};

const FacetGroupsContainer = ({
  isVisible,
  facets,
  loadAndShowAllFacets,
  shouldRenderFacetGroup,
  activeFacets,
  onClose,
  handleMultipleComposers,
  getTriggerText,
  location,
}: OwnProps) => {
  if (!isVisible) {
    return null;
  }

  const FacetGroup = (facetInfo: Object) => {
    if (!shouldRenderFacetGroup(facetInfo)) {
      return null;
    }

    const { count, type, results } = facetInfo;

    return (
      <li className={styles.item} key={type}>
        <FacetGroupTopFacets
          getTriggerText={getTriggerText}
          type={type}
          results={results}
          isBoolean={selectIsBoolean(type)}
          activeFacets={activeFacets.filter(facet => facet.type === type)}
          count={count}
          loadAndShowAllFacets={loadAndShowAllFacets}
          location={location}
          handleMultipleComposers={handleMultipleComposers}
        />
      </li>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      data-test="facet.groups.container"
    >
      <List items={facets} renderItem={FacetGroup} className={styles.list} />
      <ActiveFacets activeFacets={activeFacets} location={location} onClickClear={onClose} />
    </motion.div>
  );
};

export default FacetGroupsContainer;
