import React, { Component } from 'react';
import styles from './SonosAuth.css';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import SonosStep from './SonosStep';

export default class ConnectToSonosStep extends Component {
  static propTypes = {
    totalSteps: PropTypes.number.isRequired,
    currentStep: PropTypes.number.isRequired,
    linkCode: PropTypes.string.isRequired,
    accessToken: PropTypes.string.isRequired,
    authorizeSonos: PropTypes.func.isRequired,
    onAuthorize: PropTypes.func,
  };

  render() {
    const { totalSteps, currentStep } = this.props;
    return (
      <SonosStep
        iconName={'connected'}
        currentStep={currentStep}
        totalSteps={totalSteps}
        onButtonClick={this.authorizeSonos}
      >
        <h2>
          <FormattedMessage
            id="sonos-auth.connect-to-sonos.header"
            defaultMessage="Connect SONOS to your IDAGIO account"
          />
        </h2>
        <p className={styles.subheading}>
          <FormattedMessage
            id="sonos-auth.connect-to-sonos.data-text"
            defaultMessage="SONOS will be able to receive your account data."
          />
        </p>
      </SonosStep>
    );
  }

  authorizeSonos = async () => {
    const { linkCode, authorizeSonos, accessToken, onAuthorize } = this.props;
    await authorizeSonos(accessToken, linkCode);

    if (onAuthorize) {
      onAuthorize();
    }
  };
}
