import { connect } from 'react-redux';

import { loadDiscoverPage } from '../actions/discover';
import { compose } from 'redux';
import dataComponent from '../hoc/dataComponent';

import DiscoverGroupList from '../components/discover/DiscoverGroupList';
import { DISCOVER_PAGE_TYPE_EVENTS_OVERVIEW } from '../constants';
import { selectDiscoverPageResult } from '../selectors/discover';

function fetchData(store) {
  return store.dispatch(loadDiscoverPage(DISCOVER_PAGE_TYPE_EVENTS_OVERVIEW));
}

function mapStateToProps(state) {
  const isHomePage = false;
  const hasBanner = true;

  return {
    result: selectDiscoverPageResult(state, DISCOVER_PAGE_TYPE_EVENTS_OVERVIEW, false, true),
    trackingScreen: 'events-overview',
    isHomePage,
    hasBanner,
  };
}

export default compose(connect(mapStateToProps), dataComponent(fetchData))(DiscoverGroupList);
