function unwrapComponentFromRouteTree(component) {
  if (component.WrappedComponent) {
    return unwrapComponentFromRouteTree(component.WrappedComponent);
  }
  return component;
}

export function unwrapRouteComponent(routes) {
  /*
    routes[1] will always be the top level route, routes [0] is always the index route.
    We're currently not using the index route on its own but it should still be handled here.
  */
  const parentRoute = routes.length ? routes[1] : routes[0];
  return unwrapComponentFromRouteTree(parentRoute.component);
}
