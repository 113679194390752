// @flow
import { loadedCursor } from '../middleware/apiCacheValidators';
import { PersonList } from '../schema';
import { normalizeMetaList } from '../schema/normalizeMeta';

import type { Request } from './types';

export type LoadConductorsAction = { type: 'FETCH_CONDUCTORS' } & Request;

export type ConductorAction = LoadConductorsAction;

export function loadConductors(cursor: string, limit: number = 50): LoadConductorsAction {
  let params = { limit };

  if (cursor) {
    params = {
      ...params,
      cursor,
    };
  }

  return {
    type: 'FETCH_CONDUCTORS',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/metadata/conductors',
      params,
    },
    meta: {
      schema: PersonList,
      normalize: normalizeMetaList,
    },
    cache: {
      fetch: state => state.lists.conductors,
      validate: loadedCursor(cursor),
    },
  };
}
