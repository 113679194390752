// @flow
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import { connect } from 'react-redux';

import * as clientActions from '../../actions/client';

import {
  AUDIO_QUALITY_HIGH,
  AUDIO_QUALITY_LOSSLESS,
  AUDIO_QUALITY_LOW,
  AUDIO_QUALITY_PREVIEW,
} from '../../constants';

import { selectAudioQualityPreference } from '../../selectors/user';
import { selectPlaybackIsUsingAAC } from '../../selectors/client';
import { selectSonosIsConnected } from '../../selectors/sonos';
import { compose } from 'redux';
import Feature from '../util/Feature';

type OwnProps = {
  children: Function,
};

type MapStateToProps = {
  playbackIsUsingAAC: boolean,
  currentQuality: number,
};

type DispatchProps = {
  setAudioQuality: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps & { intl: IntlShape };

const messages = defineMessages({
  normal: {
    id: 'settings.app.audio-quality.normal.label',
    defaultMessage: 'Normal {details}',
  },
  high: {
    id: 'settings.app.audio-quality.high.label',
    defaultMessage: 'High {details}',
  },
  lossless: {
    id: 'settings.app.audio-quality.lossless.label',
    defaultMessage: 'Lossless {details}',
  },
});

const generateSupportedAudioQuality = (intl, playbackIsUsingAAC, qualities) => {
  const losslessAllowed = qualities.includes(AUDIO_QUALITY_LOSSLESS);
  const highAllowed = qualities.includes(AUDIO_QUALITY_HIGH);

  return [
    {
      title: intl.formatMessage(messages.lossless, { details: '(FLAC)' }),
      value: AUDIO_QUALITY_LOSSLESS,
      allowed: losslessAllowed,
      disabled: highAllowed && !losslessAllowed,
    },
    {
      title: intl.formatMessage(messages.high, {
        details: playbackIsUsingAAC ? '(AAC 320 Kbps)' : '(MP3 320 Kbps)',
      }),
      value: AUDIO_QUALITY_HIGH,
      allowed: highAllowed,
      disabled: false,
    },
    {
      title: intl.formatMessage(messages.normal, {
        details: playbackIsUsingAAC ? '(AAC 160 Kbps)' : '(MP3 192 Kbps)',
      }),
      value: AUDIO_QUALITY_LOW || AUDIO_QUALITY_PREVIEW,
      allowed: true,
      disabled: false,
    },
  ];
};

const AudioQuality = ({
  currentQuality,
  setAudioQuality,
  playbackIsUsingAAC,
  intl,
  children,
}: Props) => {
  const parseQualityLevel = quality => parseInt(quality, 10);

  const currentQualityLevel = parseQualityLevel(currentQuality);

  const onQualityChange = quality => {
    const newQuality = parseQualityLevel(quality);
    setAudioQuality(newQuality);
  };

  return (
    <Feature id="audio_quality">
      {qualities => {
        if (!Array.isArray(qualities)) {
          return null;
        }

        const supportedAudioQuality = generateSupportedAudioQuality(
          intl,
          playbackIsUsingAAC,
          qualities
        );
        return children(currentQualityLevel, onQualityChange, supportedAudioQuality);
      }}
    </Feature>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    playbackIsUsingAAC: selectPlaybackIsUsingAAC(state) || selectSonosIsConnected(state),
    currentQuality: selectAudioQualityPreference(state),
  };
}

const dispatchProps: DispatchProps = {
  setAudioQuality: clientActions.setAudioQuality,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(AudioQuality);
