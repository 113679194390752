import React from 'react';
import { compose } from 'redux';

import { CHROME_CLEAN } from '../constants';
import chromeComponent from '../hoc/chromeComponent';

import ConsentManagerComponent from '../components/consent-manager/ConsentManager';

const ConsentManager = () => {
  return (
    <section className="u-page-container">
      <ConsentManagerComponent showSelection />
    </section>
  );
};

export default compose(chromeComponent({ type: CHROME_CLEAN }))(ConsentManager);
