// @flow
// $FlowFixMe
import React, { useCallback, useState, useEffect } from 'react';
import classnames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { DownloadPlugin } from '../../capacitor-connector';
import { downloadUpdateAction } from '../../actions/download';
import { addToCollection as addToCollectionAction } from '../../actions/collection';
import { selectEntityDownloadInfo } from '../../selectors/download';
import { selectEntityIsInCollection } from '../../selectors/collection';
import { selectUserIsPatron } from '../../selectors/user';
import { showUpsellView as showUpsellViewAction } from '../../actions/capacitor';
import styles from './DownloadButton.css';
import IconLabel from '../util/IconLabel';
import stylesShareButton from '../common/ShareButton.css';

const messages = defineMessages({
  download: {
    id: 'download.button.text',
    defaultMessage: 'Download',
  },
  downloaded: {
    id: 'download.button.downloaded',
    defaultMessage: 'Downloaded',
  },
});

type OwnProps = {
  entityType: string,
  entityIdOrSlug: string | number,
};

type MapStateToProps = {
  downloadInfo?: {
    downloadStatus: string,
    downloadedTrackIds?: Array<string>,
  },
  userIsPatron: boolean,
  isInCollection: boolean,
};

type DispatchProps = {
  downloadUpdate: Function,
  addToCollection: Function,
  showUpsellView: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps & { intl: IntlShape };

const DownloadButton = ({
  entityType,
  entityIdOrSlug,
  downloadUpdate,
  downloadInfo,
  userIsPatron,
  showUpsellView,
  addToCollection,
  isInCollection,
  intl,
}: Props) => {
  const isLoadingInfo = typeof downloadInfo === 'undefined';
  const { downloadStatus } = downloadInfo || {};
  // We want optimistic UI updates, so we have an internal state, but sync it
  // up to what we get from the store if it should change.
  const [internalDownloadStatus, setInternalDownloadStatus] = useState(downloadStatus);

  const handleDownloadUpdate = useCallback(
    ({ entityIdOrSlug: entityId, downloadStatus: status, downloadedTrackIds: trackIds }) => {
      if (!entityId) {
        return;
      }

      // Android can emit a status that matched the previous state (downloaded or download_running)
      // When this state is received, the toggle needs to be set back to ON
      if (status === 'downloaded' || status === 'download_running') {
        setInternalDownloadStatus(status);
      }

      downloadUpdate({
        entityId,
        downloadStatus: status,
        downloadedTrackIds: trackIds && trackIds.length > 0 ? trackIds : undefined,
      });
    },
    [downloadUpdate]
  );

  useEffect(() => {
    if (downloadStatus) {
      setInternalDownloadStatus(downloadStatus);
    }
  }, [downloadStatus]);

  useEffect(() => {
    DownloadPlugin.subscribe(
      {
        entityType: entityType,
        entityIdOrSlug: entityIdOrSlug.toString(),
      },
      handleDownloadUpdate
    );
    return () => {
      DownloadPlugin.unsubscribe();
    };
  }, [entityIdOrSlug, entityType, handleDownloadUpdate]);

  const startDownload = e => {
    if (!userIsPatron) {
      e.preventDefault();
      showUpsellView('fromDownloadToggle');
      return;
    }
    setInternalDownloadStatus('download_running');
    if (!isInCollection && entityType !== 'personal_playlist') {
      addToCollection(entityType, [entityIdOrSlug]);
    }
    DownloadPlugin.downloadSubscribedEntity();
  };

  const removeDownload = () => {
    setInternalDownloadStatus('not_downloading');
    DownloadPlugin.removeDownloadForSubscribedEntity();
  };

  const shouldShowDownloaded =
    userIsPatron &&
    (internalDownloadStatus === 'downloaded' || internalDownloadStatus === 'download_running');

  return (
    <div className={classnames(stylesShareButton.shareButton, styles.component)}>
      {isLoadingInfo ? (
        <div className={styles.spinner} />
      ) : (
        <React.Fragment>
          {shouldShowDownloaded ? (
            <button onClick={removeDownload} title={intl.formatMessage(messages.downloaded)}>
              <IconLabel name="download-circle-block" size="default" />
              {internalDownloadStatus === 'download_running' && <div className={styles.spinner} />}
            </button>
          ) : (
            <button onClick={startDownload} title={intl.formatMessage(messages.download)}>
              <IconLabel name="download-circle" size="default" />
            </button>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

function mapStateToProps(state: Object, ownProps: OwnProps): MapStateToProps {
  const collectionIdsState = state.lists.collection.ids;
  return {
    downloadInfo: selectEntityDownloadInfo(state, ownProps.entityIdOrSlug),
    userIsPatron: selectUserIsPatron(state),
    isInCollection: selectEntityIsInCollection(
      ownProps.entityType,
      collectionIdsState,
      ownProps.entityIdOrSlug
    ),
  };
}

const dispatchProps: DispatchProps = {
  downloadUpdate: downloadUpdateAction,
  addToCollection: addToCollectionAction,
  showUpsellView: showUpsellViewAction,
};

export default connect(mapStateToProps, dispatchProps)(injectIntl(DownloadButton));
