// @flow
import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';

type OwnProps = {
  availabilityEnd: string,
};

const RecordingAvailabilityText = ({ availabilityEnd }: OwnProps) => {
  const availabilityEndDate = (
    <FormattedDate
      weekday="short"
      year="numeric"
      month="short"
      day="numeric"
      value={availabilityEnd}
    />
  );

  return (
    <FormattedMessage
      id="course.recording-availability.text"
      defaultMessage="All sessions will be recorded and are available to participants for re-watching until {availabilityEndDate}."
      values={{ availabilityEndDate }}
    />
  );
};

export default RecordingAvailabilityText;
