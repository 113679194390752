import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';

class YouTubeVideoAdaptor extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    showControls: PropTypes.bool.isRequired,
    autoPlay: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,

    onPlay: PropTypes.func.isRequired,
    onPause: PropTypes.func.isRequired,
    onEnded: PropTypes.func.isRequired,
  };

  componentDidMount = async () => {
    const { id } = this.props;
    const url = `https://stream.mux.com/${id}.m3u8`;

    if (!this.container) return;
    const player = this.container;
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(player);
    }

    player.addEventListener('ended', this.onEnded);
    player.addEventListener('play', this.onPlay);
    player.addEventListener('pause', this.onPause);

    this.player = player;
  };

  componentWillUnmount() {
    this.player.removeEventListener('ended', this.onEnded);
    this.player.removeEventListener('play', this.onPlay);
    this.player.removeEventListener('pause', this.onPause);
  }

  onPlay = () => {
    this.props.onPlay(this.getEventData());
  };

  onPause = () => {
    this.props.onPause(this.getEventData());
  };

  onEnded = () => {
    this.props.onEnded(this.getEventData());
  };

  getEventData = () => {
    return {
      currentTime: this.player.currentTime || 0,
      duration: this.player.duration,
    };
  };

  render = () => {
    const { className, showControls, autoPlay, id } = this.props;
    const src = `https://playout.3qsdn.com/${id}`;
    return (
      <video ref={this.ref} className={className} controls={showControls} autoPlay={autoPlay}>
        <source src={src} />
      </video>
    );
  };

  ref = container => {
    this.container = container;
  };
}

export default YouTubeVideoAdaptor;
