import React from 'react';
import { FormattedMessage } from 'react-intl';

import LiveIndicator from './LiveIndicator';

import styles from './CurrentlyLive.css';

export default () => {
  return (
    <div className={styles.container}>
      <LiveIndicator />
      <FormattedMessage
        defaultMessage="Streaming now on IDAGIO"
        id="livestream-event.currently-live"
      />
    </div>
  );
};
