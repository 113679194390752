import React from 'react';
import { FormattedMessage } from 'react-intl';

export default () => (
  <FormattedMessage
    id="server-error.text"
    defaultMessage="Sorry, something went wrong. Please try again or contact {link}."
    values={{
      link: <a href="mailto:support@idagio.com">support@idagio.com</a>,
    }}
  />
);
