import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl, intlShape } from 'react-intl';

import { addNotification, dismissNotification } from '../../actions/notifications';
import { loadMe } from '../../actions/me';
import { applyVoucherCode, loadSubscription, handleCouponError } from '../../actions/subscription';
import { loadFeatureFlags } from '../../actions/client';

import { APPLYING_COUPON, COUPON_ERROR, COUPON_SUCCESS } from '../../lib/notifications';
import styles from './SectionItem.css';
import classNames from 'classnames';

const messages = defineMessages({
  placeholder: {
    id: 'settings.subs.coupon.placeholder',
    defaultMessage: 'Enter your voucher code here',
  },
});

class VoucherCodeForm extends Component {
  static propTypes = {
    loadMe: PropTypes.func.isRequired,
    loadFeatureFlags: PropTypes.func.isRequired,
    loadSubscription: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    applyVoucherCode: PropTypes.func.isRequired,
    intl: intlShape,
  };

  state = {
    updatingSubscription: false,
  };

  componentDidMount = async () => {
    if (document.location.hash === '#voucher') {
      this.discountCodeNode.scrollIntoView();
      this.discountCodeNode.focus();
    }
  };

  render() {
    const { updatingSubscription } = this.state;

    return (
      <form data-test="voucher-code-form.container" className={styles.form}>
        <div className="c-form-field">
          <input
            className="c-form-field__input"
            ref={node => {
              this.discountCodeNode = node;
            }}
            disabled={updatingSubscription}
            id="voucher"
            data-test="voucher-code-form.voucher-input"
            placeholder={this.props.intl.formatMessage(messages.placeholder)}
          />
        </div>
        <div className={styles.btns}>
          <button
            className={classNames('c-btn', styles.primaryBtn)}
            onClick={this.useVoucherCode}
            disabled={updatingSubscription}
            data-test="voucher-code-form.submit-btn"
          >
            <FormattedMessage
              id="settings.subs.coupon.redeem-voucher"
              defaultMessage="Redeem Voucher"
            />
          </button>
        </div>
      </form>
    );
  }

  handleVoucherError = error => {
    handleCouponError(error, this.props.addNotification);
  };

  handleVoucherSuccess = async () => {
    this.props.addNotification(COUPON_SUCCESS);
    await this.props.loadFeatureFlags();
    await this.props.loadMe();
    await this.props.loadSubscription();
  };

  useVoucherCode = () => {
    const code = this.discountCodeNode.value;
    if (!code) {
      this.props.addNotification(COUPON_ERROR);
    }

    this.props.addNotification(APPLYING_COUPON);
    this.setState(
      {
        updatingSubscription: true,
      },
      () => {
        this.props
          .applyVoucherCode(code)
          .then(this.handleVoucherSuccess)
          .catch(this.handleVoucherError)
          .finally(() => this.setState({ updatingSubscription: false }));
      }
    );
  };

  discountCodeNode = null;
}

export default connect(null, {
  loadMe,
  loadSubscription,
  applyVoucherCode,
  addNotification,
  dismissNotification,
  loadFeatureFlags,
})(injectIntl(VoucherCodeForm));
