import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class SelectOption extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  };

  render() {
    const {
      children,
      onClick, // eslint-disable-line no-unused-vars
      ariaLabel,
      disabled,
      className,
    } = this.props;

    return (
      <li className={className}>
        <button
          onClick={this.handleClick}
          className="dummy-btn"
          aria-label={ariaLabel}
          disabled={disabled}
        />
        <div>{children}</div>
      </li>
    );
  }

  handleClick = () => {
    const { onClick, value } = this.props;

    onClick(value);
  };
}
