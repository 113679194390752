// @flow
import React from 'react';
import { motion } from 'framer-motion';
import styles from './HeroSliderItemBackground.css';
import { IMAGES_BASE } from '../../constants';

type OwnProps = {
  item: Object,
  isActive: boolean,
  isLivestreamEventGroup: boolean,
};

type Props = OwnProps;

export default function HeroSliderItemBackground({
  item,
  isActive,
  isLivestreamEventGroup,
}: Props) {
  const fullOpacity = 0.6;
  const { imageUrl } = item;

  let backgroundImage = 'none';
  let boxShadow = 'none';
  if (isLivestreamEventGroup) {
    backgroundImage = `url(${imageUrl ? imageUrl : `${IMAGES_BASE}/assets/web/live-fallback.jpg`})`;
    boxShadow = 'inset 0 0 0 50vw rgba(0,0,0,0.75)';
  } else {
    // Using the imgix blur param instead of a CSS filter is better for browser compatibility and performance.
    backgroundImage = `url(${imageUrl}?blur=50&w=200)`;
  }

  return (
    <motion.div
      className={styles.backdrop}
      role="img"
      style={{ backgroundImage, boxShadow }}
      initial={{
        opacity: isActive ? fullOpacity : 0,
      }}
      animate={{
        opacity: isActive ? fullOpacity : 0,
      }}
      transition={{
        duration: 1,
      }}
    />
  );
}
