// @flow
import React from 'react';
import ContextMenuItem from '../util/ContextMenuItem';
import IconLabel from '../util/IconLabel';
import { FormattedMessage } from 'react-intl';

type OwnProps = {
  onClick: Function,
  text?: React$Element<typeof FormattedMessage>,
};

declare var __CAPACITOR__: boolean;

const ShareContextMenuItem = ({ onClick, text }: OwnProps) => (
  <ContextMenuItem onClick={onClick}>
    <IconLabel name={__CAPACITOR__ ? 'share-android' : 'share'} size="default" />
    {text ? (
      <span>{text}</span>
    ) : (
      <FormattedMessage id="aria-button-share" defaultMessage="Share" />
    )}
  </ContextMenuItem>
);

export default ShareContextMenuItem;
