// @flow
import * as React from 'react';

import styles from './LivestreamEventParticipants.css';

import LivestreamEventArtists from './LivestreamEventArtists';
import FeaturedComposersTitle from '../messages/FeaturedComposersTitle';
import HostsTitle from '../messages/HostsTitle';
import FeaturedArtistsTitle from '../messages/FeaturedArtistsTitle';

import type { LivestreamEventArtist } from '../../shapes/types';

type OwnProps = {
  composers: Array<LivestreamEventArtist>,
  hosts: Array<LivestreamEventArtist>,
  featuredArtists: Array<LivestreamEventArtist>,
};

type Props = OwnProps;

const LivestreamEventParticipants = ({ composers, hosts, featuredArtists }: Props) => {
  return (
    <section className={styles.component}>
      <LivestreamEventArtists
        title={<FeaturedComposersTitle />}
        artists={composers}
        data-test="livestream-event.participants.composers"
      />
      <LivestreamEventArtists
        title={<HostsTitle />}
        artists={hosts}
        data-test="livestream-event.participants.hosts"
      />
      <LivestreamEventArtists
        title={<FeaturedArtistsTitle />}
        artists={featuredArtists}
        data-test="livestream-event.participants.featured-artists"
      />
    </section>
  );
};

export default LivestreamEventParticipants;
