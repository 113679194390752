// @flow
import React from 'react';
import Language from './Language';
import Autoplay from './Autoplay';
import Theme from './Theme';
import AudioQuality from '../settings/AudioQuality';

const App = () => {
  return (
    <React.Fragment>
      <AudioQuality />
      <Language />
      <Theme />
      <Autoplay />
    </React.Fragment>
  );
};

export default App;
