import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import FormattedTime from '../util/FormattedTime';
import * as Shapes from '../../shapes';
import IconLabel from './../util/IconLabel';
import { defineMessages, intlShape, injectIntl } from 'react-intl';

import styles from './PlayerProgress.css';

const messages = defineMessages({
  labelTitle: {
    id: 'label-title-seek-slider-handle',
    defaultMessage: 'Seek Slider Handle',
  },
});

class PlayerProgress extends Component {
  static propTypes = {
    duration: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    seekBegin: PropTypes.func,
    seekFinish: PropTypes.func,
    currentQueueItem: PropTypes.shape({ track: Shapes.Track.id }),
    isPlayingAd: PropTypes.bool.isRequired,
    intl: intlShape,
  };

  static defaultProps = {
    progress: 0,
    duration: 0,
  };

  state = {
    seeking: false,
    hover: false,
    animateProgressBar: false,
  };

  componentWillReceiveProps(nextProps) {
    const currentProgress = this.props.progress;
    const newProgress = nextProps.progress;

    // Compare the current progress to the new progress value;
    //
    // If the difference is larger than 1 (in seconds), then we do not want to
    // animate the change in player progress, and instead just jump directly
    // to the new value.

    const delta = newProgress - currentProgress;
    const isPositive = delta > 0;
    const withinThreshold = delta < 2; // Progress updates only happens ROUGHLY 1/sec so leave a little buffer
    const animateProgressBar = isPositive && withinThreshold;

    if (animateProgressBar !== this.state.animateProgressBar) {
      this.setState({ animateProgressBar });
    }
  }

  onInput = event => {
    const { duration } = this.props;
    const value = parseInt(event.target.value, 10);

    this.props.seekBegin();
    this.setState({
      seeking: true,
      seekingPlaybackPosition: value / duration,
      seekingPlaybackTime: value,
    });
  };

  onChange = () => {
    this.props.seekFinish(parseInt(this.state.seekingPlaybackTime, 10));
    this.setState(
      {
        seeking: false,
      },
      () => this.input.blur()
    );
  };

  render() {
    const { progress, duration, currentQueueItem, isPlayingAd, intl } = this.props;
    const progressClassNames = classnames(styles.progress, {
      [styles.queueIsEmpty]: !currentQueueItem,
    });
    const positionInTrack = this.state.seeking ? this.state.seekingPlaybackTime : progress;

    const progressBarWidthPercent = currentQueueItem ? (positionInTrack / duration) * 100 : 0;

    const labelTitle = intl.formatMessage(messages.labelTitle);

    return (
      <div className={progressClassNames}>
        <FormattedTime>{positionInTrack}</FormattedTime>
        <div className={styles.handle}>
          <label htmlFor="input-handle" className={styles.label}>
            <IconLabel name="seek-slideer-handle" title={labelTitle} />
          </label>
          <input
            ref={this.createProgressRef}
            className={styles.input}
            readOnly
            onInput={this.onInput}
            onMouseUp={this.onChange}
            type="range"
            min={0}
            max={duration}
            value={positionInTrack}
            disabled={isPlayingAd || !currentQueueItem}
            id="input-handle"
          />
          <div className={styles.progressWrap}>
            <div className={styles.progressBar} style={{ width: `${progressBarWidthPercent}%` }} />
          </div>
        </div>
        <div className={styles.timeTotal}>
          <FormattedTime>{duration}</FormattedTime>
        </div>
      </div>
    );
  }

  createProgressRef = element => {
    this.input = element;
  };
}

export default injectIntl(PlayerProgress);
