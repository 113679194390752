import React from 'react';
import PropTypes from 'prop-types';
import Head from '../components/chrome/Head';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

import { compose } from 'redux';

import RecordingsCarouselSection from '../components/live-compare/RecordingsCarouselSection';
import HeaderSection from '../components/live-compare/HeaderSection';
import RecordingInfoSection from '../components/live-compare/RecordingInfoSection';
import LocationAndYearSection from '../components/live-compare/LocationAndYearSection';

import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import metaInjector from '../hoc/metaInjector';
import { withRouter } from 'react-router';
import { loadLiveCompareAction } from '../actions/liveCompare/api';

import {
  dismissLiveCompareInactivityTooltip,
  setLiveCompareCurrentlyOn,
} from '../actions/liveCompare';

import {
  selectLiveCompareAvailable,
  selectLiveCompareComposer,
  selectLiveCompareActiveRecording,
  selectLiveCompareActiveRecordingIndex,
  selectLiveCompareLoaded,
  selectLiveCompareRecordings,
  selectLiveCompareWork,
  selectLiveCompareIsSwitching,
  selectLiveComparePlayingRecordingIndex,
  selectLiveCompareHasPlayedIndices,
  selectLiveCompareHasSwitched,
  selectLiveCompareInactivityTooltipDismissed,
  selectLiveComparePiece,
} from '../selectors/liveCompare';
import PlaybackSection from '../components/live-compare/PlaybackSection';
import {
  LiveCompareComposerShape,
  LiveComparePieceShape,
  LiveCompareRecordingShape,
  LiveCompareWorkShape,
} from '../shapes/liveCompare';
import { selectPlayerControlsEnabled, selectPlayerIsPlaying } from '../selectors/player';
import * as playerActions from '../actions/player';
import { getQueueOrigin } from '../utils/liveCompare';
import { loadTracks } from '../actions/tracks';
import protectedComponent from '../hoc/protectedComponent';

const messages = defineMessages({
  metaTitle: {
    id: 'live-compare.title',
    defaultMessage: 'Live Compare',
  },
  metaDescription: {
    id: 'live-compare.description',
    defaultMessage: 'Compare performances',
  },
  metaKeywords: {
    id: 'live-compare.keywords',
    defaultMessage:
      'live compare, compare recordings, discover classical music, classical music albums, classical releases, curated classical music playlists',
  },
});

class LiveCompare extends React.PureComponent {
  static propTypes = {
    intl: intlShape,
    work: LiveCompareWorkShape.isRequired,
    piece: LiveComparePieceShape.isRequired,
    recordings: PropTypes.arrayOf(LiveCompareRecordingShape).isRequired,
    activeRecording: LiveCompareRecordingShape,
    activeRecordingIndex: PropTypes.number,
    playingRecordingIndex: PropTypes.number,
    composer: LiveCompareComposerShape.isRequired,
    loaded: PropTypes.bool.isRequired,
    available: PropTypes.bool,
    isPlaying: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSwitching: PropTypes.bool.isRequired,
    hasSwitched: PropTypes.bool.isRequired,
    inactivityTooltipDismissed: PropTypes.bool.isRequired,
    hasPlayedMoreThanOneTrack: PropTypes.bool.isRequired,
    resume: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    onChangeActiveRecording: PropTypes.func.isRequired,
    onSelectNextRecording: PropTypes.func.isRequired,
    onSelectPreviousRecording: PropTypes.func.isRequired,
    onInactivityTooltipDismissed: PropTypes.func.isRequired,
    setIsCurrentlyOn: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.setIsCurrentlyOn(true);
  }

  componentWillUnmount() {
    this.props.pause();
    this.props.setIsCurrentlyOn(false);
  }

  render() {
    const { formatMessage } = this.props.intl;

    // do not use ssr for anything other than for data-preloading
    if (__SERVER__ || !this.props.loaded) {
      return null;
    }

    if (this.props.available === false) {
      return (
        <div className="u-page-container">
          <div>Live Compare is not available</div>
        </div>
      );
    }

    return (
      <div className="u-page-container">
        <Head
          title={formatMessage(messages.metaTitle)}
          description={formatMessage(messages.metaDescription)}
        />
        <HeaderSection />
        <RecordingInfoSection
          piece={this.props.piece}
          work={this.props.work}
          composer={this.props.composer}
          activeRecordingIndex={this.props.activeRecordingIndex}
          recordingsCount={this.props.recordings.length}
          isSwitching={this.props.isSwitching}
        />
        <RecordingsCarouselSection
          recordings={this.props.recordings}
          activeRecordingIndex={this.props.activeRecordingIndex}
          isCarouselDisabled={!this.props.available}
          onChangeActiveRecording={this.props.onChangeActiveRecording}
          isLoading={this.props.isLoading}
          isSwitching={this.props.isSwitching}
          isPlayingTheFirstRecording={
            this.props.isPlaying && !this.props.hasPlayedMoreThanOneTrack && !this.props.hasSwitched
          }
          inactivityTooltipDismissed={this.props.inactivityTooltipDismissed}
          playingRecordingIndex={this.props.playingRecordingIndex}
          onPressPause={this.props.pause}
          onPressResume={this.props.resume}
          onInactivityTooltipDismissed={this.props.onInactivityTooltipDismissed}
        />
        <LocationAndYearSection
          albumSlug={this.props.activeRecording.albumSlug}
          performerSummary={this.props.activeRecording.performerSummary}
          recordingDate={this.props.activeRecording.recordingDate}
          venue={this.props.activeRecording.venue}
          location={this.props.activeRecording.location}
        />
        <PlaybackSection
          isPlaying={this.props.isPlaying}
          isLoading={this.props.isLoading}
          isSwitching={this.props.isSwitching}
          onPlayPausePressed={this.handlePlayPauseButtonPress}
          onBackPressed={this.props.onSelectPreviousRecording}
          onForwardPressed={this.props.onSelectNextRecording}
        />
      </div>
    );
  }

  handlePlayPauseButtonPress = () => {
    if (!this.props.isLoading) {
      if (this.props.isPlaying) {
        this.props.pause();
      } else {
        this.props.resume();
      }
    }
  };
}

const mapStateToProps = (state, { params }) => {
  const { pieceId } = params;
  return {
    piece: selectLiveComparePiece(state),
    work: selectLiveCompareWork(state),
    composer: selectLiveCompareComposer(state),
    recordings: selectLiveCompareRecordings(state),
    available: selectLiveCompareAvailable(state),
    loaded: selectLiveCompareLoaded(state, pieceId),
    activeRecording: selectLiveCompareActiveRecording(state),
    activeRecordingIndex: selectLiveCompareActiveRecordingIndex(state),
    isPlaying: selectPlayerIsPlaying(state),
    isLoading: !selectPlayerControlsEnabled(state),
    isSwitching: selectLiveCompareIsSwitching(state),
    hasSwitched: selectLiveCompareHasSwitched(state),
    inactivityTooltipDismissed: selectLiveCompareInactivityTooltipDismissed(state),
    hasPlayedMoreThanOneTrack: Object.keys(selectLiveCompareHasPlayedIndices(state)).length > 1,
    playingRecordingIndex: selectLiveComparePlayingRecordingIndex(state),
  };
};

const mapDispatchToProps = {
  onChangeActiveRecording: playerActions.changeLiveCompareRecording,
  onSelectPreviousRecording: playerActions.previousThunk,
  onSelectNextRecording: playerActions.nextThunk,
  resume: playerActions.resumeThunk,
  pause: playerActions.pause,
  setIsCurrentlyOn: setLiveCompareCurrentlyOn,
  onInactivityTooltipDismissed: dismissLiveCompareInactivityTooltip,
};

const fetchData = async (store, { pieceId }) => {
  // don't do both server and client rendering
  if (selectLiveCompareLoaded(store.getState(), pieceId)) {
    return;
  }

  await store.dispatch(loadLiveCompareAction(pieceId));

  const state = store.getState();

  const liveCompareRecordings = selectLiveCompareRecordings(state);

  await store.dispatch(loadTracks(liveCompareRecordings.map(({ trackId }) => trackId.toString())));

  const recordingsCount = liveCompareRecordings.length;

  await store.dispatch(playerActions.pause());

  // probably obsolete
  if (recordingsCount > 0) {
    const middleRecordingIndex = Math.floor(recordingsCount / 2);
    const trackIdInTheMiddle = liveCompareRecordings[middleRecordingIndex].trackId;
    const tracks = [trackIdInTheMiddle].map(playerActions.createItem);
    store.dispatch(
      playerActions.setQueueAndPlay(
        getQueueOrigin(pieceId, trackIdInTheMiddle),
        tracks,
        playerActions.getItemById(trackIdInTheMiddle, tracks),
        true,
        'internal',
        {
          progress: 0,
          startPlayback: false,
        }
      )
    );
  }
};

function getMetaInformation(props) {
  const { intl } = props;
  const { formatMessage } = intl;
  return {
    title: formatMessage(messages.metaTitle),
    description: formatMessage(messages.metaDescription),
    keywords: formatMessage(messages.metaKeywords),
  };
}

export default compose(
  protectedComponent({ authenticatedOnly: true }, true),
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  dataComponent(fetchData),
  metaInjector(getMetaInformation)
)(withRouter(LiveCompare));
