import { createSelector } from 'reselect';
import { map, some } from 'lodash';
import * as Schema from '../schema';

import { selectNextCursor, selectPreviousCursor } from './common';

import assembleEntity from '../schema/assembleEntity';

import getSerializedFilterId from '../utils/getSerializedFilterId';
import filterAndSortParams from '../utils/filterAndSortParams';

import { VALID_WORK_FILTER_PARAMS } from '../constants';

export const selectFilteredAndSortedWorks = createSelector(
  [
    state => state.entities,
    state => state.maps.worksByFilter,
    (state, filterParams) => filterParams,
  ],
  (entities, worksByFilter, filterParams) => {
    const validAndSortedParams = filterAndSortParams(filterParams, VALID_WORK_FILTER_PARAMS);
    return assembleEntity(
      Schema.WorkList,
      entities,
      worksByFilter[getSerializedFilterId(validAndSortedParams, VALID_WORK_FILTER_PARAMS)].entities
    );
  }
);

export const selectWork = createSelector(
  [state => state.entities, (state, id) => id, (state, id, hydrate) => hydrate],
  (entities, id, hydrate = true) => {
    if (!entities.works[id]) {
      return null;
    }

    if (hydrate) {
      return assembleEntity(Schema.Work, entities, id);
    }

    return entities.works[id];
  }
);

export const selectFilteredAndSortedWorksLoading = state => {
  const loadingStates = map(state.maps.worksByFilter, entry => entry && entry.loading);
  return some(loadingStates, isLoading => isLoading);
};

export const selectFilteredAndSortedWorksCount = (state, filterParams) => {
  if (selectFilteredAndSortedWorksLoading(state)) {
    return 0;
  }
  const validAndSortedParams = filterAndSortParams(filterParams, VALID_WORK_FILTER_PARAMS);
  const cacheId = getSerializedFilterId(validAndSortedParams, VALID_WORK_FILTER_PARAMS);
  return state.maps.worksByFilter[cacheId].meta.count;
};

export function selectNextFilteredWorksCursor(state, filterParams) {
  if (selectFilteredAndSortedWorksLoading(state)) {
    return '';
  }
  const validAndSortedParams = filterAndSortParams(filterParams, VALID_WORK_FILTER_PARAMS);
  const cacheId = getSerializedFilterId(validAndSortedParams, VALID_WORK_FILTER_PARAMS);
  return selectNextCursor(state.maps.worksByFilter[cacheId]);
}

export function selectPreviousFilteredWorksCursor(state, filterParams) {
  if (selectFilteredAndSortedWorksLoading(state)) {
    return '';
  }
  const validAndSortedParams = filterAndSortParams(filterParams, VALID_WORK_FILTER_PARAMS);
  const cacheId = getSerializedFilterId(validAndSortedParams, VALID_WORK_FILTER_PARAMS);
  return selectPreviousCursor(state.maps.worksByFilter[cacheId]);
}
