import React from 'react';
import { FormattedMessage } from 'react-intl';
import Price from '../common/Price';

export default () => (
  <FormattedMessage
    id="sonos-auth.login-step-1.text-sonos-deal"
    defaultMessage="Expand your collection with unlimited access to the world’s best classical labels, offline playback and lossless quality audio. Enjoy your first 2 months for free, afterwards {price}. No commitment, cancel anytime."
    values={{ price: <Price /> }}
  />
);
