import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';

import metaInjector from '../hoc/metaInjector';

import * as analyticsActions from '../actions/analytics';
import CapacitorSkeletonIfLoading from '../components/capacitor/SkeletonIfLoading';

import { IMAGES_BASE } from '../constants';

const messages = defineMessages({
  metaTitle: {
    id: 'livestream-events.meta.title',
    defaultMessage: 'IDAGIO Concerts',
  },
  metaDescription: {
    id: 'livestream-events.meta.description',
    defaultMessage: 'IDAGIO Concerts',
  },
  metaKeywords: {
    id: 'livestream-events.meta.keywords-2',
    defaultMessage: 'idagio concerts, live, livestream, live stream, video, feed, stream, artists',
  },
});

class LivestreamEventsOverview extends PureComponent {
  static propTypes = {
    children: PropTypes.object,
    track: PropTypes.func.isRequired,
  };

  onOrderComplete = (order, event) => {
    const { track } = this.props;
    const trackingProps = {
      liveEvent: event.slug,
      liveStreamEventType: event.type,
      context: 'whatsOn',
      orderId: order.id,
      purchasePlatform: order.platform,
    };

    track('Completed Ticket Purchase', trackingProps);
  };

  render() {
    return (
      <div className="u-page-container">
        <CapacitorSkeletonIfLoading>
          {React.cloneElement(this.props.children, { onOrderComplete: this.onOrderComplete })}
        </CapacitorSkeletonIfLoading>
      </div>
    );
  }
}

function getMetaInformation(props) {
  const { intl } = props;
  const { formatMessage } = intl;
  return {
    'title': formatMessage(messages.metaTitle),
    'description': formatMessage(messages.metaDescription),
    'keywords': formatMessage(messages.metaKeywords),
    'og:image': `${IMAGES_BASE}/assets/web/live-fallback.jpg?w=800`,
  };
}

export default compose(
  connect(null, {
    track: analyticsActions.trackIdagioLive,
  }),
  injectIntl,
  metaInjector(getMetaInformation)
)(LivestreamEventsOverview);
