import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import Swiper from 'react-id-swiper';

import styles from './style.css';
import stylesTooltip from './tooltip.css';

import { LiveCompareRecordingShape } from '../../../shapes/liveCompare';
import CircularPlayButton from '../CircularPlayButton';

function handleTouchEvent(event) {
  const index = parseInt(event.target.getAttribute('data-index'), 10);

  if (!isNaN(index)) {
    this.slideTo(index);
  }
}

function compressedUrl(originalUrl) {
  return `${originalUrl}?auto=format&dpr=1&fit=crop&w=320&h=320`;
}

function recordingCover(
  recording,
  index,
  activeIndex,
  isPlaying,
  isLoading,
  onPressPlay,
  onPressPause,
  onPressResume
) {
  const onResume = activeIndex === index ? onPressResume : () => onPressPlay(index);

  return (
    <div className={classNames(styles.SlideContainer)} key={`carousel-slide-${index}`}>
      <div
        style={{
          backgroundImage: `url(${compressedUrl(recording.imageUrl)})`,
        }}
        className={classNames(
          'carousel-cover-slide',
          activeIndex === index && isLoading && 'carousel-cover-slide__loading',
          styles.SlideImage,
          activeIndex === index && styles.SlideImageActive,
          activeIndex - index === 1 && styles.SlideImagePrev,
          index - activeIndex === 1 && styles.SlideImageNext
        )}
        role="img"
      >
        <div
          className={classNames('carousel-slide__play-button-veil', styles.SlideDarkVeil)}
          data-index={index}
          data-track-id={recording.trackId}
        >
          <CircularPlayButton
            dark
            playing={isPlaying}
            loading={activeIndex === index && isLoading}
            onClick={isPlaying ? onPressPause : onResume}
          />
        </div>
      </div>
    </div>
  );
}

class RecordingsCarouselSection extends React.PureComponent {
  static propTypes = {
    recordings: PropTypes.arrayOf(LiveCompareRecordingShape),
    isCarouselDisabled: PropTypes.bool.isRequired,
    activeRecordingIndex: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    isSwitching: PropTypes.bool,
    isPlayingTheFirstRecording: PropTypes.bool,
    inactivityTooltipDismissed: PropTypes.bool.isRequired,
    playingRecordingIndex: PropTypes.number,
    onChangeActiveRecording: PropTypes.func.isRequired,
    onPressPause: PropTypes.func.isRequired,
    onPressResume: PropTypes.func.isRequired,
  };

  state = {
    shouldShowTooltip: false,
    didShowTooltip: false,
    isSliderMoving: false,
  };

  componentDidMount() {
    if (this.props.activeRecordingIndex !== this.swiper.activeIndex) {
      this.swiper.slideTo(this.props.activeRecordingIndex, 0);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeRecordingIndex !== prevProps.activeRecordingIndex &&
      this.props.activeRecordingIndex !== this.swiper.activeIndex
    ) {
      this.swiper.slideTo(this.props.activeRecordingIndex);
    }

    if (
      !this.props.inactivityTooltipDismissed &&
      this.props.isPlayingTheFirstRecording &&
      !prevProps.isPlayingTheFirstRecording &&
      !this.state.didShowTooltip
    ) {
      this.showTooltipTimeout = setTimeout(this.showTooltip, 3000);
    }

    if (
      !this.props.inactivityTooltipDismissed &&
      !this.props.isPlayingTheFirstRecording &&
      prevProps.isPlayingTheFirstRecording
    ) {
      clearTimeout(this.showTooltipTimeout);
      this.hideTooltip();
    }
  }

  render() {
    const params = {
      effect: 'slide',
      grabCursor: true,
      mousewheel: true,
      centeredSlides: true,
      freeMode: {
        enabled: true,
        sticky: true,
      },
      slidesPerView: 'auto',
      threshold: 15,
      coverflowEffect: {
        rotate: 0,
        stretch: -46,
        depth: 250,
        modifier: 2,
        slideShadows: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'custom',
      },
      keyboard: true,
      on: {
        tap: handleTouchEvent,
        slideChange: this.handleSlideChange,
      },
    };

    return (
      <div className={stylesTooltip.TooltipWrapper}>
        <Swiper
          {...params}
          ref={this.createSwiperReference}
          noSwiping={this.props.isCarouselDisabled}
        >
          {this.props.recordings.map((recording, index) =>
            recordingCover(
              recording,
              index,
              this.props.activeRecordingIndex,
              this.props.playingRecordingIndex === index,
              this.props.isLoading || this.props.isSwitching,
              this.handlePressPlay,
              this.props.onPressPause,
              this.props.onPressResume
            )
          )}
        </Swiper>
        {!this.props.inactivityTooltipDismissed &&
          this.state.shouldShowTooltip &&
          !this.state.isSliderMoving && (
            <div className={stylesTooltip.Tooltip}>
              <FormattedMessage
                id={'live-compare.idle-action-invitation'}
                defaultMessage="Tap here to jump to the exact same moment in the next recording."
              />
            </div>
          )}
      </div>
    );
  }

  showTooltipTimeout = null;
  sliderMoveTimeout = null;

  showTooltip = () => {
    this.setState(
      {
        shouldShowTooltip: true,
        didShowTooltip: true,
      },
      () => {
        this.swiper.on('sliderMove', this.hideTooltip);
      }
    );
  };

  hideTooltip = () => {
    this.setState(
      {
        shouldShowTooltip: false,
      },
      () => {
        this.swiper.off('sliderMove', this.hideTooltip);
        this.props.onInactivityTooltipDismissed();
      }
    );
  };

  createSwiperReference = node => {
    if (node) {
      this.swiper = node.swiper;
    }
  };

  handleSlideChange = () => {
    this.props.onChangeActiveRecording(this.swiper.activeIndex);
  };

  handlePressPlay = recordingIndex => {
    this.props.onChangeActiveRecording(recordingIndex, 'internal', true);
  };
}

export default RecordingsCarouselSection;
