import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { compose } from 'redux';

import MenuCollection from '../messages/MenuCollection';
import MenuDiscover from '../messages/MenuDiscover';
import MenuBrowse from '../messages/MenuBrowse';
import MenuMood from '../messages/MenuMood';
import MenuRecentlyPlayed from '../messages/MenuRecentlyPlayed';

import CollectionLinks from './CollectionLinks';

import stylesSidebar from './Sidebar.css';
import A11ySkipLink from '../a11y/A11ySkipLink';
import * as analyticsActions from '../../actions/analytics';
import MenuConcertsAndCourses from '../messages/MenuConcertsAndCourses';

class Sidebar extends PureComponent {
  static propTypes = {
    routes: PropTypes.array.isRequired,
    track: PropTypes.func.isRequired,
  };

  onIdagioLiveClick = () => {
    this.props.track('Clicked IDAGIO Live', {
      from: 'menu',
    });
  };

  renderWithLabels(isMoodPage) {
    const sidebarLinkStyle = stylesSidebar.sidebarLinkWithLabels;
    const classesMood = classnames(sidebarLinkStyle, { 'is-active': isMoodPage });
    return (
      <ul>
        <li>
          <Link
            to="/discover"
            onlyActiveOnIndex={false}
            className={sidebarLinkStyle}
            activeClassName="is-active"
            data-test="sidebar.discover"
          >
            <MenuDiscover />
          </Link>
        </li>
        <li>
          <Link
            to="/browse"
            className={sidebarLinkStyle}
            activeClassName="is-active"
            onlyActiveOnIndex={false}
            data-test="sidebar.browse"
          >
            <MenuBrowse />
          </Link>
        </li>
        <li>
          <Link
            to="/moods"
            className={classesMood}
            activeClassName="is-active"
            onlyActiveOnIndex={false}
          >
            <MenuMood />
          </Link>
        </li>
        <li>
          <Link
            to="/recently-played"
            className={sidebarLinkStyle}
            activeClassName="is-active"
            onlyActiveOnIndex={false}
          >
            <MenuRecentlyPlayed />
          </Link>
        </li>
      </ul>
    );
  }

  renderEventLink = () => {
    const eventPath = '/live';

    return (
      <Link
        to={eventPath}
        className={stylesSidebar.sidebarLinkWithLabels}
        activeClassName="is-active"
        onlyActiveOnIndex={false}
        onClick={this.onIdagioLiveClick}
        data-test="sidebar.events"
      >
        <div>
          <MenuConcertsAndCourses />
        </div>
      </Link>
    );
  };

  renderEventSection = () => {
    return (
      <React.Fragment>
        <p className={`${stylesSidebar.navHeading} fz--delta`}>
          <FormattedMessage id="menu.events" defaultMessage="Events" />
        </p>
        <ul>
          <li>{this.renderEventLink()}</li>
        </ul>
      </React.Fragment>
    );
  };

  render() {
    const { routes } = this.props;
    const isMoodPage = routes[1] && routes[1].path === '/moods/:id';

    return (
      <aside className={stylesSidebar.sidebar} id="app-sidebar">
        <A11ySkipLink target="#app-content" />
        <nav>
          <section className={stylesSidebar.navSection}>
            {this.renderWithLabels(isMoodPage)}
          </section>
          <section className={stylesSidebar.navSection}>{this.renderEventSection()}</section>
          <section className={stylesSidebar.navSection}>
            <p className={`${stylesSidebar.navHeading} fz--delta`}>
              <MenuCollection />
            </p>
            <CollectionLinks linkClassName={stylesSidebar.sidebarLink} />
          </section>
        </nav>
      </aside>
    );
  }
}

export default compose(
  withRouter,
  connect(null, { track: analyticsActions.trackIdagioLive })
)(Sidebar);
