// @flow
import * as React from 'react';
import type { AuthorGroup } from '../../shapes/types';
import classnames from 'classnames';

import Separated from '../util/Separated';
import Person from '../common/Person';

import styles from './AuthorList.css';

type Props = {|
  authors: Array<AuthorGroup>,
  className?: string,
|};

export default class AuthorList extends React.PureComponent<Props> {
  static renderAsPerson(authorGroup: AuthorGroup) {
    return authorGroup.persons.map(person => <Person person={person} key={person.id} />);
  }

  renderAuthors(notComposers: Array<AuthorGroup>): React.Element<HTMLSpanElement> {
    return notComposers.map(authorGroup => {
      return (
        <span key={authorGroup.key}>
          {`${authorGroup.authorType}: `}{' '}
          <Separated>{AuthorList.renderAsPerson(authorGroup)}</Separated>
        </span>
      );
    });
  }

  render() {
    const { authors, className } = this.props;
    const notComposers = authors.filter(
      ({ key }) => key !== 'composer' && key !== 'composer_original_work'
    );
    if (notComposers.length) {
      return (
        <span className={classnames(styles.component, className)}>
          <Separated>{this.renderAuthors(notComposers)}</Separated>
        </span>
      );
    }
    return null;
  }
}
