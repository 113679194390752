import React, { Component } from 'react';
import List from '../util/List';
import PropTypes from 'prop-types';
import AlbumListItem from '../common/AlbumListItem';
import LoadingIndicator from '../common/LoadingIndicator';
import styles from '../util/MediaObjectGrid.css';

export default class RecordingAlbums extends Component {
  static propTypes = {
    albums: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  renderAlbumListItem = album => {
    return (
      <li key={album.id}>
        <AlbumListItem album={album} />
      </li>
    );
  };

  render() {
    if (this.props.loading) {
      return <LoadingIndicator isLoading={this.props.loading} />;
    }
    return (
      <div>
        <List
          className={styles.list}
          items={this.props.albums}
          renderItem={this.renderAlbumListItem}
        />
      </div>
    );
  }
}
