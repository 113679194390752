// @flow

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import { injectIntl, defineMessages } from 'react-intl';

import classnames from 'classnames';

import Head from '../components/chrome/Head';
import TitleWithPopularTitle from '../components/common/TitleWithPopularTitle';
import ComposerList from '../components/common/ComposerList';
import AuthorList from '../components/common/AuthorList';
import LiveCompareInvitationOverlay from '../components/live-compare/LiveCompareInvitationOverlay';
import CapacitorHeaderBar from '../components/capacitor/HeaderBar';

import { ApiError } from '../middleware/api';

import * as recordingActions from '../actions/recording';
import * as workActions from '../actions/work';
import { loadTopRecordingFacets } from '../actions/facet';

import {
  selectNextFilteredRecordingsCursor,
  selectPreviousFilteredRecordingsCursor,
} from '../selectors/recording';

import { selectWork } from '../selectors/work';

import { selectActiveRecordingFacets } from '../selectors/facet';

import { selectRecordingsFilterParams } from '../selectors/filteredList';

import {
  WORKS_PAGE_FACET_FILTER_ORDERS,
  WORKS_PAGE_FACET_FILTER_ORDER_A as DEFAULT_WORKS_PAGE_FACET_FILTER_ORDER,
  ENTITY_TYPE_WORK,
} from '../constants';

import styles from './Works.css';
import FilteredRecordingList from '../components/common/FilteredRecordingList';

declare var __CAPACITOR__;

const messages = defineMessages({
  metaDescription: {
    id: 'work.meta.description',
    defaultMessage:
      'Listen to {work} by {composer} in stunning, lossless sound quality. Stream now on IDAGIO',
  },
  title: {
    id: 'work.meta.title',
    defaultMessage: '{work} by {composer} | Stream on IDAGIO',
  },
});

import type { Work as WorkShape, Recording, Facet, FilterParams } from '../shapes/types';
import type { LoadRecordingsByFilter } from '../actions/recording';
import type { IntlShape } from 'react-intl';
import type { Connector } from 'react-redux'; // eslint-disable-line
import shareableEntity from '../hoc/shareableEntity';

// TODO type params and location
type OwnProps = {
  params: Object,
  location: Object,
  showShareModal: Function,
};

type MapStateToProps = {
  work: WorkShape,
  currentCursor: ?string,
  nextCursor: ?string,
  prevCursor: ?string,
  location: Object,

  activeFacets: Array<Facet>,
  recordings: Array<Recording>,

  filterParams: FilterParams,
} & OwnProps;

type DispatchProps = { loadRecordingsByFilter: LoadRecordingsByFilter };

type Props = MapStateToProps & DispatchProps & { intl: IntlShape };

class Work extends React.PureComponent<Props> {
  static onDataFetch(store, params) {
    const state = store.getState();
    const work = selectWork(state, params.id);

    if (!work) {
      return Promise.reject(new ApiError(404, 'Not found', 'Work not found'));
    }

    return null;
  }

  static getCanonicalRoute(id) {
    return `/works/${id}`;
  }

  renderHeader(work) {
    const profileClass = classnames(styles.header, 'u-page-container');
    return (
      <div>
        <div className={profileClass}>
          <div className={styles.info}>
            <ComposerList authors={work.authors} hyperlinks className={styles.composerList} />
            <h1 className={`${styles.name} fz--beta`}>
              <TitleWithPopularTitle {...work} />
            </h1>
            <AuthorList authors={work.authors} className={styles.authorList} />
            <LiveCompareInvitationOverlay workId={work.id} />
          </div>
        </div>
      </div>
    );
  }

  renderRecordingsWithFilter() {
    const { work, location, params, filterParams, activeFacets } = this.props;

    // Our data model allows for works without genres
    const facetOrder = work.genre
      ? WORKS_PAGE_FACET_FILTER_ORDERS[work.genre.id]
      : DEFAULT_WORKS_PAGE_FACET_FILTER_ORDER;

    return (
      <FilteredRecordingList
        contextType="work"
        contextFacet="Work"
        contextId={work.id.toString()}
        facetOrder={facetOrder}
        location={location}
        params={params}
        ignoredFacets={['works']}
        filterParams={filterParams}
        hideWorkTitle
        activeFacets={activeFacets}
      />
    );
  }

  render() {
    const { work, intl, currentCursor, nextCursor, prevCursor } = this.props;
    const { formatMessage } = intl;
    const classesProfile = classnames(styles.component, 'u-page-container');
    return (
      <div>
        <Head
          title={formatMessage(messages.title, {
            work: work.title,
            composer: work.composer.name,
          })}
          description={formatMessage(messages.metaDescription, {
            work: work.title,
            composer: work.composer.name,
          })}
          opengraph={[{ property: 'og:type', content: 'work' }]}
          currentCursor={currentCursor}
          nextCursor={nextCursor}
          prevCursor={prevCursor}
          canonicalRoute={Work.getCanonicalRoute(work.id)}
        />
        {__CAPACITOR__ && (
          <CapacitorHeaderBar
            showShareModal={this.props.showShareModal}
            title={work.title}
            onlyShowTitleOnScroll
          />
        )}
        {this.renderHeader(work)}
        <div className={classesProfile}>{this.renderRecordingsWithFilter()}</div>
      </div>
    );
  }
}

function fetchData(store, { id }, { query }) {
  const filterParams = selectRecordingsFilterParams(query, id, 'works');
  return [
    store.dispatch(workActions.loadWork(id)),
    store.dispatch(recordingActions.loadRecordingsByFilter(filterParams)),
    store.dispatch(loadTopRecordingFacets(filterParams)),
  ];
}

function mapStateToProps(state: Object, ownProps: OwnProps): MapStateToProps | {} {
  const { params, location } = ownProps;
  const { cursor, id } = params;
  const work = selectWork(state, id);
  const filterParams = selectRecordingsFilterParams(location.query, id, 'works');
  const selectNextCursor = selectNextFilteredRecordingsCursor;
  const selectPrevCursor = selectPreviousFilteredRecordingsCursor;
  return {
    work,
    currentCursor: cursor,
    nextCursor: selectNextCursor(state, filterParams),
    prevCursor: selectPrevCursor(state, filterParams),
    location,
    activeFacets: selectActiveRecordingFacets(state, filterParams, 'works'),
    filterParams,
  };
}

const dispatchProps: DispatchProps = {
  loadRecordingsByFilter: recordingActions.loadRecordingsByFilter,
};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, dispatchProps);

function getShareTrackingContext(props) {
  return {
    sharedContentType: 'Work',
    sharedContentId: props.work.id,
    contextType: 'Work',
    contentId: props.work.id,
  };
}

export default compose(
  dataComponent(fetchData),
  connector,
  injectIntl,
  shareableEntity(ENTITY_TYPE_WORK, getShareTrackingContext)
)(Work);
