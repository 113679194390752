import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import StickyNavigation from '../common/StickyNavigation';
import BrowseCapacitorNavigation from './BrowseCapacitorNavigation';
import { Link } from 'react-router';
import styles from '../common/StickyNavigation.css';
import * as analyticsActions from '../../actions/analytics';
import CapacitorRipple from '../capacitor/Ripple';
import { connect } from 'react-redux';

class BrowseNavigation extends PureComponent {
  static propTypes = {
    track: PropTypes.func.isRequired,
  };

  onClick = event => {
    const category = event.currentTarget.getAttribute('data-tracking-category');
    this.props.track('Selected Browse Category', { category });
  };

  render() {
    const navigationContent = (
      <React.Fragment>
        <Link
          to="/browse/composers"
          className={styles.link}
          activeClassName={styles.linkIsActive}
          data-tracking-category="composers"
          onClick={this.onClick}
        >
          <CapacitorRipple />
          <FormattedMessage id="browse.nav.composers" defaultMessage="Composers" />
        </Link>

        <Link
          to="/browse/performers"
          className={styles.link}
          activeClassName={styles.linkIsActive}
          data-tracking-category="performers"
          onClick={this.onClick}
        >
          <CapacitorRipple />
          <FormattedMessage id="browse.nav.performers" defaultMessage="Performers" />
        </Link>

        <Link
          to="/browse/epochs"
          className={styles.link}
          activeClassName={styles.linkIsActive}
          data-tracking-category="periods"
          onClick={this.onClick}
        >
          <CapacitorRipple />
          <FormattedMessage id="browse.nav.periods" defaultMessage="Periods" />
        </Link>

        <Link
          to="/browse/genres"
          className={styles.link}
          activeClassName={styles.linkIsActive}
          data-tracking-category="genres"
          onClick={this.onClick}
        >
          <CapacitorRipple />
          <FormattedMessage id="browse.nav.genres" defaultMessage="Genres" />
        </Link>

        <Link
          to="/browse/instruments"
          className={styles.link}
          activeClassName={styles.linkIsActive}
          data-tracking-category="instruments"
          onClick={this.onClick}
        >
          <CapacitorRipple />
          <FormattedMessage id="browse.nav.instruments" defaultMessage="Instruments" />
        </Link>
      </React.Fragment>
    );

    if (__CAPACITOR__) {
      return <BrowseCapacitorNavigation>{navigationContent}</BrowseCapacitorNavigation>;
    }

    return <StickyNavigation>{navigationContent}</StickyNavigation>;
  }
}

export default connect(null, {
  track: analyticsActions.track,
})(BrowseNavigation);
