// @flow
import React from 'react';

import type { CourseResource } from '../../shapes/types';
import CourseResourceGroup from './CourseResourceGroup';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import type { IntlShape } from 'react-intl';
import { COURSE_RESOURCE_TYPE } from '../../constants';
import styles from './CourseResources.css';

type OwnProps = {
  images: Array<CourseResource>,
  videos: Array<CourseResource>,
  audios: Array<CourseResource>,
  documents: Array<CourseResource>,
};

type Props = OwnProps & { intl: IntlShape };

const messages = defineMessages({
  images: {
    id: 'course.resource.images.title',
    defaultMessage: 'Image Gallery',
  },
  videos: {
    id: 'course.resource.videos.title',
    defaultMessage: 'Videos',
  },
  audios: {
    id: 'course.resource.audios.title',
    defaultMessage: 'Audios',
  },
  documents: {
    id: 'course.resource.documents.title',
    defaultMessage: 'Documents',
  },
});

const CourseResources = ({ images, videos, audios, documents, intl }: Props) => {
  if (!images && !videos && !audios && !documents) {
    return null;
  }

  const { formatMessage } = intl;
  return (
    <section className={styles.wrapper} data-test="livestream-event.course-resources">
      <h2>
        <FormattedMessage
          id="livestream-events.course-resources.title"
          defaultMessage="Course resources"
        />
      </h2>

      {images && (
        <CourseResourceGroup
          title={formatMessage(messages.images)}
          items={images}
          resourceType={COURSE_RESOURCE_TYPE.IMAGE}
        />
      )}

      {videos && (
        <CourseResourceGroup
          title={formatMessage(messages.videos)}
          items={videos}
          resourceType={COURSE_RESOURCE_TYPE.VIDEO}
        />
      )}

      {audios && (
        <CourseResourceGroup
          title={formatMessage(messages.audios)}
          items={audios}
          resourceType={COURSE_RESOURCE_TYPE.AUDIO}
        />
      )}

      {documents && (
        <CourseResourceGroup
          title={formatMessage(messages.documents)}
          items={documents}
          resourceType={COURSE_RESOURCE_TYPE.DOCUMENT}
        />
      )}
    </section>
  );
};

export default injectIntl(CourseResources);
