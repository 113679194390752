import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import { CONSTANT_MESSAGES } from '../../constants';

const messages = defineMessages({
  ariaLink: {
    id: 'aria-labels-annotation-link',
    defaultMessage: 'Image credit link',
  },
});

class ImageCredit extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    annotation: PropTypes.object,
    intl: intlShape,
  };

  render() {
    const { className, annotation } = this.props;

    if (!annotation) {
      return null;
    }

    if (annotation.link) {
      const text = this.props.intl.formatMessage(messages.ariaLink);
      const ariaLink = this.props.intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
        linkText: text,
      });

      return (
        <a
          href={annotation.link}
          className={className}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={ariaLink}
        >
          Photo: {annotation.v}
        </a>
      );
    }

    return <div className={className}>Photo: {annotation.v}</div>;
  }
}

export default injectIntl(ImageCredit);
