import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

import { Link } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { selectTrackIsDownloading, selectTrackIsDownloaded } from '../../selectors/download';
import ExpandButton from '../common/ExpandButton';
import TitleWithPopularTitle from '../common/TitleWithPopularTitle';
import Separated from '../util/Separated';

import CapacitorRipple from '../capacitor/Ripple';
import InterpreterList from '../common/InterpreterList';
import ComposerList from '../common/ComposerList';
import AuthorList from '../common/AuthorList';
import RecordingInfo from '../recording/RecordingInfo';
import RecordingTags from '../recording/RecordingTags';
import DownloadIndicator from '../capacitor/DownloadIndicator';

import collectibleEntity, { collectibleEntityPropTypes } from '../../hoc/collectibleEntity';

import * as Shapes from '../../shapes/index';

import styles from './PlaylistItem.css';
import PlayButton from '../common/PlayButton';

import ContextMenu from '../util/ContextMenu';
import ContextMenuItem from '../util/ContextMenuItem';
import { FormattedMessage } from 'react-intl';
import SaveToCollectionButton from '../messages/SaveToCollectionButton';
import IconLabel from '../util/IconLabel';
import getRecordingIsPopular from '../../utils/getRecordingIsPopular';
import { ENTITY_TYPE_TRACK } from '../../constants';
import AddToPlaylistContextMenuItem from '../common/AddToPlaylistContextMenuItem';

class PlaylistItem extends PureComponent {
  static propTypes = {
    track: Shapes.Track.isRequired,
    indexInPlaylist: PropTypes.number.isRequired,
    playlist: Shapes.Playlist,

    entityType: PropTypes.oneOf(['album', 'playlist', 'personal_playlist']),
    onClick: PropTypes.func,
    isCurrentTrack: PropTypes.bool,
    playing: PropTypes.bool,
    isDuplicatePiece: PropTypes.bool.isRequired,
    onAddToPlaylistButtonClick: PropTypes.func.isRequired,
    isInPersonalPlaylist: PropTypes.bool.isRequired,
    removeFromPlaylist: PropTypes.func,
    measure: PropTypes.func,

    dragHandleProps: PropTypes.object,
    trackIsDownloaded: PropTypes.bool,
    trackIsDownloading: PropTypes.bool,

    ...collectibleEntityPropTypes,
  };

  static defaultProps = {
    isInPersonalPlaylist: false,
  };

  constructor(props, context) {
    super(props, context);
    this.hasExpanded = false;
  }

  onClick = playing => {
    const { onClick, track, indexInPlaylist } = this.props;
    onClick(track.id, indexInPlaylist, playing);
  };

  onAddToPlaylistClick = () => {
    this.props.onAddToPlaylistButtonClick(this.props.track.id);
  };

  onRemoveFromPlaylistClick = () => {
    const { indexInPlaylist, track } = this.props;

    this.props.removeFromPlaylist(track, indexInPlaylist);
  };

  renderRecordingInfo() {
    const track = this.props.track;
    const recording = track.recording;
    const hyperlinks = this.props.isExpanded;
    return (
      <div className={styles.recordingInfo}>
        <InterpreterList
          recording={recording}
          hyperlinks={hyperlinks}
          linkClassNames="playlist-item__performer-link"
          multiline={!__CAPACITOR__}
        />
        <RecordingInfo
          date={recording.recordingDate}
          venue={recording.venue}
          location={recording.location}
          className={styles.recordingInfo}
        />
      </div>
    );
  }

  static renderPieceTitle(workTitle, workpartTitle, piece) {
    if (workTitle !== piece.title) {
      return (
        <span>
          {workpartTitle && <span>{`${workpartTitle} • `}</span>}
          <TitleWithPopularTitle {...piece} />
        </span>
      );
    }
    return null;
  }

  renderInfoDetail() {
    const track = this.props.track;
    const workpartTitle = track.piece.workpart.title;
    const { work, tags, id } = track.recording;
    const renderLinks = true;
    const tabIndex = this.props.isExpanded ? null : -1;
    return (
      <div key={track.id + 'expanded'}>
        <div className={styles.detailComposer}>
          <ComposerList
            tabIndex={tabIndex}
            authors={work.authors}
            linkClassNames={styles.link}
            hyperlinks
          />
        </div>
        <Link to={`/recordings/${id}`} className={styles.linkRecording} tabIndex={tabIndex}>
          {__CAPACITOR__ && (
            <DownloadIndicator
              trackIsDownloaded={this.props.trackIsDownloaded}
              trackIsDownloading={this.props.trackIsDownloading}
              className={styles.downloadIndicator}
            />
          )}
          <span className={styles.detailWorkTitle}>
            {work.title}
            <br />
          </span>
          {/*
            <br /> is used to achieve a line break between work-title and piece title but still benefit from the display: inline characteristic.
            This wasn't achievable with inline-block, nor any other display property bc we have a <Link> wrapping both elements.
          */}
          <span>{PlaylistItem.renderPieceTitle(work.title, workpartTitle, track.piece)}</span>
        </Link>
        <AuthorList authors={work.authors} className={styles.authorList} />
        {this.renderRecordingInfo(renderLinks)}
        {tags && <RecordingTags tags={tags} isPopular={getRecordingIsPopular(track.recording)} />}
      </div>
    );
  }

  renderInfoDefault(work, track) {
    const renderLinks = false;
    return (
      <div
        className={styles.infoDefaultContainer}
        key={track.id + 'collapsed'}
        data-test="playlist-item.info-container"
      >
        <div className={classnames(styles.composer, styles.infoPart)}>{work.composer.surname}</div>
        {/* eslint-disable-next-line css-modules/no-undef-class */}
        <span className={styles.separator}> • </span>
        <span className={styles.title}>
          {/* eslint-disable-next-line css-modules/no-undef-class */}
          <Separated separator={<span className={styles.separator}> • </span>}>
            <div className={classnames(styles.infoPart, styles.workTitle)}>
              {__CAPACITOR__ && (
                <DownloadIndicator
                  trackIsDownloaded={this.props.trackIsDownloaded}
                  trackIsDownloading={this.props.trackIsDownloading}
                  className={styles.downloadIndicator}
                />
              )}
              <TitleWithPopularTitle {...work} />
            </div>
            <div className={styles.infoPart}>
              {PlaylistItem.renderPieceTitle(work.title, track.piece.workpart.title, track.piece)}
            </div>
          </Separated>
        </span>
        {this.props.isDuplicatePiece && this.renderRecordingInfo(renderLinks)}
      </div>
    );
  }

  renderReorderButton() {
    return (
      <button
        {...this.props.dragHandleProps}
        /* eslint-disable-next-line css-modules/no-undef-class */
        className={classnames(this.props.dragHandleProps.className, styles.dragHandle)}
      >
        <IconLabel name="menu" title="Re-order" />
      </button>
    );
  }

  renderContextMenu() {
    const { isInPersonalPlaylist, isInCollection, toggleIsInCollection } = this.props;

    return (
      <ContextMenu data-test="playlist-item.context-menu">
        <ContextMenuItem
          onClick={toggleIsInCollection}
          keepOpenAfterClick
          data-test="context-menu.toggle-collection"
        >
          {isInCollection ? (
            <React.Fragment>
              <IconLabel name="heart-block" size="default" />
              <FormattedMessage
                id="collection.button.remove"
                defaultMessage="Remove from my collection"
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <IconLabel name="heart" size="default" />
              <SaveToCollectionButton />
            </React.Fragment>
          )}
        </ContextMenuItem>
        <AddToPlaylistContextMenuItem onClick={this.onAddToPlaylistClick} />
        {isInPersonalPlaylist && (
          <ContextMenuItem
            onClick={this.onRemoveFromPlaylistClick}
            data-test="context-menu-item.remove-from-playlist"
          >
            <IconLabel name="remove-from-playlist" size="default" />
            <FormattedMessage
              id="playlist-item.context-menu.remove-from-playlist-text"
              defaultMessage="Remove from playlist"
            />
          </ContextMenuItem>
        )}
      </ContextMenu>
    );
  }

  render() {
    const { track, isCurrentTrack, playing, isInPersonalPlaylist } = this.props;

    const workpart = track.piece.workpart;
    const work = workpart.work;

    const { isExpanded } = this.props;

    if (this.props.isExpanded) {
      // Hack to avoid re-rendering after prop update
      this.hasExpanded = true;
    }

    const playingThisTrack = isCurrentTrack && playing;

    return (
      <div className={styles.item} data-test="playlist-item">
        <CapacitorRipple onClick={this.onClick} />

        <div className={styles.wrapper}>
          <PlayButton
            className={styles.statusButton}
            iconClassName={styles.status}
            size="default"
            playing={playingThisTrack}
            onClick={this.onClick}
            data-test="playlist-item.play-btn"
          />
          <div className={styles.info}>
            {isExpanded ? (
              <motion.div
                key="expanded"
                animate={{
                  opacity: 1,
                }}
                initial={{ opacity: 0 }}
                transition={{
                  duration: 0.5,
                }}
                className={styles.infoDetail}
              >
                {this.renderInfoDetail()}
              </motion.div>
            ) : (
              <motion.div
                key="collapsed"
                className={styles.infoDefault}
                ref={ref => (this.infoDefaultRef = ref)}
                onClick={this.onClick}
                animate={{ opacity: 1 }}
                initial={{ opacity: this.hasExpanded ? 0 : 1 }}
                transition={{
                  duration: 0.5,
                }}
              >
                {this.renderInfoDefault(work, track)}
              </motion.div>
            )}
          </div>
        </div>
        <div className={styles.actions}>
          {isInPersonalPlaylist && this.renderReorderButton()}
          {this.renderContextMenu()}
          <ExpandButton
            isExpanded={isExpanded}
            onClick={() => this.props.toggleExpanded(track.id, this.props.measure)}
          />
        </div>
      </div>
    );
  }
}

function getCollectibleEntityDescription(props) {
  return {
    id: props.track.id,
    trackingSource: props.entityType === 'album' ? 'Album' : 'Playlist',
    capacitorContextEntityType: props.entityType,
    capacitorContextEntityId: props.playlist ? props.playlist.id : undefined,
  };
}

export default compose(
  connect((state, props) => {
    if (!__CAPACITOR__ || !props.playlist || !props.playlist.id) {
      return {};
    }
    return {
      trackIsDownloading: selectTrackIsDownloading(state, props.playlist.id, props.track.id),
      trackIsDownloaded: selectTrackIsDownloaded(state, props.playlist.id, props.track.id),
    };
  }),
  collectibleEntity(ENTITY_TYPE_TRACK, getCollectibleEntityDescription)
)(PlaylistItem);
