import React from 'react';
import DataFetcher from './DataFetcher';
import qs from 'qs';

function getLocationWithQuery(location) {
  if (!location.query) {
    return {
      ...location,
      query: qs.parse(location.search.substr(1)),
    };
  }
  return location;
}

function fetchDataMiddleware() {
  return {
    renderRouterContext: function wrapRouterContext(children) {
      const props = {
        ...children.props,
        location: getLocationWithQuery(children.props.location),
      };
      const childrenWithQueryInProps = {
        ...children,
        props,
      };

      return <DataFetcher {...props}>{childrenWithQueryInProps}</DataFetcher>;
    },
  };
}

export default fetchDataMiddleware;
