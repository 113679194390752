import React, { Component } from 'react';
import CapacitorRipple from '../capacitor/Ripple';
import stylesBrowseElement from './BrowseElement.css';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import ArtistAvatar from '../common/ArtistAvatar';

export default class BrowseElement extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  };

  render() {
    const { id, name, href } = this.props;
    return (
      <Link key={id} to={href} className={stylesBrowseElement.el} data-test="browse-element">
        <CapacitorRipple />
        <ArtistAvatar profileId={id} className={stylesBrowseElement.img} />
        <span className={stylesBrowseElement.text}>{name}</span>
      </Link>
    );
  }
}
