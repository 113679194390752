import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Slider from 'rc-slider';
import IconLabel from './../util/IconLabel';
import { defineMessages, intlShape, injectIntl } from 'react-intl';

import styles from './PlayerVolume.css';

const messages = defineMessages({
  ariaButton: {
    id: 'aria-button-player-volume',
    defaultMessage: 'Volume options',
  },
});

class PlayerVolume extends PureComponent {
  static propTypes = {
    volume: PropTypes.number.isRequired,
    changeVolume: PropTypes.func.isRequired,
    toggleMute: PropTypes.func.isRequired,
    isMuted: PropTypes.bool.isRequired,
    intl: intlShape,
  };

  render() {
    const { isMuted, volume, intl } = this.props;
    const volumeControlClassNames = classnames(styles.volume, {
      [styles.isMuted]: isMuted,
    });
    const volumeBar = (
      <Slider className={styles.bar} value={volume} onChange={this.handleVolumeChange} />
    );
    const ariaButton = intl.formatMessage(messages.ariaButton);

    return (
      <div className={volumeControlClassNames}>
        <button className={styles.btn} onClick={this.handleToggleMuteClick} aria-label={ariaButton}>
          <IconLabel name="volume-mute-block" size="small" className={styles.iconMute} />
          <IconLabel name="volume-mute" size="small" className={styles.iconIsMuted} />
          <IconLabel name="volume-block" size="small" className={styles.iconDefault} />
        </button>
        <div className={styles.container}>{volumeBar}</div>
      </div>
    );
  }

  handleToggleMuteClick = () => {
    this.props.toggleMute();
  };

  handleVolumeChange = volume => {
    this.props.changeVolume(volume);
  };
}

export default injectIntl(PlayerVolume);
