// @flow

import { loadedCursor } from '../middleware/apiCacheValidators';
import { PersonList } from '../schema';
import { normalizeMetaList } from '../schema/normalizeMeta';

import type { Request } from './types';

export type LoadComposersAction = { type: 'FETCH_COMPOSERS' } & Request;

export type ComposerAction = LoadComposersAction;

export function loadComposers(cursor: string, limit: number = 50): LoadComposersAction {
  let params = { limit };

  if (cursor) {
    params = {
      ...params,
      cursor,
    };
  }

  return {
    type: 'FETCH_COMPOSERS',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/metadata/composers',
      params,
    },
    meta: {
      schema: PersonList,
      normalize: normalizeMetaList,
    },
    cache: {
      fetch: state => state.lists.composers,
      validate: loadedCursor(cursor),
    },
  };
}
