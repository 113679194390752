// @flow
import React from 'react';
import ContextMenuItem from '../util/ContextMenuItem';
import IconLabel from '../util/IconLabel';
import { FormattedMessage } from 'react-intl';

type OwnProps = {
  onClick: Function,
};

const AddToPlaylistContextMenuItem = ({ onClick }: OwnProps) => (
  <ContextMenuItem onClick={onClick}>
    <IconLabel name="add-to-playlist" size="default" />
    <FormattedMessage id="playlist.add-to-playlist-button.text" defaultMessage="Add to playlist" />
  </ContextMenuItem>
);

export default AddToPlaylistContextMenuItem;
