// @flow
import React from 'react';
import styles from './ClearAllActiveFacetsButton.css';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import ClearAllActiveFacets from './ClearAllActiveFacets';

type OwnProps = {
  pathname: string,
  query: Object,
  onClickClear?: Function,
};

const ClearAllActiveFacetsButton = ({ pathname, query, onClickClear }: OwnProps) => {
  const linkClassNames = classNames(
    'c-btn',
    'c-btn--has-outline',
    'c-btn--is-small',
    styles.component
  );

  return (
    <ClearAllActiveFacets
      pathname={pathname}
      query={query}
      title={
        <FormattedMessage id="filtered-list.active-facets.clear-all" defaultMessage="Clear all" />
      }
      className={linkClassNames}
      onClickClear={onClickClear}
    />
  );
};

export default ClearAllActiveFacetsButton;
