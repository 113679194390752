// @flow
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router';
import CapacitorRipple from '../capacitor/Ripple';
import IconLabel from '../util/IconLabel';
import RecordingInfo from './RecordingInfo';
import RecordingTags from './RecordingTags';
import styles from './RecordingItem.css';
import getRecordingIsPopular from '../../utils/getRecordingIsPopular';
import type { Recording } from '../../shapes/types';
import ComposerList from '../common/ComposerList';
import shouldOmitForenameFromComposers from '../../utils/shouldOmitForenameFromComposers';
import TitleWithPopularTitle from '../common/TitleWithPopularTitle';

type Props = {
  recording: Recording,
  hideWorkTitle?: boolean,
};

const RecordingItemLink = ({ recording, hideWorkTitle }: Props) => {
  const { id, summary, recordingDate, location, venue, tags, work } = recording;

  return (
    <Link
      className={classNames(styles.link, 'c-link--expand-hitbox')}
      to={`/recordings/${id}`}
      data-test="recording-item.link"
    >
      <CapacitorRipple />
      <div className={styles.infoContainer} data-test="recording-item.info">
        {!hideWorkTitle && (
          <div className={styles.title}>
            <div className={styles.composer}>
              <ComposerList
                authors={work.authors}
                omitForename={shouldOmitForenameFromComposers(work.authors)}
              />
            </div>
            <TitleWithPopularTitle {...work} />
          </div>
        )}
        <div className={hideWorkTitle ? styles.title : styles.info}>
          <span>{summary}</span>
        </div>
        <RecordingInfo
          className={styles.info}
          location={location}
          venue={venue}
          date={recordingDate}
        />
        {tags && (
          <div className={styles.tags}>
            <RecordingTags tags={tags} isPopular={getRecordingIsPopular(recording)} />
          </div>
        )}
      </div>
      <IconLabel name="chevron-right" className={styles.linkIcon} size="default" />
    </Link>
  );
};

export default RecordingItemLink;
