import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Shapes from '../../shapes';
import CapacitorVideoPlayer from './CapacitorVideoPlayer';
import YouTubeVideoAdaptor from './VideoAdaptors/YouTubeVideoAdaptor';
import VimeoVideoAdaptor from './VideoAdaptors/VimeoVideoAdaptor';
import MuxVideoAdaptor from './VideoAdaptors/MuxVideoAdaptor';
import IconLabel from '../util/IconLabel';

import styles from './LivestreamEventVideo.css';

const noop = () => null;

export class LiveStreamEventVideo extends React.Component {
  static propTypes = {
    video: Shapes.LivestreamEventVideo,

    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onEnded: PropTypes.func,
    onProgressUpdate: PropTypes.func,

    className: PropTypes.string,
    imageUrl: PropTypes.string,
  };

  state = {
    chatIsCollapsed: true,
  };

  onClickToggleChat = () => {
    const oldValue = this.state.chatIsCollapsed;
    this.setState({ chatIsCollapsed: !oldValue });
  };

  onPlay = (data, isResume) => {
    if (this.props.onPlay) {
      this.props.onPlay(data, this.props.video, isResume);
    }
  };

  onPause = data => {
    if (this.props.onPause) {
      this.props.onPause(data, this.props.video);
    }
  };

  onEnded = data => {
    if (this.props.onEnded) {
      this.props.onEnded(data, this.props.video);
    }
  };

  renderYoutube = ({ videoId, showControls, autoPlay, hideYoutubeLogo }) => {
    return (
      <YouTubeVideoAdaptor
        id={videoId}
        className={styles.video}
        autoPlay={autoPlay}
        showControls={showControls}
        hideYoutubeLogo={hideYoutubeLogo}
        onPlay={this.onPlay}
        onPause={this.onPause}
        onEnded={this.onEnded}
      />
    );
  };

  renderVimeo = ({ videoId, showControls, autoPlay }) => {
    return (
      <VimeoVideoAdaptor
        id={videoId}
        className={styles.video}
        autoPlay={autoPlay}
        showControls={showControls}
        onPlay={this.onPlay}
        onPause={this.onPause}
        onEnded={this.onEnded}
        onProgressUpdate={this.props.onProgressUpdate || noop}
      />
    );
  };

  renderMux = ({ videoId, showControls, autoPlay }) => {
    return (
      <MuxVideoAdaptor
        id={videoId}
        className={styles.video}
        autoPlay={autoPlay}
        showControls={showControls}
        onPlay={this.onPlay}
        onPause={this.onPause}
        onEnded={this.onEnded}
      />
    );
  };

  render3Q = ({ videoId }) => {
    const src = `https://playout.3qsdn.com/${videoId}`;
    return <iframe src={src} className={styles.video} frameBorder="0" allowFullScreen />;
  };

  renderChat(video) {
    const className = classnames(styles.chatContainer, {
      [styles.chatIsCollapsed]: this.state.chatIsCollapsed,
    });

    return <div className={className}>{this.renderVimeoChat(video)}</div>;
  }

  renderVimeoChat = ({ videoId }) => {
    return (
      <iframe
        src={`https://vimeo.com/live-chat/${videoId}/`}
        className={styles.chat}
        frameBorder="0"
      />
    );
  };

  renderMenu = () => (
    <div className={styles.menuContainer}>
      <button type="button" className={`btn ${styles.chatToggle}`} onClick={this.onClickToggleChat}>
        <IconLabel name="chat" title="Toggle chat" className={styles.chatToggleIcon} />
      </button>
    </div>
  );

  renderPlayer = () => {
    const { video } = this.props;
    const { source } = video;

    const isYoutube = !source || source === 'youtube';
    const is3Q = source === '3q';
    const isVimeo = source === 'vimeo';
    const isMux = source === 'mux';

    if (__CAPACITOR__ && (isYoutube || isVimeo)) {
      return <CapacitorVideoPlayer video={this.props.video} imageUrl={this.props.imageUrl} />;
    }
    if (isYoutube) {
      return this.renderYoutube(video);
    }
    if (is3Q) {
      return this.render3Q(video);
    }
    if (isVimeo) {
      return this.renderVimeo(video);
    }
    if (isMux) {
      return this.renderMux(video);
    }
    return null;
  };

  render() {
    const { video, className } = this.props;
    const { source, chatEnabled } = video;

    const isVimeo = source === 'vimeo';
    const shouldRenderChat = isVimeo && chatEnabled;

    const containerClassName = classnames(styles.container, className, {
      [styles.chatIsEnabled]: shouldRenderChat,
    });

    const innerClassName = classnames(styles.chatAndVideoContainer, {
      [styles.chatIsEnabled]: shouldRenderChat,
    });

    return (
      <div className={containerClassName}>
        <div className={innerClassName}>
          <figure
            className={styles.videoContainer}
            role="none"
            data-test="livestream-event-hero.video-container"
          >
            {this.renderPlayer()}
          </figure>
          {shouldRenderChat && this.renderChat(video)}
        </div>
        {shouldRenderChat && this.renderMenu()}
      </div>
    );
  }
}
