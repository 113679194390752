import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import * as Shapes from '../../shapes';

import RecordingItemLink from './RecordingItemLink';
import PlayButton from '../common/PlayButton';
import CollectionButton from '../common/CollectionButton';
import AddToPlaylistButton from '../common/AddToPlaylistButton';

import { QUEUE_TYPE_RECORDING, ENTITY_TYPE_RECORDING } from '../../constants';

import { selectPlayerCurrentQueueItem, selectPlayerIsPlaying } from '../../selectors/player';

import * as uiActions from '../../actions/ui';

import styles from './RecordingItem.css';
import { compose } from 'redux';
import queueOriginComponent, {
  queueOriginComponentPropTypes,
} from '../../hoc/queueOriginComponent';

import playlistableEntity, { playlistableEntityPropTypes } from '../../hoc/playlistableEntity';
import collectibleEntity, { collectibleEntityPropTypes } from '../../hoc/collectibleEntity';

class RecordingItem extends Component {
  static propTypes = {
    contextClassName: PropTypes.string,
    recording: Shapes.Recording,
    hideWorkTitle: PropTypes.bool,
    hidePlayButton: PropTypes.bool,
    currentQueueItem: PropTypes.object,
    user: PropTypes.object,
    pause: PropTypes.func.isRequired,
    play: PropTypes.func.isRequired,
    setQueueAndPlay: PropTypes.func.isRequired,
    sourceClassName: PropTypes.string,
    sourceUserName: PropTypes.string,
    timeStamp: PropTypes.element,
    description: PropTypes.string,
    recordingId: PropTypes.number,
    trackId: PropTypes.number,
    showDescription: PropTypes.bool,
    showCollectionButton: PropTypes.bool,
    beforeTogglePlay: PropTypes.func,
    showAddToPlaylistModal: PropTypes.func.isRequired,
    ...collectibleEntityPropTypes,
    ...playlistableEntityPropTypes,
    ...queueOriginComponentPropTypes,
  };

  renderFeaturedRecordingLink(description, recordingId, trackId) {
    const link = `/recordings/${recordingId}#t=${trackId}`;
    return (
      <Link to={link} data-test="recording-item.link">
        <p className={styles.italic} data-test="recording-item.info">
          {description}
        </p>
      </Link>
    );
  }

  render() {
    const {
      recording,
      hideWorkTitle,
      hidePlayButton,
      description,
      recordingId,
      trackId,
      showDescription,
      showCollectionButton,
      isQueued,
      isPlaying,
      isInCollection,
      toggleIsInCollection,
    } = this.props;

    return (
      <div className={styles.item} data-test="recording-item">
        {!hidePlayButton && (
          <div className={styles.playButton}>
            <PlayButton
              className={styles.btnPlay}
              playing={isPlaying}
              onClick={this.props.togglePlayAll}
            />
          </div>
        )}
        {showDescription ? (
          this.renderFeaturedRecordingLink(description, recordingId, trackId, isQueued)
        ) : (
          <RecordingItemLink recording={recording} hidewWorkTitle={hideWorkTitle} />
        )}
        {showCollectionButton && (
          <CollectionButton
            className={styles.btn}
            active={isInCollection}
            onClick={toggleIsInCollection}
          />
        )}
        {!__CAPACITOR__ && (
          <AddToPlaylistButton onClick={this.props.onAddToPlaylistClick} className={styles.btn} />
        )}
      </div>
    );
  }
}

function getQueueOrigin(originId) {
  return {
    type: QUEUE_TYPE_RECORDING,
    id: originId,
  };
}

function mapQueueOriginStateToProps(state, ownProps) {
  const { id, tracks, trackIds } = ownProps.recording;
  const currentQueueItem = selectPlayerCurrentQueueItem(state);
  const recordingIsInQueue =
    Boolean(currentQueueItem) &&
    tracks.some(track => track.id.toString() === currentQueueItem.track);

  return {
    originId: id.toString(),
    tracks,
    trackIds,
    isPlaying: recordingIsInQueue && selectPlayerIsPlaying(state),
    isQueued: recordingIsInQueue,
  };
}

function getTrackingContext(props) {
  const { contextType, contextId, contextFacet } = props;
  if (contextType && contextId && contextFacet) {
    return {
      contextType,
      contextId,
      contextFacet,
    };
  }

  return {
    contextType: 'recording',
    contextId: props.recording.id.toString(),
  };
}

function getEntityTrackingData(props) {
  return {
    itemType: 'recording',
    itemId: props.recording.id.toString(),
  };
}

function getCollectibleEntityDescription(props) {
  return {
    id: props.recording.id,
    trackingSource: 'Recording',
  };
}

export default compose(
  connect(null, {
    showAddToPlaylistModal: uiActions.showAddToPlaylistModal,
  }),
  playlistableEntity({
    getEntityTrackingData,
    getTrackingContext,
    entityType: ENTITY_TYPE_RECORDING,
  }),
  queueOriginComponent(getQueueOrigin, mapQueueOriginStateToProps, getTrackingContext),
  collectibleEntity(ENTITY_TYPE_RECORDING, getCollectibleEntityDescription)
)(RecordingItem);
