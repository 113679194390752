import GenericTrackListView from '../components/common/GenericTrackListView';
import { QUEUE_TYPE_ALBUM, ENTITY_TYPE_ALBUM } from '../constants';
import { loadAlbum } from '../actions/album';
import { showAddToPlaylistModal } from '../actions/ui';
import {
  selectAlbum,
  selectAlbumIsPlaying,
  selectAlbumIsQueued,
  selectAlbumImageAnnotation,
  selectAlbumIdFromSlug,
} from '../selectors/album';

import * as playerActions from '../actions/player';

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import collectibleEntity from '../hoc/collectibleEntity';
import shareableEntity from '../hoc/shareableEntity';
import playlistableEntity from '../hoc/playlistableEntity';
import queueOriginComponent from '../hoc/queueOriginComponent';
import metaInjector from '../hoc/metaInjector';
import trackedViewComponent from '../hoc/trackedViewComponent';

import { selectCollectionIds } from '../selectors/collection';
import { selectUserIsPatron } from '../selectors/user';

import { injectIntl, defineMessages } from 'react-intl';
import * as analyticsActions from '../actions/analytics';

import { albumVideos } from '../discover-videos';
import OriginalContentVideo from './OriginalContentVideo';

import styles from '../components/common/Albums.css';

function fetchData(store, { slug }) {
  return [store.dispatch(loadAlbum(slug))];
}

const messages = defineMessages({
  playAllButtonPausedText: {
    id: 'album.play-all.paused.title',
    defaultMessage: 'Play album',
  },
  playAllButtonPlayingText: {
    id: 'album.play-all.playing.title',
    defaultMessage: 'Pause album',
  },
  albumsMetaDescription: {
    id: 'albums.meta.description',
    defaultMessage: 'Listen to {albumName} by {artistName}. Stream now on IDAGIO',
  },
  albumsMetaTitle: {
    id: 'albums.meta.title',
    defaultMessage: '{albumName} {upc} | Stream on IDAGIO',
  },
  albumsMetaOgTitle: {
    id: 'albums.meta.og-title',
    defaultMessage: '{albumName} | Stream on IDAGIO',
  },
});

function mapStateToProps(state, ownProps) {
  const slug = ownProps.params.slug;
  const album = selectAlbum(state, slug);
  return {
    playlist: selectAlbum(state, slug),
    album,

    userIsPatron: selectUserIsPatron(state),
    collectionIds: selectCollectionIds(state),
    imageAnnotation: selectAlbumImageAnnotation(state, slug),
    pathname: ownProps.location.pathname,
    messages: messages,
    bookletUrl: album.bookletUrl,
  };
}

function getCollectibleEntityDescription(props) {
  return {
    id: props.album.id,
    trackingSource: 'Album',
  };
}

function getQueueOrigin(originId) {
  return {
    type: QUEUE_TYPE_ALBUM,
    id: originId,
  };
}

function mapQueueOriginStateToProps(state, ownProps) {
  const { slug, tracks, trackIds } = ownProps.album;
  return {
    originId: slug,

    tracks,
    trackIds,

    isPlaying: selectAlbumIsPlaying(state, slug),
    isQueued: selectAlbumIsQueued(state, slug),
  };
}

function getTrackingContext(props) {
  return {
    contextType: 'album',
    contextId: props.album.id.toString(),
  };
}

function getShareTrackingContext(props) {
  return {
    sharedContentType: 'Album',
    sharedContentId: props.album.id,
    contextType: 'Album',
    contentId: props.album.id,
  };
}

function getEntityTrackingData(props) {
  return {
    itemType: 'album',
    itemId: props.album.id,
  };
}

function getMetaInformation(props) {
  const { intl, playlist } = props;
  const { formatMessage } = intl;
  return {
    'title': formatMessage(messages.albumsMetaTitle, {
      albumName: playlist.title,
      upc: playlist.upc,
    }),
    'description': formatMessage(messages.albumsMetaDescription, {
      albumName: playlist.title,
      artistName: playlist.participants.map(participant => participant.name).join(', '),
    }),
    'keywords': [playlist.title, ...playlist.participants.map(x => x.name)].join(', '),
    'og:image': `${props.album.imageUrl}?auto=format&dpr=1&fit=fill&fill=blur&w=1200&h=630`,
    'og:title': formatMessage(messages.albumsMetaOgTitle, {
      albumName: playlist.title,
    }),
  };
}

// Adds a proper displayName used for tracking
const Album = props => {
  return (
    <React.Fragment>
      <GenericTrackListView
        {...props}
        entityType="album"
        key="album-track-list"
        isOnlyRadioAvailable={__CAPACITOR__ && !props.userIsPatron}
      />
      {albumVideos.find(x => x.id === props.album.slug) ? (
        <div className={styles.albumVideoContainer} id="video">
          <OriginalContentVideo id={props.album.slug} key="album-video" type="album" />
        </div>
      ) : null}
    </React.Fragment>
  );
};

Album.onDataFetch = (store, params, location) => {
  if (!__CAPACITOR__ || !location.query || !location.query.trackId) {
    return;
  }
  // In capacitor we may have deeplinks that request a track to be played upon startup.
  const state = store.getState();
  const albumSlug = params.slug;
  const requestedTrack = location.query && location.query.trackId;

  const album = selectAlbum(state, albumSlug);

  store.dispatch(playerActions.rewind());

  const tracks = album.trackIds.map(playerActions.createItem);

  store.dispatch(
    playerActions.setQueueAndPlay(
      getQueueOrigin(albumSlug),
      tracks,
      playerActions.getItemById(requestedTrack, tracks)
    )
  );
};

const trackingProps = (state, params) => {
  return {
    contextType: 'Album',
    contextId: selectAlbumIdFromSlug(state, params.slug),
    contextSlug: params.slug,
  };
};

export default compose(
  trackedViewComponent('Album', trackingProps),
  dataComponent(fetchData),
  connect(mapStateToProps, {
    track: analyticsActions.track,
    showAddToPlaylistModal,
  }),
  shareableEntity(ENTITY_TYPE_ALBUM, getShareTrackingContext),
  collectibleEntity(ENTITY_TYPE_ALBUM, getCollectibleEntityDescription),
  playlistableEntity({
    getEntityTrackingData,
    getTrackingContext,
    entityType: ENTITY_TYPE_ALBUM,
  }),
  queueOriginComponent(getQueueOrigin, mapQueueOriginStateToProps, getTrackingContext),
  injectIntl,
  metaInjector(getMetaInformation)
)(Album);
