import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectPeriodLinks } from '../../selectors/categories';
import BrowseElementList from './BrowseElementList';
import BrowseListContainer from './BrowseListContainer';
import styles from './BrowseEpochs.css';
import composers from './data/period-composers';

const PERIOD_RANGES = {
  2476: '500 - 1400',
  2482: '1400 - 1600',
  2488: '1600 - 1760',
  2494: '1730 - 1820',
  802: '1780 - 1910',
  2512: '1900 - 2000',
  2518: '2000 - ',
};

class BrowseEpochs extends Component {
  static propTypes = {
    periodLinks: PropTypes.array.isRequired,
  };

  render() {
    const { periodLinks } = this.props;

    return (
      <div>
        <ul className={styles.list}>
          {
            // Since periodLinks is a memoized value and keeps referencing the
            // same javascript array, reverse() will mutate the array on each
            // render execution, causing unexpected behaviour
            //
            // Making a copy of the array will ensure the same order across
            // renders
            periodLinks
              .slice()
              .reverse()
              // 2500 is Impressionism and we don't want it here
              .filter(x => x.id !== 2500)
              .map(period => {
                const periodComposers = composers[period.id];
                const link = `/periods/${period.id}`;

                // We must check if there is an interesection between hardcoded
                // data and backend response selected below so that we will not
                // try to view either browse items that don’t exist on backend,
                // or will not try to render items that are not in the hardcoded
                // lists
                return periodComposers ? (
                  <li className={styles.listElement} key={`period-${period.id}`}>
                    <BrowseListContainer
                      title={period.title}
                      overTitle={PERIOD_RANGES[period.id]}
                      link={link}
                      category="Periods"
                    >
                      <BrowseElementList items={periodComposers} />
                    </BrowseListContainer>
                  </li>
                ) : null;
              })
          }
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    periodLinks: selectPeriodLinks(state),
  };
}

export default connect(mapStateToProps)(BrowseEpochs);
