import React, { PureComponent } from 'react';
import * as Shapes from '../../shapes';

export default class RecordingVenue extends PureComponent {
  static propTypes = {
    venue: Shapes.RecordingVenue,
  };

  render() {
    const { venue } = this.props;

    if (!venue || !venue.name) {
      return null;
    }

    return <span>{venue.name}</span>;
  }
}
