import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ENTITY_TYPE_RECORDING, QUEUE_TYPE_FILTERED_RECORDING_LIST } from '../../constants';

import FilteredList from '../common/FilteredList';

import { loadRecordingsByFilter } from '../../actions/recording';
import * as facetActions from '../../actions/facet';
import { setQueue, setQueueAndPlay } from '../../actions/player';

import {
  selectFilteredAndSortedRecordings,
  selectFilteredAndSortedRecordingsLoading,
  selectNextFilteredRecordingsCursor,
  selectPreviousFilteredRecordingsCursor,
  selectTrackIdsFromRecordings,
  selectTracksFromRecordings,
} from '../../selectors/recording';
import { selectRecordingFilterTopFacets } from '../../selectors/facet';

import * as Shapes from '../../shapes';

import { FormattedMessage } from 'react-intl';
import RecordingItem from '../recording/RecordingItem';
import queueOriginComponent, {
  queueOriginComponentPropTypes,
} from '../../hoc/queueOriginComponent';
import {
  selectFilteredListIsPlaying,
  selectFilteredListQueued,
  selectSortOrder,
} from '../../selectors/filteredList';

import { getHashedFilterParams } from '../../utils/hashFilterParams';

const viewQueueOrigin = {
  type: QUEUE_TYPE_FILTERED_RECORDING_LIST,
};

function getQueueOrigin(originId) {
  return {
    ...viewQueueOrigin,
    id: originId,
  };
}

function getTrackingData(props) {
  const { contextType, contextFacet, contextId } = props;

  const trackingData = { contextType };
  if (contextFacet) {
    trackingData.contextFacet = contextFacet;
  }

  if (contextId) {
    trackingData.contextId = contextId;
  }

  return trackingData;
}

class FilteredRecordingList extends Component {
  static propTypes = {
    recordings: PropTypes.arrayOf(Shapes.Recording).isRequired,
    topFacets: PropTypes.object,
    location: PropTypes.object,
    activeFacets: PropTypes.array.isRequired,
    filterParams: PropTypes.object.isRequired,
    nextCursor: PropTypes.string,
    loadRecordingsByFilter: PropTypes.func.isRequired,
    sort: PropTypes.string.isRequired,
    setQueueAndPlay: PropTypes.func.isRequired,
    setQueue: PropTypes.func.isRequired,
    currentCursor: PropTypes.string,
    prevCursor: PropTypes.string,
    recordingsAreLoading: PropTypes.bool.isRequired,
    hideWorkTitle: PropTypes.bool,
    loadAllRecordingFacets: PropTypes.func.isRequired,
    handleMultipleComposers: PropTypes.bool,

    ...queueOriginComponentPropTypes,
  };

  static getEmptyListMessage() {
    return (
      <FormattedMessage
        id="filtered-recording-list.no-recordings"
        defaultMessage="No recordings match these criteria."
      />
    );
  }

  renderRecordingListItem = recording => {
    const { hideWorkTitle } = this.props;
    const { contextType, contextId, contextFacet } = this.props;

    return (
      <li key={recording.id}>
        <RecordingItem
          recording={recording}
          showCollectionButton={!__CAPACITOR__}
          beforeTogglePlay={this.trackPlayPausePressed}
          hideWorkTitle={hideWorkTitle}
          contextFacet={contextFacet}
          contextType={contextType}
          contextId={contextId}
        />
      </li>
    );
  };

  render() {
    const {
      topFacets,
      recordings,
      location,
      activeFacets,
      filterParams,
      nextCursor,
      sort,
      currentCursor,
      prevCursor,
      recordingsAreLoading,
      loadAllRecordingFacets,
      togglePlayAll,
      isPlaying,
      contextType,
      contextFacet,
      contextId,
      handleMultipleComposers,
      facetOrder,
      ignoredFacets,
    } = this.props;

    return (
      <div>
        <FilteredList
          contextType={contextType}
          contextFacet={contextFacet}
          contextId={contextId}
          handleMultipleComposers={handleMultipleComposers}
          topFacets={topFacets}
          location={location}
          activeFacets={activeFacets}
          filterParams={filterParams}
          listIsEmpty={!recordings || recordings.length === 0}
          nextCursor={nextCursor}
          prevCursor={prevCursor}
          loadMore={this.props.loadRecordingsByFilter}
          sort={sort}
          currentCursor={currentCursor}
          facetOrder={facetOrder}
          isLoading={recordingsAreLoading}
          emptyListMessage={FilteredRecordingList.getEmptyListMessage()}
          items={recordings}
          filterType={'recordings'}
          renderFilteredListItem={this.renderRecordingListItem}
          loadAllFacets={loadAllRecordingFacets}
          togglePlayAll={togglePlayAll}
          isPlaying={isPlaying}
          ignoredFacets={ignoredFacets}
          shouldRenderPlayAllButton={!__CAPACITOR__}
        />
      </div>
    );
  }

  trackPlayPausePressed = (item, playing) => {
    this.props.trackPlayPausePressed(
      {
        contextId: item.content.id,
        contextType: item.content.type,
      },
      playing
    );
  };
}

function mapStateToProps(state, ownProps) {
  const query = ownProps.location.query;
  const { cursor } = ownProps.params;
  const sort = selectSortOrder(query, ENTITY_TYPE_RECORDING);
  const filterParams = ownProps.filterParams;
  const activeFacets = ownProps.activeFacets;

  return {
    topFacets: selectRecordingFilterTopFacets(state, filterParams),
    activeFacets,
    recordings: selectFilteredAndSortedRecordings(state, filterParams),
    location: ownProps.location,
    filterParams,
    nextCursor: selectNextFilteredRecordingsCursor(state, filterParams),
    prevCursor: selectPreviousFilteredRecordingsCursor(state, filterParams),
    sort,
    currentCursor: cursor,
    recordingsAreLoading: selectFilteredAndSortedRecordingsLoading(state),
    isPlaying: selectFilteredListIsPlaying(
      state,
      getHashedFilterParams(filterParams),
      QUEUE_TYPE_FILTERED_RECORDING_LIST
    ),
  };
}

function mapQueueOriginStateToProps(state, ownProps) {
  const { recordings, filterParams } = ownProps;

  const tracks = selectTracksFromRecordings(recordings);
  const trackIds = selectTrackIdsFromRecordings(recordings);
  const originId = getHashedFilterParams(filterParams);

  return {
    tracks,
    trackIds,
    originId,
    isPlaying: ownProps.isPlaying,
    isQueued: selectFilteredListQueued(state, originId, QUEUE_TYPE_FILTERED_RECORDING_LIST),
  };
}

export default compose(
  connect(mapStateToProps, {
    loadRecordingsByFilter,
    setQueueAndPlay,
    setQueue,
    loadAllRecordingFacets: facetActions.loadAllRecordingFacets,
  }),
  queueOriginComponent(getQueueOrigin, mapQueueOriginStateToProps, getTrackingData)
)(FilteredRecordingList);
