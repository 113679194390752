import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as uiActions from '../actions/ui';
import { selectUserIsPatron } from '../selectors/user';
import {
  ENTITY_TYPE_PLAYLIST,
  ENTITY_TYPE_ALBUM,
  ENTITY_TYPE_MIX,
  ENTITY_TYPE_RECORDING,
} from '../constants';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

export const playlistableEntityPropTypes = {
  userIsPatron: PropTypes.bool.isRequired,
  showAddToPlaylistModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  onAddToPlaylistClick: PropTypes.func,
  onAddTrackToPlaylistClick: PropTypes.func,
};

function getTrackEntityTrackingData(trackId) {
  return {
    itemType: 'track',
    itemId: trackId.toString(),
  };
}

export default function playlistableEntity({
  getEntityTrackingData, // the entity itself: track, playlist, album, etc.
  getTrackingContext, // the page from which the entity was added
  entityType = ENTITY_TYPE_PLAYLIST,
  options = {},
}) {
  const { withRef = false } = options;

  return WrappedComponent => {
    class Playlistable extends Component {
      static propTypes = playlistableEntityPropTypes;

      constructor(props) {
        super(props);
        if (!getEntityTrackingData || !getTrackingContext) {
          throw new Error(
            'getEntityTrackingData and getTrackingContext need to be passed to playlistableEntity'
          );
        }
        this.entity = this.getEntity();
      }

      onAddToPlaylistClick = () => {
        if (!this.props.userIsPatron) {
          this.showPreviewsModal();
          return;
        }
        const trackIds = this.entity.trackIds;
        const trackingContext = {
          ...getTrackingContext(this.props),
          ...getEntityTrackingData(this.props),
        };
        this.props.showAddToPlaylistModal(trackIds, trackingContext);
      };

      onAddTrackToPlaylistClick = trackId => {
        if (!this.props.userIsPatron) {
          this.showPreviewsModal();
          return;
        }

        const trackingContext = {
          ...getTrackingContext(this.props),
          ...getTrackEntityTrackingData(trackId),
        };

        this.props.showAddToPlaylistModal([trackId], trackingContext);
      };

      getEntity = () => {
        switch (entityType) {
          case ENTITY_TYPE_ALBUM:
            return this.props.album;
          case ENTITY_TYPE_MIX:
            return this.props.mix;
          case ENTITY_TYPE_RECORDING:
            return this.props.recording;
          default:
            return this.props.playlist;
        }
      };

      render() {
        return (
          <WrappedComponent
            {...this.props}
            ref={withRef ? 'wrappedInstance' : null}
            onAddToPlaylistClick={this.onAddToPlaylistClick}
            onAddTrackToPlaylistClick={this.onAddTrackToPlaylistClick}
          />
        );
      }

      showPreviewsModal = () => {
        this.props.showModal(
          'PREMIUM_EXPERIENCE_PREVIEWS_MODAL',
          { trigger: 'addToPlaylist' },
          {
            imageUrl: this.entity ? this.entity.imageUrl : null,
            trigger: 'addToPlaylistPreviewsModal',
            title: (
              <FormattedMessage
                id="create-playlist-previews-modal.header"
                defaultMessage="Want to build your own playlist?"
              />
            ),
            subtitle: (
              <FormattedMessage
                id="create-playlist-previews-modal.subtitle"
                defaultMessage="Subscribe to create your own personal playlists and customize your listening experience."
              />
            ),
          }
        );
      };
    }

    Playlistable.displayName = `Playlistable(${getDisplayName(WrappedComponent)}`;

    Playlistable.WrappedComponent = WrappedComponent;

    function mapStateToProps(state) {
      return {
        userIsPatron: selectUserIsPatron(state),
      };
    }

    return connect(mapStateToProps, {
      showAddToPlaylistModal: uiActions.showAddToPlaylistModal,
      showModal: uiActions.showModal,
    })(Playlistable);
  };
}
