import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import { QUEUE_TYPE_FILTERED_ALBUM_LIST, IMAGES_BASE, ENTITY_TYPE_ALBUM } from '../../constants';

import * as facetActions from '../../actions/facet';

import { selectProfile } from '../../selectors/profile';
import {
  selectCreateQueueOriginFromAlbums,
  selectFilteredAndSortedAlbums,
  selectFilteredAndSortedAlbumsLoading,
  selectNextFilteredAlbumsCursor,
  selectPreviousFilteredAlbumsCursor,
  selectTrackIdsFromAlbums,
  selectTracksFromAlbums,
} from '../../selectors/album';

import { selectActiveAlbumFacets, selectAlbumFilterTopFacets } from '../../selectors/facet';
import dataComponent from '../../hoc/dataComponent';
import { compose } from 'redux';
import { loadAlbumsByFilter } from '../../actions/album';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { messages } from './messages';
import styles from '../common/FilteredList.css';

import FilteredList from '../common/FilteredList';
import AlbumListItem from '../common/AlbumListItem';
import queueOriginComponent, {
  queueOriginComponentPropTypes,
} from '../../hoc/queueOriginComponent';
import {
  selectAlbumsFilterParams,
  selectFilteredListIsPlaying,
  selectFilteredListQueued,
  selectSortOrder,
} from '../../selectors/filteredList';
import { getHashedFilterParams } from '../../utils/hashFilterParams';

import metaInjector from '../../hoc/metaInjector';

import Head from '../chrome/Head';

function getQueueOrigin(originId) {
  return {
    type: QUEUE_TYPE_FILTERED_ALBUM_LIST,
    id: originId,
  };
}

function getTrackingData(props) {
  const { contextType, contextFacet, contextId } = props;

  const trackingData = { contextType };
  if (contextFacet) {
    trackingData.contextFacet = contextFacet;
  }

  if (contextId) {
    trackingData.contextId = contextId;
  }

  return trackingData;
}

class ProfileAlbums extends Component {
  static propTypes = {
    artistName: PropTypes.string.isRequired,
    profileSlug: PropTypes.string.isRequired,
    profileIsEnsemble: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    filterParams: PropTypes.object.isRequired,
    activeFacets: PropTypes.array.isRequired,
    albums: PropTypes.array.isRequired,
    topFacets: PropTypes.object,
    nextCursor: PropTypes.string,
    loadAlbumsByFilter: PropTypes.func,
    sort: PropTypes.string.isRequired,
    currentCursor: PropTypes.string,
    prevCursor: PropTypes.string,
    albumsAreLoading: PropTypes.bool.isRequired,
    loadAllAlbumFacets: PropTypes.func.isRequired,
    intl: intlShape,
    ...queueOriginComponentPropTypes,
  };

  static getCanonicalRoute(slug) {
    return `/profiles/${slug}/albums`;
  }

  getEmptyListMessage = () => {
    return (
      <FormattedMessage
        id="filtered-recording-list.no-albums"
        defaultMessage="No albums match these criteria."
      />
    );
  };

  renderAlbumListItem = album => {
    return (
      <li className={styles.item} key={album.id}>
        <AlbumListItem album={album} onItemClick={this.onItemClick} />
      </li>
    );
  };

  render() {
    const {
      artistName,
      profileSlug,
      albums,
      location,
      filterParams,
      topFacets,
      activeFacets,
      nextCursor,
      sort,
      prevCursor,
      currentCursor,
      albumsAreLoading,
      loadAllAlbumFacets,
      profileIsEnsemble,
      togglePlayAll,
      intl,
      isPlaying,
    } = this.props;

    const { formatMessage } = intl;

    return (
      <div>
        <Head
          canonicalRoute={ProfileAlbums.getCanonicalRoute(profileSlug)}
          title={formatMessage(messages.profileAlbumsMetaTitle, {
            name: artistName,
          })}
          description={formatMessage(messages.profileAlbumsMetaDescription, {
            name: artistName,
          })}
          keywords={formatMessage(messages.profileAlbumsMetaKeywords, {
            name: artistName,
          })}
        />
        <FilteredList
          contextType="profile"
          contextFacet={profileIsEnsemble ? 'Ensemble' : 'Artist'}
          topFacets={topFacets}
          location={location}
          activeFacets={activeFacets}
          filterParams={filterParams}
          listIsEmpty={!albums || albums.length === 0}
          nextCursor={nextCursor}
          prevCursor={prevCursor}
          loadMore={this.props.loadAlbumsByFilter}
          sort={sort}
          currentCursor={currentCursor}
          shouldRenderPlayAllButton={false}
          isLoading={albumsAreLoading}
          isPlaying={isPlaying}
          emptyListMessage={this.getEmptyListMessage()}
          items={albums}
          filterType={'albums'}
          renderFilteredListItem={this.renderAlbumListItem}
          loadAllFacets={loadAllAlbumFacets}
          ignoredFacets={['albums']}
          togglePlayAll={togglePlayAll}
          gridLayout
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { slug, cursor } = ownProps.params;
  const profile = selectProfile(state, slug);
  const profileIsEnsemble = includes(profile.functions, 'ensemble');
  const query = ownProps.location.query;
  const filterParams = selectAlbumsFilterParams(query, profile.id);

  return {
    artistName: profile.name,
    profileSlug: profile.slug,
    profileId: profile.id,
    topFacets: selectAlbumFilterTopFacets(state, filterParams),
    activeFacets: selectActiveAlbumFacets(state, filterParams, 'albums'),
    albums: selectFilteredAndSortedAlbums(state, filterParams),
    location: ownProps.location,
    filterParams,
    nextCursor: selectNextFilteredAlbumsCursor(state, filterParams),
    prevCursor: selectPreviousFilteredAlbumsCursor(state, filterParams),
    sort: selectSortOrder(query, ENTITY_TYPE_ALBUM),
    currentCursor: cursor,
    albumsAreLoading: selectFilteredAndSortedAlbumsLoading(state),
    profileIsEnsemble,
    isPlaying: selectFilteredListIsPlaying(
      state,
      getHashedFilterParams(filterParams),
      QUEUE_TYPE_FILTERED_ALBUM_LIST
    ),
  };
}

function mapQueueOriginStateToProps(state, ownProps) {
  const { albums, filterParams, isPlaying } = ownProps;
  const originId = getHashedFilterParams(filterParams);
  const tracks = selectTracksFromAlbums(albums);
  const trackIds = selectTrackIdsFromAlbums(albums);

  return {
    queues: selectCreateQueueOriginFromAlbums(albums),
    tracks,
    trackIds: trackIds,
    originId,
    isPlaying,
    isQueued: selectFilteredListQueued(state, originId, QUEUE_TYPE_FILTERED_ALBUM_LIST),
  };
}

function fetchData(store, { slug, cursor }, location) {
  const state = store.getState();
  const profile = selectProfile(state, slug);
  const filterParams = selectAlbumsFilterParams(location.query, profile.id);
  return [
    store.dispatch(loadAlbumsByFilter(filterParams, cursor, __CAPACITOR__ ? 20 : 50)),
    store.dispatch(facetActions.loadTopAlbumFacets(filterParams)),
  ];
}

function getMetaInformation(props) {
  const { artistName, profileId, intl } = props;

  return {
    'og:description': intl.formatMessage(messages.profileAlbumsOgDescription, {
      name: artistName,
    }),
    'og:image': `${IMAGES_BASE}/artists/${profileId}/main.jpg?auto=format&dpr=1&fit=fill&fill=blur&w=1200&h=630`,
  };
}

export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, {
    loadAlbumsByFilter,
    loadAllAlbumFacets: facetActions.loadAllAlbumFacets,
  }),
  queueOriginComponent(getQueueOrigin, mapQueueOriginStateToProps, getTrackingData),
  injectIntl,
  metaInjector(getMetaInformation)
)(ProfileAlbums);
