import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  imprintLink: {
    id: 'front-page.footer.imprint',
    defaultMessage: 'Imprint',
  },
  termsLink: {
    id: 'front-page.footer.terms',
    defaultMessage: 'Terms',
  },
  privacyLink: {
    id: 'front-page.footer.privacy',
    defaultMessage: 'Privacy',
  },
});
