// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

type OwnProps = {
  durationInMins: string,
};

const CourseSessionDuration = ({ durationInMins }: OwnProps) => {
  return (
    <FormattedMessage
      id="course.session.duration.text"
      defaultMessage="Session duration: {durationInMins} minutes"
      values={{ durationInMins }}
    />
  );
};

export default CourseSessionDuration;
