import GenericTrackListView from '../components/common/GenericTrackListView';

import { getQueueOrigin } from '../lib/queue';
import { loadPlaylist } from '../actions/playlist';
import * as analyticsActions from '../actions/analytics';
import { showAddToPlaylistModal } from '../actions/ui';
import * as playerActions from '../actions/player';

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import collectibleEntity from '../hoc/collectibleEntity';
import shareableEntity from '../hoc/shareableEntity';
import queueOriginComponent from '../hoc/queueOriginComponent';
import trackedViewComponent from '../hoc/trackedViewComponent';

import { selectCollectionIds } from '../selectors/collection';
import { selectUserIsPatron } from '../selectors/user';

import {
  selectPlaylist,
  selectPlaylistIsPlaying,
  selectPlaylistIsQueued,
  selectPlaylistImageAnnotation,
  getPlaylistQueueOrigin,
  selectPlaylistIdFromSlug,
} from '../selectors/playlist';

import { ENTITY_TYPE_PLAYLIST } from '../constants';

import { injectIntl, defineMessages } from 'react-intl';
import playlistableEntity from '../hoc/playlistableEntity';
import metaInjector from '../hoc/metaInjector';

function fetchData(store, { slug }) {
  return [store.dispatch(loadPlaylist(slug))];
}

export const messages = defineMessages({
  playAllButtonPausedText: {
    id: 'playlist.play-all.paused.title',
    defaultMessage: 'Play playlist',
  },
  playAllButtonPlayingText: {
    id: 'playlist.play-all.playing.title',
    defaultMessage: 'Pause playlist',
  },
  playlistMetaDescription: {
    id: 'playlist.meta.description',
    defaultMessage:
      'Want to listen to our playlist {title}? Stream your favorite classical playlists now on IDAGIO',
  },
  playlistMetaTitle: {
    id: 'playlist.meta.title',
    defaultMessage: '{title} | Stream on IDAGIO',
  },
  playlistMetaKeywords: {
    id: 'playlist.meta.keywords',
    defaultMessage: '{title}, stream playists on idagio, classical playlists ',
  },
});

function mapStateToProps(state, ownProps) {
  const slug = ownProps.params.slug;
  return {
    playlist: selectPlaylist(state, slug),
    userIsPatron: selectUserIsPatron(state),
    collectionIds: selectCollectionIds(state),
    imageAnnotation: selectPlaylistImageAnnotation(state, slug),
    pathname: ownProps.location.pathname,
    messages,
  };
}

function getCollectibleEntityDescription(props) {
  return {
    id: props.playlist.id,
    trackingSource: 'Playlist',
  };
}

function mapQueueOriginStateToProps(state, ownProps) {
  const { slug, tracks, trackIds } = ownProps.playlist;
  return {
    originId: slug,

    tracks,
    trackIds,

    isPlaying: selectPlaylistIsPlaying(state, slug),
    isQueued: selectPlaylistIsQueued(state, slug),
  };
}

function getShareTrackingContext(props) {
  return {
    sharedContentType: 'Playlist',
    sharedContentId: props.playlist.id,
    contextType: 'Playlist',
    contentId: props.playlist.id,
  };
}

function getTrackingContext(props) {
  return {
    contextType: 'playlist',
    contextId: props.playlist.id.toString(),
  };
}

function getEntityTrackingData(props) {
  return {
    itemType: 'playlist',
    itemId: props.playlist.id,
  };
}

function getMetaInformation(props) {
  const { intl, playlist } = props;
  const { formatMessage } = intl;
  return {
    'title': formatMessage(messages.playlistMetaTitle, { title: playlist.title }),
    'description': formatMessage(messages.playlistMetaDescription, {
      title: playlist.title,
    }),
    'keywords': formatMessage(messages.playlistMetaKeywords, {
      title: playlist.title,
    }),
    'og:image': `${props.playlist.imageUrl}?auto=format&dpr=1&fit=fill&fill=blur&w=1200&h=630`,
  };
}

// Adds a proper displayName used for tracking
const Playlist = props => (
  <GenericTrackListView
    {...props}
    isOnlyRadioAvailable={__CAPACITOR__ && !props.userIsPatron}
    entityType="playlist"
  />
);

Playlist.onDataFetch = (store, params, location) => {
  if (!__CAPACITOR__ || !location.query || !location.query.trackId) {
    return;
  }
  // In capacitor we may have deeplinks that request a track to be played upon startup.
  const state = store.getState();
  const playlistSlug = params.slug;
  const requestedTrack = location.query && location.query.trackId;

  const playlist = selectPlaylist(state, playlistSlug);

  store.dispatch(playerActions.rewind());

  const tracks = playlist.trackIds.map(playerActions.createItem);

  store.dispatch(
    playerActions.setQueueAndPlay(
      getQueueOrigin(ENTITY_TYPE_PLAYLIST, { slug: playlistSlug }),
      tracks,
      playerActions.getItemById(requestedTrack, tracks)
    )
  );
};

const trackingProps = (state, params) => {
  return {
    contextType: 'Playlist',
    contextId: selectPlaylistIdFromSlug(state, params.slug),
    contextSlug: params.slug,
  };
};

export default compose(
  trackedViewComponent('Playlist', trackingProps),
  dataComponent(fetchData),
  connect(mapStateToProps, {
    track: analyticsActions.track,
    showAddToPlaylistModal,
  }),
  shareableEntity(ENTITY_TYPE_PLAYLIST, getShareTrackingContext),
  collectibleEntity(ENTITY_TYPE_PLAYLIST, getCollectibleEntityDescription),
  playlistableEntity({
    getEntityTrackingData,
    getTrackingContext,
  }),
  queueOriginComponent(getPlaylistQueueOrigin, mapQueueOriginStateToProps, getTrackingContext),
  injectIntl,
  metaInjector(getMetaInformation)
)(Playlist);
