// @flow
import React from 'react';
import Email from './Email';
import FirstName from './FirstName';
import Password from './Password';
import LinkedAccount from './LinkedAccount';
import { connect } from 'react-redux';
import { selectFacebookIsLinked, selectUser } from '../../selectors/user';
import * as authActions from '../../actions/auth';
import * as notificationActions from '../../actions/notifications';

type MapStateToProps = {
  email: string,
  firstName: string | null,
  onlyHasSocialLogin: boolean,
};

type DispatchProps = {
  addNotification: Function,
  changePassword: Function,
  changeFirstName: Function,
};

type Props = MapStateToProps & DispatchProps;

const Account = ({
  email,
  firstName,
  onlyHasSocialLogin,
  addNotification,
  changeFirstName,
  changePassword,
}: Props) => {
  return (
    <React.Fragment>
      <Email email={email} />
      <FirstName firstName={firstName} onChangeFirstName={changeFirstName} />
      <Password email={email} addNotification={addNotification} onChangePassword={changePassword} />
      <LinkedAccount onlyHasSocialLogin={onlyHasSocialLogin} addNotification={addNotification} />
    </React.Fragment>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  const user = selectUser(state);
  /*
    Support for decrypting user.encrypted_email was removed in the PR below because
    support was never implemented on other clients.
    https://github.com/IDAGIO/idagio-web/pull/6110
  */
  const email = user && user.email;
  const firstName = user && user.firstname;
  const facebookIsLinked = selectFacebookIsLinked(state);
  const onlyHasSocialLogin = facebookIsLinked && user.login_methods.length === 1;

  return {
    email,
    firstName,
    onlyHasSocialLogin,
  };
}

const dispatchProps: DispatchProps = {
  addNotification: notificationActions.addNotification,
  changePassword: authActions.changePassword,
  changeFirstName: authActions.changeFirstName,
};

export default connect(mapStateToProps, dispatchProps)(Account);
