let randomString;
if (__SERVER__) {
  const cryptiles = require('cryptiles');
  randomString = cryptiles.randomString;
} else {
  const fallBack = {
    getRandomValues: function getRandomValues(byteArray) {
      /*
        KLUDGE Don't use for any important crypto stuff.
        This is obviously not secure but in a wost case scenario it
        should do the trick as a device id.
      */
      for (let i = 0; i < byteArray.length; i++) {
        byteArray[i] = Math.floor(Math.random() * 256);
      }
    },
  };
  randomString = length => {
    const crypto = window.crypto || window.msCrypto || fallBack;
    const byteArray = new Uint8Array(length);
    crypto.getRandomValues(byteArray);
    const stringArray = [];
    for (let i = 0; i < byteArray.length; i++) {
      stringArray.push(String.fromCharCode(byteArray[i]));
    }
    const string = stringArray.join('');
    return btoa(string).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  };
}

export default function generateToken(length = 256) {
  return randomString(length);
}
