import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import { Link } from 'react-router';
import { FormattedMessage, defineMessages, intlShape, injectIntl } from 'react-intl';

import * as Shapes from '../shapes';

import List from '../components/util/List';
import Head from '../components/chrome/Head';

import { loadPeriods } from '../actions/period';
import { selectPeriods } from '../selectors/categories';

import styles from './Periods.css';

const messages = defineMessages({
  metaTitle: {
    id: 'periods.meta.title',
    defaultMessage: 'Periods',
  },
  metaDescription: {
    id: 'periods.meta.description',
    defaultMessage:
      'Quickly find and play classical music from our curated repertoire, including recordings from classical masters and contemporary artists. Browse by composer, performer, instrument, genre or epoch.',
  },
});

class Periods extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(Shapes.Category).isRequired,
    intl: intlShape,
  };

  renderPeriod = item => {
    return (
      <li key={item.id}>
        <Link to={`/periods/${item.id}`}>{item.title}</Link>
      </li>
    );
  };

  render() {
    const { intl, items } = this.props;
    const { formatMessage } = intl;
    return (
      <div className="u-page-container periods">
        <Head
          title={formatMessage(messages.metaTitle)}
          description={formatMessage(messages.metaDescription)}
        />
        <h1 className={`${styles.heading} fz--beta`}>
          <FormattedMessage id="periods.title" defaultMessage="Periods" />
        </h1>
        <List items={items} renderItem={this.renderPeriod} />
      </div>
    );
  }
}

function fetchData(store) {
  return store.dispatch(loadPeriods());
}

function mapStateToProps(state) {
  return {
    items: selectPeriods(state),
  };
}

export default compose(dataComponent(fetchData), connect(mapStateToProps), injectIntl)(Periods);
