import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import { FormattedMessage, defineMessages, injectIntl, intlShape } from 'react-intl';

import * as Shapes from '../shapes';

import { selectLivestreamEvent } from '../selectors/livestreamEvents';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';

import { ENTITY_TYPE_LIVESTREAM_EVENT } from '../constants';

import { LiveStreamEventVideo } from '../components/livestream-event/LivestreamEventVideo';

import CapacitorSkeletonIfLoading from '../components/capacitor/SkeletonIfLoading';
import LivestreamEventHero from '../components/livestream-event/LivestreamEventHero';
import ContentfulLink from '../components/util/ContentfulLink';
import LivestreamEventHeader from '../components/livestream-event/LivestreamEventHeader';
import Course from '../components/livestream-event/Course';
import LegalNote from '../components/livestream-event/LegalNote';
import Support from '../components/livestream-event/Support';
import AvailabilityText from '../components/livestream-event-preview/AvailabilityText';
import LivestreamEventParticipants from '../components/livestream-event/LivestreamEventParticipants';

import * as analyticsActions from '../actions/analytics';
import * as livestreamActions from '../actions/livestreamEvents';

import shareableEntity, { shareableEntityPropTypes } from '../hoc/shareableEntity';
import pusherComponent from '../hoc/pusherComponent';
import livestreamEventComponent, {
  livestreamEventComponentPropTypes,
} from '../hoc/livestreamEventComponent';
import metaInjector from '../hoc/metaInjector';

import eventStyles from './LivestreamEvent.css';
import bodyStyles from './LivestreamEventBody.css';
import RecordingAvailabilityText from '../components/livestream-event/RecordingAvailabilityText';
import FeaturedEventsAndAudios from '../components/livestream-event/FeaturedEventsAndAudios';
import IconLabel from '../components/util/IconLabel';
import ContentfulImage from '../components/util/ContentfulImage';

const messages = defineMessages({
  metaKeywords: {
    id: 'livestream-event.meta.keywords-2',
    defaultMessage: 'idagio concerts, livestream',
  },
});

const getOptions = () => ({
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <ContentfulLink node={node} fromId="livestreamEventBody">
        {children}
      </ContentfulLink>
    ),
    [BLOCKS.TABLE]: (node, children) => (
      <table>
        <tbody>{children}</tbody>
      </table>
    ),
    [BLOCKS.TABLE_ROW]: (node, children) => <tr>{children}</tr>,
    [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
  },
});

class LivestreamEvent extends Component {
  static propTypes = {
    event: Shapes.LivestreamEvent,
    reloadLivestreamEvent: PropTypes.func.isRequired,
    track: PropTypes.func.isRequired,
    liveStreamPlaybackProgress: PropTypes.func.isRequired,

    ...shareableEntityPropTypes,
    ...livestreamEventComponentPropTypes,

    intl: intlShape,
    location: PropTypes.object.isRequired,
  };

  // onEvent handlers, tracking bubbled up to here to capture context

  onOrderComplete = (order, event) => {
    const { track } = this.props;
    const trackingProps = {
      liveEvent: event.slug,
      liveStreamEventType: event.type,
      context: 'livestreamEvent',
      contextId: event.slug,
      orderId: order.id,
      purchasePlatform: order.platform,
      purchasePlatformEventId: order.platformEventId,
    };

    track('Completed Ticket Purchase', trackingProps);
  };

  onPlay = ({ currentTime: currentPlaybackTime }, video, isResume) => {
    const eventName = isResume ? 'Resumed Video Playback' : 'Started Video Playback';
    const { track } = this.props;
    const trackingProps = this.getPlaybackTrackingEventProps();
    track(eventName, {
      ...trackingProps,
      currentPlaybackTime,
    });
  };

  onPause = ({ currentTime: currentPlaybackTime, accumulatedTime }) => {
    const { track } = this.props;
    const trackingProps = this.getPlaybackTrackingEventProps();
    track('Paused Video Playback', {
      ...trackingProps,
      currentPlaybackTime,
      duration: accumulatedTime,
    });
  };

  onEnded = ({ currentTime: currentPlaybackTime, accumulatedTime }) => {
    const { track } = this.props;
    const trackingProps = this.getPlaybackTrackingEventProps();
    track('Stopped Video Playback', {
      ...trackingProps,
      currentPlaybackTime,
      duration: accumulatedTime,
    });
  };

  onProgressUpdate = ({ delta, currentTime, videoProvider }) => {
    const { isLive, slug, type } = this.props.event;
    this.props.liveStreamPlaybackProgress(delta, slug, type, currentTime, videoProvider, isLive);
  };

  getPlaybackTrackingEventProps = () => {
    const { event } = this.props;
    return {
      context: 'livestreamEvent',
      contextId: event.slug,
      liveEvent: event.slug,
      liveEventTitle: event.title,
      liveEventType: `cf.livestreamEvent.${event.type}`,
      isLive: event.isLive,
    };
  };

  // end of onEvent handlers
  renderPhotoCredit = photoCredit => {
    if (!photoCredit) {
      return null;
    }
    return <div className={eventStyles.photoCredit}>Photo: {photoCredit}</div>;
  };

  renderImageAndPhotoCredit = (imageUrl, photoCredit) => {
    if (imageUrl) {
      return (
        <React.Fragment>
          <figure
            role="none"
            className={eventStyles.imageWrapper}
            data-test="livestream-event-hero.placeholder-image"
          >
            <ContentfulImage
              src={imageUrl}
              options={{
                fm: 'jpg',
                fl: 'progressive',
              }}
            />
          </figure>
          {this.renderPhotoCredit(photoCredit)}
        </React.Fragment>
      );
    }

    return null;
  };

  renderVideo = video => (
    <LiveStreamEventVideo
      video={video}
      imageUrl={this.props.event.imageUrl}
      className={eventStyles.video}
      onPlay={this.onPlay}
      onPause={this.onPause}
      onEnded={this.onEnded}
      onProgressUpdate={this.onProgressUpdate}
    />
  );

  renderHeroMedia = () => {
    const { event, shouldShowVideo } = this.props;
    const { video, imageUrl, photoCredit } = event;
    if (shouldShowVideo) {
      return this.renderVideo(video);
    }

    return this.renderImageAndPhotoCredit(imageUrl, photoCredit);
  };

  renderRecordingAndAvailabilityInfo() {
    const { time, location, isUpcoming, isLive, isAvailable, availabilityEnd } = this.props.event;

    return (
      <React.Fragment>
        {time && (
          <div className={eventStyles.infoWithIcon}>
            <IconLabel name="date" title="Recording Date" size="small" />
            <FormattedMessage
              id="livestream-events.recording-date.title"
              defaultMessage="Recording date: {time}"
              values={{ time }}
            />
          </div>
        )}
        {location && (
          <div className={eventStyles.infoWithIcon}>
            <IconLabel name="location" title="Recording Location" size="small" />
            <FormattedMessage
              id="livestream-events.recording-location.title"
              defaultMessage="Recording location: {location}"
              values={{ location }}
            />
          </div>
        )}
        {!isUpcoming && !isLive && (
          <div className={eventStyles.infoWithIcon}>
            <IconLabel name="clock" title="Availability Info" size="small" />
            <AvailabilityText isAvailable={isAvailable} availabilityEnd={availabilityEnd} />
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { event, intl, userHasAccess, showShareModal, location, userCanPurchaseEvent } =
      this.props;
    const {
      upperBody,
      body,
      course,
      slug,
      composers,
      hosts,
      featuredArtists,
      creditor,
      availabilityEnd,
      isCourse,
      featuredEventsAndAudios,
      reportIncludesSingleTicketBuyersEmails,
    } = event;

    return (
      <div className="u-page-container">
        <div className={eventStyles.pageContainer}>
          <CapacitorSkeletonIfLoading>
            <React.Fragment>
              <div data-test="livestream-event.info">
                <LivestreamEventHeader event={event} showShareModal={showShareModal} />

                <LivestreamEventHero
                  event={event}
                  renderHeroMedia={this.renderHeroMedia}
                  onOrderComplete={this.onOrderComplete}
                  contextType="livestreamEvent"
                />

                <div className={eventStyles.separator} />

                <section className={bodyStyles.container}>
                  {upperBody && documentToReactComponents(upperBody, getOptions(intl))}
                  {isCourse && (
                    <p
                      className={eventStyles.recordingAvailabilityText}
                      data-test="livestream-event.course.recording-availability-text"
                      data-test-date={availabilityEnd}
                    >
                      <RecordingAvailabilityText availabilityEnd={availabilityEnd} />
                    </p>
                  )}
                </section>

                <LivestreamEventParticipants
                  composers={composers}
                  hosts={hosts}
                  featuredArtists={featuredArtists}
                />

                <section className={bodyStyles.container}>
                  {documentToReactComponents(body, getOptions(intl))}
                  {this.renderRecordingAndAvailabilityInfo()}
                </section>

                {isCourse && course && (
                  <Course
                    course={course}
                    slug={slug}
                    userHasAccess={userHasAccess}
                    location={location}
                  />
                )}

                {userCanPurchaseEvent && reportIncludesSingleTicketBuyersEmails && (
                  <LegalNote creditor={creditor} />
                )}
                <Support />
              </div>
              <div className={eventStyles.separator} />
              <FeaturedEventsAndAudios
                items={featuredEventsAndAudios}
                trackingScreen={isCourse ? 'course-detail' : 'event-detail'}
                slug={slug}
              />
            </React.Fragment>
          </CapacitorSkeletonIfLoading>
        </div>
      </div>
    );
  }
}

function getShareTrackingContext(props) {
  return {
    sharedContentType: 'Livestream Event',
    sharedContentId: props.event.id,
    contextType: 'Livestream',
    contentId: props.event.id,
  };
}

function fetchData(store, { slug }) {
  return store.dispatch(livestreamActions.loadLivestreamEvent(slug));
}

function mapStateToProps(state, ownProps) {
  const slug = ownProps.params.slug;
  const event = selectLivestreamEvent(state, slug);
  return {
    event,
  };
}

function getMetaInformation(props) {
  const { intl, event } = props;
  const { formatMessage } = intl;
  const metaTitle = event.metaTitle || event.title;
  const metaDescription = event.metaDescription || event.description;

  return {
    'title': metaTitle,
    'description': metaDescription,
    'keywords': event.metaKeywords || formatMessage(messages.metaKeywords),
    'og:description': metaDescription,
    'og:image': event.imageUrl + '?w=800',
  };
}

export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, {
    track: analyticsActions.trackIdagioLive,
    reloadLivestreamEvent: livestreamActions.reloadLivestreamEvent,
    liveStreamPlaybackProgress: livestreamActions.liveStreamPlaybackProgress,
  }),
  injectIntl,
  shareableEntity(ENTITY_TYPE_LIVESTREAM_EVENT, getShareTrackingContext, {
    sharedUrlParams: {
      utm_medium: 'share',
      utm_campaign: 'idagiolive',
    },
  }),
  metaInjector(getMetaInformation),
  livestreamEventComponent(),
  pusherComponent({
    getPusherChannel: props => props.event.pusherChannel,
    onPusherMessage: (msg, props) => {
      if (msg._type === 'ServiceContentfulUpdateV1') {
        props.reloadLivestreamEvent(props.event.slug);
      }
    },
  })
)(LivestreamEvent);
