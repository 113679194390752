/**
 * Documentation regarding the data format/schema can be found at:
 * https://developers.google.com/search/docs/data-types/music
 * A validator is also available at:
 * https://search.google.com/structured-data/testing-tool
 */

// TODO i18n

import { capitalize } from 'lodash';

export function formatGoogleMusicGroup(profile, locale) {
  const { slug, name, functions, avatarImage } = profile;
  const baseUrl = `https://www.idagio.com/${slug}/recordings/`;

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'MusicGroup',
    'url': baseUrl,
    'name': name,
    'description': functions.map(fun => capitalize(fun)).join(', '),
    'image': avatarImage,
    'potentialAction': {
      '@type': 'ListenAction',
      'target': [
        {
          '@type': 'EntryPoint',
          'urlTemplate': `${baseUrl}?sort=popular`,
          'actionPlatform': [
            'https://schema.org/DesktopWebPlatform',
            'https://schema.org/IOSPlatform',
          ],
          'inLanguage': locale,
        },
      ],
      'expectsAcceptanceOf': {
        '@type': 'Offer',
        'ineligibleRegion': [
          {
            '@type': 'Country',
            'name': 'US',
          },
          {
            '@type': 'Country',
            'name': 'CA',
          },
        ],
      },
    },
  });
}

export function formatGoogleMusicAlbum(recording, locale) {
  const { id, summary, work, recordingDate } = recording;
  const baseUrl = `https://www.idagio.com/recordings/${id}/`;
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'MusicGroup',
    'url': baseUrl,
    'name': `${work.title} | ${work.composer.name} | ${summary} (${recordingDate.from})`,
    'description': `A ${recordingDate.from} recording of ${work.title}, composed by ${work.composer.name} and performed by ${summary}`,
    'potentialAction': {
      '@type': 'ListenAction',
      'target': [
        {
          '@type': 'EntryPoint',
          'urlTemplate': baseUrl,
          'actionPlatform': [
            'https://schema.org/DesktopWebPlatform',
            'https://schema.org/IOSPlatform',
          ],
          'inLanguage': locale,
        },
      ],
      'expectsAcceptanceOf': {
        '@type': 'Offer',
        'ineligibleRegion': [
          {
            '@type': 'Country',
            'name': 'US',
          },
          {
            '@type': 'Country',
            'name': 'CA',
          },
        ],
      },
    },
  });
}
