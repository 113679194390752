import { flatten } from 'lodash';

function encodeBase64(str) {
  if (__SERVER__) {
    return Buffer.from(str).toString('base64');
  }

  return btoa(str);
}

export function getHashedFilterParams(filterParams) {
  return encodeBase64(
    flatten(Object.entries(filterParams).sort()) // Need to sort them so we always get the same hash
      .join('-')
  );
}
