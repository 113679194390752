import { normalizeMetaItem, normalizeMetaList } from './normalizeMeta';
import { includes, intersection, reduce, omitBy } from 'lodash';
import {
  BOOLEAN_FACET_PARAMS,
  BOOLEAN_FACET_PARAMS_VALUE,
  NULLABLE_BOOLEAN_FACET_PARAMS,
} from '../constants';
import {
  selectIsBoolean,
  selectIsFacetGroupWithAcceptableSingleEntry,
  selectIsNullableBooleanFacetGroup,
} from '../selectors/facet';

function appendUnsetToNullableBooleanParamValues(results, facetType) {
  if (
    selectIsNullableBooleanFacetGroup(facetType) &&
    !results.some(({ value }) => value === 'unset')
  ) {
    return [...results, { value: 'unset' }];
  }

  return results;
}

function filterBooleanParamValues(results, facetType) {
  if (selectIsBoolean(facetType)) {
    const validValues = BOOLEAN_FACET_PARAMS_VALUE[facetType]
      ? BOOLEAN_FACET_PARAMS_VALUE[facetType].options
      : [];
    return results.filter(({ value }) => includes(validValues, value));
  }

  return results;
}

function normalizeActiveFacets(activeFacets, validParams) {
  // when user unsets a nullable boolean facet param, backend will not show the
  // 'unset' value as activeFacets, so we need to append it manually
  const validNullableBooleanFacetParams = intersection(validParams, NULLABLE_BOOLEAN_FACET_PARAMS);
  validNullableBooleanFacetParams.forEach(type => {
    if (!activeFacets[type]) {
      activeFacets[type] = appendUnsetToNullableBooleanParamValues([], type);
    }
  });

  BOOLEAN_FACET_PARAMS.forEach(type => {
    if (activeFacets[type]) {
      // remove default value from boolean facets
      activeFacets[type] = filterBooleanParamValues(activeFacets[type], type);
      // remove key if no facet values are left
      if (!activeFacets[type].length) {
        delete activeFacets[type];
      }
    }
  });

  return reduce(
    activeFacets,
    (acc, facet, key) => {
      return [
        ...acc,
        ...facet.map(item => {
          // eslint-disable-next-line no-unused-vars
          const { hits, ...rest } = item;
          return {
            facet: rest,
            type: key,
          };
        }),
      ];
    },
    []
  );
}

function normalizeTopFacets(topFacets, validParams) {
  // backend will not show the 'unset' options for nullable boolean facets
  // so we need to append it manually
  const validNullableBooleanFacetParams = intersection(validParams, NULLABLE_BOOLEAN_FACET_PARAMS);
  validNullableBooleanFacetParams.forEach(type => {
    if (!topFacets[type]) {
      topFacets[type] = { count: 0, results: [] };
    }
    topFacets[type].results = appendUnsetToNullableBooleanParamValues(
      topFacets[type].results,
      type
    );
    topFacets[type].count = topFacets[type].results.length;
  });

  BOOLEAN_FACET_PARAMS.forEach(type => {
    if (topFacets[type]) {
      // remove default value from boolean facets
      topFacets[type].results = filterBooleanParamValues(topFacets[type].results, type);
      topFacets[type].count = topFacets[type].results.length;
    }
  });

  // remove facets with no multiple entries, except for facets with acceptable single entry
  return omitBy(
    topFacets,
    ({ count }, type) => count <= 1 && !selectIsFacetGroupWithAcceptableSingleEntry(type)
  );
}

export function normalizeTopFacetsResult(object, validParams) {
  const { resultsCount, selectedFacets, topFacets } = normalizeMetaItem(object);
  return {
    result: {
      resultsCount,
      activeFacets: normalizeActiveFacets(selectedFacets, validParams),
      topFacets: normalizeTopFacets(topFacets, validParams),
    },
  };
}

function filterAllFacets(results, facetType) {
  const newResults = filterBooleanParamValues(
    appendUnsetToNullableBooleanParamValues(results, facetType),
    facetType
  );

  // clear if no multiple entries, except for facets with acceptable single entry
  if (newResults.length <= 1 && !selectIsFacetGroupWithAcceptableSingleEntry(facetType)) {
    return [];
  }

  return newResults;
}

export function normalizeAllFacets(object, facetType) {
  const results = normalizeMetaList(object);

  return {
    result: filterAllFacets(results, facetType),
  };
}
