import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { CHROME_CLEAN } from '../constants';
import chromeComponent from '../hoc/chromeComponent';
import AcceptTerms from '../components/common/AcceptTerms';
import { selectSubscriptionFromId } from '../selectors/subscriptionOffer';
import { selectHttpRequestPostData } from '../selectors/client';
import AccountPasswordLabel from '../components/messages/AccountPasswordLabel';
import styles from '../components/modals/FacebookPasswordConfirmationModal.css';
import PasswordInput from '../components/common/PasswordInput';

class AppleRedirect extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    fromId: PropTypes.string.isRequired,
    idToken: PropTypes.string.isRequired,
    socialLoginStatus: PropTypes.number,
  };

  state = {
    emailCheckboxChecked: false,
    termsCheckboxChecked: true,
    // ^ this is default true because of implicit consent
  };

  onEmailCheckBox = () => {
    const current = this.state.emailCheckboxChecked;
    this.setState({ emailCheckboxChecked: !current });
  };

  render() {
    const { idToken, fromId, email, socialLoginStatus } = this.props;
    const { termsCheckboxChecked } = this.state;

    const buttonClassName = classnames('c-btn', 'c-btn--is-purple');

    /*
    This <form> actually does a POST to our auth backend (/login-social)
    It does not do a javascript HTTP request.
    This is because we already land on this view with POST data and we want to
    avoid the `Are you sure you want to reload` dialogue.
     */

    const shouldShowTOC = !(
      socialLoginStatus === 401 ||
      socialLoginStatus === 403 ||
      socialLoginStatus === 400
    );
    const submitDisabled = shouldShowTOC && !termsCheckboxChecked;

    return (
      <div className="u-page-container">
        <form className={styles.formContainer} method="post" action="/apple-redirect">
          {shouldShowTOC && (
            <div>
              <h1 className="fz--beta">
                <FormattedMessage id="signup.options.almost-done" defaultMessage="Almost Done" />
              </h1>
              <p>
                <FormattedMessage
                  id="signup.options.terms-paragraph"
                  defaultMessage="To finish setting up your account, please agree to the following:"
                />
              </p>
              <AcceptTerms />
            </div>
          )}

          {!shouldShowTOC && (
            <div>
              <h1 className="fz--beta">
                <FormattedMessage
                  id="confirm-password.validate.header"
                  defaultMessage="Validate your account"
                />
              </h1>
              <p>{this.props.email}</p>
              <p>
                <FormattedMessage
                  id="login.options.password-confirm"
                  defaultMessage="This email address has already been used to create an IDAGIO account. To continue, please enter your IDAGIO password."
                />
              </p>
              <PasswordInput id="password" autoFocus title={<AccountPasswordLabel />} />
            </div>
          )}

          <input type="hidden" name="id_token" value={idToken} />
          <input type="hidden" name="fromId" value={fromId} />
          <input type="hidden" name="email" value={email} />

          <button type="submit" className={buttonClassName} disabled={submitDisabled}>
            <FormattedMessage id="signup.options.continue" defaultMessage="Continue" />
          </button>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const postData = selectHttpRequestPostData(state);
  const { email, id_token: idToken, socialLoginStatus } = postData;

  return {
    idToken,
    socialLoginStatus,
    email,
    fromId: selectSubscriptionFromId(state),
  };
}

export default compose(
  chromeComponent({ type: CHROME_CLEAN }),
  connect(mapStateToProps)
)(AppleRedirect);
