// @flow
import React from 'react';
import { Link, withRouter } from 'react-router';
import { some } from 'lodash';
import classnames from 'classnames';
import CapacitorRipple from '../capacitor/Ripple';
import { FormattedMessage } from 'react-intl';

import IconLabel from '../util/IconLabel';
import TitleWithPopularTitle from '../common/TitleWithPopularTitle';
import Separated from '../util/Separated';

import styles from './Facet.css';
import * as qs from 'qs';
import { booleanMessages } from '../messages/FilterMessages';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  type: string,
  facet: Object,
  pathname: string,
  query: Object,
  className?: string,
  onClickFacet?: Function,
  isBoolean: boolean,
  router: Object,
};

type Props = OwnProps;

function facetIsActive(filterKey: string, id: number | string | boolean, query: Object): boolean {
  const filterIds = query[filterKey];
  const filterArray = filterIds !== undefined ? filterIds.split(',') : [];
  return some(filterArray, filterId => filterId === id.toString());
}

function generateLinkTo(
  filterKey: string,
  id: number | string | boolean,
  pathname: string,
  query: Object,
  isActive: boolean
): string {
  const filterIds = query[filterKey];
  const filterArray = filterIds ? filterIds.split(',') : [];

  const alteredQuery = { ...query };
  if (isActive) {
    alteredQuery[filterKey] = filterArray.filter(filterId => filterId !== id.toString()).join(',');

    if (alteredQuery[filterKey] === '') {
      delete alteredQuery[filterKey];
    }
  } else {
    alteredQuery[filterKey] = [...filterArray, id].join(',');
  }

  return pathname + '?' + qs.stringify(alteredQuery);
}

const getContent = (type, facet) => {
  switch (type) {
    case 'works':
      return <TitleWithPopularTitle popularTitle={facet.popularTitle} title={facet.title} />;
    case 'composers':
    case 'conductors':
    case 'soloists':
      return (
        <Separated>
          {facet.surname}
          {facet.forename}
        </Separated>
      );
    case 'instruments':
    case 'genres':
    case 'epochs':
      return facet.title;
    case 'ensembles':
    case 'recordingTypes':
      return facet.name;
    case 'isExclusive':
      return <FormattedMessage {...booleanMessages.isExclusive} />;
    case 'isOriginalWork':
    case 'isCompleteWork':
      return (
        <FormattedMessage
          id="facets.type.hide"
          defaultMessage="Hide {facetType}"
          values={{ facetType: <FormattedMessage {...booleanMessages[type]} /> }}
        />
      );
    default:
      return null;
  }
};

const Facet = ({
  className,
  type,
  facet,
  pathname,
  query,
  onClickFacet,
  isBoolean,
  router,
}: Props) => {
  const classNames = classnames(styles.facet, styles.preview, className);
  const id = isBoolean ? facet.value : facet.id;
  const isActive = facetIsActive(type, id, query);
  const linkTo = generateLinkTo(type, id, pathname, query, isActive);

  const onClick = event => {
    if (onClickFacet) {
      onClickFacet(event, id);
      return;
    }
    if (__CAPACITOR__) {
      event.preventDefault();
      router.replace(linkTo);
    }
  };

  return (
    <Link to={linkTo} className={classNames} onClick={onClick} data-test="facet.item">
      <CapacitorRipple />
      <div className={styles.container}>{getContent(type, facet)}</div>
      {facet.hits && <span className={styles.count}>{facet.hits}</span>}
      {isActive && <IconLabel name="close" className={styles.remove} />}
    </Link>
  );
};

export default withRouter(Facet);
