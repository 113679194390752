// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import classNames from 'classnames';

import * as analyticsActions from '../../actions/analytics';

import styles from './EventCollectionFooter.css';
import CapacitorRipple from '../capacitor/Ripple';

type OwnProps = {
  trackingSource: string,
};

type DispatchProps = {
  track: Function,
};

type Props = OwnProps & DispatchProps;

const EventCollectionFooter = ({ trackingSource, track }: Props) => {
  const onIdagioLiveClick = () => {
    track('Clicked IDAGIO Live', {
      from: trackingSource,
    });
  };

  return (
    <div className={styles.container}>
      <Link
        to="/live"
        className={classNames('c-btn', styles.btn)}
        onClick={onIdagioLiveClick}
        onlyActiveOnIndex
      >
        <CapacitorRipple />
        <FormattedMessage
          id="livestream-event.view-all-events.cta"
          defaultMessage="View all events"
        />
      </Link>
    </div>
  );
};

const dispatchProps: DispatchProps = {
  track: analyticsActions.trackIdagioLive,
};

export default connect(null, dispatchProps)(EventCollectionFooter);
