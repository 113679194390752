import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './RecordingVideo.css';
import { connect } from 'react-redux';
import * as analyticsActions from '../actions/analytics';

class OriginalContentVideo extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
    type: PropTypes.string.isRequired,
    analyticsTrack: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.video) {
      this.video.addEventListener('play', this.onVideoStart);
    }
  }

  onVideoStart = event => {
    const { id, type } = this.props;
    if (event.target.currentTime === 0) {
      this.props.analyticsTrack('Started Video Playback', { id, type });
    }
  };

  render() {
    const { id } = this.props;
    return (
      <div className={styles.videoContainer}>
        <video ref={this.createVideoRef} className={styles.video} controls>
          <source src={`https://dl.idagio.com/videos/${id}.mp4`} />
        </video>
      </div>
    );
  }

  createVideoRef = element => {
    this.video = element;
  };
}

export default connect(() => ({}), {
  analyticsTrack: analyticsActions.track,
})(OriginalContentVideo);
