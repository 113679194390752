import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import dataComponent from '../hoc/dataComponent';
import queueOriginComponent from '../hoc/queueOriginComponent';
import playlistableEntity from '../hoc/playlistableEntity';
import editableEntity from '../hoc/editableEntity';
import { selectUserId, selectUserIsAuthenticated, selectUserIsPatron } from '../selectors/user';
import GenericTrackListView from '../components/common/GenericTrackListView';
import {
  getPersonalPlaylistQueueOrigin,
  selectPersonalPlaylist,
  selectPersonalPlaylistIsPlaying,
  selectPersonalPlaylistIsQueued,
} from '../selectors/personalPlaylist';

import { ENTITY_TYPE_PERSONAL_PLAYLIST } from '../constants';

import * as analyticsActions from '../actions/analytics';
import * as uiActions from '../actions/ui';
import * as personalPlaylistActions from '../actions/personalPlaylist';
import shareableEntity from '../hoc/shareableEntity';
import metaInjector from '../hoc/metaInjector';
import trackedViewComponent from '../hoc/trackedViewComponent';

const messages = defineMessages({
  playAllButtonPausedText: {
    id: 'playlist.play-all.paused.title',
    defaultMessage: 'Play playlist',
  },
  playAllButtonPlayingText: {
    id: 'playlist.play-all.playing.title',
    defaultMessage: 'Pause playlist',
  },
  personalPlaylistMetaDescription: {
    id: 'personalPlaylist.meta.description',
    defaultMessage: 'Listen to {title} playlist on IDAGIO. {description}',
  },
});

function fetchData(store, { id }) {
  return store.dispatch(personalPlaylistActions.loadPersonalPlaylist(id));
}

function mapStateToProps(state, ownProps) {
  const id = ownProps.params.id;
  const playlist = selectPersonalPlaylist(state, id);
  const userIsPatron = selectUserIsPatron(state);
  const isAuthenticated = selectUserIsAuthenticated(state);
  return {
    pathname: ownProps.location.pathname,
    isAuthenticated,
    playlist,
    messages,
    userIsPatron,
    isOwnPlaylist: selectUserId(state) === playlist.user_id,
  };
}

function mapQueueOriginStateToProps(state, ownProps) {
  const { id, tracks, trackIds } = ownProps.playlist;

  return {
    originId: id,

    tracks,
    trackIds,

    isPlaying: selectPersonalPlaylistIsPlaying(state, id),
    isQueued: selectPersonalPlaylistIsQueued(state, id),
  };
}

function getTrackingContext(props) {
  return {
    contextType: 'personalPlaylist',
    contextId: props.playlist.id.toString(),
  };
}

function getEntityTrackingData(props) {
  return {
    itemType: 'personalPlaylist',
    itemId: props.playlist.id,
  };
}

function getShareTrackingContext(props) {
  return {
    sharedContentType: 'PersonalPlaylist',
    sharedContentId: props.playlist.id,
    contextType: 'PersonalPlaylist',
    contentId: props.playlist.id,
  };
}

const PersonalPlaylist = props => {
  return (
    <GenericTrackListView
      {...props}
      isCollectible={false}
      // Since other people‘s playlists cant be added to the collection, we
      // don‘t want to show the download bar, since the download couldn’t be
      // accessed.
      hideDownloadButton={!props.isOwnPlaylist}
      entityType="personal_playlist"
    />
  );
};

const trackingProps = (state, params) => {
  return {
    contextType: ENTITY_TYPE_PERSONAL_PLAYLIST,
    contextId: params.id,
  };
};

function getMetaInformation(props) {
  const { intl, playlist } = props;
  const { formatMessage } = intl;
  return {
    'title': playlist.title,
    'description': formatMessage(messages.personalPlaylistMetaDescription, {
      title: playlist.title,
      description: playlist.description,
    }),
    'og:image': `${playlist.imageUrl}?auto=format&dpr=1&crop=faces&fit=crop&w=1200&h=630`,
  };
}

export default compose(
  trackedViewComponent('PersonalPlaylist', trackingProps),
  dataComponent(fetchData),
  connect(mapStateToProps, {
    track: analyticsActions.track,
  }),
  playlistableEntity({
    getEntityTrackingData,
    getTrackingContext,
  }),
  editableEntity(
    {
      onEdit: uiActions.showEditPlaylistModal,
      onDelete: uiActions.showDeletePlaylistModal,
      onDeleteTrack: personalPlaylistActions.deleteTrackFromPlaylist,
      onReorder: personalPlaylistActions.reorderTrackIdInPlaylist,
    },
    (state, playlist) => selectUserId(state, playlist) === playlist.user_id
  ),
  queueOriginComponent(
    getPersonalPlaylistQueueOrigin,
    mapQueueOriginStateToProps,
    getTrackingContext
  ),
  shareableEntity(ENTITY_TYPE_PERSONAL_PLAYLIST, getShareTrackingContext),
  injectIntl,
  metaInjector(getMetaInformation)
)(PersonalPlaylist);
