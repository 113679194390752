import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { some } from 'lodash';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { hasNextQueueItem, hasPreviousQueueItem, isQueueEmpty } from '../../reducers/player';

import * as Shapes from '../../shapes';
import * as analyticsActions from '../../actions/analytics';
import * as playerActions from '../../actions/player';
import * as sonosActions from '../../actions/sonos';

import PlayerControls from './PlayerControls';
import PlayerInfo from './PlayerInfo';
import PlayerProgress from './PlayerProgress';
import PlayerVolume from './PlayerVolume';
import CollectionButton from '../common/CollectionButton';
import PlayerQuality from './PlayerQuality';
import SonosGroups from './SonosGroups';

import { selectTrack } from '../../selectors/track';
import {
  selectConnectedSonosGroup,
  selectSonosGroups,
  selectSonosIsConnected,
} from '../../selectors/sonos';
import { selectCtaTrackingContext } from '../../selectors/tracking';
import { unwrapRouteComponent } from '../../utils/unwrapRouteComponent';

import {
  selectDuration,
  selectPlayerControlsEnabled,
  selectPlayerCurrentQueueItem,
  selectPlayerIsPlayingAd,
  selectPlayerIsPlayingForPlayerBar,
  selectPlayerMuted,
  selectPlayerQueueOrigin,
  selectPlayerState,
  selectPlayerVolume,
  selectProgress,
  selectRepeatAll,
  selectRepeatOne,
  selectShuffleRecordings,
  selectShuffleTracks,
} from '../../selectors/player';

import { getQueueOriginUrl } from '../../lib/queue';

import styles from './PlayerBar.css';
import { selectCurrentlyPlayingAd } from '../../selectors/ads';
import { selectPlayerBarInfoHidden } from '../../selectors/client';
import { selectLiveCompareCurrentlyOn } from '../../selectors/liveCompare';
import collectibleEntity, { collectibleEntityPropTypes } from '../../hoc/collectibleEntity';
import { ENTITY_TYPE_TRACK } from '../../constants';

class PlayerBar extends PureComponent {
  static propTypes = {
    currentQueueItem: PropTypes.shape({ track: Shapes.Track.id }),
    track: Shapes.Track,
    analyticsTrack: PropTypes.func.isRequired,
    resume: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    seekBegin: PropTypes.func.isRequired,
    seekFinish: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    previous: PropTypes.func.isRequired,
    rewind: PropTypes.func.isRequired,
    infoHidden: PropTypes.bool.isRequired,
    changeVolume: PropTypes.func.isRequired,
    toggleMute: PropTypes.func.isRequired,
    volumeIsVisible: PropTypes.bool.isRequired,
    toggleRepeat: PropTypes.func.isRequired,
    toggleShuffle: PropTypes.func.isRequired,
    shuffleRecordingsAndTracks: PropTypes.func.isRequired,
    playing: PropTypes.bool.isRequired,
    controlsEnabled: PropTypes.bool.isRequired,
    repeatAll: PropTypes.bool.isRequired,
    repeatOne: PropTypes.bool.isRequired,
    shuffleRecordings: PropTypes.bool.isRequired,
    shuffleTracks: PropTypes.bool.isRequired,
    volume: PropTypes.number.isRequired,
    isMuted: PropTypes.bool.isRequired,
    queueOrigin: PropTypes.object,
    shouldRewind: PropTypes.bool.isRequired,
    disablePreviousControl: PropTypes.bool.isRequired,
    pickSonosGroup: PropTypes.func.isRequired,
    connectedSonosGroup: PropTypes.string,
    sonosIsConnected: PropTypes.bool.isRequired,
    shouldRender: PropTypes.bool.isRequired,
    hasNextQueueItem: PropTypes.bool.isRequired,
    isQueueEmpty: PropTypes.bool.isRequired,
    sonosGroups: PropTypes.array.isRequired,
    previewsBannerTrackingContext: PropTypes.object,

    duration: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,

    isPlayingAd: PropTypes.bool.isRequired,
    currentlyPlayingAd: PropTypes.object,

    ...collectibleEntityPropTypes,
  };

  onPreviewsBannerClick = () => {
    this.props.analyticsTrack('Clicked Previews Banner', this.props.previewsBannerTrackingContext);
  };

  render() {
    if (__SERVER__ || !this.props.shouldRender) {
      return null;
    }

    const {
      track,
      resume,
      pause,
      seekBegin,
      seekFinish,
      rewind,
      next,
      previous,
      changeVolume,
      toggleMute,
      toggleRepeat,
      toggleShuffle,
      shuffleRecordingsAndTracks,
      analyticsTrack,
    } = this.props;

    const {
      infoHidden,
      volumeIsVisible,
      isInCollection,
      toggleIsInCollection,
      playing,
      controlsEnabled,
      repeatAll,
      repeatOne,
      shuffleRecordings,
      shuffleTracks,
      volume,
      isMuted,
      queueOrigin,
      shouldRewind,
      disablePreviousControl,
      sonosGroups,
      connectedSonosGroup,
      pickSonosGroup,
      currentQueueItem,
      sonosIsConnected,
      duration,
      progress,
      isPlayingAd,
      currentlyPlayingAd,
    } = this.props;

    const showInfo = !infoHidden && track;
    return (
      <div className={styles.bar}>
        <div className={styles.barInner}>
          <PlayerControls
            play={resume}
            pause={pause}
            next={next}
            previous={previous}
            rewind={rewind}
            toggleRepeat={toggleRepeat}
            toggleShuffle={toggleShuffle}
            shuffleRecordingsAndTracks={shuffleRecordingsAndTracks}
            analyticsTrack={analyticsTrack}
            hasNextQueueItem={this.props.hasNextQueueItem}
            disablePrevious={disablePreviousControl}
            isQueueEmpty={this.props.isQueueEmpty}
            isPlaying={playing}
            shouldRewind={shouldRewind}
            enabled={controlsEnabled}
            isPlayingAd={isPlayingAd}
            repeatAll={repeatAll}
            repeatOne={repeatOne}
            shuffleRecordings={shuffleRecordings}
            shuffleTracks={shuffleTracks}
            sonosIsConnected={sonosIsConnected}
          />
          <PlayerProgress
            seekBegin={seekBegin}
            seekFinish={seekFinish}
            play={resume}
            pause={pause}
            progress={progress}
            duration={duration}
            currentQueueItem={currentQueueItem}
            isPlayingAd={isPlayingAd}
          />
          {currentQueueItem && (
            <CollectionButton
              disabled={isPlayingAd}
              className={styles.buttonCollection}
              active={isInCollection}
              onClick={toggleIsInCollection}
            />
          )}
          {showInfo && (
            <PlayerInfo
              track={track}
              piece={track.piece}
              recording={track.recording}
              trackLink={getQueueOriginUrl(queueOrigin, track.recording)}
              isPlayingAd={isPlayingAd}
              currentlyPlayingAd={currentlyPlayingAd}
            />
          )}
          <PlayerQuality disabled={isPlayingAd} />
          {__ELECTRON__ && (
            <SonosGroups
              sonosGroups={sonosGroups}
              pickGroup={pickSonosGroup}
              connectedSonosGroup={connectedSonosGroup}
            />
          )}
          <PlayerVolume
            volume={volume}
            isMuted={isMuted}
            changeVolume={changeVolume}
            toggleMute={toggleMute}
            volumeIsVisible={volumeIsVisible}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  /*
    Routes are whitelisted in our routes file in order to show the playerbar.
    This avoids the playerbar accidentally showing up on new landing pages etc.

    TODO this should no longer be necessary after web/marketing split.
  */
  const shouldRender = some(ownProps.routes, route => !!route.playerbar);

  const playerState = selectPlayerState(state);
  const ui = state.client.ui;
  const currentTrackId = playerState.currentQueueItem ? playerState.currentQueueItem.track : null;
  const progress = selectProgress(state);
  const isLiveCompare = selectLiveCompareCurrentlyOn(state);

  const { slug } = ownProps.params;

  const routeComponentName = unwrapRouteComponent(ownProps.routes).displayName;

  return {
    disablePreviousControl: !isLiveCompare && !hasPreviousQueueItem(playerState) && progress === 0,
    shouldRewind: !isLiveCompare && progress >= 1,
    queueOrigin: selectPlayerQueueOrigin(state),
    volume: selectPlayerVolume(state),
    isMuted: selectPlayerMuted(state),
    playing: selectPlayerIsPlayingForPlayerBar(state),
    controlsEnabled: selectPlayerControlsEnabled(state),
    repeatAll: selectRepeatAll(state),
    repeatOne: selectRepeatOne(state),
    shuffleRecordings: selectShuffleRecordings(state),
    shuffleTracks: selectShuffleTracks(state),
    hasNextQueueItem: isLiveCompare || hasNextQueueItem(playerState),
    isQueueEmpty: isQueueEmpty(playerState),
    currentQueueItem: selectPlayerCurrentQueueItem(state),
    track: selectTrack(state, currentTrackId, true),

    playerbarHidden: ui.playerbarHidden,
    infoHidden: selectPlayerBarInfoHidden(state),
    volumeIsVisible: ui.volumeIsVisible,

    sonosGroups: selectSonosGroups(state),
    connectedSonosGroup: selectConnectedSonosGroup(state),
    sonosIsConnected: selectSonosIsConnected(state),

    shouldRender,

    previewsBannerTrackingContext: selectCtaTrackingContext(state, slug, routeComponentName),
    progress: selectProgress(state),
    duration: selectDuration(state),

    isPlayingAd: selectPlayerIsPlayingAd(state),
    currentlyPlayingAd: selectCurrentlyPlayingAd(state),
  };
}

function getCollectibleEntityDescription(props) {
  return {
    id: props.track ? props.track.id : null,
    trackingSource: 'Player',
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    resume: playerActions.resumeThunk,
    pause: playerActions.pause,
    seekBegin: playerActions.seekBegin,
    seekFinish: playerActions.seekFinish,
    next: playerActions.nextThunk,
    previous: playerActions.previousThunk,
    rewind: playerActions.rewind,
    changeVolume: playerActions.changeVolume,
    toggleMute: playerActions.toggleMute,
    toggleRepeat: playerActions.toggleRepeat,
    toggleShuffle: playerActions.toggleShuffle,
    shuffleRecordingsAndTracks: playerActions.shuffleRecordingsAndTracks,
    analyticsTrack: analyticsActions.track,
    pickSonosGroup: sonosActions.pickSonosGroup,
  }),
  collectibleEntity(ENTITY_TYPE_TRACK, getCollectibleEntityDescription)
)(PlayerBar);
