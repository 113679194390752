import { loadTopRecordingFacets } from '../actions/facet';
import { loadRecordingsByFilter } from '../actions/recording';
import { selectActiveRecordingFacets, selectRecordingFilterTopFacets } from '../selectors/facet';
import {
  selectFilteredAndSortedRecordings,
  selectNextFilteredRecordingsCursor,
  selectPreviousFilteredRecordingsCursor,
} from '../selectors/recording';
import { selectRecordingsFilterParams, selectSortOrder } from '../selectors/filteredList';
import { ENTITY_TYPE_RECORDING } from '../constants';

export const getCategoryFetchData = (categoryLoader, facetType) => {
  return function fetchData(store, params, location) {
    const { dispatch } = store;
    const { id, cursor } = params;
    const filterParams = selectRecordingsFilterParams(location.query, id, facetType);

    return [
      dispatch(categoryLoader(id)),
      dispatch(loadRecordingsByFilter(filterParams, cursor)),
      dispatch(loadTopRecordingFacets(filterParams)),
    ];
  };
};

export const getCategoryMapStateToProps = (categorySelector, facetType, contextFacet) => {
  const ignoredFacets = [facetType];
  return function mapStateToProps(state, ownProps) {
    const { location, params } = ownProps;
    const { cursor, id } = params;
    const { query } = location;

    const category = categorySelector(state, id);
    if (!category) {
      const notFoundError = new Error('Category not found');
      notFoundError.status = 404;

      throw notFoundError;
    }

    const filterParams = selectRecordingsFilterParams(query, id, facetType);

    const facets = selectRecordingFilterTopFacets(state, filterParams);
    const activeFacets = selectActiveRecordingFacets(state, filterParams, facetType);

    const recordings = selectFilteredAndSortedRecordings(state, filterParams);
    const recordingsAreEmpty = !recordings || recordings.length === 0;
    return {
      category,

      // filtering and sorting
      sort: selectSortOrder(query, ENTITY_TYPE_RECORDING),
      filterParams,
      facets,
      ignoredFacets,
      activeFacets,

      // the recordings
      recordings,
      recordingsAreEmpty,

      // cursors
      currentCursor: cursor,
      nextCursor: selectNextFilteredRecordingsCursor(state, filterParams),
      prevCursor: selectPreviousFilteredRecordingsCursor(state, filterParams),

      // generics needed for CategoryPage
      location,
      params,

      // context information
      contextFacet,
      contextId: category.id.toString(),
    };
  };
};
