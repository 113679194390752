/* @flow */
import * as React from 'react';
import PropTypes from 'prop-types';
import { DataFetcherErrorContext } from '../../lib/routing/DataFetcher';
import NotFound from '../../views/NotFound';
import ServerError from '../../views/ServerError';
import GeoblockedContent from '../../views/GeoblockedContent';

type Props = { children: React.ChildrenArray<React.Element<any>> };

class AppChildrenOrError extends React.Component<Props> {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  renderErrorPage(error: { status: number }): React.Element<typeof NotFound | typeof ServerError> {
    switch (error.status) {
      case 404:
        return <NotFound />;
      case 406:
        return <GeoblockedContent />;
      default:
        return <ServerError />;
    }
  }

  render() {
    const error = this.context;
    return error ? this.renderErrorPage(error) : this.props.children;
  }

  static contextType = DataFetcherErrorContext;
}

export default AppChildrenOrError;
