// @flow
import * as React from 'react';
import { Link } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';

import CapacitorRipple from '../capacitor/Ripple';

import styles from './LivestreamEventArtists.css';
import LivestreamEventArtistInfo from './LivestreamEventArtistInfo';
import { CONSTANT_MESSAGES } from '../../constants';
import type { IntlShape } from 'react-intl';
import type { LivestreamEventArtist } from '../../shapes/types';

type OwnProps = {
  'title': React.Element<typeof FormattedMessage>,
  'artists': Array<LivestreamEventArtist>,
  'data-test'?: string,
};

type Props = OwnProps & { intl: IntlShape };

const ClickableArtist = ({
  profileId,
  name,
  functions,
  imageUrl,
  externalUrl,
  intl,
}: LivestreamEventArtist & { intl: IntlShape }) => {
  const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: name });
  return (
    <React.Fragment>
      {profileId ? (
        <Link
          onlyActiveOnIndex
          className={styles.item}
          to={`/profiles/${profileId}`}
          data-test="livestream-event.artist.clickable-profileId"
        >
          <CapacitorRipple />
          <LivestreamEventArtistInfo
            profileId={profileId}
            name={name}
            functions={functions}
            imageUrl={imageUrl}
          />
        </Link>
      ) : (
        <a
          className={styles.item}
          href={externalUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={ariaLink}
          data-test="livestream-event.artist.clickable-externalUrl"
        >
          <CapacitorRipple />
          <LivestreamEventArtistInfo
            profileId={profileId}
            name={name}
            functions={functions}
            imageUrl={imageUrl}
          />
        </a>
      )}
    </React.Fragment>
  );
};

const NonClickableArtist = ({
  profileId,
  name,
  functions,
  imageUrl,
}: $Diff<LivestreamEventArtist, { externalUrl?: string }>) => (
  <div className={styles.item} data-test="livestream-event.artist.non-clickable">
    <LivestreamEventArtistInfo
      profileId={profileId}
      name={name}
      functions={functions}
      imageUrl={imageUrl}
    />
  </div>
);

const LivestreamEventArtists = ({ title, artists, intl, 'data-test': dataTest }: Props) => {
  if (!artists || !artists.length) {
    return false;
  }

  return (
    <div className={styles.component} data-test={dataTest}>
      <div className={styles.title}>{title}</div>
      <div className={styles.container}>
        {artists.map((item, index) => {
          const { profileId, name, functions, imageUrl, externalUrl } = item;

          return (
            <React.Fragment key={index}>
              {profileId || externalUrl ? (
                <ClickableArtist
                  profileId={profileId}
                  name={name}
                  functions={functions}
                  imageUrl={imageUrl}
                  externalUrl={externalUrl}
                  intl={intl}
                />
              ) : (
                <NonClickableArtist
                  profileId={profileId}
                  name={name}
                  functions={functions}
                  imageUrl={imageUrl}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default injectIntl(LivestreamEventArtists);
