// @flow
import React from 'react';

import type { Course as CourseProps } from '../../shapes/types';
import CourseSessions from './CourseSessions';
import CourseResources from './CourseResources';

type OwnProps = {
  course: CourseProps,
  slug: string,
  userHasAccess: boolean,
  location: Object,
};

type Props = OwnProps;

const Course = ({ course, slug, userHasAccess, location }: Props) => {
  const { sessions, images, videos, audios, documents } = course;
  return (
    <React.Fragment>
      <CourseSessions
        sessions={sessions}
        slug={slug}
        userHasAccess={userHasAccess}
        location={location}
      />
      {userHasAccess && (
        <CourseResources images={images} videos={videos} audios={audios} documents={documents} />
      )}
    </React.Fragment>
  );
};

export default Course;
