import React, { PureComponent } from 'react';
import * as Shapes from '../../shapes';
import styles from './RecordingDate.css';

export default class RecordingDate extends PureComponent {
  static propTypes = {
    recordingDate: Shapes.RecordingDate,
  };

  render() {
    let dateString;

    const { from, to, fromKeyword } = this.props.recordingDate;

    if (from) {
      if (to) {
        dateString = `${from} - ${to}`;
      } else if (fromKeyword) {
        dateString = `${fromKeyword} ${from}`;
        return (
          <span>
            <span className={styles.symbol}>{fromKeyword}</span> {from}
          </span>
        );
      } else {
        dateString = `${from}`;
      }
    } else if (to) {
      dateString = `${to}`;
    }

    return !!dateString ? <span>{dateString}</span> : null;
  }
}
