// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import type { IntlShape } from 'react-intl';
import type { LivestreamEventCreditor } from '../../shapes/types';

import { selectLocale } from '../../selectors/client';
import { getWebsiteUrlInLocale } from '../../lib/locale-utils';
import { CONSTANT_MESSAGES } from '../../constants';
import styles from './LegalNote.css';
import Expandable from '../common/Expandable';

const messages = defineMessages({
  generalTerms: {
    id: 'account.general-terms',
    defaultMessage: 'General Terms and Conditions (GTC)',
  },
  privacyPolicy: {
    id: 'account.privacy-policy',
    defaultMessage: 'Privacy Policy',
  },
});

type OwnProps = {
  creditor?: LivestreamEventCreditor,
};

type MapStateToProps = {
  currentLocale: string,
};

type DispatchProps = {};

type Props = OwnProps & MapStateToProps & DispatchProps & { intl: IntlShape };

const LegalNote = ({ creditor, currentLocale, intl }: Props) => {
  if (!creditor || !creditor.partnerLegalName) {
    return null;
  }

  const generalTermsText = intl.formatMessage(messages.generalTerms);
  const ariaGeneralTermsLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: generalTermsText,
  });

  const privacyPolicyText = intl.formatMessage(messages.privacyPolicy);
  const ariaPrivacyPolicyLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: privacyPolicyText,
  });

  const generateCreditorInfo = () => {
    const { partnerLegalName, partnerAddress, partnerContactEmailAddress } = creditor;
    let creditorInfo = partnerLegalName;
    if (partnerAddress) {
      creditorInfo += `, ${partnerAddress.replace(/\n/g, ', ')}`;
    }
    if (partnerContactEmailAddress) {
      creditorInfo += `, ${partnerContactEmailAddress}`;
    }
    return creditorInfo;
  };

  return (
    <section className={styles.wrapper}>
      <Expandable>
        <p>
          <FormattedMessage id="livestream.legal-note.title" defaultMessage="LEGAL NOTE:" />
        </p>
        <p>
          <FormattedMessage
            id="livestream.legal-note.description-1"
            defaultMessage="This content is provided by {provider}. By purchasing access to this content you will be entering into a direct agreement with the provider. IDAGIO is acting as an intermediary of the respective services only. For further information please see section 2 and 3 of our {terms} and section C 4 of our {privacy}."
            values={{
              provider: generateCreditorInfo(),
              terms: (
                <a
                  className="c-text-link--is-visible"
                  href={getWebsiteUrlInLocale('/terms', currentLocale)}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={ariaGeneralTermsLink}
                >
                  {generalTermsText}
                </a>
              ),
              privacy: (
                <a
                  className="c-text-link--is-visible"
                  href={getWebsiteUrlInLocale('/privacy', currentLocale)}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={ariaPrivacyPolicyLink}
                >
                  {privacyPolicyText}
                </a>
              ),
            }}
          />
        </p>
        <p>
          <FormattedMessage
            id="livestream.legal-note.description-2"
            defaultMessage="After you have purchased access to the content, we will provide the above mentioned provider with your name and email address which have been stored with your user account for the purpose of processing of the agreement. The provider may use your email address to inform you about similar goods and services from time to time. You have the right to object to such use at any time e.g. by sending an email to the above email address of the provider, free of cost."
          />
        </p>
      </Expandable>
    </section>
  );
};

function mapStateToProps(state) {
  return {
    currentLocale: selectLocale(state),
  };
}

export default compose(connect(mapStateToProps, {}), injectIntl)(LegalNote);
