import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import IconLabel from './../util/IconLabel';

import styles from './PlayerControls.css';
import LoadingIndicator from '../common/LoadingIndicator';

import { USER_CLICKED_NEXT } from '../../constants';

import { defineMessages, intlShape, injectIntl } from 'react-intl';

const messages = defineMessages({
  shuffleOff: {
    id: 'aria-button-player-shuffle.off',
    defaultMessage: 'Shuffle off, press to shuffle recordings',
  },
  shuffleRecordings: {
    id: 'aria-button-player-shuffle.recordings',
    defaultMessage: 'Shuffling recordings, press to shuffle tracks',
  },
  shuffleTracks: {
    id: 'aria-button-player-shuffle.tracks',
    defaultMessage: 'Shuffling tracks, press to switch off shuffle',
  },
  repeatOff: {
    id: 'aria-button-player-repeat.off',
    defaultMessage: 'Repeat off, press to repeat queue',
  },
  repeatAll: {
    id: 'aria-button-player-repeat.all',
    defaultMessage: 'Repeating queue, press to repeat track',
  },
  repeatOne: {
    id: 'aria-button-player-repeat.one',
    defaultMessage: 'Repeating track, press to switch off repeat',
  },
});

class PlayerControls extends PureComponent {
  static propTypes = {
    play: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    previous: PropTypes.func.isRequired,
    rewind: PropTypes.func.isRequired,
    analyticsTrack: PropTypes.func.isRequired,
    hasNextQueueItem: PropTypes.bool.isRequired,
    disablePrevious: PropTypes.bool.isRequired,
    isQueueEmpty: PropTypes.bool.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    shouldRewind: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    repeatAll: PropTypes.bool.isRequired,
    repeatOne: PropTypes.bool.isRequired,
    shuffleRecordings: PropTypes.bool.isRequired,
    shuffleTracks: PropTypes.bool.isRequired,
    sonosIsConnected: PropTypes.bool.isRequired,
    toggleRepeat: PropTypes.func.isRequired,
    toggleShuffle: PropTypes.func.isRequired,
    shuffleRecordingsAndTracks: PropTypes.func.isRequired,
    isPlayingAd: PropTypes.bool.isRequired,

    intl: intlShape,
  };

  renderPlayPauseControl() {
    const { isQueueEmpty, enabled, isPlaying, isPlayingAd } = this.props;
    const disabled = !enabled && !isPlaying;

    if (!enabled && !isPlayingAd) {
      return <LoadingIndicator className="loading-indicator--play-pause" isLoading />;
    }

    if (isPlaying) {
      return (
        <button
          disabled={disabled}
          className={styles.btn}
          onClick={this.handlePlayPauseClick}
          data-test="player-controls.pause-btn"
        >
          <IconLabel name="pause" title="Pause" />
        </button>
      );
    }

    return (
      <button
        className={styles.btn}
        disabled={isQueueEmpty || disabled}
        onClick={this.handlePlayPauseClick}
        data-test="player-controls.play-btn"
      >
        <IconLabel name="play" title="Play" />
      </button>
    );
  }

  renderPreviousControl() {
    const { enabled, disablePrevious, isPlayingAd } = this.props;
    let disabled = disablePrevious || !enabled;
    if (isPlayingAd) {
      disabled = true;
    }

    return (
      <button className={styles.btn} disabled={disabled} onClick={this.handlePreviousClick}>
        <IconLabel name="skip-back" size="small" title="Previous" />
      </button>
    );
  }

  renderNextControl() {
    const { enabled, hasNextQueueItem, repeatAll, isPlayingAd, repeatOne } = this.props;
    let disabled;

    if (repeatAll) {
      disabled = !enabled;
    } else {
      disabled = !hasNextQueueItem || !enabled;
    }

    if (repeatOne || isPlayingAd) {
      disabled = true;
    }

    return (
      <button className={styles.btn} disabled={disabled} onClick={this.handleNextClick}>
        <IconLabel name="skip-forward" size="small" title="Next" />
      </button>
    );
  }

  renderRepeatControl() {
    const { repeatAll, repeatOne, isPlayingAd, intl } = this.props;
    const iconClass = repeatAll || repeatOne ? null : styles.iconInactive;

    const iconName = repeatOne ? 'repeat-one' : 'repeat-all';

    let iconTitle = messages.repeatOff;
    if (repeatAll) {
      iconTitle = messages.repeatAll;
    } else if (repeatOne) {
      iconTitle = messages.repeatOne;
    }

    const ariaButton = intl.formatMessage(iconTitle);

    return (
      <button
        className={styles.btn}
        onClick={this.handleRepeatClick}
        disabled={isPlayingAd}
        title={ariaButton}
        aria-label={ariaButton}
      >
        <IconLabel name={iconName} className={iconClass} title="Repeat Queue" />
      </button>
    );
  }

  renderShuffleControl() {
    const { shuffleRecordings, shuffleTracks, isPlayingAd, intl } = this.props;
    const iconClass = shuffleRecordings || shuffleTracks ? null : styles.iconInactive;

    const iconName = shuffleRecordings ? 'shuffle-recordings' : 'shuffle-tracks';

    let iconTitle = messages.shuffleOff;
    if (shuffleRecordings) {
      iconTitle = messages.shuffleRecordings;
    } else if (shuffleTracks) {
      iconTitle = messages.shuffleTracks;
    }

    const ariaButton = intl.formatMessage(iconTitle);

    return (
      <button
        className={styles.btn}
        onClick={this.handleShuffleClick}
        disabled={isPlayingAd}
        title={ariaButton}
        aria-label={ariaButton}
      >
        <IconLabel name={iconName} className={iconClass} title="Shuffle Queue" />
      </button>
    );
  }

  render() {
    return (
      <div className={styles.controls}>
        {this.renderShuffleControl()}
        {this.renderPreviousControl()}
        {this.renderPlayPauseControl()}
        {this.renderNextControl()}
        {!this.props.sonosIsConnected && this.renderRepeatControl()}
      </div>
    );
  }

  handlePlayPauseClick = event => {
    event.preventDefault();

    if (this.props.isPlaying) {
      this.props.pause();
      this.props.analyticsTrack('Pause Pressed', {
        source: 'Playerbar',
      });
    } else {
      // TODO should this be resume instead?
      this.props.play('internal', { timestamp: Date.now() });
      this.props.analyticsTrack('Play Pressed', {
        source: 'Playerbar',
      });
    }
  };

  handlePreviousClick = event => {
    event.preventDefault();

    // When we're into a track for a second or more, clicking previous
    // should rewind the current track,
    // instead of starting to play a previous track in the playlist
    if (this.props.shouldRewind) {
      this.props.rewind();
      this.props.analyticsTrack('Rewind Pressed', {
        source: 'Playerbar',
      });
    } else {
      this.props.previous();
      this.props.analyticsTrack('Previous Pressed', {
        source: 'Playerbar',
      });
    }
  };

  handleNextClick = event => {
    event.preventDefault();
    this.props.next(USER_CLICKED_NEXT, 'internal', Date.now());
    this.props.analyticsTrack('Next Pressed', {
      source: 'Playerbar',
    });
  };

  handleRepeatClick = event => {
    event.preventDefault();
    this.props.toggleRepeat();
    this.props.analyticsTrack('Repeat Pressed', {
      source: 'Playerbar',
    });
  };

  handleShuffleClick = event => {
    event.preventDefault();
    this.props.toggleShuffle();
    this.props.analyticsTrack('Shuffle Pressed', {
      source: 'Playerbar',
    });
    this.props.shuffleRecordingsAndTracks();
  };
}

export default injectIntl(PlayerControls);
