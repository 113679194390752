import classNames from 'classnames';

import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router';

import { RecordingDate, RecordingVenue, Location } from '../../../shapes';

import typography from '../common/typography.css';
import styles from './style.css';

import RecordingInfo from '../../recording/RecordingInfo';

export default class RecordingInfoOverviewSection extends React.PureComponent {
  static propTypes = {
    performerSummary: PropTypes.string,
    recordingDate: RecordingDate,
    venue: RecordingVenue,
    location: Location,
    albumSlug: PropTypes.string,
  };

  render() {
    const { performerSummary, recordingDate, venue, location, albumSlug } = this.props;

    return (
      <div className={styles.Section}>
        <Link to={`/albums/${albumSlug}`} onlyActiveOnIndex>
          <span className={classNames(typography.Base, typography.Italic)}>{performerSummary}</span>
        </Link>
        <Link to={`/albums/${albumSlug}`} onlyActiveOnIndex>
          <RecordingInfo
            date={recordingDate}
            venue={venue}
            location={location}
            className={classNames(typography.Base, typography.Italic)}
          />
        </Link>
      </div>
    );
  }
}
