import React from 'react';
import { IndexRoute, Route, Redirect, IndexRedirect } from 'react-router';

import App from './views/App';
import Login from './views/Login';
import Signup from './views/Signup';
import ForgotPassword from './views/ForgotPassword';
import SetPassword from './views/SetPassword';
import Geoblocked from './views/Geoblocked';

import Discover from './views/Discover';
import DiscoverGroup from './views/DiscoverGroup';
import DiscoverPage from './views/DiscoverPage';

import Browse from './views/Browse';
import Booklet from './views/Booklet';

import Moods from './views/Moods';
import Mood from './views/Mood';
import Composers from './views/Composers';
import Conductors from './views/Conductors';
import Soloists from './views/Soloists';
import Ensembles from './views/Ensembles';

import Instruments from './views/Instruments';
import Instrument from './views/Instrument';

import Genres from './views/Genres';
import Genre from './views/Genre';

import Periods from './views/Periods';
import Period from './views/Period';

import Profile from './views/Profile';
import ProfileRecordings from './components/profile/ProfileRecordings';
import ProfileWorks from './components/profile/ProfileWorks';
import ProfileAlbums from './components/profile/ProfileAlbums';
import Recording from './views/Recording';
import Search from './views/Search';
import NotFound from './views/NotFound';
import NotSupported from './views/NotSupported';

import Settings from './views/Settings';

import Playlist from './views/Playlist';
import Mix from './views/Mix';
import Album from './views/Album';

import Collection from './views/Collection';
import TrackCollection from './components/collection/TrackCollection';
import RecordingCollection from './components/collection/RecordingCollection';
import PlaylistCollection from './components/collection/PlaylistCollection';
import AlbumCollection from './components/collection/AlbumCollection';
import ArtistCollection from './components/collection/ArtistCollection';
import LivestreamEventCollection from './components/collection/LivestreamEventCollection';

import Download from './views/Download';

import Subscribe from './views/Subscribe';
import RecentlyPlayed from './views/RecentlyPlayed';
import LiveCompare from './views/LiveCompare';
import SonosLogin from './views/SonosLogin';
import SonosSignup from './views/SonosSignup';
import SuccessSonos from './views/SuccessSonos';
import DownloadDesktopInstall from './views/DownloadDesktopInstall';

import Work from './views/Work';
import ConsentManager from './views/ConsentManager';
import Playlists from './views/Playlists';
import PersonalPlaylistCollection from './components/collection/PersonalPlaylistCollection';
import PersonalPlaylist from './views/PersonalPlaylist';
import Skeleton from './views/Skeleton';

import BrowseComposers from './components/browse/BrowseComposers';
import BrowsePerformers from './components/browse/BrowsePerformers';
import BrowseEpochs from './components/browse/BrowseEpochs';
import BrowseGenres from './components/browse/BrowseGenres';
import BrowseInstruments from './components/browse/BrowseInstruments';
import ProfilePlaylists from './components/profile/ProfilePlaylists';
import ProfileAbout from './components/profile/ProfileAbout';

import AppleRedirect from './views/AppleRedirect';
import Tv from './views/Tv';

import LivestreamEvent from './views/LivestreamEvent';
import LivestreamEventsOverview from './views/LivestreamEventsOverview';
import LivestreamEventsOverviewPage from './views/LivestreamEventsOverviewPage';
import MyEvents from './views/MyEvents';

const historicalRedirects = [
  { from: 'edison', to: '/playlists/edison', state: { permanent: true } }, // temporary, because of stupidity
  { from: 'activity', to: '/', state: { permanent: true } },
  { from: 'welcome', to: '/', state: { permanent: true } },
  { from: 'promotion', to: '/', state: { permanent: true } },
  { from: 'upgrade', to: '/subscribe', state: { permanent: true } },
  {
    from: '/live-compare',
    to: '/live-compare/6739543',
    state: { permanent: true },
  },
  { from: '/artists/:id', to: 'profiles/:id', state: { permanent: true } },
  { from: '/a/p/:id', to: 'profiles/:id', state: { permanent: true } },
  { from: '/a/e/:id', to: 'profiles/:id', state: { permanent: true } },
  { from: '/conductors/:id', to: 'profiles/:id', state: { permanent: true } },
  { from: '/ensembles/:id', to: 'profiles/:id', state: { permanent: true } },
  { from: '/soloists/:id', to: 'profiles/:id', state: { permanent: true } },
  { from: '/composers/:id', to: 'profiles/:id', state: { permanent: true } },
  { from: '/recording/:id', to: '/recordings/:id', state: { permanent: true } },
  {
    from: '/work/:workId/recording/:recordingId',
    to: '/recordings/:recordingId',
    state: { permanent: true },
  },
  { from: '/:slug/feed', to: '/:slug', state: { permanent: true } },
  { from: '/join-premium', to: '/subscribe', state: { permanent: true } },
  { from: '/premium', to: '/discover', state: { permanent: true } },
  { from: '/activate-trial', to: '/discover', state: { permanent: true } },
  { from: '/activate', to: '/discover', state: { permanent: true } },
  { from: '/', to: '/discover', state: { permanent: true } },
  { from: '/discover-group/:type/:slug', to: '/discover/:type/:slug', state: { permanent: true } },
  { from: '/live/concerts/upcoming', to: '/live', state: { permanent: true } },
].map((config, i) => <Redirect {...config} key={i} />);

export default (
  <Route path="/" component={App}>
    {__ELECTRON__ ? (
      <IndexRoute component={Login} />
    ) : (
      <IndexRoute component={Discover} playerbar>
        <IndexRoute component={DiscoverPage} />
        <Route path=":page" component={DiscoverPage} />
        <Route path=":type/:slug" component={DiscoverGroup} />
      </IndexRoute>
    )}

    {!__ELECTRON__ && <IndexRedirect form="/" to="/discover" />}

    <Route path="/consent-manager" component={ConsentManager} />

    <Route path="/install" component={DownloadDesktopInstall} />

    <Route path="/discover" component={Discover} playerbar>
      <IndexRoute component={DiscoverPage} />
      <Route path=":page" component={DiscoverPage} />
      <Route path=":type/:slug" component={DiscoverGroup} />
    </Route>

    <Route path="/apple-redirect" component={AppleRedirect} />

    {/*
    This is a route that is EXCLUDED in apple-app-site-association file,
    so that it always opens in web, in order to let users add a subscription
    via Stripe and allow for special conditions (trials / discounts etc.)
    */}
    <Route path="/special-offer" component={Discover} playerbar>
      <IndexRoute component={DiscoverPage} />
    </Route>

    <Route path="/download" component={Download} />
    <Route path="/booklet" component={Booklet} hideUi />

    <Route path="/moods" component={Moods} playerbar />
    <Route path="/moods/:id" component={Mood} playerbar />

    <Route path="/recently-played" component={RecentlyPlayed} playerbar />

    <Route path="/live-compare/:pieceId" component={LiveCompare} playerbar />

    <Route path="/sonos/login" component={SonosLogin} />
    <Route path="/sonos/join" component={SonosSignup} />
    <Route path="/sonos/success" component={SuccessSonos} />

    <Route path="/login" component={Login} />
    <Route path="/join" component={Signup} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/set-password" component={SetPassword} />

    <Route path="/not-supported" component={NotSupported} />
    <Route path="/geoblocked" component={Geoblocked} />

    <Route path="/composers(/pages/:cursor)" component={Composers} playerbar />
    <Route path="/conductors(/pages/:cursor)" component={Conductors} playerbar />
    <Route path="/soloists(/pages/:cursor)" component={Soloists} playerbar />
    <Route path="/ensembles(/pages/:cursor)" component={Ensembles} playerbar />

    <Route path="/account" component={Settings} playerbar />

    <Route path="/instruments" component={Instruments} playerbar />
    <Route path="/instruments/:id(/pages/:cursor)" component={Instrument} playerbar />

    <Route path="/genres" component={Genres} playerbar />
    <Route path="/genres/:id(/pages/:cursor)" component={Genre} playerbar />

    <Route path="/periods" component={Periods} playerbar />
    <Route path="/periods/:id(/pages/:cursor)" component={Period} playerbar />

    <Route path="/recordings/:recording_id" component={Recording} playerbar />
    <Route path="/recordings/:recording_id#:track_info" component={Recording} playerbar />

    <Route path="/playlists/:slug" component={Playlist} playerbar />
    <Route path="/playlists/personal/:id" component={PersonalPlaylist} playerbar />
    <Route path="/mixes/:slug" component={Mix} playerbar />
    <Route path="/albums/:slug" component={Album} playerbar />

    <Route path="/search" component={Search} playerbar />

    <Route path="/live/event/:slug" component={LivestreamEvent} playerbar />

    <Route path="/live" component={LivestreamEventsOverview} playerbar>
      <IndexRoute component={LivestreamEventsOverviewPage} />
    </Route>
    <Route path="/my-events(/pages/:cursor)" component={MyEvents} playerbar />

    <Route path="/subscribe" component={Subscribe} />

    <Route path="/works/:id" component={Work} playerbar />

    <Route path="profiles/:slug" component={Profile} playerbar>
      <Route path="about" component={ProfileAbout} />
      <Route path="playlists(/pages/:cursor)" component={ProfilePlaylists} />
      <Route path="recordings(/pages/:cursor)" component={ProfileRecordings} />
      <Route path="works(/pages/:cursor)" component={ProfileWorks} />
      <Route path="albums(/pages/:cursor)" component={ProfileAlbums} />
    </Route>

    <Route path="browse" component={Browse} playerbar>
      <IndexRedirect to="composers" />
      <Route path="composers" component={BrowseComposers} />
      <Route path="performers" component={BrowsePerformers} />
      <Route path="epochs" component={BrowseEpochs} />
      <Route path="genres" component={BrowseGenres} />
      <Route path="instruments" component={BrowseInstruments} />
    </Route>

    <Route path="collection" component={Collection} playerbar>
      <Route path="tracks" component={TrackCollection} />
      <Route path="recordings" component={RecordingCollection} />
      <Route path="albums" component={AlbumCollection} />
      <Route path="artists" component={ArtistCollection} />
      <Route path="playlists" component={Playlists}>
        <IndexRoute component={PlaylistCollection} />
        <Route path="personal" component={PersonalPlaylistCollection} />
      </Route>
      <Route path="events" component={LivestreamEventCollection} />
    </Route>

    <Route path="tv" component={Tv} />
    <Route path="blank" component={Skeleton} />

    {historicalRedirects}

    <Route path="*" component={NotFound} />
  </Route>
);
