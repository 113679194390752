import * as Schema from '../schema';
import assembleEntity from '../schema/assembleEntity';
import { createSelector } from 'reselect';

export const selectComposers = createSelector(
  [state => state.entities, state => state.lists.composers.entities],
  (entities, composers) => assembleEntity(Schema.PersonList, entities, composers)
);

export const selectConductors = createSelector(
  [state => state.entities, state => state.lists.conductors.entities],
  (entities, conductors) => assembleEntity(Schema.PersonList, entities, conductors)
);

export const selectSoloists = createSelector(
  [state => state.entities, state => state.lists.soloists.entities],
  (entities, soloists) => assembleEntity(Schema.PersonList, entities, soloists)
);

export const selectEnsembles = createSelector(
  [state => state.entities, state => state.lists.ensembles.entities],
  (entities, ensembles) => assembleEntity(Schema.EnsembleList, entities, ensembles)
);

export function selectPeriod(state, id) {
  return state.entities.epochs[id];
}

export const selectPeriods = createSelector(
  [state => state.entities, state => state.lists.epochs.entities],
  (entities, epochs) => assembleEntity(Schema.EpochList, entities, epochs)
);

export function selectGenre(state, id) {
  return state.entities.genres[id];
}

export const selectGenres = createSelector(
  [state => state.entities, state => state.lists.genres.entities],
  (entities, genres) => assembleEntity(Schema.GenreList, entities, genres)
);

export function selectInstrument(state, id) {
  return state.entities.instruments[id];
}

export const selectInstruments = createSelector(
  [state => state.entities, state => state.lists.instruments.entities],
  (entities, instruments) => assembleEntity(Schema.InstrumentList, entities, instruments)
);

// Links
function linkForNamedEntity(prefix) {
  return entity => {
    return {
      id: entity.id,
      href: `/${prefix}/${entity.id}`,
      title: entity.title,
    };
  };
}

export const selectPeriodLinks = createSelector([selectPeriods], periods =>
  periods.map(linkForNamedEntity('periods'))
);

export const selectGenresLinks = createSelector([selectGenres], genres =>
  genres.map(linkForNamedEntity('genres'))
);

export const selectInstrumentsLinks = createSelector([selectInstruments], instruments =>
  instruments.map(linkForNamedEntity('instruments'))
);
