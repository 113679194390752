// @flow

import * as React from 'react';
import stylesListGrid from './BrowseListGrid.css';
import BrowseElement from './BrowseElement';
import { connect } from 'react-redux';
import { selectLocale } from '../../selectors/client';
import { splitLocale } from '../../lib/locale-utils';
import { isString } from 'lodash';
import List from '../util/List';
import type { BrowseListItem } from '../../shapes/types';

type Props = {|
  items: Array<BrowseListItem>,
  language: string,
|};

type MapStateToProps = {
  language: string,
};

class BrowseElementList extends React.PureComponent<Props> {
  renderItem = item => {
    return (
      <li key={item.id}>
        <BrowseElement id={item.id} name={item.name} href={`/profiles/${item.id}`} />
      </li>
    );
  };

  render() {
    const { items, language } = this.props;
    const translatedItems = items.map(item => {
      return {
        ...item,
        name: isString(item.name) ? item.name : item.name[language] || item.name.en,
      };
    });
    return (
      <List items={translatedItems} renderItem={this.renderItem} className={stylesListGrid.list} />
    );
  }
}

function mapStateToProps(state: Object): MapStateToProps {
  const locale = selectLocale(state);
  return {
    language: splitLocale(locale).language,
  };
}

export default connect(mapStateToProps)(BrowseElementList);
