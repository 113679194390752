// @flow
import React from 'react';
import type { ChildrenArray, Element } from 'react';
import Modal from './Modal';
import styles from './FacetGroupAllFacetsModal.css';

type OwnProps = {
  isVisible: boolean,
  hideModal: Function,
  title: Element<any> | string,
  children?: ChildrenArray<Element<any>>,
};

type Props = OwnProps;

const FacetGroupAllFacetsModal = ({ isVisible, hideModal, title, children }: Props) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Modal isOpen={isVisible} onClose={hideModal} contentLabel="facet-group-all-facets-modal">
      <div className={styles.modal} data-test="facet.group.all-facets-modal">
        <h1 className={`${styles.header} fz--gamma`}>{title}</h1>
        {children}
      </div>
    </Modal>
  );
};

export default FacetGroupAllFacetsModal;
