import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import { Link } from 'react-router';
import { defineMessages, intlShape, injectIntl } from 'react-intl';

import CapacitorHeaderBar from '../components/capacitor/HeaderBar';
import { loadSoloists } from '../actions/soloist';
import { selectSoloists } from '../selectors/categories';
import CapacitorRipple from '../components/capacitor/Ripple';
import styles from './GenericList.css';
import {
  selectSoloistsIsLoading,
  selectNextSoloistsCursor,
  selectPrevSoloistsCursor,
} from '../selectors/soloists';

import * as Shapes from '../shapes';

import Head from '../components/chrome/Head';
import List from '../components/util/List';
import PaginatedList from '../components/util/PaginatedList';
import shareableEntity, { shareableEntityPropTypes } from '../hoc/shareableEntity';
import { ENTITY_TYPE_ARTISTS } from '../constants';

const messages = defineMessages({
  metaTitle: {
    id: 'soloists.meta.title',
    defaultMessage: 'Soloists',
  },
  metaDescription: {
    id: 'soloists.meta.description',
    defaultMessage:
      'Find and play curated classical music from hundreds of soloists. Compare alternative recordings and browse by soloist.',
  },
});

class Soloists extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(Shapes.Person).isRequired,
    intl: intlShape,
    currentCursor: PropTypes.string,
    nextCursor: PropTypes.string,
    prevCursor: PropTypes.string,
    loadSoloists: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,

    ...shareableEntityPropTypes,
  };

  renderItem(soloist) {
    return (
      <li key={soloist.id}>
        <Link to={`/profiles/${soloist.id}`} className={styles.link}>
          <CapacitorRipple />
          <strong>{soloist.surname}</strong>, {soloist.forename}
        </Link>
      </li>
    );
  }

  render() {
    const { intl, items, currentCursor, nextCursor, prevCursor, isLoading, location } = this.props;
    const { formatMessage } = intl;
    const title = formatMessage(messages.metaTitle);
    return (
      <div className="u-page-container">
        {__CAPACITOR__ && (
          <CapacitorHeaderBar
            showShareModal={this.props.showShareModal}
            title={title}
            onlyShowTitleOnScroll
            showSearch
          />
        )}
        <Head
          title={title}
          description={formatMessage(messages.metaDescription)}
          currentCursor={currentCursor}
          nextCursor={nextCursor}
          prevCursor={prevCursor}
        />
        <h1 className="fz--beta">{title}</h1>
        <PaginatedList
          isLoading={isLoading}
          currentCursor={currentCursor}
          nextCursor={nextCursor}
          prevCursor={prevCursor}
          location={location}
          loadMore={this.loadMore}
        >
          <List items={items} renderItem={this.renderItem} />
        </PaginatedList>
      </div>
    );
  }

  loadMore = () => {
    const { nextCursor, isLoading } = this.props;
    if (!isLoading && nextCursor) {
      this.props.loadSoloists(nextCursor);
    }
  };
}

function fetchData(store, { cursor }) {
  return [store.dispatch(loadSoloists(cursor))];
}

function mapStateToProps(state, ownProps) {
  return {
    items: selectSoloists(state),
    currentCursor: ownProps.params.cursor,
    nextCursor: selectNextSoloistsCursor(state),
    prevCursor: selectPrevSoloistsCursor(state),
    isLoading: selectSoloistsIsLoading(state),
    location: ownProps.location,
  };
}

function getShareTrackingContext() {
  return {
    sharedContentType: 'Artist',
    sharedContentId: null,
    contextType: 'Category',
    contentId: null,
  };
}

export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, { loadSoloists }),
  injectIntl,
  shareableEntity(ENTITY_TYPE_ARTISTS, getShareTrackingContext)
)(Soloists);
