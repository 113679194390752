import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import * as Shapes from '../../shapes';

import Person from '../common/Person';
import InterpreterList from '../common/InterpreterList';
import IconLabel from '../util/IconLabel';
import TitleWithPopularTitle from '../common/TitleWithPopularTitle';
import PlayerInfoAd from './PlayerInfoAd';

import styles from './PlayerInfo.css';

export default class PlayerInfo extends PureComponent {
  static propTypes = {
    recording: Shapes.Recording.isRequired,
    track: Shapes.Track.isRequired,
    piece: Shapes.Piece.isRequired,
    trackLink: PropTypes.string.isRequired,
    isPlayingAd: PropTypes.bool.isRequired,
    currentlyPlayingAd: PropTypes.object,
  };

  renderPieceTitle(workTitle, piece) {
    if (workTitle !== piece.title) {
      if (piece && piece.workpart) {
        const workPartTitle = piece.workpart.title;
        return (
          <span>
            <span>&nbsp;&ndash;&nbsp;</span>
            {workPartTitle && <span>{`${workPartTitle} • `}</span>}
            <TitleWithPopularTitle {...piece} />
          </span>
        );
      }
    }
    return null;
  }

  render() {
    const { track, currentlyPlayingAd, isPlayingAd } = this.props;
    if (isPlayingAd && currentlyPlayingAd) {
      return <PlayerInfoAd ad={currentlyPlayingAd} />;
    }

    if (track) {
      const { piece, recording, trackLink } = this.props;
      const { work } = recording;

      return (
        <Link className={styles.info} to={trackLink} data-test="playerbar.info-text">
          <IconLabel name="playing" size="19.2px" className={styles.iconPlaying} />
          <div className={styles.infoEl}>
            <Person person={work.composer} />
            <span>&nbsp;&ndash;&nbsp;</span>
            <TitleWithPopularTitle {...work} />
            {this.renderPieceTitle(work.title, piece)}
          </div>
          <div className={styles.recordingInfo}>
            <InterpreterList recording={recording} />
          </div>
        </Link>
      );
    }

    return <div className={styles.info} />;
  }
}
