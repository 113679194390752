import { createSelector } from 'reselect';
import Fuse from 'fuse.js';

export function selectSearchQuery(state, id) {
  return state.localListSearch[id] || '';
}

export function getSearchKeys(type) {
  switch (type) {
    case 'composers':
    case 'conductors':
    case 'soloists':
      return ['surname', 'forename'];
    case 'works':
      return ['title', 'popularTitle'];
    case 'instruments':
    case 'genres':
    case 'epochs':
      return ['title'];
    case 'ensembles':
    case 'recordingTypes':
      return ['name'];
    default:
      return [];
  }
}

export const selectFilteredItems = createSelector(
  [items => items, (items, searchQuery) => searchQuery, (items, searchQuery, type) => type],
  (items, searchQuery, type) => {
    if (items && items.length && searchQuery) {
      const searchKeys = getSearchKeys(type);
      const fuse = new Fuse(items, {
        keys: searchKeys,
        minMatchCharLength: 3,
      });
      return fuse.search(searchQuery).map(({ item }) => item);
    }

    return items;
  }
);
