import React, { Component } from 'react';
import { defineMessages, intlShape, injectIntl } from 'react-intl';

import ServerErrorText from '../components/messages/ServerErrorText';

const messages = defineMessages({
  serverError: {
    id: 'error.server.title',
    defaultMessage: 'Server error',
  },
});

class ServerError extends Component {
  static propTypes = {
    intl: intlShape,
  };

  render() {
    const { intl } = this.props;
    const serverError = intl.formatMessage(messages.serverError);

    return (
      <div className="u-page-container" role="main" aria-label={serverError}>
        <h1>{serverError}</h1>
        <p>
          <ServerErrorText />
        </p>
      </div>
    );
  }
}

export default injectIntl(ServerError);
