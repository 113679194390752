// @flow
import React from 'react';
import { connect } from 'react-redux';

import {
  selectAllAlbumFacets,
  selectAllWorkFacets,
  selectAllRecordingFacets,
  selectAllFacetsById,
  getCompoundKey,
} from '../../selectors/facet';

import * as localListSearchActions from '../../actions/localListSearch';
import styles from './FacetGroupAllFacetsContent.css';
import { FACET_FILTER_TYPES } from '../../constants';
import FacetGroupContent from './FacetGroupContent';
import LoadingIndicator from '../common/LoadingIndicator';
import LocalListSearch from '../common/LocalListSearch';
import { selectSearchQuery, selectFilteredItems } from '../../selectors/localListSearch';
import FacetApplyFiltersBtn from './FacetApplyFiltersBtn';

type OwnProps = {
  location: Object,
  activeFacets: Array<any>,
  filterType: string,
  onClose: Function,
  type: string,
  id: string,
  isBoolean: boolean,
  onClickClear: Function,
  selectedFacetIds: Array<number | string | boolean>,
  onClickFacet: Function,
  shouldRenderApplyFiltersBtn: boolean,
  handleMultipleComposers?: boolean,
};

type MapStateToProps = {
  results?: Array<any>,
  searchTerm?: string,
  compoundKey: string,
  activeFacetList: Array<any>,
};

type DispatchProps = {
  setSearchQuery: Function,
};

type Props = $Diff<OwnProps, { filterType: string, id: string, activeFacets: Array<any> }> &
  MapStateToProps &
  DispatchProps;

const FacetGroupAllFacetsContent = ({
  location,
  activeFacetList,
  onClose,
  onClickClear,
  selectedFacetIds,
  onClickFacet,
  shouldRenderApplyFiltersBtn,
  results,
  type,
  isBoolean,
  searchTerm,
  compoundKey,
  setSearchQuery,
  handleMultipleComposers,
}: Props) => {
  const onSearchChange = async term => {
    setSearchQuery(compoundKey, term);
  };

  return (
    <React.Fragment>
      {!results ? (
        <div>
          <LoadingIndicator isLoading />
        </div>
      ) : (
        <React.Fragment>
          {!isBoolean && (
            <LocalListSearch
              query={searchTerm}
              onSearchChange={onSearchChange}
              id="facet-group-all-facets-modal-search-box-input"
              type={type}
              className={styles.searchBox}
            />
          )}
          <FacetGroupContent
            type={type}
            results={results}
            activeFacetList={activeFacetList}
            location={location}
            onClickFacet={onClickFacet}
            onClickClear={onClickClear}
            selectedFacetIds={selectedFacetIds}
            isBoolean={isBoolean}
            showActiveFacetsSeparately
            handleMultipleComposers={handleMultipleComposers}
            shouldSortItems={!searchTerm}
          />
        </React.Fragment>
      )}
      {shouldRenderApplyFiltersBtn && (
        <div className={styles.applyFiltersWrapper}>
          <FacetApplyFiltersBtn
            selectedFacetIds={selectedFacetIds}
            type={type}
            pathname={location.pathname}
            query={location.query}
            onClick={onClose}
          />
        </div>
      )}
    </React.Fragment>
  );
};

function getFacetSelector(filterType) {
  switch (filterType) {
    case FACET_FILTER_TYPES.ALBUM:
      return selectAllAlbumFacets;
    case FACET_FILTER_TYPES.WORK:
      return selectAllWorkFacets;
    default:
      return selectAllRecordingFacets;
  }
}

function mapStateToProps(state: Object, ownProps: OwnProps): MapStateToProps {
  const { filterType, type, id, activeFacets } = ownProps;

  const results = selectAllFacetsById(getFacetSelector(filterType))(state, id);
  const compoundKey = getCompoundKey(id, filterType, type);
  const searchTerm = selectSearchQuery(state, compoundKey);
  const filteredResults = selectFilteredItems(results, searchTerm, type);
  const filteredActiveFacets = selectFilteredItems(
    activeFacets.map(({ facet }) => facet),
    searchTerm,
    type
  );

  return {
    results: filteredResults,
    activeFacetList: filteredActiveFacets,
    searchTerm,
    compoundKey,
  };
}

const dispatchProps: DispatchProps = {
  setSearchQuery: localListSearchActions.setSearchQuery,
};

export default connect(mapStateToProps, dispatchProps)(FacetGroupAllFacetsContent);
