export const selectComposersIsLoading = state => state.lists.composers.loading;

export const selectNextComposersCursor = state => {
  const cursor = state.lists.composers.meta.cursor;
  if (cursor) {
    return cursor.next;
  }
  return null;
};

export const selectPrevComposersCursor = state => {
  const cursor = state.lists.composers.meta.cursor;
  if (cursor) {
    return cursor.prev;
  }
  return null;
};
