// @flow

import React from 'react';
import classnames from 'classnames';
import qs from 'querystring';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

import Slider from '../common/Slider';
import PlayableItemPreview from '../common/PlayableItemPreview';
import DiscoverLivestreamEventItem from './DiscoverLivestreamEventItem';

import styles from './DiscoverGroupSlider.css';
import stylesPlayableItemPreview from '../common/PlayableItemPreview.css';
import A11ySkipLink from '../a11y/A11ySkipLink';
import DiscoverLiveCompareItem from './DiscoverLiveCompareItem';
import {
  DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP,
  DISCOVER_GROUP_TYPE_LIVE_COMPARE,
  DISCOVER_GROUP_TYPE_PLAYLIST_PORTAL,
} from '../../constants';

import type { DiscoverGroup } from '../../shapes/types';
import IconLabel from '../util/IconLabel';
import CapacitorRipple from '../capacitor/Ripple';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  group: DiscoverGroup,
  nextGroupId?: string,
  viewAllQueryParams?: Object,
  isHomePage?: boolean,
  onItemClick: Function,
  onItemBeforeTogglePlay: Function,
};

type Props = OwnProps;

function DiscoverGroupSlider({
  group,
  nextGroupId,
  isHomePage,
  viewAllQueryParams,
  onItemClick,
  onItemBeforeTogglePlay,
}: Props) {
  const isLiveCompareGroup = group.type === DISCOVER_GROUP_TYPE_LIVE_COMPARE;
  const isLivestreamEventGroup = group.type === DISCOVER_GROUP_TYPE_LIVESTREAM_EVENT_GROUP;
  const isInvertedGroup = isLivestreamEventGroup && isHomePage;
  const isPlayableGroup = !isLiveCompareGroup && !isLivestreamEventGroup && !isInvertedGroup;
  const isPlaylistPortalGroup = group.type === DISCOVER_GROUP_TYPE_PLAYLIST_PORTAL;

  const groupClassNames = classnames(styles.group, {
    [styles.inverted]: isInvertedGroup,
  });

  const getSliderItem = () => {
    if (isLivestreamEventGroup) {
      return DiscoverLivestreamEventItem;
    }

    if (isLiveCompareGroup) {
      return DiscoverLiveCompareItem;
    }

    return PlayableItemPreview;
  };

  const getViewAllLink = () => {
    if (isLivestreamEventGroup && isHomePage) {
      return '/live';
    }
    if (!group.canViewAll) {
      return null;
    }

    let to = '/discover';

    if (group.hasSubDiscoverPage && group.subDiscoverPage) {
      to += `/${group.subDiscoverPage}`;
    } else {
      to += `/${group.type}/${group.id}`;
      if (viewAllQueryParams) {
        to += '?' + qs.stringify(viewAllQueryParams);
      }
    }

    return to;
  };

  const renderItem = item => {
    const SliderItem = getSliderItem();

    return (
      <SliderItem
        item={item}
        key={item.content.id}
        onClick={onItemClick}
        beforeTogglePlay={onItemBeforeTogglePlay}
        isInverted={isInvertedGroup}
        isHomePage={isHomePage}
        isSliderItem
        groupId={group.id}
      />
    );
  };

  const viewAllLink = getViewAllLink();
  const viewAllLabel = (
    <FormattedMessage id="discover.group.show-more" defaultMessage="Show More" />
  );

  const ViewAllItem = (
    <Link
      to={viewAllLink}
      className={classnames(styles.viewAllItem, {
        [stylesPlayableItemPreview.playlistGroupBg]: isPlaylistPortalGroup,
        [stylesPlayableItemPreview.bg]: !isPlaylistPortalGroup,
      })}
      onlyActiveOnIndex
    >
      {__CAPACITOR__ && (
        <div className={stylesPlayableItemPreview.ripple}>
          <CapacitorRipple />
        </div>
      )}
      <div className={styles.viewAllText}>
        {viewAllLabel}
        <IconLabel name="chevron-right" className={styles.viewAllIcon} />
      </div>
    </Link>
  );

  const sliderItems = group.items.map(renderItem);

  if (isPlayableGroup && viewAllLink) {
    sliderItems.push(ViewAllItem);
  }

  return (
    <div className={groupClassNames} id={group.id}>
      {nextGroupId && <A11ySkipLink target={nextGroupId} />}
      <Slider
        title={group.name}
        subtitle={group.subtitle}
        items={sliderItems}
        viewAllLink={viewAllLink}
        viewAllLabel={viewAllLabel}
      />
    </div>
  );
}

export default DiscoverGroupSlider;
