import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLivestreamEventInfoFromDiscoverGroupItemContent } from './DiscoverLivestreamEventItem';
import styles from './HeroSliderItem.css';
import LivestreamEventImage from '../livestream-event/LivestreamEventImage';
import HeroSliderItemLink from './HeroSliderItemLink';
import CollectionButton from '../common/CollectionButton';

import * as livestreamActions from '../../actions/livestreamEvents';
import collectibleEntity, { collectibleEntityPropTypes } from '../../hoc/collectibleEntity';
import { ENTITY_TYPE_LIVESTREAM_EVENT } from '../../constants';

function LivestreamEventHeroSliderItem({
  isActive,
  url,
  item,
  onClick,
  isInCollection,
  toggleIsInCollection,
  loadLivestreamEvent,
}) {
  const event = getLivestreamEventInfoFromDiscoverGroupItemContent(item.content);
  const { title, imageCredits, slug, isLive, isAvailable, isUpcoming } = event;

  const toggleInCollection = () => {
    // load event entity in the background and save it into the store
    loadLivestreamEvent(slug);
    toggleIsInCollection();
  };

  return (
    <div className={styles.eventSlideContent}>
      <HeroSliderItemLink onClick={onClick} isActive={isActive} url={url}>
        <LivestreamEventImage event={event} isHeroItem>
          {imageCredits && (
            <figcaption className={styles.credits}>Photo: {imageCredits}</figcaption>
          )}
        </LivestreamEventImage>
      </HeroSliderItemLink>
      <div className={styles.eventDescription}>
        <p className={styles.eventTitle}>
          <HeroSliderItemLink onClick={onClick} isActive={isActive} url={url}>
            {title}
          </HeroSliderItemLink>
        </p>
        <CollectionButton
          active={isInCollection}
          onClick={toggleInCollection}
          disabled={!isLive && !isAvailable && !isUpcoming}
          className={styles.collectionBtn}
        />
      </div>
    </div>
  );
}

LivestreamEventHeroSliderItem.propTypes = {
  item: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,

  loadLivestreamEvent: PropTypes.func.isRequired,

  ...collectibleEntityPropTypes,
};

function getCollectibleEntityDescription(props) {
  return {
    id: props.item.content.id,
    trackingSource: 'Livestream Event',
  };
}

export default compose(
  connect(null, {
    loadLivestreamEvent: livestreamActions.loadLivestreamEvent,
  }),
  collectibleEntity(ENTITY_TYPE_LIVESTREAM_EVENT, getCollectibleEntityDescription)
)(LivestreamEventHeroSliderItem);
