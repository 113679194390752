import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as subscriptionActions from '../../actions/subscription';
import { selectBaseUrl } from '../../selectors/client';
import classNames from 'classnames';
import styles from './SectionItem.css';

class StripeCustomerPortalButton extends Component {
  static propTypes = {
    openStripeCustomerPortal: PropTypes.func.isRequired,
    baseUrl: PropTypes.string.isRequired,
  };

  onClick = async () => {
    const { openStripeCustomerPortal, baseUrl } = this.props;
    const returnUrl = `${baseUrl}/account`;
    const { normalized: url } = await openStripeCustomerPortal(returnUrl);
    document.location = url;
  };

  render() {
    return (
      <button className={classNames('c-btn', styles.primaryBtn)} onClick={this.onClick}>
        <FormattedMessage
          id="settings.subs.stripe-customer-portal.button"
          defaultMessage="Manage billing"
        />
      </button>
    );
  }
}

function mapStateToProps(state) {
  return {
    baseUrl: selectBaseUrl(state),
  };
}

export default connect(mapStateToProps, {
  openStripeCustomerPortal: subscriptionActions.openStripeCustomerPortal,
})(StripeCustomerPortalButton);
