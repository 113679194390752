import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import trackedViewComponent from '../hoc/trackedViewComponent';
import { loadDiscoverPage } from '../actions/discover';
import dataComponent from '../hoc/dataComponent';
import { selectDiscoverPageResult, selectHotlinkItems } from '../selectors/discover';
import { DISCOVER_PAGE_TYPE_HOME } from '../constants';

import DiscoverGroupList from '../components/discover/DiscoverGroupList';

export function fetchData(store, { page = DISCOVER_PAGE_TYPE_HOME }) {
  return store.dispatch(loadDiscoverPage(page));
}

const getPageByParams = params => {
  return params.page || DISCOVER_PAGE_TYPE_HOME;
};

export const trackingProps = (state, params) => {
  const page = getPageByParams(params);

  return {
    isSubPage: page !== DISCOVER_PAGE_TYPE_HOME,
    subPageId: page,
  };
};

export function mapStateToProps(state, ownProps) {
  const page = getPageByParams(ownProps.params);
  const isHomePage = page === DISCOVER_PAGE_TYPE_HOME;
  const hasBanner = page === DISCOVER_PAGE_TYPE_HOME;

  let hotlinkItems;
  if (isHomePage) {
    hotlinkItems = selectHotlinkItems(state);
  }

  return {
    result: selectDiscoverPageResult(state, page, isHomePage, hasBanner),
    trackingScreen: 'discover',
    slug: page,
    isHomePage,
    hasBanner,
    hotlinkItems,
  };
}

export default compose(
  trackedViewComponent('Discover', trackingProps),
  connect(mapStateToProps),
  dataComponent(fetchData),
  withRouter
)(DiscoverGroupList);
