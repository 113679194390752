import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import stylesNavigation from '../components/common/StickyNavigation.css';
import { FormattedMessage } from 'react-intl';

import { Link } from 'react-router';
import StickyNavigation from '../components/common/StickyNavigation';
import CollectionPlaylists from '../components/messages/CollectionPlaylists';
import Feature from '../components/util/Feature';

class Playlists extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div>
        <Feature id="user_playlists">
          {allowed => {
            if (allowed) {
              return (
                <React.Fragment>
                  <div>
                    <h1 className="fz--beta">
                      <CollectionPlaylists />
                    </h1>
                  </div>
                  <StickyNavigation>
                    <Link
                      to="/collection/playlists/personal"
                      className={stylesNavigation.link}
                      activeClassName={stylesNavigation.linkIsActive}
                    >
                      <FormattedMessage
                        id="collection.playlists.personal"
                        defaultMessage="Your Playlists"
                      />
                    </Link>
                    <Link
                      to="/collection/playlists"
                      className={stylesNavigation.link}
                      onlyActiveOnIndex
                      activeClassName={stylesNavigation.linkIsActive}
                    >
                      <FormattedMessage
                        id="collection.playlists.curated"
                        defaultMessage="Collected Playlists"
                      />
                    </Link>
                  </StickyNavigation>
                </React.Fragment>
              );
            }
            return null;
          }}
        </Feature>
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default Playlists;
