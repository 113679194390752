// @flow
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import FacebookLogin from '../common/FacebookLogin';
import { CONNECTED_FACEBOOK_SUCCESS, DISCONNECTED_FACEBOOK_SUCCESS } from '../../lib/notifications';
import SectionItem from './SectionItem';
import styles from './SectionItem.css';
import { selectFacebookIsLinked } from '../../selectors/user';

type OwnProps = {
  onlyHasSocialLogin: boolean,
  addNotification: Function,
};

type MapStateToProps = {
  facebookIsLinked: boolean,
};

type Props = OwnProps & MapStateToProps;

const LinkedAccount = ({ onlyHasSocialLogin, addNotification, facebookIsLinked }: Props) => {
  if (!facebookIsLinked) {
    return null;
  }

  return (
    <SectionItem
      title={
        <FormattedMessage
          id="settings.account.linked-accounts.title"
          defaultMessage="Connect IDAGIO with Facebook:"
        />
      }
    >
      <div className={styles.form}>
        <FacebookLogin
          canDisconnect={facebookIsLinked}
          disabled={onlyHasSocialLogin}
          showPasswordConfirmationInModal
          passwordConfirmCopy={
            <FormattedMessage
              id="settings.account.re-enter-password"
              defaultMessage="To continue, please re-enter your IDAGIO password."
            />
          }
          onAuthComplete={() => addNotification(CONNECTED_FACEBOOK_SUCCESS)}
          onDisconnect={() => addNotification(DISCONNECTED_FACEBOOK_SUCCESS)}
        />
        {onlyHasSocialLogin && (
          <FormattedMessage
            id="facebook.unlink.disabled.description"
            defaultMessage="You need to set a password for your account to be able to unlink your social account."
          />
        )}
      </div>
    </SectionItem>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    facebookIsLinked: selectFacebookIsLinked(state),
  };
}

export default connect(mapStateToProps)(LinkedAccount);
