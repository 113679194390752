export default {
  2530: {
    summary: {
      de: "Kammermusik hat ihren Ursprung in der 'Kammer', einem größeren Wohnraum in einem fürstlichen Schloß oder häuslichen Kontext, wo sie zum Genuss der Musizierenden und der Zuhörerschaft aufgeführt wurde. Die Kammermusik entwickelte sich im Laufe des Barockzeitalters und insbesondere während der Klassik. Im 19. Jahrhundert wurden ihre Formen erweitert, sodass sie auch in der Öffentlichkeit aufgeführt wurde. Kammermusik existiert in unterschiedlichen Besetzungen von bis zu zwölf Instrumenten mit jeweils einem Ausführenden pro Stimme. Das wohl wichtigste Genre der Kammermusik ist das Streichquartett (zwei Violinen, Viola und Violoncello), allerdings wurden viele Meisterwerke für andere Kombinationen von Streichern, Holzbläsern und Blechbläsern geschrieben. Das Klaviertrio (Violine, Violoncello, Klavier) bezieht auch Tasteninstrumente mit ein.",
      en: 'Chamber music has its origins in the "chamber", a smaller room in a palace or domestic context, where it was performed as much for the enjoyment of the musicians as for those listening. It established itself throughout the Baroque and especially Classical periods, and further expanded its forms also to fit public stages during the 19th century. It can exist for any configuration of up to around a dozen instruments with one musician to a part, and the string quartet (two violins, viola and cello) is perhaps the quintessential chamber music genre. But there are great works for other string, wind and even brass groups, with genres such as the piano trio (violin, cello, piano) also including keyboard instruments.',
    },
    recordings: [
      {
        id: 28303426,
        title: {
          de: 'Quintett für Klarinette und Streichinstrumente in A-Dur KV 581',
          en: 'Quintet for Clarinet and Strings in A major KV 581',
        },
        workId: 10943419,
        composerName: 'Mozart',
      },
      {
        id: 22184741,
        title: {
          de: 'Streichquintett in C-Dur op. posth. 163 D 956',
          en: 'String Quintet in C major op. posth. 163 D 956',
        },
        workId: 109284,
        composerName: 'Schubert',
      },
      {
        id: 22197385,
        title: {
          de: 'Streichquartett in Es-Dur',
          en: 'String Quartet in E flat major',
        },
        workId: 21439467,
        composerName: 'Fanny Mendelssohn-Hensel',
      },
      {
        id: 26888184,
        title: {
          de: 'Klaviertrio Nr. 4 in e-Moll op. 90 B 166 "Dumky"',
          en: "Piano Trio No. 4 in E minor op. 90 B 166 'Dumky'",
        },
        workId: 113322,
        composerName: 'Dvořák',
      },
      {
        id: 29864708,
        title: {
          de: 'Klavierquintett in g-Moll op. 57',
          en: 'Piano Quintet in G minor op. 57',
        },
        workId: 7465923,
        composerName: 'Shostakovich',
      },
    ],
  },
  2527: {
    summary: {
      de: 'Das Konzert ist ein Werk für ein (oder mehrere) Soloinstrumente und Orchester. Der Begriff wurde im Barockzeitalter weitläufig verwendet, so auch für Vokalmusik und ‘Concerti grossi’ für mehrere Solisten. Antonio Vivaldis hunderte von Solo-Konzerten verhalfen der Form zu seiner dreisätzigen Anlage und virtuosen Veranlagung. Die Konzertform wurde in der Klassik weiter standardisiert und im 19. Jahrhundert erheblich erweitert, wobei der virtuose Solist im Wettstreit mit dem ständig erweiterten Orchester steht. Das Klavier und die Violine sind die häufigsten Soloinstrumente des Instrumentalkonzerts; das Repertoire umfasst aber die gesamte Bandbreite von Instrumenten von der Tuba bis zum Piccolo.',
      en: "The concerto is a work for a solo instrumentalist(s) and orchestra. The term was used loosely in the Baroque period, where it was also applied occasionally to vocal music as well as 'concerti grossi' for multiple soloists. Antonio Vivaldi's hundreds of solo concertos, however, helped fix the genre's standard three-movement form and tendency towards brilliant solo writing. That form became fixed further in the Classical period, and expanded in 19th-century works in which virtuoso soloists often seem to battle against ever-expanding orchestras. Pianists and violinists feature most often as concerto soloists, but the repertory includes concertos for instruments across the whole range, from tuba to piccolo.",
    },
    recordings: [
      {
        id: 17100012,
        title: {
          de: 'Le quattro stagioni (Die vier Jahreszeiten) op. 8/1-4',
          en: 'Le quattro stagioni (The Four Seasons) op. 8/1-4',
        },
        workId: 108646,
        composerName: 'Vivaldi',
      },
      {
        id: 23497245,
        title: {
          de: 'Konzert für Klavier und Orchester Nr. 5 in Es-Dur op. 73',
          en: "Concerto for Piano and Orchestra No. 5 in E flat major op. 73 'Emperor'",
        },
        workId: 678934,
        composerName: 'Beethoven',
      },
      {
        id: 25636006,
        title: {
          de: 'Konzert für Violine und Orchester in e-Moll op. 64 MWV O 14',
          en: 'Concerto for Violin and Orchestra in E minor op. 64 MWV O 14',
        },
        workId: 108013,
        composerName: 'Mendelssohn ',
      },
      {
        id: 11420054,
        title: {
          de: 'Konzert für Violoncello und Orchester in e-Moll op. 85',
          en: 'Cello Concerto Concerto for Violoncello and Orchestra in E minor op. 85',
        },
        workId: 112744,
        composerName: 'Elgar ',
      },
      {
        id: 12227203,
        title: {
          de: 'Konzert für Klavier und Orchester Nr. 3 in C-Dur op. 26 (1917-1921)',
          en: 'Concerto for Piano and Orchestra No. 3 in C major op. 26 (1917-1921)',
        },
        workId: 7260372,
        composerName: 'Prokofiev ',
      },
    ],
  },
  2533: {
    summary: {
      de: 'Tasteninstrumente spielen seit jeher eine zentraler Rolle in der klassischen Musik. Schon vor mehr als fünfhundert Jahren fand die Orgel ihre Rolle in Verbindung mit sakraler Musik. Frühe Kielinstrumente wie das Spinett und das Virginal wurden vom Cembalo und später vom Klavier abgelöst, bei dem die Saiten von Hämmern angeschlagen werden. Das “Pianoforte” (es verdankt den Namen seiner Fähigkeit, sowohl leise also auch laut zu spielen) entwickelte sich zu Beginn des 18. Jahrhunderts und das Klavier erlangte seine moderne Form im Laufe des 19. Jahrhunderts. Viele große Komponisten waren zugleich hoch-talentierte Klaviervirtuosen; aus diesem Grunde ist das Klavierrepertoire äußerst breit gefächert, von kleinen Werken für den häuslichen Gebrauch bis hin zur großen Klaviersonate.',
      en: 'Keyboard instruments have been central to music making for centuries. Organs have existed in churches for over half a millennium and continue to be central to religious music. Early plucked keyboard instruments such as the spinet and virginal, however, were replaced first by harpsichord and then by the piano, in which strings are instead struck by hammers connected to the keys. The "pianoforte" (the name reflects the instrument\'s ability to play soft and loud) was first developed in the 18th century and reached its modern form throughout the 19th. Many great composers were also virtuoso keyboard players and the repertoire for the piano in particular is vast, running the gamut from grand sonatas to modest works composed specifically for domestic performance.',
    },
    recordings: [
      {
        id: 22183856,
        title: {
          de: 'Goldberg Variationen (Clavier-Übung IV) BWV 988',
          en: 'Goldberg Variations (Clavier-Übung IV) BWV 988',
        },
        workId: 482560,
        composerName: 'Bach',
      },
      {
        id: 25859734,
        title: {
          de: "Sonate für Klavier Nr. 14 in cis-Moll op. 27/2 'Mondscheinsonate'",
          en: 'Sonata for Piano No. 14 in C sharp minor op. 27/2 "Moonlight Sonata"',
        },
        workId: 484031,
        composerName: 'Beethoven',
      },
      {
        id: 17589058,
        title: { de: 'Etüden op. 10', en: 'Études op. 10' },
        workId: 485838,
        composerName: 'Chopin',
      },
      {
        id: 21305669,
        title: { de: 'Kreisleriana op. 16', en: 'Kreisleriana op. 16' },
        workId: 5478688,
        composerName: 'Schumann',
      },
      {
        id: 22158835,
        title: { de: 'Gaspard de la Nuit M 55 (1908)', en: 'Gaspard de la Nuit M 55 (1908)' },
        workId: 486079,
        composerName: 'Ravel',
      },
    ],
  },
  790: {
    summary: {
      de: 'Die Geschichte der Oper begann mit dem Versuch einer Gruppe florentinischer Adliger, ein gesungenes Drama zu kreieren, das die Mischung aus Wort und Musik des antiken griechischen Theaters widerspiegelt. Eines der frühesten Werke dieser Gattung ist Claudio Monteverdi’s L’Orfeo. Die Kunstform der Oper befasste sich zunächst mit Themen, die ihren Mäzenen zusagten; später reflektierte sie auch die politischen oder ästhetischen Ideale des Komponisten. Die Oper war am stärksten in Italien verwurzelt und breitete sich seit dem 18. Jahrhundert zunächst in Frankreich und später in der ganzen Welt aus. Im Laufe seiner Geschichte hat die komplexe und kostspielige, aber auch äußerst bereichernde Form der Oper immer wieder versucht, eine delikate Balance zwischen der Musik und dem (von einem Librettisten verfassten) Text und der Musik herzustellen, die größer ist als die Summe ihrer Bestandteile.',
      en: "Opera’s history began with a specific attempt by a group of Florentine nobles to produce a new form of sung drama that reflected the mixture of words and music of Ancient Greek theatre. The earliest extant opera to result from this attempt is Claudio Monteverdi’s 'L'Orfeo' (1607). Throughout the following centuries the new art form developed in many directions, dealing with a variety of subjects serious, heroic and comic. It remained rooted in Italy, but quickly developed across Europe and the world, especially in France and later Germany. A uniquely complex, rewarding and expensive art form, opera has throughout its history been defined by the attempt to strike a delicate balance between words (normally written by a librettist) and music to create a special form of intense drama greater than the sum of its parts.",
    },
    recordings: [
      {
        id: 29468418,
        title: { de: "L'Orfeo SV 318", en: "L'Orfeo SV 318" },
        workId: 1059063,
        composerName: 'Monteverdi',
      },
      {
        id: 28290265,
        title: { de: 'Le nozze di Figaro KV 492', en: 'Le nozze di Figaro KV 492' },
        workId: 1008300,
        composerName: 'Mozart',
      },
      {
        id: 28385550,
        title: { de: 'Carmen WD 31', en: 'Carmen WD 31' },
        workId: 641156,
        composerName: 'Bizet',
      },
      {
        id: 28443115,
        title: {
          de: 'Evgenii Onegin (Eugen Onegin) op. 24 TH 5',
          en: 'Evgenii Onegin (Eugene Onegin) op. 24 TH 5',
        },
        workId: 1118902,
        composerName: 'Tchaikovsky',
      },
      {
        id: 28443511,
        title: { de: 'La Bohème', en: 'La Bohème' },
        workId: 651784,
        composerName: 'Puccini',
      },
    ],
  },
  2524: {
    summary: {
      de: 'Das Orchester entwickelte sich aus bescheidenen Anfängen in das vielseitige und glänzende musikalische Ensemble, für welches viele Komponisten ihre berühmtesten Werke schufen. Bis zum Zeitalter des Barock war die exakte Konfiguration variabel; seit der Klassik jedoch (und insbesondere Joseph Haydns 104 Sinfonien) gaben Komponisten genau vor, wie viele Instrumente beteiligt sein sollten, wobei Holz- und Blechbläser sowie Schlaginstrumente zu den Streichern hinzugefügt wurden. Die Sinfonie hatte sich seit Beethoven zum wichtigsten Genre der Orchestermusik entwickelt; allerdings kamen im 19. Jahrhundert auch neue Genres wie die Tondichtung hinzu. Obwohl zeitgenössische Komponisten von der Sinfonie weniger Gebrauch machen, sind Werke für großes Orchester auch heute ein unverzichtbarer Bestandteil des klassischen Musikbetriebs.',
      en: "The orchestra developed from informal beginnings to become the versatile and brilliant musical machine for which many composers produced their greatest works. In Baroque music and earlier its exact configuration could be fluid, but during the classical period – especially with the 104 symphonies by Joseph Haydn – composers specified which instruments it should contain, with woodwind, brass and percussion instruments added to the ever-present string section (first and second violins, violas, cellos and double basses). With Beethoven the symphony became the most prestigious genre of all, but was complemented by new genres (such as the tone poem) throughout the 19th century. The symphony is less common among today's composers, but works for full orchestra remain the backbone of classical music life to this day.",
    },
    recordings: [
      {
        id: 17936429,
        title: { de: 'Sinfonie Nr. 40 in g-Moll KV 550', en: 'Symphony No. 40 in G minor KV 550' },
        workId: 106986,
        composerName: 'Mozart',
      },
      {
        id: 22662326,
        title: {
          de: "Sinfonie Nr. 3 in Es-Dur op. 55 'Eroica'",
          en: 'Symphony No. 3 in E flat major op. 55 "Eroica"',
        },
        workId: 106184,
        composerName: 'Beethoven',
      },
      {
        id: 18832506,
        title: { de: 'Don Juan op. 20 TrV 156', en: 'Don Juan op. 20 TrV 156' },
        workId: 7012972,
        composerName: 'Strauss',
      },
      {
        id: 20071845,
        title: {
          de: 'Le Sacre du printemps (Das Frühlingsopfer) (1910-1913)',
          en: 'Le Sacre du printemps (The Rite of Spring) (1910-1913)',
        },
        workId: 7013170,
        composerName: 'Stravinsky',
      },
      {
        id: 16608110,
        title: { de: 'Atmosphères (1961)', en: 'Atmosphères (1961)' },
        workId: 11300806,
        composerName: 'Ligeti ',
      },
    ],
  },
  2536: {
    summary: {
      de: 'Musik für religiöse Zwecke war eine der ersten, die in einer Notation festgehalten wurde, zunächst als einfacher Gesang, und seit dem Zeitalter der Renaissance in immer komplexeren Formen der Polyphonie, welche durch die Verflechtung mehrerer Stimmen das Göttliche zum Ausdruck zu bringen versucht. Die musikalischen Formen der Sakralmusik spiegelten zunächst die zulässigen Rollen von Gesang und Instrumenten in der Liturgie wider; seit der Zeit Johann Sebastian Bachs wurden Instrumentalensembles sowohl für Messen (also einen festen liturgischen Text auf Lateinisch) als auch für Kantaten und Passionen benutzt, welche volkssprachliche Texte vertonten. Während sich musikalische Formen für Chor und Orchester (wie auch Händels Oratorien) weiterentwickelten, gewannen auch Werke für Chor allein, häufig inspiriert von den Formen der Renaissance, zunehmend an Bedeutung und sind für viele zeitgenössische Komponisten von zentraler Bedeutung.',
      en: 'Music sung for religious occasions was among the first to be notated. First came simple chants and then, especially during the Renaissance, ever-more complex polyphony – where multiple voices intertwine in an attempt to reflect and express the divine. The different forms of sacred music reflect the permitted role of singing and instruments in worship, but from J. S. Bach on, an instrumental ensemble was regularly used for masses (setting a standard liturgical text in Latin) as well as cantatas and passions, which used religious texts in the vernacular. Music for chorus and orchestra developed quickly to including such additional forms as oratorios, but music for choir alone – often inspired by renaissance models – remains central to many important composers to this day.',
    },
    recordings: [
      {
        id: 25784580,
        title: { de: 'Miserere mei, Deus', en: 'Miserere mei, Deus' },
        workId: 11763338,
        composerName: 'Allegri',
      },
      {
        id: 26929942,
        title: {
          de: 'Matthäus-Passion BWV 244',
          en: 'Matthäus-Passion (St. Matthew Passion) BWV 244',
        },
        workId: 1018149,
        composerName: 'Bach',
      },
      {
        id: 29422366,
        title: { de: 'Messiah (Der Messias) HWV 56', en: 'Messiah HWV 56' },
        workId: 834027,
        composerName: 'Handel',
      },
      {
        id: 25557313,
        title: { de: 'Requiem in d-Moll KV 626', en: 'Requiem in D minor KV 626' },
        workId: 622212,
        composerName: 'Mozart',
      },
      {
        id: 27585641,
        title: { de: 'Messa da Requiem', en: 'Messa da Requiem' },
        workId: 1008715,
        composerName: 'Verdi',
      },
    ],
  },
  2539: {
    summary: {
      de: 'Weltlicher Gesang entwickelte sich parallel zur Sakralmusik und entlehnte sich im Laufe seiner Geschichte viele seiner Merkmale zur Lobpreisung und Reflexion über irdische Dinge wie der Liebe, der Natur und jedwede Themen poetischer Art. Zu den frühesten Formen zählen der Minnesang und Allegorien, aber auch Derberes und später das Madrigal. Die weltliche Kantate und das Oratorium (welches in Gegensatz zur Oper ausschließlich konzertant aufgeführt wird) entstanden zur Zeit des Barocks. Die Blüte des Kunstlieds (zunächst für Singtimme und Klavier, später auch in Form des Orchesterliedes) folgte im 19. Jahrhundert. Franz Schubert gilt als der wichtigste Vertreter dieser Gattung.',
      en: 'Secular song and sung music developed parallel to religious music. Throughout its history it has borrowed many of sacred music\'s characteristics to praise, celebrate and contemplate more earthly matters: love, the beloved, nature and any manner of other subjects of poetic interest. Early forms include courtly songs and allegorical entertainments, as well as more worldly fare. Madrigals proliferated in the early Baroque period, and then secular cantatas and oratorios (many very close to opera in form, but sung only in concert). The 19th century witnessed the golden age of the Art Song ("Lied" in German) composed for a solo singer with piano – and then later also orchestral – accompaniment, with Austrian composer Franz Schubert its most important early proponent.',
    },
    recordings: [
      {
        id: 25554347,
        title: {
          de: 'Die schöne Müllerin op. 25 D 795',
          en: 'Die schöne Müllerin op. 25 D 795',
        },
        workId: 703177,
        composerName: 'Schubert',
      },
      {
        id: 19537073,
        title: {
          de: 'Rückert-Lieder für Singstimme und Orchester',
          en: 'Rückert-Lieder for Voice and Orchestra',
        },
        workId: 1164971,
        composerName: 'Mahler',
      },
      {
        id: 20890776,
        title: {
          de: 'Vier letzte Lieder für Singstimme und Orchester TrV 296',
          en: 'Vier letzte Lieder (Four Last Songs) for Voice and Orchestra TrV 296',
        },
        workId: 7013112,
        composerName: 'Strauss',
      },
      {
        id: 29633447,
        title: { de: "Les nuits d'été op. 7 H 81a", en: "Les nuits d'été op. 7 H 81a" },
        workId: 13252119,
        composerName: 'Berlioz',
      },
      {
        id: 16512359,
        title: { de: 'Let Me Tell You (2012-2013)', en: 'Let Me Tell You (2012-2013)' },
        workId: 16511311,
        composerName: 'Abrahamsen',
      },
    ],
  },
};
