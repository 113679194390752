import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { motion } from 'framer-motion';
import CapacitorRipple from '../capacitor/Ripple';
import styles from './Expandable.css';

const messages = defineMessages({
  ariaButtonExpand: {
    id: 'aria-button-expand',
    defaultMessage: 'Expand',
  },

  ariaButtonCondense: {
    id: 'aria-button-condense',
    defaultMessage: 'Condense',
  },
});

const Expandable = ({
  children,
  intl,
  expandMessage = 'Show More',
  collapseMessage = 'Show Less',
}) => {
  const [expanded, setExpanded] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [expandable, setExpandable] = useState(true);
  const childrenHeightRef = useRef(null);
  const collapsibleContainerRef = useRef(null);

  useEffect(() => {
    // Note that 1st render has occurred to hand over height controls from css to framer-motion
    setMounted(true);

    // If the height of the children elements are <= the default collapsed height, show the children without max height or expand button/shadow
    const childrenHeight = childrenHeightRef.current.getBoundingClientRect().height;
    const maxCollapsedHeight = collapsibleContainerRef.current.getBoundingClientRect().height;
    if (childrenHeight <= maxCollapsedHeight) {
      setExpandable(false);
    }
  }, []);

  const { collapsedHeight, animationDuration } = styles;
  return (
    <div
      className={`${styles.container} ${mounted ? styles.mounted : ''} ${
        expanded && expandable ? styles.expanded : ''
      } ${expandable ? styles.expandable : ''}`}
    >
      <motion.div
        className={styles.collapsibleContainer}
        ease="inertia"
        transition={{ type: 'tween', duration: parseFloat(animationDuration) }}
        animate={{
          height: expanded || !expandable ? 'auto' : collapsedHeight,
        }}
        ref={collapsibleContainerRef}
      >
        <span ref={childrenHeightRef}>{children}</span>
      </motion.div>
      <button
        aria-label={
          expanded
            ? intl.formatMessage(messages.ariaButtonExpand)
            : intl.formatMessage(messages.ariaButtonCondense)
        }
        className={styles.button}
        onClick={() => setExpanded(!expanded)}
      >
        <CapacitorRipple />
        {expanded ? collapseMessage : expandMessage}
      </button>
    </div>
  );
};

Expandable.propTypes = {
  intl: intlShape,
  children: PropTypes.node,
  expandMessage: PropTypes.string,
  collapseMessage: PropTypes.string,
};

export default compose(injectIntl)(Expandable);
