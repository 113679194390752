// @flow
// $FlowFixMe
import React, { useMemo } from 'react';

import { groupFacet, sortFacet } from '../../selectors/facet';
import FacetList from './FacetList';
import FacetListGrouped from './FacetListGrouped';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  type: string,
  results: Array<any>,
  location: Object,
  onClickFacet?: Function,
  activeFacets: Array<any>,
  showActiveFacetsSeparately?: boolean,
  isBoolean: boolean,
  getFilterIsActive: Function,
  handleMultipleComposers?: boolean,
  shouldSortItems?: boolean,
};

type Props = OwnProps;

const FacetGroupContentList = ({
  type,
  results,
  location,
  onClickFacet,
  isBoolean,
  activeFacets,
  showActiveFacetsSeparately,
  getFilterIsActive,
  handleMultipleComposers,
  shouldSortItems,
}: Props) => {
  const groupedFacet = useMemo(
    () =>
      groupFacet(
        shouldSortItems ? sortFacet(results, type) : results,
        type,
        handleMultipleComposers
      ),
    [results, type, handleMultipleComposers, shouldSortItems]
  );

  const Wrapper = Array.isArray(groupedFacet) ? FacetList : FacetListGrouped;

  return (
    <Wrapper
      isVirtualized={__CAPACITOR__}
      items={groupedFacet}
      type={type}
      location={location}
      activeFacets={shouldSortItems ? sortFacet(activeFacets, type) : activeFacets}
      showActiveFacetsSeparately={showActiveFacetsSeparately}
      onClickFacet={onClickFacet}
      isBoolean={isBoolean}
      getFilterIsActive={getFilterIsActive}
      shouldSortItems={shouldSortItems}
    />
  );
};

export default FacetGroupContentList;
