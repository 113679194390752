// @flow
import React from 'react';
import classnames from 'classnames';
import IconLabel from '../util/IconLabel';
import styles from './HeroSliderControls.css';

type OwnProps = {
  onPrevClick: Function,
  onNextClick: Function,
  activeIndex: number,
  itemCount: number,
};

type Props = OwnProps;

export default function HeroSliderControls({
  onPrevClick,
  onNextClick,
  activeIndex,
  itemCount,
}: Props) {
  return (
    <React.Fragment>
      <button
        className={classnames(
          styles.sliderButton,
          styles.prevButton,
          activeIndex === 0 && styles.inactive
        )}
        onClick={onPrevClick}
      >
        <IconLabel name="chevron-left" className={styles.icon} title="Previous" size="3rem" />
      </button>
      <button
        className={classnames(
          styles.sliderButton,
          styles.nextButton,
          activeIndex === itemCount - 1 && styles.inactive
        )}
        onClick={onNextClick}
      >
        <IconLabel name="chevron-right" className={styles.icon} title="Next" size="3rem" />
      </button>
    </React.Fragment>
  );
}
