import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CHROME_CLEAN, FROM_ID_CAMPAIGNS } from '../constants';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as auth from '../actions/auth';
import { FormattedMessage } from 'react-intl';
import * as styles from './Tv.css';
import chromeComponent from '../hoc/chromeComponent';
import protectedComponent from '../hoc/protectedComponent';

class Tv extends Component {
  static chrome = CHROME_CLEAN;
  static propTypes = {
    fetchAuthorizationCode: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    code: null,
    error: null,
  };

  componentDidMount = async () => {
    try {
      const response = await this.props.fetchAuthorizationCode();
      const code = response.normalized.data.code;
      this.setState({ code, loading: false });
    } catch (e) {
      this.setState({ error: e, loading: false });
      console.error(e); // eslint-disable-line
    }
  };

  renderGenerateNewCodeLink = () => {
    return (
      <a href="?" className="c-text-link--is-visible">
        <FormattedMessage id="tv.generate-new-code" defaultMessage="Generate a new code" />
      </a>
    );
  };

  renderContactUsLink = () => {
    return (
      <a href="mailto:contact@idagio.com" className="c-text-link--is-visible">
        <FormattedMessage id="tv.contact-us" defaultMessage="contact us" />
      </a>
    );
  };

  render() {
    const { code, loading, error } = this.state;

    return (
      <section className={'u-page-container ' + styles.container}>
        <h1 className="fz--beta">
          <FormattedMessage
            id="tv.code.title"
            defaultMessage="Enter this 9-digit code on your TV:"
          />
        </h1>
        {loading && <FormattedMessage id="tv.code.loading" defaultMessage="Loading..." />}
        {!loading && code && <div className={styles.code}>{code.match(/.{1,3}/g).join('-')}</div>}

        {!loading && error && (
          <div>
            <FormattedMessage
              id="tv.error"
              defaultMessage="An error occured. Please {contactUsLink}"
              values={{
                generateNewCodeLink: this.renderGenerateNewCodeLink(),
                contactUsLink: this.renderContactUsLink(),
              }}
            />
          </div>
        )}
        <div className={styles.helpSection}>
          <FormattedMessage
            id="tv.code.help"
            defaultMessage="Having trouble? {generateNewCodeLink}, or {contactUsLink}."
            values={{
              generateNewCodeLink: this.renderGenerateNewCodeLink(),
              contactUsLink: this.renderContactUsLink(),
            }}
          />
        </div>
      </section>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default compose(
  protectedComponent({ authenticatedOnly: true }, false, FROM_ID_CAMPAIGNS.tv.fromId),
  chromeComponent({
    type: CHROME_CLEAN,
  }),
  connect(mapStateToProps, {
    fetchAuthorizationCode: auth.fetchAuthorizationCode,
  })
)(Tv);
