import PropTypes from 'prop-types';
import classNames from 'classnames';

import React, { PureComponent } from 'react';
import { getSDK } from '../../../utils/dom';
import styles from './YouTubeVideoAdaptor.css';

const SDK_URL = 'https://www.youtube.com/iframe_api';
const SDK_GLOBAL = 'YT';
const SDK_GLOBAL_READY = 'onYouTubeIframeAPIReady';

class YouTubeVideoAdaptor extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    showControls: PropTypes.bool.isRequired,
    autoPlay: PropTypes.bool.isRequired,
    hideYoutubeLogo: PropTypes.bool,
    className: PropTypes.string.isRequired,

    onPlay: PropTypes.func.isRequired,
    onPause: PropTypes.func.isRequired,
    onEnded: PropTypes.func.isRequired,
  };

  componentDidMount = async () => {
    const { id } = this.props;
    const { autoPlay, showControls, hideYoutubeLogo } = this.props;

    const YT = await getSDK(SDK_URL, SDK_GLOBAL, SDK_GLOBAL_READY, sdk => sdk.loaded);
    if (!this.container) return;
    this.player = new YT.Player(this.container, {
      videoId: id,
      playerVars: {
        autoplay: +autoPlay,
        controls: +showControls,
        modestbranding: +hideYoutubeLogo,
      },
      events: {
        onReady: this.onPlayerReady,
        onStateChange: ({ data }) => this.onStateChange(data, YT.PlayerState),
      },
    });
  };

  componentDidUpdate = prevProps => {
    if (!this.player) return;
    if (this.props.id !== prevProps.id) {
      this.player.cueVideoById({
        videoId: this.props.id,
      });
    }
  };

  onPlay = () => {
    this.props.onPlay(this.getEventData());
  };

  onPause = () => {
    this.props.onPause(this.getEventData());
  };

  onEnded = () => {
    this.props.onEnded(this.getEventData());
  };

  onStateChange = (data, States) => {
    const { PLAYING, PAUSED, ENDED } = States;
    if (data === PLAYING) {
      this.onPlay();
    }
    if (data === PAUSED) {
      this.onPause();
    }
    if (data === ENDED) {
      this.onEnded();
    }
  };

  onPlayerReady = event => {
    if (this.props.autoPlay) {
      event.target.playVideo();
    }
  };

  getEventData = () => {
    return {
      currentTime: this.player.getCurrentTime(),
      duration: this.player.getDuration(),
    };
  };

  render = () => {
    const { className } = this.props;
    // IMPORTANT the dom node holding the player instance needs to be wrapped
    // in an extra div like this. Otherwise it crashes on unmount.
    return (
      <div className={classNames(className, styles.youtubeVideoWrapper)}>
        <div ref={this.ref} />
      </div>
    );
  };

  ref = container => {
    this.container = container;
  };
}

export default YouTubeVideoAdaptor;
