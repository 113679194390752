// @flow
import React from 'react';
import { compose } from 'redux';

import Head from '../components/chrome/Head';
import SignupComponent from '../components/common/Signup';

import chromeComponent from '../hoc/chromeComponent';

import trackedViewComponent from '../hoc/trackedViewComponent';

import { CHROME_CLEAN_LINKS } from '../constants';
import { selectSubscriptionFromId } from '../selectors/subscriptionOffer';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import protectedComponent from '../hoc/protectedComponent';

const messages = defineMessages({
  metaTitle: {
    id: 'signup.meta-title',
    defaultMessage: 'Create account',
  },
});

type OwnProps = {};

type MapStateToProps = {
  fromId: string,
};

type Props = OwnProps & MapStateToProps & { intl: IntlShape };

const Signup = ({ fromId, intl }: Props) => {
  return (
    <div className="u-page-container">
      <Head title={intl.formatMessage(messages.metaTitle)} />
      <SignupComponent fromId={fromId} />
    </div>
  );
};

const trackingProps = state => {
  return {
    landing_page_id: selectSubscriptionFromId(state),
  };
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    fromId: selectSubscriptionFromId(state),
  };
}

export default compose(
  protectedComponent({ anonymousOnly: true }),
  connect(mapStateToProps),
  trackedViewComponent('Signup', trackingProps),
  chromeComponent({ type: CHROME_CLEAN_LINKS })
)(injectIntl(Signup));
