import PropTypes from 'prop-types';
import CapacitorRipple from '../capacitor/Ripple';
import { Link } from 'react-router';
import React, { Component } from 'react';
import stylesInstrument from './Instrument.css';
import RecordingItem from './RecordingItem';
import classnames from 'classnames';
import { connect } from 'react-redux';
import * as analyticsActions from '../../actions/analytics';
import { FormattedMessage } from 'react-intl';

class Instrument extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    recordings: PropTypes.array.isRequired,
    href: PropTypes.string.isRequired,
    track: PropTypes.func.isRequired,
  };

  onLinkClick = () => {
    const { track, title } = this.props;
    track('Selected View All', { subCategory: title, category: 'Instrument' });
  };

  render() {
    const { title, recordings, href } = this.props;
    const firstThreeRecordings = recordings.slice(0, 3);
    const lastThreeRecordings = recordings.slice(3);
    const classesLink = classnames('c-text-link--is-visible', stylesInstrument.link);

    return (
      <div className={stylesInstrument.container}>
        <div className={stylesInstrument.header}>
          <h2 className="fz--gamma">{title}</h2>
          <p className={`${stylesInstrument.recommendedText} fz--delta`}>
            <FormattedMessage
              id="browse.genre.recommended-works"
              defaultMessage="Recommended Works"
            />
          </p>
          <Link className={classesLink} onClick={this.onLinkClick} to={href}>
            <CapacitorRipple />
            <FormattedMessage id="browse.explore.link" defaultMessage="Explore" />
          </Link>
        </div>
        <ul className={stylesInstrument.recordingsContainer}>
          {firstThreeRecordings.map((recording, index) => (
            <RecordingItem contextTitle={title} key={index} recording={recording} />
          ))}
          {lastThreeRecordings.map((recording, index) => (
            <RecordingItem contextTitle={title} key={index} recording={recording} />
          ))}
        </ul>
      </div>
    );
  }
}

export default connect(() => ({}), {
  track: analyticsActions.track,
})(Instrument);
