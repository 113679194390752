// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ClearActiveFacetsByGroup from './ClearActiveFacetsByGroup';

type OwnProps = {
  type: string,
  pathname: string,
  query: Object,
  onClickClear: Function,
};

const ResetActiveFacetsByGroupButton = ({ type, pathname, query, onClickClear }: OwnProps) => {
  return (
    <ClearActiveFacetsByGroup
      type={type}
      pathname={pathname}
      query={query}
      title={<FormattedMessage id="filtered-list.active-facets.reset" defaultMessage="Reset" />}
      onClickClear={onClickClear}
    />
  );
};

export default ResetActiveFacetsByGroupButton;
