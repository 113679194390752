// @flow
// $FlowFixMe
import React, { useState } from 'react';
import styles from './FacetGroupTopFacets.css';
import FacetGroupDropdown from './FacetGroupDropdown';
import classNames from 'classnames';
import FacetGroupContent from './FacetGroupContent';
import FacetShowAllBtn from './FacetShowAllBtn';

type OwnProps = {
  getTriggerText: Function,
  type: string,
  results: Array<any>,
  activeFacets: Array<any>,
  count: number,
  loadAndShowAllFacets: Function,
  location: Object,
  isBoolean: boolean,
  handleMultipleComposers?: boolean,
};

type Props = OwnProps;

const FacetGroupTopFacets = ({
  getTriggerText,
  type,
  results,
  activeFacets,
  count,
  loadAndShowAllFacets,
  location,
  isBoolean,
  handleMultipleComposers,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  const getFacetSize = () => {
    switch (type) {
      case 'isExclusive':
      case 'isOriginalWork':
      case 'isCompleteWork':
      case 'recordingTypes':
      case 'genres':
      case 'epochs':
        return 'medium';
      default:
        return 'large';
    }
  };

  return (
    <FacetGroupDropdown
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      getTriggerText={getTriggerText}
      type={type}
    >
      <div className={classNames(styles.container, styles[`${getFacetSize()}`])}>
        <FacetGroupContent
          type={type}
          results={results}
          activeFacetList={activeFacets.map(({ facet }) => facet)}
          location={location}
          isBoolean={isBoolean}
          onClickFacet={() => setIsVisible(false)}
          handleMultipleComposers={handleMultipleComposers}
          shouldSortItems
        />
        <FacetShowAllBtn
          facetLength={results.length}
          loadAndShowAllFacets={loadAndShowAllFacets}
          type={type}
          count={count}
        />
      </div>
    </FacetGroupDropdown>
  );
};

export default FacetGroupTopFacets;
