import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, FormattedRelative, FormattedTime } from 'react-intl';
import * as Shapes from '../../shapes';

class LivestreamEventRelativeTime extends Component {
  static propTypes = {
    event: Shapes.LivestreamEvent,
    className: PropTypes.string,
  };

  render() {
    const { event, className } = this.props;
    const { isLive, startDate, isUpcoming } = event;

    const RelativeTime = (
      <FormattedRelative value={startDate} numeric="auto" updateIntervalInSeconds={10} />
    );

    if (isLive) {
      return (
        <span className={className}>
          <FormattedMessage
            id="livestream-events.relative-time.live-now"
            defaultMessage="Online now, started {RelativeTime}"
            values={{ RelativeTime }}
          />
        </span>
      );
    }

    const Date = <FormattedDate year="numeric" month="short" day="numeric" value={startDate} />;

    if (isUpcoming) {
      const Time = <FormattedTime value={startDate} />;
      return (
        <span className={className}>
          <FormattedMessage
            id="livestream-events.relative-time.upcoming"
            defaultMessage="Online {RelativeTime}, {Time} {Date}"
            values={{ RelativeTime, Time, Date }}
          />
        </span>
      );
    }

    return <span className={className}>{Date}</span>;
  }
}

export default LivestreamEventRelativeTime;
