// @flow
import React from 'react';
import classnames from 'classnames';

import styles from './DiscoverLivestreamEventItem.css';
import { livestreamEventStatusDecorator } from '../../selectors/livestreamEvents';
import LivestreamEventPreview from '../livestream-event/LivestreamEventPreview';

type OwnProps = {
  item: {
    content: Object,
  },
  onClick: Function,
  isInverted?: boolean,
  fullWidth?: boolean,
  isHomePage?: boolean,
  isSliderItem?: boolean,
  groupId?: string,
};

type Props = OwnProps;

export const getLivestreamEventInfoFromDiscoverGroupItemContent = (content: Object): Object => {
  // Discover will send `startDate` instead of `start` (on event endpoints)
  content.start = content.startDate;
  return livestreamEventStatusDecorator(content);
};

const DiscoverLivestreamEventItem = ({
  item,
  onClick,
  isInverted,
  fullWidth,
  isHomePage,
  isSliderItem,
  groupId,
}: Props) => {
  const { content } = item;
  const event = getLivestreamEventInfoFromDiscoverGroupItemContent(content);
  return (
    <div className={classnames(styles.container, { [styles.fullWidth]: fullWidth })}>
      <LivestreamEventPreview
        event={event}
        onClickLink={() => onClick(item, groupId)}
        isInverted={isInverted}
        shouldShowDetails={isHomePage}
        isSliderItem={isSliderItem}
      />
    </div>
  );
};

export default DiscoverLivestreamEventItem;
