// @flow
// $FlowFixMe
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingIndicator from '../common/LoadingIndicator';
import styles from './SectionItem.css';
import SectionItem from './SectionItem';
import classNames from 'classnames';

type OwnProps = {
  firstName: string | null,
  onChangeFirstName: Function,
};

type Props = OwnProps;

const FirstName = ({ firstName, onChangeFirstName }: Props) => {
  const [inEditMode, setInEditMode] = useState(false);
  const [newFirstName, setNewFirstName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setNewFirstName(firstName);
  }, [firstName]);

  const onSubmit = async event => {
    event.preventDefault();

    try {
      setIsLoading(true);

      await onChangeFirstName(newFirstName);
      setInEditMode(false);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <SectionItem
      title={<FormattedMessage id="settings.account.firstname.title" defaultMessage="Username:" />}
    >
      {inEditMode ? (
        <form onSubmit={onSubmit} noValidate method="POST" className={styles.form}>
          <div className="c-form-field">
            <input
              type="text"
              id="firstname"
              name="firstname"
              disabled={isLoading}
              className="c-form-field__input"
              value={newFirstName}
              onChange={e => setNewFirstName(e.target.value)}
              data-test="account.change-firstname.firstname-input"
            />
          </div>
          <div className={styles.btns}>
            <button
              className="c-btn c-btn--is-purple"
              data-test="account.change-firstname.submit-btn"
              type="submit"
              disabled={isLoading}
            >
              <LoadingIndicator isLoading={isLoading} className="hideText" />
              <FormattedMessage id="settings.account.save-new-username.cta" defaultMessage="Save" />
            </button>
            <button
              className="c-btn"
              data-test="account.change-firstname.cancel-btn"
              onClick={() => {
                setNewFirstName(firstName);
                setInEditMode(false);
              }}
              disabled={isLoading}
            >
              <FormattedMessage
                id="settings.account.cancel-new-username.cta"
                defaultMessage="Cancel"
              />
            </button>
          </div>
        </form>
      ) : (
        <React.Fragment>
          <span className={styles.value} data-test="account.change-firstname.firstname">
            {firstName || '-'}
          </span>
          <div className={classNames(styles.action, styles.btns)}>
            <button
              className={classNames('c-btn', styles.primaryBtn)}
              onClick={() => setInEditMode(true)}
              data-test="account.change-firstname.btn"
            >
              <FormattedMessage
                id="settings.account.change-firstname.cta"
                defaultMessage="Change Username"
              />
            </button>
          </div>
        </React.Fragment>
      )}
    </SectionItem>
  );
};

export default FirstName;
