// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { selectLocale, selectSupportedLanguages } from '../../selectors/client';

import styles from './Footer.css';
import { Link, withRouter } from 'react-router';
import AccountLogin from '../messages/AccountLogin';
import { getWebsiteUrlInLocale } from '../../lib/locale-utils';

import type { SupportedLanguage } from '../../shapes/types';
import type { IntlShape } from 'react-intl';
import { CONSTANT_MESSAGES } from '../../constants';
import { messages } from '../messages/Footer';

type OwnProps = {
  noLangSwitch?: boolean,
};

type MapStateToProps = {
  currentLocale: string,
  supportedLanguages: Array<SupportedLanguage>,
};

type Props = OwnProps & MapStateToProps & { intl: IntlShape };

const Footer = ({ noLangSwitch, currentLocale, supportedLanguages, intl }: Props) => {
  const LanguageLink = () => {
    const links = supportedLanguages
      .filter(lang => lang.preferredLocale.indexOf(currentLocale) === -1)
      .map(({ preferredLocale, title }) => {
        const href = `/change-locale?locale=${preferredLocale}&oldLocale=${currentLocale}`;
        return (
          <a key={preferredLocale} className={styles.footerLink} href={href}>
            <span>{title}</span>
          </a>
        );
      });

    return <React.Fragment>{links}</React.Fragment>;
  };

  const imprintText = intl.formatMessage(messages.imprintLink);
  const ariaImprintLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: imprintText,
  });

  const termsText = intl.formatMessage(messages.termsLink);
  const ariaTermsLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: termsText,
  });

  const privacyText = intl.formatMessage(messages.privacyLink);
  const ariaPrivacyLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: privacyText,
  });

  return (
    <footer>
      <div className={styles.footerLinksContainer}>
        <Link to="/login" className={styles.footerLink}>
          <AccountLogin />
        </Link>
        <a
          className={styles.footerLink}
          href={getWebsiteUrlInLocale('/imprint', currentLocale)}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={ariaImprintLink}
        >
          {imprintText}
        </a>
        <a
          className={styles.footerLink}
          href={getWebsiteUrlInLocale('/terms', currentLocale)}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={ariaTermsLink}
        >
          {termsText}
        </a>
        <a
          className={styles.footerLink}
          href={getWebsiteUrlInLocale('/privacy', currentLocale)}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={ariaPrivacyLink}
        >
          {privacyText}
        </a>
        {!noLangSwitch && <LanguageLink />}
        <div className={styles.cookiePolicy}>
          <FormattedMessage
            id="front-page.footer.cookie-policy"
            defaultMessage="By using idagio.com, you agree to our Cookie Policy."
          />
        </div>
      </div>
      <div className={styles.footerImprint}>
        <span>&copy; IDAGIO {new Date().getFullYear()}</span>
        <div className={styles.flag} />
        <span>MADE IN BERLIN</span>
      </div>
    </footer>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    currentLocale: selectLocale(state),
    supportedLanguages: selectSupportedLanguages(state),
  };
}

export default compose(connect(mapStateToProps, {}), injectIntl, withRouter)(Footer);
