import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router';

import * as Shapes from '../../shapes';

import { ENTITY_TYPE_LIVESTREAM_EVENT } from '../../constants';

import CollectionButton from '../common/CollectionButton';
import ShareButton from '../common/ShareButton';
import CapacitorHeaderBar from '../capacitor/HeaderBar';

import { getEntityUrl } from '../../lib/entities';

import styles from './LivestreamEventHeader.css';
import collectibleEntity, { collectibleEntityPropTypes } from '../../hoc/collectibleEntity';

class LivestreamEventHeader extends Component {
  static propTypes = {
    event: Shapes.LivestreamEvent,
    showShareModal: PropTypes.func,
    onClick: PropTypes.func,

    ...collectibleEntityPropTypes,
  };

  renderTitle = () => {
    const { event } = this.props;
    const eventUrl = getEntityUrl(ENTITY_TYPE_LIVESTREAM_EVENT, event);
    const title = (
      <h1 className="fz--beta" data-test="livestream-event-header.title">
        {event.title}
      </h1>
    );

    if (this.props.onClick) {
      return (
        <Link
          to={eventUrl}
          onlyActiveOnIndex={false}
          onClick={this.onClick}
          className={styles.link}
        >
          {title}
        </Link>
      );
    }

    return title;
  };

  render() {
    const { event, showShareModal, isInCollection, toggleIsInCollection } = this.props;
    const { isLive, isAvailable, isUpcoming } = event;

    if (__CAPACITOR__) {
      return (
        <CapacitorHeaderBar
          isInCollection={isInCollection}
          toggleIsInCollection={toggleIsInCollection}
          showShareModal={showShareModal}
        />
      );
    }
    return (
      <React.Fragment>
        <div className={styles.btns} data-test="livestream-event-header">
          <CollectionButton
            active={isInCollection}
            onClick={toggleIsInCollection}
            disabled={!isLive && !isAvailable && !isUpcoming}
          />
          <ShareButton onClick={showShareModal} />
        </div>
        {this.renderTitle()}
      </React.Fragment>
    );
  }
}

function getCollectibleEntityDescription(props) {
  return {
    id: props.event.id,
    trackingSource: 'Livestream Event',
  };
}

export default collectibleEntity(
  ENTITY_TYPE_LIVESTREAM_EVENT,
  getCollectibleEntityDescription
)(LivestreamEventHeader);
