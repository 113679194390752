import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';

import ShareButton from '../common/ShareButton';
import AddToPlaylistButton from '../common/AddToPlaylistButton';
import ContextMenu from '../util/ContextMenu';
import CapacitorRipple from '../capacitor/Ripple';
import FormattedTime from '../util/FormattedTime';
import CollectionButton from '../common/CollectionButton';
import IconLabel from './../util/IconLabel';
import TitleWithPopularTitle from '../common/TitleWithPopularTitle';
import DownloadIndicator from '../capacitor/DownloadIndicator';
import { selectTrackIsDownloading, selectTrackIsDownloaded } from '../../selectors/download';

import shareableEntity, { shareableEntityPropTypes } from '../../hoc/shareableEntity';
import collectibleEntity, { collectibleEntityPropTypes } from '../../hoc/collectibleEntity';
import { ENTITY_TYPE_TRACK } from '../../constants';

import * as Shapes from '../../shapes/index';

import styles from './RecordingTrackListItem.css';
import AddToPlaylistContextMenuItem from '../common/AddToPlaylistContextMenuItem';
import ShareContextMenuItem from '../common/ShareContextMenuItem';

class RecordingTrackListItem extends PureComponent {
  static propTypes = {
    track: Shapes.Track.isRequired,
    index: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    isCurrentTrack: PropTypes.bool,
    onCollectionButtonClick: PropTypes.func,
    trackIsInCollection: PropTypes.bool,
    onShareButtonClick: PropTypes.func,
    isPlaying: PropTypes.bool.isRequired,
    onAddToPlaylistButtonClick: PropTypes.func.isRequired,
    trackIsDownloaded: PropTypes.bool,
    trackIsDownloading: PropTypes.bool,

    ...shareableEntityPropTypes,
    ...collectibleEntityPropTypes,
  };

  onClick = () => {
    const { isCurrentTrack, track, index, isPlaying } = this.props;

    if (isCurrentTrack && isPlaying) {
      return;
    }

    this.props.onClick(track.id, index);
  };

  onAddToPlaylistClick = () => {
    this.props.onAddToPlaylistButtonClick(this.props.track.id);
  };

  render() {
    const {
      track,
      duration,
      isCurrentTrack,
      isInCollection,
      showShareModal,
      toggleIsInCollection,
      isPlaying,
    } = this.props;

    const listItemClassNames = classnames(styles.component, {
      [styles.isPlaying]: isCurrentTrack && isPlaying,
    });

    return (
      <li className={listItemClassNames}>
        <button
          onClick={this.onClick}
          className={`dummy-btn ${styles.playBtn}`}
          aria-label={track.piece.title}
        >
          <CapacitorRipple />
        </button>
        <div className={styles.info}>
          <IconLabel name="play" size="default" className={styles.iconPlay} />
          <IconLabel name="playing" size="default" className={styles.iconPlaying} />
          <span className={styles.name}>
            {__CAPACITOR__ && (
              <DownloadIndicator
                trackIsDownloaded={this.props.trackIsDownloaded}
                trackIsDownloading={this.props.trackIsDownloading}
                className={styles.downloadIndicator}
              />
            )}
            <TitleWithPopularTitle {...track.piece} />
          </span>
        </div>
        <span className={styles.durationIndicator}>
          <FormattedTime>{duration}</FormattedTime>
        </span>
        <CollectionButton
          onClick={toggleIsInCollection}
          active={isInCollection}
          className={styles.otherBtn}
        />

        {__CAPACITOR__ ? (
          <ContextMenu>
            <AddToPlaylistContextMenuItem onClick={this.onAddToPlaylistClick} />
            <ShareContextMenuItem onClick={showShareModal} />
          </ContextMenu>
        ) : (
          <React.Fragment>
            <AddToPlaylistButton onClick={this.onAddToPlaylistClick} className={styles.otherBtn} />
            <ShareButton onClick={showShareModal} className={styles.otherBtn} />
          </React.Fragment>
        )}
      </li>
    );
  }
}

function getShareUrl(props) {
  return `/recordings/${props.recordingId}?trackId=${props.track.id}`;
}

function getShareTrackingContext(props) {
  return {
    sharedContentType: 'Track',
    sharedContentId: props.track.id,
    contextType: 'Recording',
    contentId: props.recordingId,
  };
}

function getCollectibleEntityDescription(props) {
  return {
    id: props.track.id,
    trackingSource: 'Recording',
    capacitorContextEntityType: 'recording',
    capacitorContextEntityId: props.recordingId,
  };
}

export default compose(
  connect((state, props) => {
    if (!__CAPACITOR__) {
      return {};
    }
    return {
      trackIsDownloading: selectTrackIsDownloading(state, props.recordingId, props.track.id),
      trackIsDownloaded: selectTrackIsDownloaded(state, props.recordingId, props.track.id),
    };
  }),
  shareableEntity(ENTITY_TYPE_TRACK, getShareTrackingContext, { getShareUrl }),
  collectibleEntity(ENTITY_TYPE_TRACK, getCollectibleEntityDescription)
)(RecordingTrackListItem);
