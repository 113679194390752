import React, { Component } from 'react';
import BrowseElementList from './BrowseElementList';
import BrowseListContainer from './BrowseListContainer';
import { compose } from 'redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import composers from './data/composers';

const messages = defineMessages({
  popularComposers: {
    id: 'browse.composers.popular',
    defaultMessage: 'Popular Composers',
  },
  moreComposers: {
    id: 'browse.composers.more',
    defaultMessage: 'More Composers',
  },
});

class BrowseComposers extends Component {
  static propTypes = {
    intl: intlShape,
  };

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;

    const popular = formatMessage(messages.popularComposers);
    const unpopular = formatMessage(messages.moreComposers);
    return (
      <React.Fragment>
        <BrowseListContainer title={popular} link="/composers" category="Composers">
          <BrowseElementList items={composers.popular} />
        </BrowseListContainer>
        <BrowseListContainer title={unpopular} link="/composers" category="Composers">
          <BrowseElementList items={composers.unpopular} />
        </BrowseListContainer>
      </React.Fragment>
    );
  }
}

export default compose(injectIntl)(BrowseComposers);
