import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { Component } from 'react';
import { includes, find } from 'lodash';
import IconLabel from './IconLabel';

import CapacitorRipple from '../capacitor/Ripple';
import styles from './Select.css';
import * as KEYS from '../../lib/key-codes';
import SelectOption from './SelectOption';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'select.placeholder',
    defaultMessage: 'Please select {placeholderTitle}',
  },
});

class Select extends Component {
  static propTypes = {
    placeholderTitle: PropTypes.string,
    optionsDescriptor: PropTypes.string,
    defaultOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.boolean]),
    currentOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.boolean]),
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelect: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    className: PropTypes.string,
    intl: intlShape.isRequired,
  };

  state = {
    isOpen: false,
    selectedIndex: -1,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  onKeyDown = event => {
    const { isOpen, selectedIndex } = this.state;
    const { options } = this.props;

    switch (event.keyCode) {
      case KEYS.TAB:
        if (isOpen) {
          event.preventDefault();
        }
        break;
      case KEYS.ESC:
        event.preventDefault();
        if (isOpen) {
          this.setState({
            isOpen: false,
            selectedIndex: -1,
          });
        }
        break;
      case KEYS.DOWN:
        event.preventDefault();
        if (selectedIndex === options.length - 1) {
          this.setState({ isOpen: true });
          break;
        }
        if (!isOpen) {
          this.setState({ isOpen: true }, () => {
            this.setSelectedIndex(selectedIndex + 1);
          });
        } else {
          this.setSelectedIndex(selectedIndex + 1);
        }
        break;
      case KEYS.UP:
        event.preventDefault();
        if (selectedIndex === 0) {
          this.setState({ isOpen: true });
          break;
        }
        if (!isOpen) {
          this.setState({ isOpen: true }, () => {
            this.setSelectedIndex(selectedIndex - 1);
          });
        } else {
          this.setSelectedIndex(selectedIndex - 1);
        }
        break;
      case KEYS.SPACE:
        event.preventDefault();
        if (!isOpen) {
          this.setState({ isOpen: true });
        }
        break;
      case KEYS.ENTER:
        if (isOpen) {
          event.preventDefault();
          const option = options[selectedIndex];
          this.handleSelect(option.value);
        }
        break;
      default:
    }
  };

  getDisplayedOption() {
    const { options, defaultOptionValue, currentOptionValue, intl, placeholderTitle } = this.props;
    const correspondingOption = this.currentOptionIsValid()
      ? currentOptionValue
      : defaultOptionValue;
    const optionToDisplay = find(options, option => option.value === correspondingOption);
    if (optionToDisplay && optionToDisplay.text) {
      return optionToDisplay.text;
    }
    return intl.formatMessage(messages.placeholder, { placeholderTitle });
  }

  renderOption = ({ text, value, allowed, disabled }, index) => {
    const tabIndex = this.state.selectedIndex === index ? 0 : -1;

    return (
      <SelectOption
        key={value}
        onClick={this.handleSelect}
        className={classnames(styles.option, {
          [styles.notAllowed]: allowed === false || disabled,
          [styles.selected]: this.state.selectedIndex === index,
        })}
        value={value}
        tabIndex={tabIndex}
        ariaLabel={text}
        disabled={disabled}
      >
        {text}
      </SelectOption>
    );
  };

  renderOptionList(options) {
    return (
      <div className={styles.options} data-test="select.options">
        <ul data-captures-keydown>{options && options.map(this.renderOption)}</ul>
      </div>
    );
  }

  render() {
    const { optionsDescriptor, options, className } = this.props;
    const { isOpen } = this.state;
    const text = (optionsDescriptor ? optionsDescriptor + ': ' : '') + this.getDisplayedOption();
    return (
      <div
        tabIndex={0}
        onKeyDown={this.props.isDisabled ? undefined : this.onKeyDown}
        data-captures-keydown
        className={classnames(styles.select, className, {
          [styles.isDisabled]: this.props.isDisabled,
        })}
        ref={node => {
          this.node = node;
        }}
      >
        <button
          onClick={this.props.isDisabled ? undefined : this.toggleSelectIsOpen}
          className="dummy-btn"
          aria-label={text}
          data-test="select.trigger"
          aria-disabled={this.props.isDisabled}
        >
          <CapacitorRipple />
        </button>
        <div className={classnames(styles.text, 'c-text--is-truncated')} data-test="select.text">
          {text}
        </div>
        <IconLabel name="chevron-down" className={styles.icon} />
        {isOpen ? this.renderOptionList(options) : null}
      </div>
    );
  }

  currentOptionIsValid() {
    return includes(
      this.props.options.map(option => option.value),
      this.props.currentOptionValue
    );
  }

  focusOption = () => {
    const { selectedIndex } = this.state;

    const options = this.node.querySelectorAll('li');
    options[selectedIndex].focus();
  };

  handleClickOutside = e => {
    if (!this.node.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  };

  handleSelect = option => {
    if (option === this.props.currentOptionValue) return;

    this.setState(
      {
        isOpen: !this.state.isOpen,
      },
      () => {
        this.props.onSelect(option);
        this.setState({ selectedIndex: -1 });
      }
    );
  };

  setSelectedIndex = selectedIndex => {
    let index = selectedIndex;
    if (index < 0) {
      index = 0;
    } else if (index > this.props.options.length - 1) {
      index = this.props.options.length - 1;
    }
    this.setState({ selectedIndex: index }, this.focusOption);
  };

  toggleSelectIsOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
}

export default injectIntl(Select);
