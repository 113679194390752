import { QUEUE_TYPE_RADIO } from '../constants';

import { selectPlayerIsPlaying } from './player';
import { selectIsQueueOriginSetAs } from './queue';

export function getQueueOrigin(slug) {
  return {
    type: QUEUE_TYPE_RADIO,
    id: slug,
  };
}

export function selectRadioIsQueued(state, slug) {
  const queueOrigin = getQueueOrigin(slug);
  return selectIsQueueOriginSetAs(state, queueOrigin);
}

export function selectRadioIsPlaying(state, slug) {
  const isQueued = selectRadioIsQueued(state, slug);
  const playerIsPlaying = selectPlayerIsPlaying(state);
  return isQueued && playerIsPlaying;
}

export const selectRadio = state => {
  return state.lists.radios.entities;
};
