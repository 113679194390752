// @flow
import React from 'react';
import SectionItem from './SectionItem';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styles from './SectionItem.css';
import {
  selectSubscriptionEndDate,
  selectSubscriptionEnds,
  selectSubscriptionIsCanceled,
  selectUserIsTrial,
} from '../../selectors/subscription';
import { connect } from 'react-redux';
import FeedbackForm from './FeedbackForm';
import classNames from 'classnames';

type OwnProps = {
  onSendFeedback: Function,
  cancelFeedbackIsOpen: boolean,
  onRejoinPremium: Function,
};

type MapStateToProps = {
  subscriptionEndDate?: Date,
  subscriptionEnds: boolean,
  isTrial: boolean,
  subscriptionCanceled: boolean,
};

type Props = OwnProps & MapStateToProps;

const SubscriptionEnd = ({
  subscriptionEndDate,
  subscriptionEnds,
  isTrial,
  subscriptionCanceled,
  cancelFeedbackIsOpen,
  onSendFeedback,
  onRejoinPremium,
}: Props) => {
  const shouldRender = subscriptionEnds && (!isTrial || subscriptionCanceled);
  if (!shouldRender) {
    return null;
  }

  return (
    <SectionItem
      title={
        <FormattedMessage id="settings.account.subscription.end.title" defaultMessage="End date:" />
      }
    >
      <span className={styles.value}>
        {subscriptionEndDate && (
          <FormattedDate year="numeric" month="2-digit" day="2-digit" value={subscriptionEndDate} />
        )}
      </span>
      {cancelFeedbackIsOpen ? (
        <FeedbackForm onSendFeedback={onSendFeedback} />
      ) : (
        subscriptionCanceled && (
          <div className={classNames(styles.action, styles.support)}>
            <div>
              <FormattedMessage
                id="settings.subs.cancel-subscription.cancelled"
                defaultMessage="Your subscription was successfully cancelled."
              />
            </div>
            <div>
              <FormattedMessage
                id="settings.subs.cancel-subscription.rejoin"
                defaultMessage="Made a mistake? {rejoinLink}"
                values={{
                  rejoinLink: (
                    <button className="c-text-link--is-visible" onClick={onRejoinPremium}>
                      <FormattedMessage
                        id="settings.subs.cancel-subscription.rejoinLink"
                        defaultMessage="Undo cancellation"
                      />
                    </button>
                  ),
                }}
              />
            </div>
          </div>
        )
      )}
    </SectionItem>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    subscriptionEndDate: selectSubscriptionEndDate(state),
    subscriptionEnds: selectSubscriptionEnds(state),
    isTrial: selectUserIsTrial(state),
    subscriptionCanceled: selectSubscriptionIsCanceled(state),
  };
}

export default connect(mapStateToProps)(SubscriptionEnd);
