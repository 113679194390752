// @flow

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PaginatedCollection from './PaginatedCollection';

import * as collectionActions from '../../actions/collection';

import {
  selectArtistCollection,
  selectCollectionIds,
  selectNextArtistCollectionCursor,
} from '../../selectors/collection';

import stylesPlaylistCollection from './PlaylistCollection.css';
import EmptyCollection from './EmptyCollection';
import ArtistCollectionElement from './ArtistCollectionElement';
import dataComponent from '../../hoc/dataComponent';
import CollectionArtists from '../../components/messages/CollectionArtists';

import { ENTITY_TYPE_ARTIST, ENTITY_TYPE_ARTISTS } from '../../constants';
import protectedComponent from '../../hoc/protectedComponent';
import type { Person } from '../../shapes/types';
import shareableEntity from '../../hoc/shareableEntity';

type OwnProps = {
  showShareModal: Function,
};

type MapStateToProps = {
  paginatedArtists: Array<Person>,
  artistIds: Array<string>,
  nextCursor: string,
  isLoading: boolean,
};

type DispatchProps = {
  loadArtistCollection: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const ArtistCollection = ({
  paginatedArtists,
  artistIds,
  loadArtistCollection,
  nextCursor,
  isLoading,
  showShareModal,
}: Props) => {
  const ArtistItem = ({ id, name }: Person) => {
    return (
      <li key={id}>
        <ArtistCollectionElement id={id} name={name} />
      </li>
    );
  };

  const loadMore = () => {
    loadArtistCollection(nextCursor);
  };

  if (!artistIds.length) {
    return <EmptyCollection entityType={ENTITY_TYPE_ARTIST} showShareModal={showShareModal} />;
  }

  return (
    <div data-test="artist-collection.container">
      <PaginatedCollection
        loadMore={loadMore}
        hasMore={!!nextCursor}
        items={paginatedArtists}
        renderItem={ArtistItem}
        isLoading={isLoading}
        styles={stylesPlaylistCollection.list}
        title={<CollectionArtists />}
        showShareModal={showShareModal}
      />
    </div>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    isLoading: state.lists.collection.playlists.loading,
    artistIds: selectCollectionIds(state).entities.artistIds,
    paginatedArtists: selectArtistCollection(state),
    nextCursor: selectNextArtistCollectionCursor(state),
  };
}

const dispatchProps: DispatchProps = {
  loadArtistCollection: collectionActions.loadArtistCollection,
};

function fetchData(store) {
  return store.dispatch(collectionActions.loadArtistCollection());
}

function getShareTrackingContext() {
  return {
    sharedContentType: 'Artist',
    sharedContentId: null,
    contextType: 'favouriteArtists',
    contentId: null,
  };
}

export default compose(
  protectedComponent({ authenticatedOnly: true }),
  dataComponent(fetchData),
  connect(mapStateToProps, dispatchProps),
  shareableEntity(ENTITY_TYPE_ARTISTS, getShareTrackingContext)
)(ArtistCollection);
