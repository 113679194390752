export function normalizeProfile(response) {
  return response.result;
}

function normalizeProfileAboutItems(items, type) {
  return (
    items &&
    items.map(item => {
      const normalizedItem = {
        content: {
          ...item,
          type,
        },
        imageUrl: item.imageUrl,
        title: item.title,
      };

      if (item.subtitle) {
        normalizedItem.subtitle = item.subtitle;
      }

      if (item.start) {
        normalizedItem.content.startDate = item.start;
      }

      return normalizedItem;
    })
  );
}

function normalizeProfileAboutAlbums(albums) {
  return normalizeProfileAboutItems(albums, 'album');
}

function normalizeProfileAboutPlaylists(playlists) {
  return normalizeProfileAboutItems(playlists, 'playlist');
}

function normalizeProfileAboutConcerts(concerts) {
  return normalizeProfileAboutItems(concerts, 'concert');
}

export function normalizeProfileAboutData(response, id) {
  const { recommendedAlbums, latestReleases, featuredOn, concerts } = response.result;
  return {
    ...response.result,
    id,
    recommendedAlbums: normalizeProfileAboutAlbums(recommendedAlbums),
    latestReleases: normalizeProfileAboutAlbums(latestReleases),
    featuredOn: normalizeProfileAboutPlaylists(featuredOn),
    concerts: normalizeProfileAboutConcerts(concerts),
  };
}
