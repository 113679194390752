import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.css';

import CircularSkipButton from '../CircularSkipButton';
import CircularPlayButton from '../CircularPlayButton';

export default class PlaybackSection extends React.PureComponent {
  static propTypes = {
    isPlaying: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSwitching: PropTypes.bool.isRequired,
    onPlayPausePressed: PropTypes.func.isRequired,
    onForwardPressed: PropTypes.func.isRequired,
    onBackPressed: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className={styles.Section}>
        <CircularSkipButton
          dark
          direction={'back'}
          className={styles.Button}
          onClick={this.props.onBackPressed}
        />
        <CircularPlayButton
          dark
          playing={this.props.isPlaying}
          loading={this.props.isLoading || this.props.isSwitching}
          className={styles.Button}
          onClick={this.props.onPlayPausePressed}
        />
        <CircularSkipButton
          dark
          direction={'forward'}
          className={styles.Button}
          onClick={this.props.onForwardPressed}
        />
      </div>
    );
  }
}
