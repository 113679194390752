// @flow
import { loaded } from '../middleware/apiCacheValidators';
import { Profile } from '../schema';
import { normalizeProfile, normalizeProfileAboutData } from '../schema/normalizeProfile';
import { selectArtistIdFromSlug } from '../selectors/profile';

import type { Request } from './types';

export type LoadProfileAction = { type: 'FETCH_PROFILE' } & Request;
export type LoadProfileAboutAction = { type: 'FETCH_PROFILE_ABOUT' } & Request;

export type ProfileAction = LoadProfileAction;
export type ProfileAboutAction = LoadProfileAboutAction;

export function loadProfile(slug: string | number): LoadProfileAction {
  return {
    type: 'FETCH_PROFILE',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/artists.v3/{slug}',
      params: {
        slug,
        banner: true,
      },
    },
    meta: {
      schema: Profile,
      normalize: normalizeProfile,
    },
    cache: {
      fetch: state => state.entities.profiles[selectArtistIdFromSlug(state, slug)],
      validate: loaded,
    },
  };
}

export function loadProfileAbout(id: string | number): LoadProfileAboutAction {
  return {
    type: 'FETCH_PROFILE_ABOUT',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/artists.v3/{id}/about.v1',
      params: {
        id,
      },
    },
    meta: {
      schema: Profile,
      normalize: response => normalizeProfileAboutData(response, id),
    },
  };
}
