// @flow
// $FlowFixMe
import React, { useRef, useState } from 'react';
import { shuffle } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import styles from './SectionItem.css';
import classNames from 'classnames';
import Select from '../util/Select';

const messages = defineMessages({
  placeholderTitle: {
    id: 'settings.subs.cancel-subscription.feedback-form.title',
    defaultMessage: 'a reason',
  },
});

const cancellationReasonMessages = defineMessages({
  audioQuality: {
    id: 'settings.subs.cancel-subscription.feedback-form.reason-1',
    defaultMessage: 'The audio quality is not high enough',
  },
  technicalProblems: {
    id: 'settings.subs.cancel-subscription.feedback-form.reason-2',
    defaultMessage: 'I experienced technical problems',
  },
  notEnoughRecordings: {
    id: 'settings.subs.cancel-subscription.feedback-form.reason-3',
    defaultMessage: 'There are not enough recordings',
  },
  otherServices: {
    id: 'settings.subs.cancel-subscription.feedback-form.reason-4',
    defaultMessage: 'I use other streaming services',
  },
});

const CANCELLATION_REASONS = shuffle([
  {
    value: 'AudioQuality',
    text: cancellationReasonMessages.audioQuality,
  },
  {
    value: 'TechnicalProblems',
    text: cancellationReasonMessages.technicalProblems,
  },
  {
    value: 'NotEnoughRecordings',
    text: cancellationReasonMessages.notEnoughRecordings,
  },
  {
    value: 'OtherServices',
    text: cancellationReasonMessages.otherServices,
  },
]);

type OwnProps = {
  onSendFeedback: Function,
};

type Props = OwnProps & { intl: IntlShape };

const FeedbackForm = ({ onSendFeedback, intl }: Props) => {
  const [feedbackReason, setFeedbackReason] = useState('');
  const [feedbackComment, setFeedbackComment] = useState('');
  const commentRef = useRef(null);

  const onCommentChange = event => {
    // Adjust height of textarea to display entire comment
    const el = commentRef.current;
    el.style.height = '1px';
    el.style.height = 25 + el.scrollHeight + 'px';

    setFeedbackComment(event.currentTarget.value);
  };

  const options = CANCELLATION_REASONS.map(({ text, value }) => ({
    text: intl.formatMessage(text),
    value,
  }));

  return (
    <div className={classNames(styles.support, styles.action)}>
      <div className="c-form-field">
        <p>
          <FormattedMessage
            id="settings.subs.cancel-subscription.cancelled"
            defaultMessage="Your subscription was successfully cancelled."
          />
          <br />
          <FormattedMessage
            id="settings.subs.cancel-subscription.feedback-form.reason"
            defaultMessage="We're curious to know why."
          />
        </p>
        <Select
          currentOptionValue={feedbackReason}
          options={options}
          onSelect={setFeedbackReason}
          className={styles.select}
          placeholderTitle={intl.formatMessage(messages.placeholderTitle)}
        />
      </div>

      <div className="c-form-field c-textarea">
        <p className="c-textarea__label fz--delta">
          <FormattedMessage
            id="settings.subs.cancel-subscription.feedback-form.comments-label"
            defaultMessage="Anything else we should know?"
          />
        </p>
        <textarea
          ref={commentRef}
          className="c-textarea__textarea"
          onChange={onCommentChange}
          value={feedbackComment}
        />
      </div>
      <div className={styles.btns}>
        <button className="c-btn" onClick={e => onSendFeedback(e, feedbackReason, feedbackComment)}>
          <FormattedMessage
            id="settings.subs.cancel-subscription.feedback-form.cta"
            defaultMessage="Send feedback"
          />
        </button>
      </div>
    </div>
  );
};

export default injectIntl(FeedbackForm);
