import React, { Component } from 'react';
import { defineMessages, intlShape, injectIntl } from 'react-intl';

import NotFoundText from '../components/messages/NotFoundText';

const messages = defineMessages({
  notFound: {
    id: 'error.not-found.title',
    defaultMessage: 'Not found',
  },
});

class NotFound extends Component {
  static propTypes = {
    intl: intlShape,
  };

  render() {
    const { intl } = this.props;
    const notFound = intl.formatMessage(messages.notFound);

    return (
      <div className="u-page-container" role="main" aria-label={notFound}>
        <h1>{notFound}</h1>
        <p>
          <NotFoundText />
        </p>
      </div>
    );
  }
}

export default injectIntl(NotFound);
