import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import chromeComponent from '../hoc/chromeComponent';
import { CHROME_CLEAN_LINKS } from '../constants';

import Head from '../components/chrome/Head';

import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { selectCurrentClientDesktopDownloadUrl, selectLocale } from '../selectors/client';
import * as analyticsActions from '../actions/analytics';

import styles from './DownloadDesktopInstall.css';

import Footer from '../components/common/Footer';

export const messages = defineMessages({
  metaTitle: {
    id: 'front-page.meta.title',
    defaultMessage: 'Discover Classical Music',
  },
  metaDescription: {
    id: 'front-page.meta.description',
    defaultMessage:
      'Quickly find and play classical music from our curated repertoire, including recordings from classical masters and contemporary artists. Browse by composer, performer, instrument, genre or epoch',
  },
});

class DownloadDesktopInstall extends Component {
  static propTypes = {
    intl: intlShape,
    analyticsTrack: PropTypes.func.isRequired,
    desktopDownloadUrl: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { desktopDownloadUrl, analyticsTrack } = this.props;
    analyticsTrack('Started Downloading Desktop App');
    document.location = desktopDownloadUrl;
  }

  onTryAgain = () => {
    const { desktopDownloadUrl, analyticsTrack } = this.props;
    analyticsTrack('Started Downloading Desktop App');
    analyticsTrack('Clicked Download Desktop App Try Again');
    document.location = desktopDownloadUrl;
  };

  render() {
    const { intl } = this.props;

    return (
      <div>
        <Head
          title={intl.formatMessage(messages.metaTitle)}
          description={intl.formatMessage(messages.metaDescription)}
        />
        <section className={styles.section}>
          <div className={styles.content}>
            <h1 className={styles.headline}>
              <FormattedMessage
                id="landing.install"
                defaultMessage="Click the install file to complete your download"
              />
            </h1>
            <p className={styles.subline}>
              <FormattedMessage
                id="landing.install-paragraph"
                defaultMessage="If your download didn’t start,"
              />
              &nbsp;
              <a
                className={styles.link}
                href={this.props.desktopDownloadUrl}
                onClick={this.onTryAgain}
              >
                <FormattedMessage id="landing.install-link" defaultMessage="try again" />
              </a>
            </p>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locale: selectLocale(state),
    desktopDownloadUrl: selectCurrentClientDesktopDownloadUrl(state),
  };
}

export default compose(
  chromeComponent({ type: CHROME_CLEAN_LINKS }),
  connect(mapStateToProps, {
    trackPlayPausePressed: analyticsActions.trackPlayPausePressed,
    analyticsTrack: analyticsActions.track,
  }),
  injectIntl
)(DownloadDesktopInstall);
