import { defineMessages } from 'react-intl';
import { ENTITY_TYPE_ALBUM, ENTITY_TYPE_PLAYLIST, ENTITY_TYPE_MIX } from '../../constants';

export const thisMessages = defineMessages({
  [ENTITY_TYPE_ALBUM]: {
    id: 'booklet-previews-modal.this-album',
    defaultMessage: 'this album',
  },
  [ENTITY_TYPE_PLAYLIST]: {
    id: 'booklet-previews-modal.this-playlist',
    defaultMessage: 'this playlist',
  },
  [ENTITY_TYPE_MIX]: {
    id: 'booklet-previews-modal.this-mix',
    defaultMessage: 'this mix',
  },
});
