// @flow
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import CapacitorRipple from '../capacitor/Ripple';
import React from 'react';
import type { ChildrenArray, Element } from 'react';
import styles from './ContextMenu.css';

type OwnProps = {
  'onClick'?: (event: SyntheticEvent<HTMLButtonElement>) => void,
  'children'?: ChildrenArray<Element<any>>,
  'data-test'?: string,
  'keepOpenAfterClick'?: boolean,
};

const ContextMenuItem = ({
  children,
  onClick,
  keepOpenAfterClick,
  'data-test': dataTest,
}: OwnProps) => {
  const handleSelect = (event: SyntheticEvent<HTMLButtonElement>) => {
    if (keepOpenAfterClick) {
      event.preventDefault();
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <DropdownMenu.Item
      data-test={dataTest}
      onSelect={handleSelect}
      className={styles.contextMenuButton}
    >
      <CapacitorRipple />
      <div className={styles.children}>{children}</div>
    </DropdownMenu.Item>
  );
};

export default ContextMenuItem;
