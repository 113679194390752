import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import { Link } from 'react-router';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import CapacitorHeaderBar from '../components/capacitor/HeaderBar';
import { loadEnsembles } from '../actions/ensemble';
import { selectEnsembles } from '../selectors/categories';
import CapacitorRipple from '../components/capacitor/Ripple';
import styles from './GenericList.css';

import {
  selectEnsemblesIsLoading,
  selectNextEnsemblesCursor,
  selectPrevEnsemblesCursor,
} from '../selectors/ensemble';

import * as Shapes from '../shapes';

import Head from '../components/chrome/Head';
import List from '../components/util/List';
import PaginatedList from '../components/util/PaginatedList';
import shareableEntity, { shareableEntityPropTypes } from '../hoc/shareableEntity';
import { ENTITY_TYPE_ARTISTS } from '../constants';

const messages = defineMessages({
  metaTitle: {
    id: 'ensembles.meta.title',
    defaultMessage: 'Ensembles',
  },
  metaDescription: {
    id: 'ensembles.meta.description',
    defaultMessage:
      'Find and play classical music recordings by hundreds of ensembles. Compare alternative recordings and browse by composer.',
  },
});

class Ensembles extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(Shapes.Ensemble).isRequired,
    intl: intlShape,
    currentCursor: PropTypes.string,
    nextCursor: PropTypes.string,
    prevCursor: PropTypes.string,
    loadEnsembles: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,

    ...shareableEntityPropTypes,
  };

  renderItem(ensemble) {
    return (
      <li key={ensemble.id}>
        <Link to={`/profiles/${ensemble.id}`} className={styles.link}>
          <CapacitorRipple />
          {ensemble.name}
        </Link>
      </li>
    );
  }

  render() {
    const { items, intl, currentCursor, nextCursor, prevCursor, isLoading, location } = this.props;
    const { formatMessage } = intl;
    const title = formatMessage(messages.metaTitle);
    return (
      <div className="u-page-container">
        {__CAPACITOR__ && (
          <CapacitorHeaderBar
            showShareModal={this.props.showShareModal}
            title={title}
            onlyShowTitleOnScroll
            showSearch
          />
        )}
        <Head
          title={title}
          description={formatMessage(messages.metaDescription)}
          currentCursor={currentCursor}
          nextCursor={nextCursor}
          prevCursor={prevCursor}
        />
        <h1 className="fz--beta">{title}</h1>
        <PaginatedList
          isLoading={isLoading}
          currentCursor={currentCursor}
          nextCursor={nextCursor}
          prevCursor={prevCursor}
          location={location}
          loadMore={this.loadMore}
        >
          <List items={items} renderItem={this.renderItem} />
        </PaginatedList>
      </div>
    );
  }

  loadMore = () => {
    const { nextCursor, isLoading } = this.props;
    if (!isLoading && nextCursor) {
      this.props.loadEnsembles(nextCursor);
    }
  };
}

function fetchData(store, { cursor }) {
  return [store.dispatch(loadEnsembles(cursor))];
}

function mapStateToProps(state, ownProps) {
  return {
    items: selectEnsembles(state),
    currentCursor: ownProps.params.cursor,
    nextCursor: selectNextEnsemblesCursor(state),
    prevCursor: selectPrevEnsemblesCursor(state),
    isLoading: selectEnsemblesIsLoading(state),
    location: ownProps.location,
  };
}

function getShareTrackingContext() {
  return {
    sharedContentType: 'Ensemble',
    sharedContentId: null,
    contextType: 'Category',
    contentId: null,
  };
}

export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, { loadEnsembles }),
  injectIntl,
  shareableEntity(ENTITY_TYPE_ARTISTS, getShareTrackingContext)
)(Ensembles);
