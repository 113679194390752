// @flow
import React from 'react';
import type { ChildrenArray, Element } from 'react';
import styles from './Section.css';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

type OwnProps = {
  title: Element<typeof FormattedMessage>,
  children: ChildrenArray<Element<any>>,
  id?: string,
};

const Section = ({ title, children, id }: OwnProps) => {
  return (
    <div className={styles.section}>
      <h2 className={classNames('fz--gamma', styles.title)} id={id}>
        {title}
      </h2>
      {children}
    </div>
  );
};

export default Section;
