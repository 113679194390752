import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MEETING_EARLY_ACCESS_PERIOD } from '../../constants';

export default () => (
  <FormattedMessage
    id="livestream-events.access-zoom-tooltip.text"
    defaultMessage="You will have access {earlyAccessPeriod} minutes before the session starts."
    values={{
      earlyAccessPeriod: MEETING_EARLY_ACCESS_PERIOD,
    }}
  />
);
