// @flow

import { loaded } from '../middleware/apiCacheValidators';
import { Genre, GenreList } from '../schema';
import { normalizeMetaItem, normalizeMetaList } from '../schema/normalizeMeta';

import type { Request } from './types';

export type LoadGenresAction = { type: 'FETCH_GENRES' } & Request;
export type LoadGenreAction = { type: 'FETCH_GENRE' } & Request;

export type GenreAction = LoadGenresAction | LoadGenreAction;

export function loadGenres(): LoadGenresAction {
  return {
    type: 'FETCH_GENRES',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/metadata/genres',
    },
    meta: {
      schema: GenreList,
      normalize: normalizeMetaList,
    },
    cache: {
      fetch: state => state.lists.genres,
      validate: loaded,
    },
  };
}

export function loadGenre(id: string): LoadGenreAction {
  return {
    type: 'FETCH_GENRE',
    cacheId: id,
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: '/v2.0/metadata/genres/{id}',
      params: { id },
    },
    meta: {
      schema: Genre,
      normalize: normalizeMetaItem,
    },
    cache: {
      fetch: state => state.maps.genres[id],
      validate: loaded,
    },
  };
}
