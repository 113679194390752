// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from '../util/Select';

import { DEFAULT_LOCALE } from '../../constants';
import type { IntlShape } from 'react-intl';
import type { SupportedLanguage } from '../../shapes/types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { selectIsI18nEnabled, selectSupportedLanguages } from '../../selectors/client';
import * as meActions from '../../actions/me';
import SectionItem from './SectionItem';
import styles from './SectionItem.css';

declare var __ELECTRON__: boolean;

type OwnProps = {};

type MapStateToProps = {
  isI18nEnabled: boolean,
  supportedLanguages: Array<SupportedLanguage>,
};

type DispatchProps = {
  updatePreferences: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps & { intl: IntlShape };

const Language = ({ intl, isI18nEnabled, updatePreferences, supportedLanguages }: Props) => {
  const onChangeLocale = async (locale: string) => {
    if (!__ELECTRON__) {
      document.location.href = `/change-locale?locale=${locale}&oldLocale=${intl.locale}`;
      return;
    }

    await updatePreferences({ locale });
    document.location.reload();
  };

  if (!isI18nEnabled) {
    return null;
  }

  const options = supportedLanguages.map(({ title, preferredLocale }) => ({
    text: title,
    value: preferredLocale,
  }));

  return (
    <SectionItem
      title={<FormattedMessage id="settings.app.i18n.title" defaultMessage="Language:" />}
    >
      <div className={styles.form}>
        <Select
          defaultOptionValue={DEFAULT_LOCALE}
          currentOptionValue={intl.locale}
          options={options}
          onSelect={onChangeLocale}
          className={styles.select}
        />
      </div>
    </SectionItem>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    isI18nEnabled: selectIsI18nEnabled(state),
    supportedLanguages: selectSupportedLanguages(state),
  };
}

const dispatchProps: DispatchProps = {
  updatePreferences: meActions.updatePreferences,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(Language);
