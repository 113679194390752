import React, { Component } from 'react';
import { selectInstrumentsLinks } from '../../selectors/categories';
import { connect } from 'react-redux';
import Instrument from './Instrument';
import PropTypes from 'prop-types';
import instrumentRecordings from './data/instrument-recordings';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import BrowseListContainer from './BrowseListContainer';
import styles from './BrowseInstruments.css';

const messages = defineMessages({
  title: {
    id: 'browse.instruments.title',
    defaultMessage: 'Instruments',
  },
});

class BrowseInstruments extends Component {
  static propTypes = {
    instrumentsLinks: PropTypes.array.isRequired,
    intl: intlShape,
  };

  render() {
    const { instrumentsLinks, intl } = this.props;
    const instruments = Object.keys(instrumentRecordings).map(x => parseInt(x, 10));
    return (
      <React.Fragment>
        <div className={styles.title}>
          <BrowseListContainer
            title={intl.formatMessage(messages.title)}
            link="/instruments"
            category="Instruments"
          />
        </div>
        {instruments.map(id => {
          const instrument = instrumentsLinks.find(x => x.id === id);
          // We must check if there is an interesection between hardcoded data
          // and backend response selected below so that we will not try to
          // view either browse items that don’t exist on backend, or will not
          // try to render items that are not in the hardcoded lists.
          if (!instrumentRecordings[instrument.id]) {
            return null;
          }
          return (
            <Instrument
              key={`instrument-${instrument.id}`}
              title={instrument.title}
              recordings={instrumentRecordings[instrument.id]}
              href={instrument.href}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    instrumentsLinks: selectInstrumentsLinks(state),
  };
}

export default connect(mapStateToProps)(injectIntl(BrowseInstruments));
