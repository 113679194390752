import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { LivestreamEvent } from '../shapes';

import metaInjector from '../hoc/metaInjector';
import dataComponent from '../hoc/dataComponent';

import CapacitorHeaderBar from '../components/capacitor/HeaderBar';
import PaginatedList from '../components/util/PaginatedList';
import List from '../components/util/List';
import LivestreamEventPreview from '../components/livestream-event/LivestreamEventPreview';
import EventCollectionFooter from '../components/livestream-event/EventCollectionFooter';

import {
  selectMyEvents,
  selectMyEventsIsLoading,
  selectNextMyEventsCursor,
  selectPrevMyEventsCursor,
} from '../selectors/myEvents';

import { loadMyEvents } from '../actions/livestreamEvents';

import styles from './MyEvents.css';

import { ENTITY_TYPE_LIVESTREAM_EVENTS, IMAGES_BASE } from '../constants';
import protectedComponent from '../hoc/protectedComponent';
import shareableEntity, { shareableEntityPropTypes } from '../hoc/shareableEntity';

const messages = defineMessages({
  metaTitle: {
    id: 'livestream-events.my-events.meta.title-2',
    defaultMessage: 'My Events',
  },
  metaDescription: {
    id: 'livestream-events.my-events.meta.description-2',
    defaultMessage: 'Your upcoming events in the IDAGIO Concerts',
  },
  metaKeywords: {
    id: 'livestream-events.my-events.meta.keywords-2',
    defaultMessage: 'idagio concerts, livestream, tickets',
  },
});

class MyEvents extends PureComponent {
  static propTypes = {
    events: PropTypes.arrayOf(LivestreamEvent),
    currentCursor: PropTypes.string,
    nextCursor: PropTypes.string,
    prevCursor: PropTypes.string,
    loadMyEvents: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,

    ...shareableEntityPropTypes,
  };

  renderItem = event => {
    return (
      <li key={event.id} className={styles.item}>
        <LivestreamEventPreview event={event} />
      </li>
    );
  };

  render() {
    const title = <FormattedMessage id="my-tickets.title" defaultMessage="My Tickets" />;
    const { events, isLoading, currentCursor, nextCursor, prevCursor, location } = this.props;
    return (
      <div className={`u-page-container ${styles.container}`}>
        {__CAPACITOR__ ? (
          <CapacitorHeaderBar title={title} showShareModal={this.props.showShareModal} />
        ) : (
          <h1 className={styles.title}>{title}</h1>
        )}
        {events.length < 1 ? (
          <p>
            <FormattedMessage
              id="my-events.no-events"
              defaultMessage="You haven't added any events yet."
            />
          </p>
        ) : (
          <PaginatedList
            isLoading={isLoading}
            currentCursor={currentCursor}
            nextCursor={nextCursor}
            prevCursor={prevCursor}
            location={location}
            loadMore={this.loadMore}
          >
            <List items={events} className={styles.list} renderItem={this.renderItem} />
          </PaginatedList>
        )}
        {!nextCursor && <EventCollectionFooter trackingSource="myEventsPage" />}
      </div>
    );
  }

  loadMore = () => {
    const { nextCursor, isLoading } = this.props;
    if (!isLoading && nextCursor) {
      this.props.loadMyEvents(nextCursor);
    }
  };
}

function fetchData(store) {
  return store.dispatch(loadMyEvents());
}

function getMetaInformation(props) {
  const { intl } = props;
  const { formatMessage } = intl;
  return {
    'title': formatMessage(messages.metaTitle),
    'description': formatMessage(messages.metaDescription),
    'keywords': formatMessage(messages.metaKeywords),
    'og:image': `${IMAGES_BASE}/assets/web/live-fallback.jpg?w=800`,
  };
}

function mapStateToProps(state, ownProps) {
  return {
    events: selectMyEvents(state),
    currentCursor: ownProps.params.cursor,
    nextCursor: selectNextMyEventsCursor(state),
    prevCursor: selectPrevMyEventsCursor(state),
    isLoading: selectMyEventsIsLoading(state),
    location: ownProps.location,
  };
}

function getShareTrackingContext() {
  return {
    sharedContentType: 'Livestream Event',
    sharedContentId: null,
    contextType: 'favouriteLivestreamEvents',
    contentId: null,
  };
}

export default compose(
  protectedComponent({ authenticatedOnly: true }),
  connect(mapStateToProps, { loadMyEvents }),
  dataComponent(fetchData),
  injectIntl,
  metaInjector(getMetaInformation),
  shareableEntity(ENTITY_TYPE_LIVESTREAM_EVENTS, getShareTrackingContext)
)(MyEvents);
