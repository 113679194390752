// @flow
// $FlowFixMe
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import Cookies from 'js-cookie';

import { setPasswordAndAuthenticate as setPasswordAndAuthenticateActionCreator } from '../actions/auth';
import { addNotification as addNotificationActionCreator } from '../actions/notifications';
import LoadingIndicator from '../components/common/LoadingIndicator';

import styles from './ForgotPassword.css';
import { CAPTCHA_ACTION_SET_NEW_PW, CHROME_CLEAN, IDAGIO_RESET_PW_COOKIE } from '../constants';
import chromeComponent from '../hoc/chromeComponent';
import CaptchaForm from '../components/common/CaptchaForm';
import { selectCaptchaType } from '../selectors/client';
import PasswordInput from '../components/common/PasswordInput';
import { Link, withRouter } from 'react-router';
import { isValidPassword } from '../utils/password';
import { selectUserIsAuthenticated } from '../selectors/user';
import InvalidPassword from '../components/messages/InvalidPassword';
import PasswordMismatch from '../components/messages/PasswordMismatch';
import { SET_PASSWORD_INVALID_TOKEN } from '../lib/notifications';

type OwnProps = {};

type MapStateToProps = {
  captchaType: string,
  userIsAuthenticated: boolean,
};

type DispatchProps = {
  setPasswordAndAuthenticate: Function,
  addNotification: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const SetPassword = ({
  captchaType,
  setPasswordAndAuthenticate,
  userIsAuthenticated,
  addNotification,
}: Props) => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [resetPasswordSubmitted, setResetPasswordSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const resetpwtoken = Cookies.get(IDAGIO_RESET_PW_COOKIE);
    if (!resetpwtoken) {
      addNotification(SET_PASSWORD_INVALID_TOKEN);
      return;
    }

    setToken(resetpwtoken);
    Cookies.remove(IDAGIO_RESET_PW_COOKIE);
  }, [addNotification]);

  const onPasswordChange = e => {
    setNewPassword(e.target.value);
    if (e.target.value && isValidPassword(e.target.value)) {
      setInvalidPassword(false);
    }

    if (e.target.value && newPasswordConfirmation && e.target.value === newPasswordConfirmation) {
      setPasswordMismatch(false);
    }
  };

  const onBlur = () => {
    const passwordError = newPassword && !isValidPassword(newPassword);
    setInvalidPassword(passwordError);
    const mismatch =
      newPassword && newPasswordConfirmation && newPassword !== newPasswordConfirmation;
    setPasswordMismatch(mismatch);
  };

  const onPasswordConfirmationChange = e => {
    setNewPasswordConfirmation(e.target.value);
    if (e.target.value && newPassword === e.target.value) {
      setPasswordMismatch(false);
    }
  };

  const submitSetPassword = async captchaToken => {
    try {
      setResetPasswordSubmitted(true);
      await setPasswordAndAuthenticate(newPassword, token, captchaType, captchaToken);
      setNewPassword('');
      setNewPasswordConfirmation('');
      document.location.href = '/';
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
  };

  const onClose = () => {
    setIsLoading(false);
  };

  const isButtonDisabled =
    !newPassword || !newPasswordConfirmation || passwordMismatch || invalidPassword || isLoading;

  const HeaderText = () => (
    <FormattedMessage id="set-password.header" defaultMessage="Reset password" />
  );

  const Header = () => (
    <div className={styles.header}>
      <h1 className="fz--beta">
        <HeaderText />
      </h1>
      <p>
        <FormattedMessage
          id="set-password.description"
          defaultMessage="Please enter a new password for your account."
        />
      </p>
    </div>
  );

  const Authenticated = () => (
    <React.Fragment>
      <h1 className="sr-only">
        <HeaderText />
      </h1>
      <p>
        <FormattedMessage
          id="set-password.authenticated.text"
          defaultMessage="You are already logged in. To change your password, please go to settings. If you have other questions, please {contactLink}."
          values={{
            contactLink: (
              <a className="c-text-link--is-visible" href="mailto:contact@idagio.com">
                <FormattedMessage
                  id="set-password.contact-support"
                  defaultMessage="contact support"
                />
              </a>
            ),
          }}
        />
      </p>
      <div className={styles.btns}>
        <Link to="/" className="c-btn c-btn--has-outline">
          <FormattedMessage id="set-password.authenticated.cancel" defaultMessage="Cancel" />
        </Link>
        <Link to="/account" className="c-btn c-btn--is-inverted">
          <FormattedMessage id="set-password.authenticated.settings" defaultMessage="Settings" />
        </Link>
      </div>
    </React.Fragment>
  );

  const viewClasses = classnames(styles.view, 'u-page-container');
  const classesBtn = classnames('c-btn', 'c-btn--is-purple', styles.btn);

  return (
    <div className={viewClasses}>
      {userIsAuthenticated && !resetPasswordSubmitted ? (
        <Authenticated />
      ) : (
        <React.Fragment>
          <Header />
          <CaptchaForm
            captchaAction={CAPTCHA_ACTION_SET_NEW_PW}
            className={styles.form}
            submit={submitSetPassword}
            onSubmit={onSubmit}
            onClose={onClose}
          >
            <div>
              <PasswordInput
                id="password"
                value={newPassword}
                onChange={onPasswordChange}
                onBlur={onBlur}
                disabled={isLoading}
                title={
                  <FormattedMessage
                    id="set-password.password-label"
                    defaultMessage="New password"
                  />
                }
                data-test="set-password.password"
                autoFocus
                inputError={invalidPassword}
                inputErrorText={<InvalidPassword />}
              />
              <PasswordInput
                id="password-confirmation"
                value={newPasswordConfirmation}
                onChange={onPasswordConfirmationChange}
                onBlur={onBlur}
                disabled={isLoading}
                title={
                  <FormattedMessage
                    id="set-password.confirm-password-label"
                    defaultMessage="Confirm new password"
                  />
                }
                inputError={passwordMismatch}
                inputErrorText={<PasswordMismatch />}
                data-test="set-password.password-confirmation"
              />
              <button
                className={classesBtn}
                data-test="set-password.submit-btn"
                type="submit"
                disabled={isButtonDisabled}
              >
                <LoadingIndicator isLoading={isLoading} className="hideText" />
                <FormattedMessage id="set-password.cta" defaultMessage="Reset Password" />
              </button>
            </div>
          </CaptchaForm>
        </React.Fragment>
      )}
    </div>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    captchaType: selectCaptchaType(state),
    userIsAuthenticated: selectUserIsAuthenticated(state),
  };
}

const dispatchProps: DispatchProps = {
  setPasswordAndAuthenticate: setPasswordAndAuthenticateActionCreator,
  addNotification: addNotificationActionCreator,
};

export default compose(
  chromeComponent({ type: CHROME_CLEAN }),
  connect(mapStateToProps, dispatchProps),
  withRouter
)(SetPassword);
