import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import Cookies from 'js-cookie';

import metaInjector from '../hoc/metaInjector';
import Head from '../components/chrome/Head';

import * as uiActions from '../actions/ui';

import { VOUCHER_STATUS_APPLIED, VOUCHER_STATUS_INVALID } from '../constants';

const messages = defineMessages({
  metaTitle: {
    id: 'discover.meta.title',
    defaultMessage: 'Discover Classical Music | Stream and Listen Offline | IDAGIO',
  },
  metaDescription: {
    id: 'discover.meta.description',
    defaultMessage:
      'Discover the best of classical music on IDAGIO with curated playlists, classical releases, award winning albums and much more!',
  },
  metaKeywords: {
    id: 'discover.meta.keywords',
    defaultMessage:
      'classical music playlists, discover classical music, classical music albums, classical releases, curated classical music playlists, listen to classical music',
  },
});

class Discover extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isSpecialOfferRoute: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
  };

  componentDidMount = async () => {
    const { isSpecialOfferRoute, showModal } = this.props;

    if (isSpecialOfferRoute) {
      const voucherCookie = Cookies.get('_idagio-voucher');

      if (voucherCookie) {
        const voucherStatus = JSON.parse(voucherCookie).status;

        if (voucherStatus === VOUCHER_STATUS_APPLIED) {
          showModal('VOUCHER_SUCCESS_MODAL');
        } else if (voucherStatus === VOUCHER_STATUS_INVALID) {
          showModal('VOUCHER_MODAL', { trigger: 'invalidVoucher' });
        }

        Cookies.remove('_idagio-voucher');
      }
    }
  };

  render() {
    return (
      <div className="u-page-container" data-test="discover-page">
        <Head canonicalRoute="/discover" />
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { location } = ownProps;

  return {
    isSpecialOfferRoute: location.pathname === 'special-offer',
  };
}

function getMetaInformation(props) {
  const { intl } = props;
  const { formatMessage } = intl;
  return {
    title: formatMessage(messages.metaTitle),
    description: formatMessage(messages.metaDescription),
    keywords: formatMessage(messages.metaKeywords),
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps, { showModal: uiActions.showModal }),
  injectIntl,
  metaInjector(getMetaInformation)
)(Discover);
