import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import { Link } from 'react-router';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import * as Schema from '../schema';
import assembleEntity from '../schema/assembleEntity';

import * as Shapes from '../shapes';
import List from '../components/util/List';

import { loadGenres } from '../actions/genre';
import metaInjector from '../hoc/metaInjector';

const messages = defineMessages({
  metaTitle: {
    id: 'genres.meta.title',
    defaultMessage: 'Genres',
  },
  metaDescription: {
    id: 'genres.meta.description',
    defaultMessage:
      'Quickly find and play classical music of various genres from our curated repertoire, including recordings from classical masters and contemporary artists.',
  },
  metaKeywords: {
    id: 'genres.meta.keywords',
    defaultMessage:
      'classical music genres, chamber music, concertos, classical orchestral music, classical vocal music, secular vocal, sacred vocal',
  },
});

class Genres extends Component {
  static propTypes = {
    genreList: PropTypes.arrayOf(Shapes.Category).isRequired,
  };

  renderGenreListItem(item) {
    return (
      <li key={item.id}>
        <Link to={`/genres/${item.id}`}>{item.title}</Link>
      </li>
    );
  }

  render() {
    return (
      <div className="genres u-page-container">
        <h1 className="fz--beta">
          <FormattedMessage id="genres.title" defaultMessage="Genres" />
        </h1>
        <List items={this.props.genreList} renderItem={this.renderGenreListItem.bind(this)} />
      </div>
    );
  }
}

function fetchData(store) {
  return store.dispatch(loadGenres());
}

function mapStateToProps(state) {
  return {
    entities: state.entities,
    genres: state.lists.genres.entities,
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { entities, genres } = stateProps;
  const genreList = assembleEntity(Schema.GenreList, entities, genres);

  return Object.assign({}, ownProps, dispatchProps, {
    genreList,
  });
}

function getMetaInformation(props) {
  const { intl } = props;
  const { formatMessage } = intl;
  return {
    title: formatMessage(messages.metaTitle),
    description: formatMessage(messages.metaDescription),
    keywords: formatMessage(messages.metaKeywords),
  };
}

export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, {}, mergeProps),
  injectIntl,
  metaInjector(getMetaInformation)
)(Genres);
