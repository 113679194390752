import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { compose } from 'redux';

import { connect } from 'react-redux';
import chromeComponent from '../hoc/chromeComponent';
import dataComponent from '../hoc/dataComponent';
import PropTypes from 'prop-types';
import { findIndex, shuffle, isEqual } from 'lodash';
import classnames from 'classnames';

import * as playerActions from '../actions/player';
import * as uiActions from '../actions/ui';
import * as analyticsActions from '../actions/analytics';
import { loadPlaylist } from '../actions/playlist';

import * as Shapes from '../shapes';

import Head from '../components/chrome/Head';
import MoodTrackInfo from '../components/mood/MoodTrackInfo';
import IconLabel from '../components/util/IconLabel';
import { Portal } from '../components/util/Portal';
import PlayButton from '../components/common/PlayButton';

import {
  selectPlaylist,
  getMoodQueueOrigin,
  selectMoodIsPlaying,
  selectMoodIsQueued,
} from '../selectors/playlist';

import { CHROME_GLASS, USER_CLICKED_NEXT, APP_CHROME_PORTAL_ID } from '../constants';

import { defineMessages, FormattedMessage, intlShape, injectIntl } from 'react-intl';

import styles from './Mood.css';
import { selectPlayerIsPlayingAd, selectPlayerState } from '../selectors/player';
import { withRouter } from 'react-router';
import playlistableEntity, { playlistableEntityPropTypes } from '../hoc/playlistableEntity';

const messages = defineMessages({
  metaDescription: {
    id: 'mood.meta.description',
    defaultMessage:
      'Feeling {moodTitle}? We have the perfect soundtrack of {moodTitle} classical music for you, hand-picked by our team of music experts.',
  },
});

function getIndexById(trackIds, trackId) {
  const indexInTrackIds = findIndex(trackIds, id => {
    return id === trackId;
  });

  return indexInTrackIds;
}

class Mood extends Component {
  static propTypes = {
    playlist: Shapes.Playlist,
    error: PropTypes.bool,
    moodId: PropTypes.string.isRequired,
    setShuffledQueue: PropTypes.func.isRequired,
    rewind: PropTypes.func.isRequired,
    play: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    playTrack: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    previous: PropTypes.func.isRequired,

    analyticsTrack: PropTypes.func.isRequired,

    currentQueueItem: PropTypes.object,
    queueItems: PropTypes.array,
    repeatOne: PropTypes.bool,

    hidePlayerInfo: PropTypes.func,
    showPlayerInfo: PropTypes.func,
    intl: intlShape,

    isPlayingAd: PropTypes.bool.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    isQueued: PropTypes.bool.isRequired,

    location: PropTypes.shape({
      query: PropTypes.shape({
        trackId: PropTypes.string,
      }),
    }),

    ...playlistableEntityPropTypes,
  };

  state = {
    currentIndex: 0,
    shuffledTracks: [],
  };

  componentWillMount() {
    const { playlist } = this.props;
    const shuffledTracks = shuffle(playlist.trackIds).map(playerActions.createItem);

    this.updateShuffledTracks(shuffledTracks);
    this.setInitialQueue(shuffledTracks);
  }

  componentDidMount() {
    const { hidePlayerInfo, queueItems, isQueued, currentQueueItem } = this.props;
    hidePlayerInfo();

    if (isQueued && queueItems.length) {
      this.updateShuffledTracks(queueItems);

      if (currentQueueItem) {
        this.findIndexInQueueAndUpdate();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isQueued) {
      return;
    }

    if (!isEqual(prevProps.queueItems, this.props.queueItems)) {
      this.updateShuffledTracks(this.props.queueItems);
      this.findIndexInQueueAndUpdate();
    }

    if (!isEqual(prevProps.currentQueueItem, this.props.currentQueueItem)) {
      this.findIndexInQueueAndUpdate();
    }
  }

  componentWillUnmount() {
    this.props.showPlayerInfo();
  }

  getTrackById(tracks, trackId) {
    const indexInTracks = findIndex(tracks, track => {
      return track.id.toString() === trackId;
    });

    if (indexInTracks >= 0) {
      return tracks[indexInTracks];
    }

    return null;
  }

  renderPlaylistControlPrevious() {
    const isFirstTrack = this.state.currentIndex === 0;

    return (
      <button
        className={styles.controlsPrev}
        disabled={this.props.isPlayingAd || isFirstTrack}
        onClick={this.handlePreviousClick}
      >
        <IconLabel name="caret-left" title="Previous" />
      </button>
    );
  }

  renderPlaylistControlNext() {
    const isLastTrack = this.state.currentIndex === this.state.shuffledTracks.length - 1;
    return (
      <button
        className={styles.controlsNext}
        disabled={this.props.isPlayingAd || isLastTrack}
        onClick={this.handleNextClick}
      >
        <IconLabel name="caret-right" title="Next" />
      </button>
    );
  }

  render() {
    const {
      error,
      playlist,
      analyticsTrack,
      moodId,
      intl,
      isPlaying,
      currentQueueItem,
      onAddTrackToPlaylistClick,
    } = this.props;
    const { formatMessage } = intl;

    if (error) {
      return (
        <div>
          <div className={styles.background} />
          <div className={styles.error}>
            <p className="app-error__header">
              <FormattedMessage
                id="mood.error.heading"
                defaultMessage="Sorry, something went wrong."
              />
            </p>
            <p>
              <FormattedMessage
                id="mood.error.body"
                defaultMessage="If refreshing the page doesn't solve the problem, please {contactLink}."
                values={{
                  contactLink: (
                    <a href="mailto:contact@idagio.com" className="c-text-link--is-visible">
                      <FormattedMessage id="mood.error.contact-us" defaultMessage="let us know" />
                    </a>
                  ),
                }}
              />
            </p>
          </div>
        </div>
      );
    }

    let moodTrackInfo = null;

    const currentTrack = this.getTrackById(
      playlist.tracks,
      this.state.shuffledTracks[this.state.currentIndex].track
    );

    if (currentTrack) {
      moodTrackInfo = (
        <CSSTransition key={currentTrack.id} classNames="moodAnim" timeout={1400}>
          <MoodTrackInfo
            track={currentTrack}
            analyticsTrack={analyticsTrack}
            moodId={moodId}
            onAddToPlaylistButtonClick={onAddTrackToPlaylistClick}
          />
        </CSSTransition>
      );
    }

    const moodClasses = classnames(styles.background, styles[`background--${moodId}`]);
    return (
      <div className={styles.mood}>
        <Head
          title={playlist.title}
          description={formatMessage(messages.metaDescription, {
            moodTitle: playlist.title.toLowerCase(),
          })}
          opengraph={[{ property: 'og:type', content: 'music.playlist' }]}
        />
        <PlayButton
          className={styles.playButton}
          onClick={this.togglePlayback}
          playing={
            isPlaying &&
            isEqual(currentQueueItem, this.state.shuffledTracks[this.state.currentIndex])
          }
          size="default"
        />
        <TransitionGroup component="div" className={styles.tracks}>
          {moodTrackInfo}
        </TransitionGroup>
        {this.renderPlaylistControlPrevious()}
        {this.renderPlaylistControlNext()}

        {!__SERVER__ && (
          <Portal selector={`#${APP_CHROME_PORTAL_ID}`}>
            <div className={moodClasses} />
          </Portal>
        )}
      </div>
    );
  }

  handlePreviousClick = event => {
    event.preventDefault();
    this.props.analyticsTrack('Previous Pressed', {
      source: 'Mood Player',
    });

    if (this.props.isQueued && !this.props.repeatOne) {
      return this.props.previous();
    }

    const previousIndex = this.state.currentIndex - 1;
    return this.updateIndex(previousIndex >= 0 ? previousIndex : 0);
  };

  handleNextClick = event => {
    event.preventDefault();
    this.props.analyticsTrack('Next Pressed', {
      source: 'Mood Player',
    });
    if (this.props.isQueued && !this.props.repeatOne) {
      return this.props.next(USER_CLICKED_NEXT, 'internal', Date.now());
    }

    const nextIndex = this.state.currentIndex + 1;
    const lastIndex = this.state.shuffledTracks.length - 1;
    return this.updateIndex(nextIndex <= lastIndex ? nextIndex : lastIndex);
  };

  updateIndex(currentIndex) {
    this.setState({ currentIndex });
  }

  updateShuffledTracks(shuffledTracks) {
    this.setState({ shuffledTracks });
  }

  findIndexInQueueAndUpdate() {
    const { currentQueueItem, queueItems } = this.props;
    const currentIndex = findIndex(queueItems, currentQueueItem);
    this.updateIndex(currentIndex);
  }

  setInitialQueue = shuffledTracks => {
    const { location, rewind } = this.props;

    const trackId = location.query && location.query.trackId;
    if (trackId) {
      const index = getIndexById(
        shuffledTracks.map(item => item.track),
        trackId
      );
      rewind();
      this.setQueue(shuffledTracks, shuffledTracks[index], false);
      this.updateIndex(0);
    }
  };

  setQueue = (shuffledTracks, track, autoPlay) => {
    const { moodId, setShuffledQueue } = this.props;

    setShuffledQueue(
      getMoodQueueOrigin(moodId),
      shuffledTracks,
      autoPlay,
      { progress: 0, startPlayback: true },
      true,
      undefined,
      true,
      track
    );
  };

  togglePlayback = () => {
    const { play, pause, isPlaying, isQueued, currentQueueItem, playTrack } = this.props;
    if (!isQueued) {
      return this.setQueue(
        this.state.shuffledTracks,
        this.state.shuffledTracks[this.state.currentIndex],
        true
      );
    }

    const currentTrack = this.state.shuffledTracks[this.state.currentIndex];

    if (isEqual(currentQueueItem, currentTrack)) {
      return isPlaying ? pause() : play('internal', { timestamp: Date.now() });
    }

    return playTrack(currentTrack, {});
  };

  play() {
    this.props.play('internal', { timestamp: Date.now() });
  }
}

const chrome = {
  type: CHROME_GLASS,
  background: (state, { id }) => `https://idagio-media.imgix.net/moods/${id}.jpg`,
};

function fetchData(store, { id }) {
  return [store.dispatch(loadPlaylist(id))];
}

function mapStateToProps(state, ownProps) {
  // moodId is the equivalent of a playlist slug, i.e. a moodId maps to a playlist id
  const moodId = ownProps.params.id;
  const playlist = selectPlaylist(state, moodId);
  if (!playlist) {
    return {
      error: true,
    };
  }

  const { currentQueueItem, queueItems, repeatOne } = selectPlayerState(state);
  const isQueued = selectMoodIsQueued(state, moodId);

  const isPlayingAd = selectPlayerIsPlayingAd(state);

  return {
    moodId,
    playlist,
    isPlayingAd,
    currentQueueItem,
    queueItems,
    repeatOne,
    isPlaying: selectMoodIsPlaying(state, moodId),
    isQueued,
  };
}

function getTrackingContext(props) {
  return {
    contextType: 'Mood',
    contextId: props.moodId,
  };
}

function getEntityTrackingData(props) {
  return {
    itemType: 'playlist',
    itemId: props.playlist.id,
  };
}

export default compose(
  dataComponent(fetchData),
  chromeComponent(chrome),
  connect(
    mapStateToProps,
    {
      setShuffledQueue: playerActions.setShuffledQueue,
      rewind: playerActions.rewind,
      setCurrentQueueItem: playerActions.setCurrentQueueItem,
      play: playerActions.play,
      pause: playerActions.pause,
      playTrack: playerActions.playTrack,
      next: playerActions.nextThunk,
      previous: playerActions.previousThunk,
      hidePlayerInfo: uiActions.hidePlayerInfo,
      showPlayerInfo: uiActions.showPlayerInfo,
      analyticsTrack: analyticsActions.track,
    },
    (stateProps, dispatchProps) => ({ ...stateProps, ...dispatchProps })
  ),
  playlistableEntity({
    getEntityTrackingData,
    getTrackingContext,
  }),
  injectIntl,
  withRouter
)(Mood);
