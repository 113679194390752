// @flow
import React from 'react';
import { connect } from 'react-redux';

import FilteredList from '../common/FilteredList';
import IconLabel from '../util/IconLabel';

import { ENTITY_TYPE_WORK } from '../../constants';

import {
  selectFilteredAndSortedWorks,
  selectFilteredAndSortedWorksLoading,
  selectFilteredAndSortedWorksCount,
  selectNextFilteredWorksCursor,
  selectPreviousFilteredWorksCursor,
} from '../../selectors/work';
import { selectWorkFilterTopFacets, selectActiveWorkFacets } from '../../selectors/facet';
import { selectProfile } from '../../selectors/profile';

import type { Work } from '../../shapes/types';

import { selectWorksFilterParams, selectSortOrder } from '../../selectors/filteredList';
import * as facetActions from '../../actions/facet';
import * as localListSearchActions from '../../actions/localListSearch';
import * as workActions from '../../actions/work';
import TitleWithPopularTitle from '../common/TitleWithPopularTitle';
import CapacitorRipple from '../capacitor/Ripple';
import { Link } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import dataComponent from '../../hoc/dataComponent';
import { compose } from 'redux';
import Head from '../chrome/Head';
import { messages } from './messages';
import stylesFilteredList from '../common/FilteredList.css';
import stylesWorks from '../../views/Works.css';
import { selectSearchQuery } from '../../selectors/localListSearch';

type OwnProps = {
  location: Object,
};

type MapStateToProps = {
  profileId: string,
  profileSlug: string,
  artistName: string,
  topFacets: Object,
  activeFacets: Array<Object>,
  works: Array<Work>,
  filterParams: Object,
  nextCursor?: string,
  prevCursor?: string,
  sort: string,
  searchTerm?: string,
  currentCursor?: string,
  worksAreLoading: boolean,
  worksCount: number,
};

type DispatchProps = {
  loadAllWorkFacets: Function,
  loadTopWorkFacets: Function,
  loadWorksByFilter: Function,
  setSearchQuery: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps & { intl: IntlShape };

const ProfileWorks = ({
  location,
  profileId,
  profileSlug,
  artistName,
  topFacets,
  activeFacets,
  works,
  filterParams,
  nextCursor,
  prevCursor,
  sort,
  searchTerm,
  currentCursor,
  worksAreLoading,
  worksCount,
  loadAllWorkFacets,
  loadTopWorkFacets,
  loadWorksByFilter,
  setSearchQuery,
  intl,
}: Props) => {
  const onSearchChange = async term => {
    const params = selectWorksFilterParams(location.query, profileId, term);

    await loadWorksByFilter(params, currentCursor);
    await loadTopWorkFacets(params);
    setSearchQuery(term);
  };

  const renderWorkListItem = work => {
    return (
      <li className={stylesFilteredList.item} key={work.id}>
        <Link to={`/works/${work.id}`} className={stylesWorks.link}>
          <CapacitorRipple />
          <div>
            <TitleWithPopularTitle {...work} />
          </div>
          <IconLabel name="chevron-right" className={stylesWorks.linkIcon} size="default" />
        </Link>
      </li>
    );
  };

  const { formatMessage } = intl;

  return (
    <div>
      <Head
        canonicalRoute={`/profiles/${profileSlug}/works`}
        title={formatMessage(messages.profileWorksMetaTitle, {
          name: artistName,
        })}
        description={formatMessage(messages.profileWorksMetaDescription, {
          name: artistName,
        })}
        keywords={formatMessage(messages.profileWorksMetaKeywords, {
          name: artistName,
        })}
      />
      <FilteredList
        contextType="profile"
        contextFacet="Composer"
        topFacets={topFacets}
        location={location}
        activeFacets={activeFacets}
        filterParams={filterParams}
        listIsEmpty={!works || works.length === 0}
        ignoredFacets={['composers']}
        nextCursor={nextCursor}
        prevCursor={prevCursor}
        loadMore={loadWorksByFilter}
        sort={sort}
        currentCursor={currentCursor}
        shouldRenderPlayAllButton={false}
        isLoading={worksAreLoading}
        emptyListMessage={
          <FormattedMessage
            id="filtered-recording-list.no-works"
            defaultMessage="No works match these criteria."
          />
        }
        items={works}
        itemsCount={worksCount}
        filterType="works"
        renderFilteredListItem={renderWorkListItem}
        loadAllFacets={loadAllWorkFacets}
        searchTerm={searchTerm}
        shouldShowSearchBox
        onSearchChange={onSearchChange}
      />
    </div>
  );
};

function mapStateToProps(state: Object, ownProps: OwnProps & { params: Object }): MapStateToProps {
  const { slug, cursor } = ownProps.params;
  const profile = selectProfile(state, slug);
  const query = ownProps.location.query;
  const searchTerm = selectSearchQuery(state, 'works');
  const filterParams = selectWorksFilterParams(query, profile.id, searchTerm);

  return {
    profileId: profile.id.toString(),
    profileSlug: profile.slug,
    artistName: profile.name,
    topFacets: selectWorkFilterTopFacets(state, filterParams),
    activeFacets: selectActiveWorkFacets(state, filterParams, 'composers'),
    works: selectFilteredAndSortedWorks(state, filterParams),
    filterParams,
    nextCursor: selectNextFilteredWorksCursor(state, filterParams),
    prevCursor: selectPreviousFilteredWorksCursor(state, filterParams),
    sort: selectSortOrder(query, ENTITY_TYPE_WORK),
    searchTerm,
    currentCursor: cursor,
    worksAreLoading: selectFilteredAndSortedWorksLoading(state),
    worksCount: selectFilteredAndSortedWorksCount(state, filterParams),
  };
}

const searchKey = 'works';
function fetchData(store, { slug, cursor }, location) {
  const state = store.getState();
  const profile = selectProfile(state, slug);
  const { query } = location;
  const filterParams = selectWorksFilterParams(query, profile.id);
  return [
    store.dispatch(workActions.loadWorksByFilter(filterParams, cursor)),
    store.dispatch(facetActions.loadTopWorkFacets(filterParams)),
    store.dispatch(localListSearchActions.setSearchQuery(searchKey, query.searchTerm || '')),
  ];
}

const dispatchProps: DispatchProps = {
  loadWorksByFilter: workActions.loadWorksByFilter,
  loadAllWorkFacets: facetActions.loadAllWorkFacets,
  loadTopWorkFacets: facetActions.loadTopWorkFacets,
  setSearchQuery: searchTerm => localListSearchActions.setSearchQuery(searchKey, searchTerm),
};

export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, dispatchProps),
  injectIntl
)(ProfileWorks);
