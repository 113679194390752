import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import chromeComponent from '../hoc/chromeComponent';
import { CHROME_CLEAN_LINKS, CONSTANT_MESSAGES } from '../constants';
import * as analyticsActions from '../actions/analytics';

import {
  selectClientName,
  selectClientPlatformUrls,
  selectElectronClientVersion,
  selectIsDesktop,
} from '../selectors/client';

import Head from '../components/chrome/Head';
import DownloadAppButton from '../components/util/DownloadAppButton';

import Footer from '../components/common/Footer';
import styles from './Download.css';

const messages = defineMessages({
  metaTitle: {
    id: 'download-page.meta.title',
    defaultMessage: 'Discover Classical Music',
  },
  metaDescription: {
    id: 'download-page.meta.description',
    defaultMessage:
      'Quickly find and play classical music from our curated repertoire, including recordings from classical masters and contemporary artists. Browse by composer, performer, instrument, genre or epoch',
  },
  downloadApp: {
    id: 'download-page.cta',
    defaultMessage: 'Download app',
  },
});

function renderStage(isDesktop, version) {
  return (
    <section className={styles.stage}>
      <h1 className={styles.heading}>
        <FormattedMessage
          id="download-page.title"
          defaultMessage="Streaming, reinvented for classical music."
        />
      </h1>
      {isDesktop && [
        <h2 key="subheading" className={styles.subheading}>
          <FormattedMessage id="download-page.subheader" defaultMessage="Now on your desktop." />
        </h2>,
        <figure key="logo" className={styles.figure} role="none">
          <img
            srcSet={`
                  https://idagio-images.global.ssl.fastly.net/desktop-app/promo/device-hero-s.jpg 480w,
                  https://idagio-images.global.ssl.fastly.net/desktop-app/promo/device-hero-m.jpg 768w,
                  https://idagio-images.global.ssl.fastly.net/desktop-app/promo/device-hero-l.jpg 1092w
                  `}
            src="https://idagio-images.global.ssl.fastly.net/desktop-app/promo/device-hero-s.jpg"
            alt="Picture of a MacBook showing the Discover screen of the IDAGIO Desktop app."
          />
        </figure>,
      ]}

      <div className={styles.cta}>
        <DownloadAppButton page="DownloadPage" className={styles.btn} />
        {isDesktop && (
          <span className={styles.span}>
            <FormattedMessage
              id="download-page.version"
              defaultMessage="Version {version}"
              values={{ version }}
            />
          </span>
        )}
      </div>
    </section>
  );
}

function renderList() {
  return (
    <section className={styles.section}>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <h2>
            <FormattedMessage
              id="download-page.exclusive-concerts.heading"
              defaultMessage="Exclusive concerts"
            />
          </h2>
          <span className={styles.listText}>
            <FormattedMessage
              id="download-page.exclusive-concerts.text"
              defaultMessage="Stream exclusive concerts from Yo-Yo Ma, The King's Singers, London Symphony Orchestra and many more."
            />
          </span>
        </li>
        <li className={styles.listItem}>
          <h2>
            <FormattedMessage
              id="download-page.streaming.heading"
              defaultMessage="Classical Music Streaming"
            />
          </h2>
          <span className={styles.listText}>
            <FormattedMessage
              id="download-page.streaming.text"
              defaultMessage="Enjoy unlimited audio streaming in lossless quality with complete metadata from all your favourite labels."
            />
          </span>
        </li>
        <li className={styles.listItem}>
          <h2>
            <FormattedMessage
              id="download-page.expert-curation.heading"
              defaultMessage="Expert Curation"
            />
          </h2>
          <span className={styles.listText}>
            <FormattedMessage
              id="download-page.expert-curation.text"
              defaultMessage="Get tailored recommendations, playlists, and pre-concert talks from world-renowned artists, musicologists and critics."
            />
          </span>
        </li>
      </ul>
    </section>
  );
}

function renderDownloadApps(isDesktop, version, clientName) {
  return (
    <section className={styles.downloadMac}>
      <h2>
        <FormattedMessage
          id="download-page.download.heading"
          defaultMessage="Download IDAGIO for { client }"
          values={{ client: clientName === 'macOS' ? 'Mac' : clientName }}
        />
      </h2>
      <div className={styles.cta}>
        <DownloadAppButton page="DownloadPage" className={styles.btn} />
        {isDesktop && (
          <span className={styles.span}>
            <FormattedMessage
              id="download-page.version"
              defaultMessage="Version {version}"
              values={{ version }}
            />
          </span>
        )}
      </div>
    </section>
  );
}

class Download extends PureComponent {
  static propTypes = {
    isDesktop: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    clientName: PropTypes.string.isRequired,
    electronVersion: PropTypes.string.isRequired,
    platformUrls: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
    track: PropTypes.func.isRequired,
  };

  onDownloadPlatformClient = clientName => {
    const { track } = this.props;
    return () => {
      const eventProps = {
        client: clientName,
        source: 'DownloadPagePlatformList',
      };
      track('Clicked on Download Client', eventProps);
    };
  };

  renderPlatforms(platformUrls) {
    const { intl } = this.props;
    const text = intl.formatMessage(messages.downloadApp);
    const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: text });

    return (
      <section className={styles.platforms}>
        <h2>
          <FormattedMessage id="download-page.platforms.heading" defaultMessage="Listen Anywhere" />
        </h2>
        <ul>
          {platformUrls.map(({ name, url }) => (
            <li key={name} className={styles.listHorizontalItem}>
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.onDownloadPlatformClient(name)}
                aria-label={ariaLink}
              >
                {name}
              </a>
            </li>
          ))}
        </ul>
      </section>
    );
  }

  render() {
    const { intl, platformUrls, electronVersion, clientName, isDesktop } = this.props;
    return (
      <div>
        <Head
          title={intl.formatMessage(messages.metaTitle)}
          description={intl.formatMessage(messages.metaDescription)}
        />
        <div className={styles.xBodyDownload}>
          {renderStage(isDesktop, electronVersion)}
          {renderList()}
          {this.renderPlatforms(platformUrls)}
          {renderDownloadApps(isDesktop, electronVersion, clientName)}
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    electronVersion: selectElectronClientVersion(state),
    clientName: selectClientName(state),
    platformUrls: selectClientPlatformUrls(state),
    isDesktop: selectIsDesktop(state),
  };
}

export default compose(
  connect(mapStateToProps, { track: analyticsActions.track }),
  chromeComponent({ type: CHROME_CLEAN_LINKS }),
  injectIntl
)(Download);
