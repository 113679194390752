import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TagsPopular from '../../components/messages/TagsPopular';
import { EXCLUSIVE_TAG_ID } from '../../constants';

import { RecordingTag } from '../../shapes/index';

export default class RecordingTags extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(RecordingTag).isRequired,
    isPopular: PropTypes.bool,
  };

  render() {
    const { tags, isPopular } = this.props;
    return (
      <React.Fragment>
        {isPopular && (
          <span className="u-tag">
            <TagsPopular />
          </span>
        )}
        {tags.map(tag => {
          return (
            <span
              className={`u-tag ${tag.id.toString() === EXCLUSIVE_TAG_ID ? 'is-exclusive' : null}`}
              key={tag.id}
            >
              {tag.title}
            </span>
          );
        })}
      </React.Fragment>
    );
  }
}
