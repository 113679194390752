// @flow
import * as React from 'react';
import { Link } from 'react-router';
import styles from './HeroSliderItem.css';

type OwnProps = {
  isActive: boolean,
  url: string,
  onClick: Function,
  children: React.ChildrenArray<React.Element<any>>,
};

type Props = OwnProps;

export default function HeroSliderItemLink({ isActive, url, onClick, children }: Props) {
  return (
    <Link
      // Prevent being able to tab to the link if its off-screen
      tabIndex={isActive ? undefined : -1}
      to={url}
      className={styles.link}
      onClick={onClick}
      // The next two lines prevent native HTML5 capabilities to drag an
      // image to e.g. a new tab or to a folder. This needs to be
      // disabled for our slider to work correctly.
      draggable={false}
      onDragStart={e => e.preventDefault()}
      onlyActiveOnIndex
    >
      {children}
    </Link>
  );
}
