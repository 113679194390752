// @flow
import React from 'react';
import { connect } from 'react-redux';

import Slider from '../common/Slider';
import PlayableItemPreview from '../common/PlayableItemPreview';
import DiscoverLivestreamEventItem from '../discover/DiscoverLivestreamEventItem';

import * as analyticsActions from '../../actions/analytics';

import styles from '../discover/DiscoverGroupSlider.css';
import A11ySkipLink from '../a11y/A11ySkipLink';

type OwnProps = {
  id: String,
  slug: String,
  title?: String,
  items: Array<Object>,
  isConcertGroup?: boolean,
  nextGroupId?: String,
};

type DispatchProps = {
  analyticsTrack: Function,
  trackPlayPausePressed: Function,
};

type Props = OwnProps & DispatchProps;

const AboutGroup = ({
  id,
  slug,
  title,
  items,
  isConcertGroup,
  nextGroupId,
  analyticsTrack,
  trackPlayPausePressed,
}: Props) => {
  const onItemBeforeTogglePlay = (item, playing) => {
    trackPlayPausePressed(
      {
        contextType: item.content.type,
        contextId: item.content.id,
        contextCategory: id,
      },
      playing
    );
  };

  const onItemClick = ({ content }) => {
    analyticsTrack('Selected Content From Category', {
      id: content.id,
      type: content.type,
      category: id,
      pageName: 'profile-about',
      slug: slug,
    });
  };

  const renderItem = item => {
    if (isConcertGroup) {
      return (
        <DiscoverLivestreamEventItem item={item} key={item.content.id} onClick={onItemClick} />
      );
    }

    return (
      <PlayableItemPreview
        item={item}
        key={item.content.id}
        onClick={onItemClick}
        beforeTogglePlay={onItemBeforeTogglePlay}
      />
    );
  };

  return (
    <div className={styles.group} id={id}>
      {nextGroupId && <A11ySkipLink target={nextGroupId} />}
      <Slider title={title} items={items.map(renderItem)} />
    </div>
  );
};

const dispatchProps: DispatchProps = {
  trackPlayPausePressed: analyticsActions.trackPlayPausePressed,
  analyticsTrack: analyticsActions.track,
};

export default connect(null, dispatchProps)(AboutGroup);
