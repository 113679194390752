// @flow
import React from 'react';
import CapacitorRipple from '../capacitor/Ripple';
import styles from './CourseSessionResource.css';
import { CONSTANT_MESSAGES, CONTENT_TYPE } from '../../constants';
import { injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import type { CourseSessionResource as CourseSessionResourceProps } from '../../shapes/types';

type OwnProps = {
  resource: CourseSessionResourceProps,
};

type Props = OwnProps & { intl: IntlShape };

const getContentType = (contentType: string): ?string => {
  for (const key of Object.keys(CONTENT_TYPE)) {
    const validContentType = CONTENT_TYPE[key];
    if (contentType.toLowerCase().includes(validContentType.toLowerCase())) {
      return validContentType;
    }
  }

  return null;
};

const CourseSessionResource = ({ resource, intl }: Props) => {
  const { file, title, description } = resource;
  if (!file || !file.url) {
    return null;
  }

  const contentType = getContentType(file.contentType);
  if (!contentType) {
    return null;
  }

  const ariaLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, { linkText: description });

  return (
    <div className={styles.resource}>
      <svg>
        <use xlinkHref={`#icon-resource-${contentType.toLowerCase()}`} />
      </svg>
      <a
        className="c-text-link--is-visible"
        href={file.url}
        aria-label={ariaLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <CapacitorRipple />
        {contentType}: {title}
      </a>
    </div>
  );
};

export default injectIntl(CourseSessionResource);
