// @flow
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import { connect } from 'react-redux';

import * as uiActions from '../../actions/ui';
import { selectAutoplay } from '../../selectors/client';
import SectionItem from './SectionItem';
import styles from './SectionItem.css';
import Select from '../util/Select';
import { compose } from 'redux';
import { DEFAULT_AUTOPLAY } from '../../constants';

const messages = defineMessages({
  off: {
    id: 'settings.app.autoplay.off.label',
    defaultMessage: 'Auto-play off',
  },
  on: {
    id: 'settings.app.autoplay.on.label',
    defaultMessage: 'Auto-play on',
  },
});

const AUTOPLAY_OPTIONS = [
  {
    title: messages.off,
    value: false,
  },
  {
    title: messages.on,
    value: true,
  },
];

type MapStateToProps = {
  autoplay: boolean,
};

type DispatchProps = {
  setAutoplay: Function,
};

type Props = MapStateToProps & DispatchProps & { intl: IntlShape };

const Autoplay = ({ autoplay, setAutoplay, intl }: Props) => {
  const options = AUTOPLAY_OPTIONS.map(({ title, value }) => ({
    text: intl.formatMessage(title),
    value,
  }));

  return (
    <SectionItem
      title={
        <FormattedMessage id="settings.app.autoplay.title" defaultMessage="Recommendations:" />
      }
    >
      <div className={styles.form}>
        <Select
          defaultOptionValue={DEFAULT_AUTOPLAY}
          currentOptionValue={autoplay}
          options={options}
          onSelect={setAutoplay}
          className={styles.select}
        />
      </div>
    </SectionItem>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    autoplay: selectAutoplay(state),
  };
}

const dispatchProps: DispatchProps = {
  setAutoplay: uiActions.updateAutoplayPreference,
};

export default compose(connect(mapStateToProps, dispatchProps), injectIntl)(Autoplay);
