// @flow
// $FlowFixMe
import React, { useMemo } from 'react';
import type { CourseResource as CourseResourceProps } from '../../shapes/types';
import Slider from '../common/Slider';
import CourseResource from './CourseResource';
import { get } from 'lodash';
import styles from '../discover/DiscoverGroupSlider.css';
import { courseResourceDecorator } from '../../selectors/livestreamEvents';

type OwnProps = {
  title: string,
  items: Array<CourseResourceProps>,
  resourceType: string,
};

type Props = OwnProps;

const CourseResourceGroup = ({ title, items, resourceType }: Props) => {
  const renderItem = (item, index) => {
    return <CourseResource item={item} key={index} resourceType={resourceType} />;
  };

  const filteredItems = useMemo(
    () =>
      items
        .filter(({ asset }) => get(asset, 'file.url'))
        .map(item => courseResourceDecorator(resourceType, item))
        .filter(({ contentType }) => contentType),
    [items, resourceType]
  );

  return (
    <div className={styles.group}>
      <Slider title={title} items={filteredItems.map(renderItem)} />
    </div>
  );
};

export default CourseResourceGroup;
