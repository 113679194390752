import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export default class CreateNewPlaylistButton extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { onClick } = this.props;
    return (
      <div
        className="c-btn c-btn--has-outline"
        onClick={onClick}
        data-test="playlist.create-button"
      >
        <FormattedMessage
          id="personal-playlists.create-button.text"
          defaultMessage="Create a new playlist"
        />
      </div>
    );
  }
}
