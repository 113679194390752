// @flow
import { normalizeRadio } from '../schema/normalizeRadio';
import * as playerActions from './player';
import { QUEUE_TYPE_RADIO } from '../constants';
import { selectProfile } from '../selectors/profile';
import { selectGenre, selectInstrument, selectPeriod } from '../selectors/categories';
import {
  getQueueOrigin,
  selectRadio,
  selectRadioIsPlaying,
  selectRadioIsQueued,
} from '../selectors/radio';
import type { Request, ThunkAction } from './types';

declare var __CAPACITOR__;
export type LoadRadioAction = { type: 'FETCH_RADIO' } & Request;

export type RadioAction = LoadRadioAction;

export function loadRadio(type: string, slug: number): LoadRadioAction {
  return {
    type: 'FETCH_RADIO',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'GET',
      endpoint: `/radio/${type}/${slug}.v2`,
    },
    meta: {
      normalize: normalizeRadio,
    },
  };
}

export function playRadio(type: string, slug: number): ThunkAction {
  return async (dispatch, getState) => {
    const compoundKey = [type, slug].filter(i => i).join('--');
    const isPlaying = selectRadioIsPlaying(getState(), compoundKey);

    if (isPlaying) {
      return dispatch(playerActions.pause());
    }

    if (__CAPACITOR__) {
      const isQueued = selectRadioIsQueued(getState(), compoundKey);
      if (isQueued) {
        return dispatch(playerActions.resume('internal'));
      }
      let radioOrigin;
      let entityName;
      if (type === 'composer') {
        radioOrigin = 'composerRadio';
        entityName = selectProfile(getState(), slug).name;
      }
      if (type === 'artist') {
        radioOrigin = 'artistRadio';
        entityName = selectProfile(getState(), slug).name;
      }
      if (type === 'epoch') {
        radioOrigin = 'epochRadio';
        entityName = selectPeriod(getState(), slug).title;
      }
      if (type === 'genre') {
        radioOrigin = 'genreRadio';
        entityName = selectGenre(getState(), slug).title;
      }
      if (type === 'instrument') {
        radioOrigin = 'instrumentRadio';
        entityName = selectInstrument(getState(), slug).title;
      }
      const payload = {
        queueOrigin: { type: QUEUE_TYPE_RADIO, id: slug.toString() },
        radioOrigin,
        entityName,
      };
      // we'll set the queue to be loaded when it comes back from capacitor
      return dispatch(playerActions.capacitorSetQueueAndPlay(payload));
    }

    await dispatch(loadRadio(type, slug));

    const { trackIds } = selectRadio(getState());

    return dispatch(
      playerActions.setQueueAndPlay(
        getQueueOrigin(compoundKey),
        trackIds.map(playerActions.createItem),
        undefined,
        true,
        'internal',
        {
          progress: 0,
          startPlayback: true,
        }
      )
    );
  };
}
