// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { AUDIO_QUALITY_LOSSLESS, DEFAULT_FEATURE_FLAGS } from '../../constants';

import SectionItem from './SectionItem';
import Select from '../util/Select';
import styles from './SectionItem.css';
import classNames from 'classnames';
import Quality from '../common/AudioQuality';

const AudioQuality = () => {
  return (
    <Quality>
      {(currentQualityLevel, onQualityChange, supportedAudioQuality) => {
        const options = supportedAudioQuality.map(({ title, ...rest }) => ({
          text: title,
          ...rest,
        }));

        return (
          <SectionItem
            title={
              <FormattedMessage
                id="settings.app.audio-quality.title"
                defaultMessage="Audio Quality:"
              />
            }
          >
            <div className={styles.form}>
              <Select
                defaultOptionValue={DEFAULT_FEATURE_FLAGS.audio_quality[0]}
                currentOptionValue={currentQualityLevel}
                options={options}
                onSelect={onQualityChange}
                className={styles.select}
              />
              {currentQualityLevel === AUDIO_QUALITY_LOSSLESS && (
                <div className={classNames(styles.action, styles.support)}>
                  <FormattedMessage
                    id="settings.app.audio-quality.data-usage-warning"
                    defaultMessage="Lossless audio files are significantly larger than compressed files. Your data usage will increase significantly as a result."
                  />
                </div>
              )}
            </div>
          </SectionItem>
        );
      }}
    </Quality>
  );
};

export default AudioQuality;
