import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export default class NotSupported extends Component {
  render() {
    return (
      <div className="u-page-container">
        <h1>
          <FormattedMessage
            id="not-supported-heading"
            defaultMessage="Ooops! Your browser is no longer supported"
          />
        </h1>
        <p>
          <FormattedMessage
            id="not-supported-text"
            defaultMessage="Unfortunately we can no longer support the browser you're using. But don't panic. Here are alternatives which will allow you to enjoy IDAGIO:"
          />
        </p>
        <ul>
          <li>
            <a className="c-text-link--is-visible" href="https://app.idagio.com/de/download">
              <FormattedMessage
                id="not-supported-download-desktop"
                defaultMessage="Use our desktop app"
              />
            </a>
          </li>
          <li>
            <a
              className="c-text-link--is-visible"
              href="https://www.mozilla.org/en-US/firefox/new/"
            >
              Firefox
            </a>
          </li>
          <li>
            <a className="c-text-link--is-visible" href="https://www.google.com/chrome/">
              Chrome
            </a>
          </li>
          <li>
            <a className="c-text-link--is-visible" href="https://www.opera.com/download">
              Opera
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
