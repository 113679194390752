import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router';
import React, { Component } from 'react';
import styles from './Genre.css';
import RecordingItem from './RecordingItem';
import { connect } from 'react-redux';
import * as analyticsActions from '../../actions/analytics';
import CapacitorRipple from '../capacitor/Ripple';
import { splitLocale } from '../../lib/locale-utils';
import { selectLocale } from '../../selectors/client';
import { FormattedMessage } from 'react-intl';

class GenreContainer extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    genre: PropTypes.shape({
      recordings: PropTypes.array.isRequired,
    }),
    href: PropTypes.string.isRequired,
    track: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  };

  onLinkClick = () => {
    const { track, title } = this.props;
    track('Selected View All', { subCategory: title, category: 'Genre' });
  };

  render() {
    const { title, genre, href, language } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <h2>{title}</h2>
          <p>{genre.summary[language] || genre.summary.en}</p>
          <Link
            /* Apparently due to a bug in css-modules eslint plugin, a class prefixed with a global selector is not recognized */
            /* eslint-disable-next-line css-modules/no-undef-class */
            className={classnames('c-text-link--is-visible', styles.exploreLink)}
            onClick={this.onLinkClick}
            to={href}
          >
            <CapacitorRipple />
            <FormattedMessage id="browse.explore.link" defaultMessage="Explore" />
          </Link>
        </div>
        <div>
          <p className={`${styles.recommendedText} fz--delta`}>
            <FormattedMessage
              id="browse.genre.recommended-works"
              defaultMessage="Recommended Works"
            />
          </p>
          <ul>
            {genre.recordings.map((recording, index) => (
              <RecordingItem contextTitle={title} key={index} recording={recording} />
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const locale = selectLocale(state);
  return {
    language: splitLocale(locale).language,
  };
}

export default connect(mapStateToProps, {
  track: analyticsActions.track,
})(GenreContainer);
