export default {
  3256: [
    {
      id: 25530168,
      title: {
        de: 'Sonate für Klavier Nr. 8 in c-Moll op. 13 "Pathétique"',
        en: "Sonata for Piano No. 8 in C minor op. 13 'Pathétique'",
      },
      workId: 484108,
      composerName: 'Beethoven',
    },
    {
      id: 28854658,
      title: {
        de: 'Italienisches Konzert in F-Dur BWV 971',
        en: 'Italian Concerto in F major BWV 971',
      },
      workId: 482766,
      composerName: 'Bach',
    },
    {
      id: 28176551,
      title: { de: 'Etudes de Concert', en: 'Etudes de Concert' },
      workId: 12589544,
      composerName: 'Chaminade ',
    },
    {
      id: 25644934,
      title: { de: 'Préludes op. 23', en: 'Préludes op. 23' },
      workId: 10912738,
      composerName: 'Rachmaninoff',
    },
    {
      id: 24310889,
      title: { de: 'Etüden für Klavier (1985-2001)', en: 'Études for Piano (1985-2001)' },
      workId: 11605295,
      composerName: 'Ligeti',
    },
  ],
  3265: [
    {
      id: 25639517,
      title: {
        de: 'Sonate für Violine und Klavier Nr. 9 in A-Dur op. 47 "Kreutzer-Sonate"',
        en: "Sonata for Violin and Piano No. 9 in A major op. 47 'Kreutzer'",
      },
      workId: 109814,
      composerName: 'Beethoven',
    },
    {
      id: 16880754,
      title: {
        de: 'Partita für Violine solo Nr. 2 in d-Moll BWV 1004',
        en: 'Partita for Solo Violin No. 2 in D minor BWV 1004',
      },
      workId: 1151459,
      composerName: 'Bach',
    },
    {
      id: 19801839,
      title: {
        de: 'Sonate für Violine und Klavier in A-Dur FWV 8',
        en: 'Sonata for Violin and Piano in A major FWV 8',
      },
      workId: 10061356,
      composerName: 'Franck',
    },
    {
      id: 21259461,
      title: {
        de: 'Capricen für Violine solo op. 1 MS 25',
        en: 'Caprices for Solo Violin op. 1 MS 25',
      },
      workId: 113425,
      composerName: 'Paganini',
    },
    {
      id: 22004160,
      title: {
        de: 'Sonate für Violine und Klavier Nr. 1 in G-Dur op. 78',
        en: 'Sonata for Violin and Piano No. 1 in G major op. 78',
      },
      workId: 109518,
      composerName: 'Brahms',
    },
    {
      id: 27103556,
      title: {
        de: 'Sonate für Violine und Klavier in g-Moll L 148 (140)',
        en: 'Sonata for Violin and Piano in G minor L 148 (140)',
      },
      workId: 6511435,
      composerName: 'Debussy',
    },
  ],
  3277: [
    {
      id: 25638469,
      title: {
        de: 'Suite für Violoncello solo Nr. 1 in G-Dur BWV 1007',
        en: 'Suite for Solo Violoncello No. 1 in G major BWV 1007',
      },
      workId: 1151578,
      composerName: 'Bach',
    },
    {
      id: 28144931,
      title: {
        de: 'Sonate für Violoncello und Klavier Nr. 1 in e-Moll op. 38',
        en: 'Sonata for Violoncello and Piano No. 1 in E minor op. 38',
      },
      workId: 109481,
      composerName: 'Brahms ',
    },
    {
      id: 17605577,
      title: {
        de: 'Sonate für Violoncello und Klavier Nr. 2 in D-Dur op. 58 MWV Q 32',
        en: 'Sonata for Violoncello and Piano No. 2 in D major op. 58 MWV Q 32',
      },
      workId: 11077129,
      composerName: 'Mendelssohn',
    },
    {
      id: 27103651,
      title: {
        de: 'Sonate für Violoncello und Klavier in d-Moll L 144 (135)',
        en: 'Sonata for Violoncello and Piano in D minor L 144 (135)',
      },
      workId: 11011582,
      composerName: 'Debussy',
    },
    {
      id: 25615727,
      title: {
        de: 'Suite für Violoncello solo Nr. 1 op. 72',
        en: 'Suite for Solo Violoncello No. 1 op. 72',
      },
      workId: 11290074,
      composerName: 'Britten',
    },
    {
      id: 19157081,
      title: {
        de: 'Konzert für Violoncello und Orchester in e-Moll op. 85',
        en: 'Concerto for Violoncello and Orchestra in E minor op. 85',
      },
      workId: 112744,
      composerName: 'Elgar ',
    },
  ],
  3316: [
    {
      id: 22960847,
      title: { de: 'Suite für Gitarre Nr. 11 in h-Moll', en: 'Suite for Guitar No. 11 in B minor' },
      workId: 17428690,
      composerName: 'Robert de Visée',
    },
    {
      id: 25458147,
      title: {
        de: 'Grande Ouverture für Gitarre op. 61',
        en: 'Grande Ouverture for Guitar op. 61',
      },
      workId: 11774380,
      composerName: 'Mauro Giuliani',
    },
    {
      id: 26259062,
      title: {
        de: 'Sonate für Violine und Gitarre in a-Moll op. 3/4 MS 27',
        en: 'Sonata for Violin and Guitar in A minor op. 3/4 MS 27',
      },
      workId: 18465391,
      composerName: 'Niccolò Paganini',
    },
    {
      id: 23039733,
      title: {
        de: 'Recuerdos de la Alhambra (Erinnerungen an die Alhambra)',
        en: 'Recuerdos de la Alhambra (Memories of the Alhambra)',
      },
      workId: 442282,
      composerName: 'Francisco Tárrega',
    },
    {
      id: 18648757,
      title: {
        de: 'Sequenza XI für Gitarre solo (1988)',
        en: 'Sequenza XI for Solo Guitar (1988)',
      },
      workId: 11369566,
      composerName: 'Luciano Berio',
    },
    {
      id: 14903019,
      title: {
        de: "Nocturnal after John Dowland: Reflections on 'Come, heavy sleep', op. 70",
        en: "Nocturnal after John Dowland: Reflections on 'Come, heavy sleep', op. 70",
      },
      workId: 12229603,
      composerName: 'Britten',
    },
  ],
  3292: [
    {
      id: 22987746,
      title: {
        de: 'Trio für Klarinette, Viola und Klavier in Es-Dur KV 498 "Kegelstatt-Trio"',
        en: "Trio for Clarinet, Viola and Piano in E flat major KV 498 'Kegelstatt'",
      },
      workId: 10943341,
      composerName: 'Mozart',
    },
    {
      id: 23409089,
      title: {
        de: 'Trio für Violine (Klarinette), Violoncello und Klavier in B-Dur op. 11 "Gassenhauertrio"',
        en: "Trio for Violin (Clarinet), Violoncello and Piano in B flat major op. 11 'Gassenhauertrio'",
      },
      workId: 1157338,
      composerName: 'Beethoven',
    },
    {
      id: 22068915,
      title: {
        de: 'Märchenerzählungen für Klarinette (Violine), Viola und Klavier op. 132',
        en: 'Märchenerzählungen for Clarinet (Violin), Viola and Piano op. 132',
      },
      workId: 1158283,
      composerName: 'Schumann',
    },
    {
      id: 15350565,
      title: {
        de: 'Sonata für Klarinette (Viola) und Klavier Nr. 1 in f-Moll op. 120/1',
        en: 'Sonata for Clarinet (Viola) and Piano No. 1 in F minor op. 120/1',
      },
      workId: 1158534,
      composerName: 'Brahms',
    },
    {
      id: 12227799,
      title: {
        de: 'Sonatine für Klarinette und Klavier H 356 (1956)',
        en: 'Sonatina for Clarinet and Piano H 356 (1956)',
      },
      workId: 12227770,
      composerName: 'Martinů',
    },
    {
      id: 12229070,
      title: { de: 'Kontraste BB 116 Sz. 111 (1938)', en: 'Contrasts BB 116 Sz. 111 (1938)' },
      workId: 7465863,
      composerName: 'Bartók',
    },
  ],
  3298: [
    {
      id: 17467879,
      title: {
        de: 'Konzert für Horn und Orchester in Es-Dur QV 5:Anh. 14',
        en: 'Concerto for Horn and Orchestra in E flat major QV 5:Anh. 14',
      },
      workId: 17467814,
      composerName: 'Quantz',
    },
    {
      id: 23381367,
      title: {
        de: 'Konzert für Horn und Orchester Nr. 3 in Es-Dur KV 447',
        en: 'Concerto for Horn and Orchestra No. 3 in E flat major KV 447',
      },
      workId: 1146475,
      composerName: 'Mozart',
    },
    {
      id: 19063795,
      title: {
        de: 'Konzertstück für 4 Hörner und Orchester in F-Dur op. 86',
        en: 'Konzertstück for 4 Horns and Orchestra in F major op. 86',
      },
      workId: 11597539,
      composerName: 'Schumann',
    },
    {
      id: 22748631,
      title: {
        de: 'Trio für Violine, Horn und Klavier in Es-Dur op. 40',
        en: 'Trio for Violin, Horn and Piano in E flat major op. 40',
      },
      workId: 1158636,
      composerName: 'Brahms',
    },
    {
      id: 27178248,
      title: {
        de: 'Konzert für Horn und Orchester Nr. 1 in Es-Dur op. 11 TrV 117',
        en: 'Concerto for Horn and Orchestra No. 1 in E flat major op. 11 TrV 117',
      },
      workId: 7746128,
      composerName: 'Strauss',
    },
    {
      id: 25608095,
      title: {
        de: 'Serenade für Tenor, Horn und Streichorchester op. 31 (1943)',
        en: 'Serenade for Tenor, Horn and Strings op. 31 (1943)',
      },
      workId: 7985027,
      composerName: 'Britten',
    },
  ],
  11212367: [
    {
      id: 28135315,
      title: { de: '... de la terre (1991)', en: '... de la terre (1991)' },
      workId: 28135287,
      composerName: 'Kaia Saariaho',
    },
    {
      id: 26139789,
      title: { de: 'Butterflies (1973)', en: 'Butterflies (1973)' },
      workId: 26139748,
      composerName: 'Morton Subotnik',
    },
    {
      id: 26139675,
      title: { de: 'Five Stone Wind (1988)', en: 'Five Stone Wind (1988)' },
      workId: 26139658,
      composerName: 'John Cage',
    },
    {
      id: 17699067,
      title: { de: 'Différences (1959)', en: 'Différences (1959)' },
      workId: 17692319,
      composerName: 'Luciano Berio',
    },
    {
      id: 19755329,
      title: {
        de: 'Comfortable Cruising Altitude (2017)',
        en: 'Comfortable Cruising Altitude (2017)',
      },
      workId: 19614691,
      composerName: 'Nico Muhly',
    },
    {
      id: 19755264,
      title: { de: 'Fields (2016)', en: 'Fields (2016)' },
      workId: 19614817,
      composerName: 'Anna Þorvaldsdóttir',
    },
  ],
};
