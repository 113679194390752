import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape, defineMessages } from 'react-intl';

import { selectLocale } from '../../selectors/client';
import { splitLocale } from '../../lib/locale-utils';
import { LANGUAGE_SUPPORT_LINKS, CONSTANT_MESSAGES } from '../../constants';
import styles from './Support.css';

const messages = defineMessages({
  helpPages: {
    id: 'livestream.support.help-pages',
    defaultMessage: 'help pages',
  },
  here: {
    id: 'livestream.support.here',
    defaultMessage: 'here',
  },
});

const Support = ({ currentLocale, intl }) => {
  const helpPagesText = intl.formatMessage(messages.helpPages);
  const ariaHelpPagesLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: helpPagesText,
  });

  const hereText = intl.formatMessage(messages.here);
  const ariaHereLink = intl.formatMessage(CONSTANT_MESSAGES.externalLink, {
    linkText: hereText,
  });

  return (
    <section className={styles.wrapper}>
      <h2>
        <FormattedMessage id="livestream.support.title" defaultMessage="We're here to help" />
      </h2>
      <p>
        <FormattedMessage
          id="livestream.support.body.text"
          defaultMessage="Should you have any questions, problems or suggestions, please have a look at our {helpPages} or get in touch with our team {here}."
          values={{
            helpPages: (
              <a
                className="c-text-link--is-visible"
                href={LANGUAGE_SUPPORT_LINKS[splitLocale(currentLocale).language]}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={ariaHelpPagesLink}
              >
                {helpPagesText}
              </a>
            ),
            here: (
              <a
                className="c-text-link--is-visible"
                href="mailto:contact@idagio.com"
                aria-label={ariaHereLink}
              >
                {hereText}
              </a>
            ),
          }}
        />
      </p>
    </section>
  );
};

Support.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  intl: intlShape,
};

function mapStateToProps(state) {
  return {
    currentLocale: selectLocale(state),
  };
}

export default compose(connect(mapStateToProps, {}), injectIntl)(Support);
