// @flow
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import IconLabel from '../util/IconLabel';

import styles from './SonosGroup.css';

import type { PickSonosGroup } from '../../actions/sonos';

export type SonosGroup = {|
  address: string,
  groupId: string,
  groupName: string,
  householdId: string,
|};

type Props = {
  onClick: PickSonosGroup,
  group: SonosGroup,
  isConnected: boolean,
  allowed: boolean,
};

export default class extends PureComponent<Props> {
  static defaultProps = {
    allowed: true,
  };

  onClick = () => this.props.onClick(this.props.group);

  getIsThisDevice = () => this.props.group.groupId === 'this-device';

  renderGroupName() {
    const { groupName, groupId } = this.props.group;
    if (groupId === 'this-device') {
      return (
        <FormattedMessage id="sonos.group.internal-speaker" defaultMessage="Internal Speaker" />
      );
    }
    return <span>{groupName}</span>;
  }

  renderDeviceType() {
    if (this.getIsThisDevice()) {
      return <FormattedMessage id="sonos.group.this-device" defaultMessage="This device" />;
    }

    return <span>SONOS</span>;
  }

  renderSubscribeButton() {
    return (
      <button className="c-btn c-btn--is-purple" onClick={this.onClick}>
        <FormattedMessage id="promo.sonos-group.subscribe" defaultMessage="Subscribe" />
      </button>
    );
  }

  render() {
    const { allowed, isConnected, group } = this.props;
    const isAllowed = allowed || this.getIsThisDevice();
    const componentStyle = classnames(styles.component);

    return (
      <li className={componentStyle} onClick={this.onClick}>
        <IconLabel name={group.groupId === 'this-device' ? 'laptop' : 'speaker'} title="Device" />
        <div className={styles.info}>
          <div className={styles.name}>{this.renderGroupName()}</div>
          <div className={styles.deviceType}>{this.renderDeviceType()}</div>
        </div>
        {!isAllowed && this.renderSubscribeButton()}
        {isConnected && <IconLabel name="check" title="Device is connected" />}
      </li>
    );
  }
}
