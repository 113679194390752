import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './SonosAuth.css';
import { PropTypes } from 'prop-types';
import SonosStep from './SonosStep';

export default class BackToSonosStep extends Component {
  static propTypes = {
    currentStep: PropTypes.number.isRequired,
    totalSteps: PropTypes.number.isRequired,
  };

  render() {
    const { currentStep, totalSteps } = this.props;
    return (
      <SonosStep
        currentStep={currentStep}
        totalSteps={totalSteps}
        shouldRenderButton={false}
        iconName={'connected'}
      >
        <h2 className={styles.backToSonosTitle}>
          <FormattedMessage
            id="sonos-auth.back-to-sonos.header"
            defaultMessage="You have successfully connected your IDAGIO account to SONOS."
          />
        </h2>
        <br />
        <p className={styles.backToSonosHeading}>
          <FormattedMessage
            id="sonos-auth.back-to-sonos.sonos-controller"
            defaultMessage="Please return to the Sonos Controller App now to complete the authorisation."
          />
        </p>
        <p className={styles.backToSonosHeading}>
          <FormattedMessage
            id="sonos-auth.back-to-sonos.idagio-app"
            defaultMessage="Once completed, you can use the IDAGIO app to stream to your Sonos device - including advanced search and filtering functionality."
          />
        </p>
        <p className={styles.backToSonosHeading}>
          <FormattedMessage
            id="sonos-auth.back-to-sonos.collection-text"
            defaultMessage="Any track or work added to your IDAGIO collection will also be automatically available in the Sonos app."
          />
        </p>
      </SonosStep>
    );
  }
}
