import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import IconLabel from './../util/IconLabel';
import CapacitorRipple from './../capacitor/Ripple';
import styles from './ShareButton.css';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';

const messages = defineMessages({
  add: {
    id: 'playlist.add-to-playlist-button.text',
    defaultMessage: 'Add to playlist',
  },
});

class AddToPlaylistButton extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    hideText: PropTypes.bool.isRequired,

    intl: intlShape,
  };

  static defaultProps = {
    hideText: true,
  };

  render() {
    const { className, hideText, intl } = this.props;

    const buttonClassNames = classnames(styles.shareButton, className);

    const title = intl.formatMessage(messages.add);

    return (
      <button
        className={buttonClassNames}
        onClick={this.props.onClick}
        title={title}
        data-test="add-to-playlist-btn"
      >
        <CapacitorRipple />
        <IconLabel name="add-to-playlist" size={hideText ? 'medium' : 'small'} />
        {!hideText && (
          <span className={styles.text}>
            <FormattedMessage
              id="playlist.add-to-playlist-button.text"
              defaultMessage="Add to playlist"
            />
          </span>
        )}
      </button>
    );
  }
}

export default injectIntl(AddToPlaylistButton);
