// @flow
import type { Request } from './types';

type SendSonosAuthDetailsAction = { type: 'SEND_SONOS_DETAILS' } & Request;
export type SonosAuthAction = SendSonosAuthDetailsAction;

export function sendSonosAuthorizationDetails(
  token: string,
  linkCode: string
): SendSonosAuthDetailsAction {
  return {
    type: 'SEND_SONOS_DETAILS',
    IDAGIO_REQUEST: {
      type: 'API_REQUEST',
      method: 'POST',
      endpoint: '/v2.0/sonos/auth',
      body: {
        token,
        linkCode,
      },
    },
  };
}
