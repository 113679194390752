import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import InfiniteScroll from '../../vendor/InfiniteScroll';

import LoadingIndicator from '../common/LoadingIndicator';
import List from '../util/List';
import RecordingItem from '../recording/RecordingItem';

import * as collectionActions from '../../actions/collection';
import * as analyticsActions from '../../actions/analytics';

import {
  selectRecordingCollection,
  selectCollectionIds,
  selectNextRecordingCollectionCursor,
} from '../../selectors/collection';

import stylesRecordingCollection from './RecordingCollection.css';
import stylesCollection from '../../views/Collection.css';

import dataComponent from '../../hoc/dataComponent';

import CollectionRecordings from '../../components/messages/CollectionRecordings';
import EmptyCollection from './EmptyCollection';

import { ENTITY_TYPE_RECORDING, ENTITY_TYPE_RECORDINGS } from '../../constants';
import protectedComponent from '../../hoc/protectedComponent';
import shareableEntity, { shareableEntityPropTypes } from '../../hoc/shareableEntity';

class RecordingCollection extends Component {
  static propTypes = {
    paginatedRecordings: PropTypes.array,
    recordingIds: PropTypes.array,
    loadRecordingCollection: PropTypes.func.isRequired,
    nextCursor: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    trackPlayPausePressed: PropTypes.func.isRequired,

    ...shareableEntityPropTypes,
  };

  renderRecordingListItem = recording => {
    return (
      <li key={recording.id}>
        <RecordingItem
          recording={recording}
          contextClassName="collection-recording-item"
          beforeTogglePlay={this.beforeTogglePlay}
        />
      </li>
    );
  };

  render() {
    const { paginatedRecordings, recordingIds, isLoading } = this.props;
    if (!recordingIds.length) {
      return (
        <EmptyCollection
          entityType={ENTITY_TYPE_RECORDING}
          showShareModal={this.props.showShareModal}
        />
      );
    }
    return (
      <div data-test="recording-collection.container">
        <div className={stylesCollection.header}>
          <h1 className="fz--beta">
            <CollectionRecordings />
          </h1>
        </div>
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadMore}
          hasMore={!!this.props.nextCursor}
          loader={null}
          scrollableSelector={'.content'}
          useWindow={false}
        >
          <List
            className={stylesRecordingCollection.list}
            items={paginatedRecordings}
            renderItem={this.renderRecordingListItem}
          />
        </InfiniteScroll>
        <LoadingIndicator
          isLoading={isLoading}
          className={stylesRecordingCollection.loadingIndicator}
        />
      </div>
    );
  }

  loadMore = () => {
    this.props.loadRecordingCollection(this.props.nextCursor);
  };

  beforeTogglePlay = (item, playing) => {
    this.props.trackPlayPausePressed({ contextType: 'favouriteRecordings' }, playing);
  };
}

function mapStateToProps(state) {
  return {
    isLoading: state.lists.collection.recordings.loading,
    paginatedRecordings: selectRecordingCollection(state),
    recordingIds: selectCollectionIds(state).entities.recordingIds,
    nextCursor: selectNextRecordingCollectionCursor(state),
  };
}

function fetchData(store) {
  return store.dispatch(collectionActions.loadRecordingCollection());
}

function getShareTrackingContext() {
  return {
    sharedContentType: 'Recording',
    sharedContentId: null,
    contextType: 'favouriteRecordings',
    contentId: null,
  };
}

export default compose(
  protectedComponent({ authenticatedOnly: true }),
  dataComponent(fetchData),
  connect(mapStateToProps, {
    trackPlayPausePressed: analyticsActions.trackPlayPausePressed,
    loadRecordingCollection: collectionActions.loadRecordingCollection,
  }),
  shareableEntity(ENTITY_TYPE_RECORDINGS, getShareTrackingContext)
)(RecordingCollection);
