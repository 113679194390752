// @flow
import React from 'react';
import SectionItem from './SectionItem';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styles from './SectionItem.css';
import StripeCustomerPortalButton from './StripeCustomerPortalButton';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  selectSubscriptionPlanName,
  selectUserCanCancelSubscription,
  selectUserCanManageSubscription,
  selectUserHasStripeDiscount,
  selectUserTrialEndDate,
} from '../../selectors/subscription';
import { selectUserCanSubscribe, selectUserIsPatron } from '../../selectors/user';
import SubscribeNowButton from '../common/SubscribeNowButton';

type OwnProps = {};

type MapStateToProps = {
  planName: string,
  isPatron: boolean,
  userCanSubscribe: boolean,
  userCanManageSubscription: boolean,
  userCanCancel: boolean,
  trialEndDate?: Date,
  discountActive: boolean,
};

type Props = OwnProps & MapStateToProps;

const CurrentPlan = ({
  planName,
  isPatron,
  userCanSubscribe,
  userCanManageSubscription,
  userCanCancel,
  trialEndDate,
  discountActive,
}: Props) => {
  let currentPlanName = '';

  if (!isPatron) {
    currentPlanName = (
      <FormattedMessage id="settings.subs.current-plan.free" defaultMessage="IDAGIO Free" />
    );
  }

  if (planName) {
    currentPlanName = (
      <FormattedMessage
        id="settings.subs.current-plan.patron"
        defaultMessage="{planName}{trialEnd}"
        values={{
          planName,
          trialEnd: trialEndDate && !discountActive && (
            <span
              data-test="settings.account.current-plan.trial-end"
              data-test-trial-end-date={trialEndDate}
            >
              <FormattedMessage
                id="settings.subs.current-plan.trial-end"
                defaultMessage="\u00a0trial until {trialEndDate}"
                values={{
                  trialEndDate: (
                    <FormattedDate
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                      value={trialEndDate}
                    />
                  ),
                }}
              />
            </span>
          ),
        }}
      />
    );
  }

  const Action = () => {
    if (userCanSubscribe) {
      return (
        <div className={classNames(styles.action, styles.btns)}>
          <SubscribeNowButton
            trackingContext={{ trigger: 'settings' }}
            data-test="settings.account.current-plan.subscribe-now"
            className={classNames('c-btn c-btn--is-purple', styles.primaryBtn)}
            onClick={() => {}}
            targetPlan=""
          />
        </div>
      );
    }

    if (userCanManageSubscription) {
      return (
        <div className={classNames(styles.action, styles.btns)}>
          <StripeCustomerPortalButton />
        </div>
      );
    }

    if (!userCanCancel && !userCanSubscribe) {
      return (
        <div className={classNames(styles.action, styles.support)}>
          <FormattedMessage
            id="settings.account.subscription.contact-cancel.text"
            defaultMessage="Please {contactLink} if you want to cancel."
            values={{
              contactLink: (
                <a className="c-text-link--is-visible" href="mailto:contact@idagio.com">
                  <FormattedMessage
                    id="settings.account.payment.contact-support"
                    defaultMessage="contact support"
                  />
                </a>
              ),
            }}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <SectionItem
      title={
        <FormattedMessage
          id="settings.account.subscription.current-plan.title"
          defaultMessage="Current IDAGIO Plan:"
        />
      }
    >
      <span className={styles.value} data-test="settings.account.current-plan.plan-name">
        {currentPlanName}
      </span>
      <Action />
    </SectionItem>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    planName: selectSubscriptionPlanName(state),
    isPatron: selectUserIsPatron(state),
    userCanSubscribe: selectUserCanSubscribe(state),
    userCanManageSubscription: selectUserCanManageSubscription(state),
    userCanCancel: selectUserCanCancelSubscription(state),
    trialEndDate: selectUserTrialEndDate(state),
    discountActive: selectUserHasStripeDiscount(state),
  };
}

export default connect(mapStateToProps)(CurrentPlan);
