import React, { Component } from 'react';
import { CHROME_CLEAN, LOGIN_INTENT } from '../constants';
import SonosAuthentication from '../components/sonos/SonosAuthentication';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import chromeComponent from '../hoc/chromeComponent';

class SonosLogin extends Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
  };

  render() {
    const linkCode = this.props.router.location.query.linkCode;
    return <SonosAuthentication intent={LOGIN_INTENT} linkCode={linkCode} />;
  }
}

export default compose(chromeComponent({ type: CHROME_CLEAN }))(SonosLogin);
