import React from 'react';

import styles from './BlurFilterSvg.css';

export default () => (
  <svg className={styles.hidden}>
    <filter id="sharpBlur">
      <feGaussianBlur stdDeviation="2" />
      <feColorMatrix type="matrix" values="1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 9 0" />
      <feComposite in2="SourceGraphic" operator="in" />
    </filter>
  </svg>
);
