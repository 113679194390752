// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dataComponent from '../hoc/dataComponent';
import { Link } from 'react-router';
import { defineMessages, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import qs from 'qs';

import { loadInstruments } from '../actions/instrument';
import { selectInstruments } from '../selectors/categories';
import { selectFilteredItems, selectSearchQuery } from '../selectors/localListSearch';

import * as localListSearchActions from '../actions/localListSearch';

import List from '../components/util/List';
import metaInjector from '../hoc/metaInjector';
import LocalListSearch from '../components/common/LocalListSearch';
import type { Category } from '../shapes/types';
import styles from './Instruments.css';
import CapacitorHeaderBar from '../components/capacitor/HeaderBar';
import shareableEntity from '../hoc/shareableEntity';
import { ENTITY_TYPE_INSTRUMENTS } from '../constants';

declare var __CAPACITOR__: boolean;

const messages = defineMessages({
  metaTitle: {
    id: 'instruments.meta.title',
    defaultMessage: 'Instruments',
  },
  metaDescription: {
    id: 'instruments.meta.description',
    defaultMessage:
      'Quickly find and play classical music featuring various instruments from our curated repertoire, including recordings from classical masters and contemporary artists.',
  },
  metaKeywords: {
    id: 'instruments.meta.keywords',
    defaultMessage:
      'classical music piano, classical instrumental music, classical violin music, classical guitar music',
  },
});

type OwnProps = {
  location: Object,
  router: Object,
  showShareModal: Function,
};

type MapStateToProps = {
  items: Array<Category>,
  searchQuery?: string,
};

type DispatchProps = {
  setSearchQuery: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps & { intl: IntlShape };

const Instruments = ({
  location,
  router,
  items,
  searchQuery,
  setSearchQuery,
  showShareModal,
  intl,
}: Props) => {
  const renderItem = ({ id, title }: Category) => {
    return (
      <li key={id} className={styles.item}>
        <Link to={`/instruments/${id}`}>{title}</Link>
      </li>
    );
  };

  const onSearchBlur = () => {
    const query = { ...location.query, query: searchQuery };
    if (!searchQuery) {
      delete query.query;
    }
    const target = location.pathname + '?' + qs.stringify(query);
    router.push(target);
  };

  const title = intl.formatMessage(messages.metaTitle);

  return (
    <div className="u-page-container">
      {__CAPACITOR__ && (
        <CapacitorHeaderBar title={title} onlyShowTitleOnScroll showShareModal={showShareModal} />
      )}
      <h1>{title}</h1>
      <LocalListSearch
        query={searchQuery}
        onSearchChange={setSearchQuery}
        onSearchBlur={onSearchBlur}
        id="instruments-search-box-input"
        count={items ? items.length : 0}
        type="instruments"
        className={styles.searchBox}
      />
      <List items={items} renderItem={renderItem} />
    </div>
  );
};

const searchKey = 'instruments';
function fetchData(store, params, location) {
  const query = location.query.query || '';
  store.dispatch(localListSearchActions.setSearchQuery(searchKey, query));
  return store.dispatch(loadInstruments());
}

function mapStateToProps(state: Object): MapStateToProps {
  const searchQuery = selectSearchQuery(state, searchKey);
  const filteredItems = selectFilteredItems(selectInstruments(state), searchQuery, searchKey);

  return {
    items: filteredItems,
    searchQuery,
  };
}

function getMetaInformation(props: Props) {
  const { intl } = props;
  const { formatMessage } = intl;
  return {
    title: formatMessage(messages.metaTitle),
    description: formatMessage(messages.metaDescription),
    keywords: formatMessage(messages.metaKeywords),
  };
}

const dispatchProps: DispatchProps = {
  setSearchQuery: query => localListSearchActions.setSearchQuery(searchKey, query),
};

function getShareTrackingContext() {
  return {
    sharedContentType: 'Instrument',
    sharedContentId: null,
    contextType: 'Category',
    contentId: null,
  };
}

export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, dispatchProps),
  injectIntl,
  metaInjector(getMetaInformation),
  shareableEntity(ENTITY_TYPE_INSTRUMENTS, getShareTrackingContext)
)(Instruments);
