import { selectPlayerIsPlaying, selectPlayerQueueOrigin } from './player';
import {
  DEFAULT_ALBUM_SORT_ORDER,
  BOOLEAN_FACET_PARAMS_VALUE,
  DEFAULT_PLAYLIST_SORT_ORDER,
  DEFAULT_RECORDING_SORT_ORDER,
  DEFAULT_WORK_SORT_ORDER,
  ENTITY_TYPE_ALBUM,
  ENTITY_TYPE_PLAYLIST,
  ENTITY_TYPE_RECORDING,
  ENTITY_TYPE_WORK,
  QUEUE_TYPE_FILTERED_RECORDING_LIST,
} from '../constants';
import { createSelector } from 'reselect';

export function selectFilteredListQueued(state, id, type = QUEUE_TYPE_FILTERED_RECORDING_LIST) {
  const queueOrigin = selectPlayerQueueOrigin(state);
  return !!queueOrigin && queueOrigin.type === type && queueOrigin.id === id;
}

export function selectFilteredListIsPlaying(state, id, type = QUEUE_TYPE_FILTERED_RECORDING_LIST) {
  const isQueued = selectFilteredListQueued(state, id, type);
  const playerIsPlaying = selectPlayerIsPlaying(state);
  return isQueued && playerIsPlaying;
}

function getDefaultSortOrder(type) {
  switch (type) {
    case ENTITY_TYPE_RECORDING:
      return DEFAULT_RECORDING_SORT_ORDER;
    case ENTITY_TYPE_ALBUM:
      return DEFAULT_ALBUM_SORT_ORDER;
    case ENTITY_TYPE_WORK:
      return DEFAULT_WORK_SORT_ORDER;
    case ENTITY_TYPE_PLAYLIST:
      return DEFAULT_PLAYLIST_SORT_ORDER;
    default:
      return DEFAULT_RECORDING_SORT_ORDER;
  }
}

export const selectSortOrder = (query, type) => query.sort || getDefaultSortOrder(type);

export const selectFilterParams = createSelector(
  [
    query => query,
    (query, id) => id,
    (query, id, idKey) => idKey,
    (query, id, idKey, sort) => sort,
    (query, id, idKey, sort, searchTerm) => searchTerm,
    (query, id, idKey, sort, searchTerm, isCompleteWork) => isCompleteWork,
    (query, id, idKey, sort, searchTerm, isCompleteWork, isOriginalWork) => isOriginalWork,
  ],
  (query, id, idKey, sort, searchTerm, isCompleteWork, isOriginalWork) => {
    const response = {
      ...query,
      sort,
      searchTerm,
      isCompleteWork,
      isOriginalWork,
      [idKey]: id,
    };
    for (const key in response) {
      if (!response[key] || response[key] === 'unset') {
        delete response[key];
      }
    }
    return response;
  }
);

export function selectIsCompleteWork(query) {
  if (query.isCompleteWork !== undefined) {
    return query.isCompleteWork;
  }

  return BOOLEAN_FACET_PARAMS_VALUE.isCompleteWork
    ? BOOLEAN_FACET_PARAMS_VALUE.isCompleteWork.default
    : 'unset';
}

export function selectIsOriginalWork(query) {
  if (query.isOriginalWork !== undefined) {
    return query.isOriginalWork;
  }

  return BOOLEAN_FACET_PARAMS_VALUE.isOriginalWork
    ? BOOLEAN_FACET_PARAMS_VALUE.isOriginalWork.default
    : 'unset';
}

export function selectWorksFilterParams(query, profileId, searchTerm) {
  return selectFilterParams(
    query,
    profileId,
    'composers',
    selectSortOrder(query, ENTITY_TYPE_WORK),
    searchTerm || query.searchTerm,
    selectIsCompleteWork(query),
    selectIsOriginalWork(query)
  );
}

export function selectAlbumsFilterParams(query, profileId) {
  return selectFilterParams(query, profileId, 'artists', selectSortOrder(query, ENTITY_TYPE_ALBUM));
}

export function selectPlaylistsFilterParams(query, profileId) {
  return selectFilterParams(
    query,
    profileId,
    'artist_id',
    selectSortOrder(query, ENTITY_TYPE_PLAYLIST)
  );
}

export function selectRecordingsFilterParams(query, profileId, idKey) {
  return selectFilterParams(
    query,
    profileId,
    idKey || 'artists',
    selectSortOrder(query, ENTITY_TYPE_RECORDING),
    undefined,
    selectIsCompleteWork(query)
  );
}
