import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as analyticsActions from '../../actions/analytics';

import ArtistAvatar from '../common/ArtistAvatar';
import CapacitorRipple from '../capacitor/Ripple';
import styles from './ArtistCollectionElement.css';

class ArtistCollectionElement extends Component {
  static propTypes = {
    analyticsTrack: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  };

  onItemClick = () => {
    const { id } = this.props;
    this.props.analyticsTrack('Selected Artist From Collection', { id });
  };

  render() {
    const { id, name } = this.props;
    return (
      <Link
        to={`/profiles/${id}`}
        className={styles.profileLink}
        onClick={this.onItemClick}
        data-test="artist-collection-element.link"
      >
        <CapacitorRipple />
        <ArtistAvatar className={styles.avatar} profileId={id} />
        <span data-test="artist-collection-element.name">{name}</span>
      </Link>
    );
  }
}

export default connect(null, { analyticsTrack: analyticsActions.track })(ArtistCollectionElement);
