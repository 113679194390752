// @flow
// $FlowFixMe
import React, { useMemo } from 'react';
import { map, includes, partition } from 'lodash';

import styles from './FacetGroupContent.css';
import FacetGroupContentHeader from './FacetGroupContentHeader';
import FacetGroupContentList from './FacetGroupContentList';
import { doesFacetExist } from '../../selectors/facet';

type OwnProps = {
  type: string,
  results: Array<any>,
  activeFacetList: Array<any>,
  selectedFacetIds?: Array<number | string | boolean>,
  location: Object,
  onClickFacet?: Function,
  onClickClear?: Function,
  isBoolean: boolean,
  showActiveFacetsSeparately?: boolean,
  handleMultipleComposers?: boolean,
  shouldSortItems?: boolean,
};

type Props = OwnProps;

const FacetGroupContent = ({
  type,
  selectedFacetIds,
  location,
  onClickFacet,
  onClickClear,
  isBoolean,
  showActiveFacetsSeparately,
  activeFacetList,
  results,
  handleMultipleComposers,
  shouldSortItems,
}: Props) => {
  const getFilterIsActive = (id: number | string | boolean) => {
    if (selectedFacetIds) {
      return includes(selectedFacetIds, id);
    }

    const activeFacetIds = map(activeFacetList, facet => (isBoolean ? facet.value : facet.id));
    return includes(activeFacetIds, id);
  };

  const [existingActiveFacets, resultsWithoutActiveFacets] = useMemo(
    () => partition(results, facet => doesFacetExist(activeFacetList, facet, type)),
    [results, activeFacetList, type]
  );

  const newActiveFacets = useMemo(
    () => activeFacetList.filter(facet => !doesFacetExist(results, facet, type)),
    [results, activeFacetList, type]
  );

  const allActiveFacets = [...newActiveFacets, ...existingActiveFacets];
  const resultsWithActiveFacets = [...allActiveFacets, ...resultsWithoutActiveFacets];

  return (
    <React.Fragment>
      {!isBoolean && (
        <div className={styles.activeFacets}>
          <FacetGroupContentHeader
            location={location}
            type={type}
            activeFacets={selectedFacetIds || allActiveFacets}
            onClickClear={onClickClear}
          />
        </div>
      )}
      <div className={styles.list} data-test="facet.group.content-list">
        <FacetGroupContentList
          type={type}
          results={
            showActiveFacetsSeparately ? resultsWithoutActiveFacets : resultsWithActiveFacets
          }
          location={location}
          onClickFacet={onClickFacet}
          showActiveFacetsSeparately={showActiveFacetsSeparately}
          activeFacets={allActiveFacets}
          isBoolean={isBoolean}
          getFilterIsActive={getFilterIsActive}
          handleMultipleComposers={handleMultipleComposers}
          shouldSortItems={shouldSortItems}
        />
      </div>
    </React.Fragment>
  );
};

export default FacetGroupContent;
