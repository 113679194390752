// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import SectionItem from './SectionItem';
import styles from './SectionItem.css';
import classNames from 'classnames';

type OwnProps = {
  email: string,
};

const Email = ({ email }: OwnProps) => {
  return (
    <SectionItem
      title={<FormattedMessage id="settings.account.change-email.title" defaultMessage="Email:" />}
    >
      <span className={styles.value} data-test="account.email.value">
        {email}
      </span>
      <div className={classNames(styles.action, styles.support)}>
        <FormattedMessage
          id="settings.account.contact-change-email.text"
          defaultMessage="Please {contactLink} to change your email address."
          values={{
            contactLink: (
              <a className="c-text-link--is-visible" href="mailto:contact@idagio.com">
                <FormattedMessage
                  id="settings.account.payment.contact-support"
                  defaultMessage="contact support"
                />
              </a>
            ),
          }}
        />
      </div>
    </SectionItem>
  );
};

export default Email;
