import React, { Component } from 'react';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

export default function trackedViewComponent(name, trackingProps = {}, options = {}) {
  const { withRef = false } = options;

  return WrappedComponent => {
    class TrackedViewComponent extends Component {
      render() {
        return <WrappedComponent {...this.props} ref={withRef ? 'wrappedInstance' : null} />;
      }
    }

    TrackedViewComponent.isTrackedViewComponent = true;

    TrackedViewComponent.explicitTarget = {
      name,
      displayName: name,
      trackingProps,
    };

    TrackedViewComponent.displayName = `TrackedViewComponent(${getDisplayName(WrappedComponent)})`;

    TrackedViewComponent.WrappedComponent = WrappedComponent;

    return TrackedViewComponent;
  };
}
