// @flow
// $FlowFixMe
import React, { useMemo } from 'react';

import styles from './Facet.css';
import FacetList from './FacetList';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  items: { [key: string]: Array<any> },
  activeFacets: Array<any>,
  type: string,
  location: Object,
  onClickFacet?: Function,
  isBoolean: boolean,
  getFilterIsActive: Function,
  showActiveFacetsSeparately?: boolean,
  shouldSortItems?: boolean,
};

type Props = OwnProps;

type GroupProps = {
  type: string,
  location: Object,
  onClickFacet?: Function,
  isBoolean: boolean,
  getFilterIsActive: Function,
  groupName: string,
  groupItems: Array<any>,
};

const Group = ({
  groupName,
  groupItems,
  type,
  location,
  onClickFacet,
  isBoolean,
  getFilterIsActive,
}: GroupProps) => {
  return (
    <div key={groupName} className={styles.listGroup} data-test="facet.group.content-list.group">
      <div className={styles.groupName}>{groupName}</div>
      <FacetList
        items={groupItems}
        type={type}
        location={location}
        onClickFacet={onClickFacet}
        isBoolean={isBoolean}
        getFilterIsActive={getFilterIsActive}
      />
    </div>
  );
};

const FacetListGrouped = ({
  items,
  type,
  location,
  onClickFacet,
  activeFacets,
  isBoolean,
  getFilterIsActive,
  showActiveFacetsSeparately,
  shouldSortItems,
}: Props) => {
  const sortedItemsArray = useMemo(() => {
    const itemsArray = Object.entries(items);
    return shouldSortItems
      ? itemsArray.sort((a, b) => {
          // Sorts by group name
          return a[0].localeCompare(b[0]);
        })
      : itemsArray;
  }, [items, shouldSortItems]);

  if (__CAPACITOR__) {
    const flatItems = [];
    if (showActiveFacetsSeparately) {
      flatItems.push(...activeFacets);
    }
    for (const [groupName, groupItems] of sortedItemsArray) {
      flatItems.push({
        title: groupName,
        isGroupTitle: true,
      });
      flatItems.push(...groupItems);
    }

    return (
      <FacetList
        items={flatItems}
        isVirtualized
        type={type}
        location={location}
        onClickFacet={onClickFacet}
        isBoolean={isBoolean}
        getFilterIsActive={getFilterIsActive}
        withStickyHeaders
      />
    );
  }

  return (
    <React.Fragment>
      {showActiveFacetsSeparately && (
        <div data-test="facet.group.content-list.group.active-facets">
          <FacetList
            items={activeFacets}
            type={type}
            location={location}
            onClickFacet={onClickFacet}
            isBoolean={isBoolean}
            getFilterIsActive={getFilterIsActive}
          />
        </div>
      )}
      {sortedItemsArray.map(([groupName, groupItems]) => (
        <Group
          key={groupName}
          groupName={groupName}
          groupItems={groupItems}
          type={type}
          location={location}
          onClickFacet={onClickFacet}
          isBoolean={isBoolean}
          getFilterIsActive={getFilterIsActive}
        />
      ))}
    </React.Fragment>
  );
};

export default FacetListGrouped;
