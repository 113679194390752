import { createSelector } from 'reselect';

import { selectPlaylistIdFromSlug } from './playlist';
import { selectArtistIdFromSlug } from './profile';
import { selectAlbumIdFromSlug } from './album';

export const selectCtaTrackingContext = createSelector(
  state => state,
  (state, slug) => slug,
  (state, slug, contextType) => contextType,
  (state, slug, contextType) => {
    const trackingContext = { contextType };
    switch (contextType) {
      case 'Profile':
        trackingContext.contextId = selectArtistIdFromSlug(state, slug);
        break;
      case 'Album':
        trackingContext.contextId = selectAlbumIdFromSlug(state, slug);
        break;
      case 'Playlist':
        trackingContext.contextId = selectPlaylistIdFromSlug(state, slug);
        break;
      default:
        trackingContext.contextId = null;
        break;
    }
    return trackingContext;
  }
);
