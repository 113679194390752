// @flow
import React from 'react';
import classnames from 'classnames';

import styles from './Facet.css';
import List from '../util/List';
import VirtualizedList from '../util/VirtualizedList';
import Facet from './Facet';

type OwnProps = {
  items: Array<any>,
  activeFacets?: Array<any>,
  isVirtualized?: boolean,
  type: string,
  location: Object,
  onClickFacet?: Function,
  isBoolean: boolean,
  getFilterIsActive: Function,
  showActiveFacetsSeparately?: boolean,
  withStickyHeaders?: boolean,
};

type Props = OwnProps;

const FacetList = ({
  items,
  activeFacets,
  type,
  location,
  onClickFacet,
  isBoolean,
  getFilterIsActive,
  isVirtualized,
  showActiveFacetsSeparately,
  withStickyHeaders,
}: Props) => {
  const renderFacet = (facet, style, registerChild) => {
    const { id, value } = facet;
    const { pathname, query } = location;

    const facetClassName = classnames(styles.isFacetGrp, {
      [styles.isActive]: getFilterIsActive(isBoolean ? value : id),
    });

    if (facet.isGroupTitle) {
      return (
        <li key={facet.title} className={styles.groupTitle} style={style} ref={registerChild}>
          {facet.title}
        </li>
      );
    }
    return (
      <li key={isBoolean ? type + value : id} style={style} ref={registerChild}>
        <Facet
          type={type}
          facet={facet}
          pathname={pathname}
          query={query}
          className={facetClassName}
          onClickFacet={onClickFacet}
          isBoolean={isBoolean}
        />
      </li>
    );
  };

  const itemsToRender =
    showActiveFacetsSeparately && activeFacets ? [...activeFacets, ...items] : items;

  if (isVirtualized) {
    return (
      <VirtualizedList
        items={itemsToRender}
        renderItem={renderFacet}
        getHeaderTitleFromItem={item => item.groupTitle}
        withStickyHeaders={withStickyHeaders}
      />
    );
  }

  return <List items={itemsToRender} renderItem={facet => renderFacet(facet)} />;
};

export default FacetList;
