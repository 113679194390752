import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BrowseElement from './BrowseElement';
import stylesBrowseVerticalElementList from './BrowseVerticalElementList.css';

export default class BrowseVerticalElementList extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        href: PropTypes.string,
      })
    ).isRequired,
    title: PropTypes.string.isRequired,
  };

  render() {
    const { items, title } = this.props;
    return (
      <li>
        <h1 className="fz--gamma">{title}</h1>
        <ul className={stylesBrowseVerticalElementList.list}>
          {items.map(item => {
            return (
              <li key={item.id}>
                <BrowseElement id={item.id} name={item.name} href={`/profiles/${item.id}`} />
              </li>
            );
          })}
        </ul>
      </li>
    );
  }
}
