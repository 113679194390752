import { compose } from 'redux';
import { connect } from 'react-redux';

import dataComponent from '../hoc/dataComponent';
import trackedViewComponent from '../hoc/trackedViewComponent';

import { loadRecordingsByFilter } from '../actions/recording';

import { getCategoryFetchData, getCategoryMapStateToProps } from '../utils/category-page';

import CategoryPage from '../components/common/CategoryPage';

import { selectInstrument } from '../selectors/categories';
import { loadInstrument } from '../actions/instrument';

const contextFacet = 'Instrument';
const facetType = 'instruments';

const fetchData = getCategoryFetchData(loadInstrument, facetType);
const mapStateToProps = getCategoryMapStateToProps(selectInstrument, facetType, contextFacet);
const trackingProps = (state, params) => ({ id: params.id });

export default compose(
  trackedViewComponent(contextFacet, trackingProps),
  dataComponent(fetchData),
  connect(mapStateToProps, { loadMore: loadRecordingsByFilter }, (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
  }))
)(CategoryPage);
