import { createSelector } from 'reselect';
import { includes, indexOf, map, sortBy } from 'lodash';

import getSerializedFilterId from '../utils/getSerializedFilterId';
import filterAndSortParams from '../utils/filterAndSortParams';

import {
  BOOLEAN_FACET_PARAMS,
  DEFAULT_FACET_FILTER_ORDER,
  VALID_ALBUM_FACET_PARAMS,
  VALID_RECORDING_FACET_PARAMS,
  VALID_WORK_FACET_PARAMS,
  NULLABLE_BOOLEAN_FACET_PARAMS,
} from '../constants';

// Helper functions

function addItemByComposer(composerInfo, groupedData, workItem) {
  const { surname, forename } = composerInfo;
  const name = [surname, forename].filter(Boolean).join(', ');
  if (!groupedData[name]) {
    groupedData[name] = [];
  }
  groupedData[name] = [...groupedData[name], { ...workItem, groupTitle: name }];
  return groupedData;
}

function groupByComposer(facet, handleMultipleComposers) {
  const groupedData = {};

  facet.forEach(item => {
    if (!handleMultipleComposers) {
      addItemByComposer(item.composer, groupedData, item);
    } else {
      item.authors.forEach(author => {
        if (['composer', 'composer_original_work'].includes(author.key)) {
          author.persons.forEach(composer => {
            addItemByComposer(composer, groupedData, item);
          });
        }
      });
    }
  });
  if (Object.keys(groupedData).length === 1) {
    // If there is only one composer, return the facet as is, no need to group
    // it because it's obvious who the composer is
    return groupedData[Object.keys(groupedData)[0]];
  }
  return groupedData;
}

export function groupFacet(facet, type, handleMultipleComposers) {
  switch (type) {
    case 'works': {
      return groupByComposer(facet, handleMultipleComposers);
    }
    default:
      return facet;
  }
}

export function sortFacet(facet, type) {
  if (facet && facet.length) {
    switch (type) {
      case 'composers':
      case 'conductors':
      case 'soloists':
        return sortBy(facet, 'surname');
      case 'works':
      case 'instruments':
      case 'genres':
      case 'epochs':
        return sortBy(facet, 'title');
      case 'ensembles':
      case 'recordingTypes':
        return sortBy(facet, 'name');
      default:
        return facet;
    }
  }

  return facet;
}

export const selectIsBoolean = type => {
  return includes(BOOLEAN_FACET_PARAMS, type);
};

export const selectIsNullableBooleanFacetGroup = type => {
  return includes(NULLABLE_BOOLEAN_FACET_PARAMS, type);
};

export const selectIsFacetGroupWithAcceptableSingleEntry = type => {
  return includes([...BOOLEAN_FACET_PARAMS, 'recordingTypes'], type);
};

export const doesFacetExist = (facets, facetToAdd, facetType) => {
  return facets.some(facet =>
    selectIsBoolean(facetType) ? facet.value === facetToAdd.value : facet.id === facetToAdd.id
  );
};

// Top facets selectors

const createSelectFilterTopFacets = (topFacetsSelector, validParams) =>
  createSelector(
    [topFacetsSelector, (state, filterParams) => filterParams],
    (topFacetsByFilter, filterParams) => {
      const validAndSortedParams = filterAndSortParams(filterParams, validParams);
      const compoundId = getSerializedFilterId(validAndSortedParams, validParams);
      return topFacetsByFilter[compoundId].entities.topFacets;
    }
  );

export const selectRecordingFilterTopFacets = createSelectFilterTopFacets(
  state => state.maps.topRecordingFacetsByFilter,
  VALID_RECORDING_FACET_PARAMS
);

export const selectAlbumFilterTopFacets = createSelectFilterTopFacets(
  state => state.maps.topAlbumFacetsByFilter,
  VALID_ALBUM_FACET_PARAMS
);

export const selectWorkFilterTopFacets = createSelectFilterTopFacets(
  state => state.maps.topWorkFacetsByFilter,
  VALID_WORK_FACET_PARAMS
);

export const selectPlaylistFilterTopFacets = () => ({});

export const addActiveFacetsGroupIfNotExist = (activeFacets, topFacets) => {
  const mergedTopFacets = { ...topFacets };
  activeFacets.forEach(({ type }) => {
    // Add facet type as key to mergedTopFacets if it doesn't exist yet
    if (!mergedTopFacets[type]) {
      mergedTopFacets[type] = { count: 0, results: [], activeFacetsArePresent: true };
    }
  });

  return mergedTopFacets;
};

export const createSelectSortedTopFacetsGroups = createSelector(
  [
    topFacets => topFacets,
    (topFacets, facetOrder) => facetOrder,
    (topFacets, facetOrder, activeFacets) => activeFacets,
  ],
  (topFacets, facetOrder, activeFacets) => {
    // merge active facets with top facets to always display active facets
    const topFacetsWithActiveFacetsGroups = addActiveFacetsGroupIfNotExist(activeFacets, topFacets);

    return sortBy(
      map(topFacetsWithActiveFacetsGroups, (facet, type) => {
        return {
          type,
          count: facet.count,
          results: facet.results,
          activeFacetsArePresent: facet.activeFacetsArePresent,
        };
      }),
      ({ type }) => indexOf(facetOrder || DEFAULT_FACET_FILTER_ORDER, type)
    );
  }
);

// Active facets selectors

const createSelectActiveFacets = (topFacetsSelector, validParams) =>
  createSelector(
    [
      state => topFacetsSelector(state),
      (state, filterParams) => filterParams,
      (state, filterParams, ignoredKey) => ignoredKey,
    ],
    (topFacetsByFilter, filterParams, ignoredKey) => {
      const validAndSortedParams = filterAndSortParams(filterParams, validParams);
      const compoundId = getSerializedFilterId(validAndSortedParams, validParams);
      return topFacetsByFilter[compoundId].entities.activeFacets.filter(
        ({ type }) => type !== ignoredKey
      );
    }
  );

export const selectActiveRecordingFacets = createSelectActiveFacets(
  state => state.maps.topRecordingFacetsByFilter,
  VALID_RECORDING_FACET_PARAMS
);

export const selectActiveAlbumFacets = createSelectActiveFacets(
  state => state.maps.topAlbumFacetsByFilter,
  VALID_ALBUM_FACET_PARAMS
);

export const selectActiveWorkFacets = createSelectActiveFacets(
  state => state.maps.topWorkFacetsByFilter,
  VALID_WORK_FACET_PARAMS
);

export const selectPlaylistActiveFacets = () => [];

// All facets selectors
export const selectAllFacetsById = allFacetsSelector =>
  createSelector([allFacetsSelector, (state, id) => id], (allFacets, id) => {
    if (!allFacets[id] || !allFacets[id].entities) {
      return null;
    }

    return allFacets[id].entities;
  });

export const selectAllRecordingFacets = state => state.maps.recordingFacets;
export const selectAllAlbumFacets = state => state.maps.albumFacets;
export const selectAllWorkFacets = state => state.maps.workFacets;

export const getCompoundKey = (id, filterType, type) => {
  return [id, filterType, 'facet', type].join('--');
};
