import { splitLocale, switchUrlLanguage } from '../lib/locale-utils';
import { selectLocale } from './client';
import { DEFAULT_LOCALE } from '../constants';

export function selectIsOnLandingPage(state) {
  const location = state.routing.locationBeforeTransitions;
  const currentLangauge = splitLocale(selectLocale(state)).language;
  const defaultLanguage = splitLocale(DEFAULT_LOCALE).language;
  const lpPathnameInCurrentLocale = switchUrlLanguage('/', defaultLanguage, currentLangauge);
  return !!location && location.pathname === lpPathnameInCurrentLocale;
}
