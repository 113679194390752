import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Collection.css';

export default class Collection extends Component {
  static propTypes = {
    children: PropTypes.object,
  };

  render() {
    return <div className={`u-page-container ${styles.component}`}>{this.props.children}</div>;
  }
}
