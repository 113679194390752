// @flow
// $FlowFixMe
import React, { useEffect, useState } from 'react';
import {
  List as VirtualizedList,
  AutoSizer,
  CellMeasurerCache,
  CellMeasurer,
} from 'react-virtualized';
import styles from './VirtualizedList.css';

type OwnProps = {
  items: Array<any>,
  renderItem: Function,
  className?: string,
  getHeaderTitleFromItem?: Function,
  withStickyHeaders?: boolean,
};

const cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 41,
  defaultHeight: 41,
});

const List = ({
  items,
  renderItem,
  className,
  getHeaderTitleFromItem,
  withStickyHeaders,
}: OwnProps) => {
  const [headerTitle, setHeaderTitle] = useState(null);

  useEffect(() => {
    // When items change (for example if user searched), the heights can change
    // because some items may break over multiple lines
    cache.clearAll();
  }, [items]);

  const handleRowsRendered = info => {
    let title = getHeaderTitleFromItem && getHeaderTitleFromItem(items[info.startIndex]);
    if (info.startIndex < 2) {
      // If scrolled to the top, we don't want a sticky header because it would
      // only cover the header inside the list
      title = null;
    } else if (items.length > info.startIndex + 1) {
      title = getHeaderTitleFromItem && getHeaderTitleFromItem(items[info.startIndex + 1]);
      if (!title) {
        return;
      }
    }
    if (title !== headerTitle) {
      setHeaderTitle(title);
    }
  };

  return (
    <div className={styles.listWrapper}>
      <AutoSizer>
        {({ width, height }) => (
          <VirtualizedList
            onRowsRendered={withStickyHeaders ? handleRowsRendered : undefined}
            className={className}
            rowCount={items.length}
            overscanRowCount={10}
            rowHeight={cache.rowHeight}
            width={width}
            height={height}
            rowRenderer={({ index, style, parent }) => (
              <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={items[index].id}
                parent={parent}
                rowIndex={index}
              >
                {({ registerChild }) => renderItem(items[index], style, registerChild)}
              </CellMeasurer>
            )}
            containerRole="list"
            role="none"
          />
        )}
      </AutoSizer>
      {withStickyHeaders && headerTitle && <div className={styles.stickyHeader}>{headerTitle}</div>}
    </div>
  );
};

export default List;
