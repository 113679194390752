// @flow
import React from 'react';
import { compose } from 'redux';
import dataComponent from '../hoc/dataComponent';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import * as meActions from '../actions/me';

import Subscription from '../components/settings/Subscription';

import Head from '../components/chrome/Head';

import styles from './Settings.css';

import type { IntlShape } from 'react-intl';
import protectedComponent from '../hoc/protectedComponent';
import Section from '../components/settings/Section';
import Account from '../components/settings/Account';
import App from '../components/settings/App';

declare var __ELECTRON__: boolean;

type OwnProps = {};

type Props = OwnProps & { intl: IntlShape };

const messages = defineMessages({
  metaTitle: {
    id: 'settings.meta.title',
    defaultMessage: 'Settings',
  },
});

const Settings = ({ intl }: Props) => {
  const { formatMessage } = intl;

  return (
    <div className={styles.acc}>
      <Head title={formatMessage(messages.metaTitle)} />
      <h1 className={styles.header}>
        <FormattedMessage id="settings.header" defaultMessage="Settings" />
      </h1>
      <Section
        id="account"
        title={<FormattedMessage id="settings.account.header" defaultMessage="Account" />}
      >
        <Account />
      </Section>
      <Section
        id="subscription"
        title={<FormattedMessage id="settings.subs.header" defaultMessage="Subscription" />}
      >
        <Subscription />
      </Section>
      <Section id="app" title={<FormattedMessage id="settings.app.header" defaultMessage="App" />}>
        <App />
      </Section>
    </div>
  );
};

function fetchData(store) {
  return store.dispatch(meActions.loadMe());
}

export default compose(
  protectedComponent({ authenticatedOnly: true }),
  dataComponent(fetchData),
  injectIntl
)(Settings);
