// @flow
// $FlowFixMe
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingIndicator from '../common/LoadingIndicator';

import { selectCaptchaType } from '../../selectors/client';
import CaptchaForm from '../common/CaptchaForm';
import { connect } from 'react-redux';
import { CAPTCHA_ACTION_CHANGE_PW } from '../../constants';
import PasswordInput from '../common/PasswordInput';
import SectionItem from './SectionItem';
import styles from './SectionItem.css';
import classNames from 'classnames';
import PasswordMismatch from '../messages/PasswordMismatch';
import InvalidPassword from '../messages/InvalidPassword';
import { isValidPassword } from '../../utils/password';

type OwnProps = {
  onChangePassword: Function,
  email: string,
};

type MapStateToProps = {
  captchaType: string,
};

type DispatchProps = {};

type Props = OwnProps & MapStateToProps & DispatchProps;

const Password = ({ onChangePassword, email, captchaType }: Props) => {
  const [inEditMode, setInEditMode] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onPasswordChange = e => {
    setPassword(e.target.value);
    if (e.target.value && isValidPassword(e.target.value)) {
      setInvalidPassword(false);
    }

    if (e.target.value && passwordConfirmation && e.target.value === passwordConfirmation) {
      setPasswordMismatch(false);
    }
  };

  const onBlur = () => {
    const passwordError = password && !isValidPassword(password);
    setInvalidPassword(passwordError);
    const mismatch = password && passwordConfirmation && password !== passwordConfirmation;
    setPasswordMismatch(mismatch);
  };

  const onPasswordConfirmationChange = e => {
    setPasswordConfirmation(e.target.value);
    if (e.target.value && password === e.target.value) {
      setPasswordMismatch(false);
    }
  };

  const submitChangePassword = async captchaToken => {
    try {
      await onChangePassword(email, password, captchaType, captchaToken);

      setPassword('');
      setPasswordConfirmation('');
      setInEditMode(false);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
  };

  const onClose = () => {
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <SectionItem
        title={<FormattedMessage id="settings.account.password.title" defaultMessage="Password:" />}
      >
        {inEditMode ? (
          <div className={styles.form}>
            <PasswordInput
              id="password"
              value={password}
              onChange={onPasswordChange}
              onBlur={onBlur}
              disabled={isLoading}
              data-test="account.change-password.password"
              inputError={invalidPassword}
              inputErrorText={<InvalidPassword />}
            />
          </div>
        ) : (
          <React.Fragment>
            <span className={styles.value} data-test="account.change-password.password-placeholder">
              ••••••••
            </span>
            <div className={classNames(styles.action, styles.btns)}>
              <button
                className={classNames('c-btn', styles.primaryBtn)}
                onClick={() => setInEditMode(true)}
                data-test="account.change-password.btn"
              >
                <FormattedMessage
                  id="settings.account.change-password.cta"
                  defaultMessage="Change Password"
                />
              </button>
            </div>
          </React.Fragment>
        )}
      </SectionItem>
      {inEditMode && (
        <SectionItem
          title={
            <FormattedMessage
              id="settings.account.repeat-password.title"
              defaultMessage="Repeat Password:"
            />
          }
        >
          <CaptchaForm
            captchaAction={CAPTCHA_ACTION_CHANGE_PW}
            submit={submitChangePassword}
            className={styles.form}
            onSubmit={onSubmit}
            onClose={onClose}
          >
            <PasswordInput
              id="password-confirmation"
              value={passwordConfirmation}
              onChange={onPasswordConfirmationChange}
              onBlur={onBlur}
              disabled={isLoading}
              data-test="account.change-password.password-confirmation"
              inputError={passwordMismatch}
              inputErrorText={<PasswordMismatch />}
            />
            <div className={styles.btns}>
              <button
                className="c-btn c-btn--is-purple"
                data-test="account.change-password.submit-btn"
                type="submit"
                disabled={
                  isLoading ||
                  !password ||
                  !passwordConfirmation ||
                  passwordMismatch ||
                  invalidPassword
                }
              >
                <LoadingIndicator isLoading={isLoading} className="hideText" />
                <FormattedMessage
                  id="settings.account.save-new-password.cta"
                  defaultMessage="Save"
                />
              </button>
              <button
                className="c-btn"
                data-test="account.change-password.cancel-btn"
                onClick={() => {
                  setPassword('');
                  setPasswordConfirmation('');
                  setInEditMode(false);
                }}
                disabled={isLoading}
              >
                <FormattedMessage
                  id="settings.account.cancel-new-password.cta"
                  defaultMessage="Cancel"
                />
              </button>
            </div>
          </CaptchaForm>
        </SectionItem>
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    captchaType: selectCaptchaType(state),
  };
}

export default connect(mapStateToProps)(Password);
