// @flow
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { motion } from 'framer-motion';
// $FlowFixMe
import React, { useEffect, useCallback } from 'react';
import type { ChildrenArray, Element } from 'react';

import styles from './FacetGroupDropdown.css';
import FacetGroupMenuTitle from './FacetGroupMenuTitle';

type OwnProps = {
  getTriggerText: Function,
  isVisible: boolean,
  setIsVisible: Function,
  type: string,
  children?: ChildrenArray<Element<any>>,
};

const FacetGroupDropdown = ({
  children,
  getTriggerText,
  isVisible,
  setIsVisible,
  type,
}: OwnProps) => {
  const handleScroll = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  useEffect(() => {
    const scrollingElement = document.querySelector('.content');
    if (scrollingElement) {
      scrollingElement.addEventListener('scroll', handleScroll);
      return () => {
        scrollingElement.removeEventListener('scroll', handleScroll);
      };
    }
    return () => {};
  }, [handleScroll]);

  return (
    <DropdownMenu.Root modal={false} onOpenChange={setIsVisible} open={isVisible}>
      <DropdownMenu.Trigger>
        <FacetGroupMenuTitle
          isActive={isVisible}
          getTriggerText={getTriggerText}
          type={type}
          isDesktop
        />
      </DropdownMenu.Trigger>
      {isVisible && (
        <DropdownMenu.Portal container={document.getElementById('radix-portal')}>
          <DropdownMenu.Content asChild align="start" forceMount>
            <motion.div
              className={styles.content}
              initial={{ opacity: 0, y: -5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              data-test="facet.group.dropdown-content"
            >
              {children}
            </motion.div>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      )}
    </DropdownMenu.Root>
  );
};

export default FacetGroupDropdown;
