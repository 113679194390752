import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { defineMessages, injectIntl, intlShape } from 'react-intl';

import PlayButton from '../common/PlayButton';
import { selectRadioIsPlaying } from '../../selectors/radio';

import * as radioActions from '../../actions/radio';
import * as analyticsActions from '../../actions/analytics';

export const messages = defineMessages({
  playAllButtonPausedText: {
    id: 'radio.play.paused.title',
    defaultMessage: 'Play radio',
  },
  playAllButtonPlayingText: {
    id: 'radio.play.playing.title',
    defaultMessage: 'Pause radio',
  },
});

class PlayRadioButton extends PureComponent {
  static propTypes = {
    'playing': PropTypes.bool.isRequired,
    'type': PropTypes.string.isRequired,
    'id': PropTypes.number.isRequired,
    'playRadio': PropTypes.func.isRequired,
    'trackPlayPausePressed': PropTypes.func.isRequired,
    'trackingContext': PropTypes.object.isRequired,
    'intl': intlShape,
    'data-test': PropTypes.string,
  };

  onClick = () => {
    const { type, id, playRadio, playing, trackingContext } = this.props;
    this.props.trackPlayPausePressed(trackingContext, playing);
    playRadio(type, id);
  };

  render() {
    const { playing } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <PlayButton
        playing={playing}
        onClick={this.onClick}
        pausedTitle={formatMessage(messages.playAllButtonPausedText)}
        playingTitle={formatMessage(messages.playAllButtonPlayingText)}
        moduleVariant="header"
        size="small"
        labelVisible
        isRadio
        data-test={this.props['data-test']}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { type, id } = ownProps;
  const compoundKey = [type, id].filter(i => i).join('--');

  return {
    ...ownProps,
    playing: selectRadioIsPlaying(state, compoundKey),
  };
}

export default compose(
  connect(mapStateToProps, {
    playRadio: radioActions.playRadio,
    trackPlayPausePressed: analyticsActions.trackPlayPausePressed,
  }),
  injectIntl
)(PlayRadioButton);
