import PropTypes from 'prop-types';
import React, { Component } from 'react';
import StickyBox from 'react-sticky-box';

import styles from './StickyNavigation.css';

export default class StickyNavigation extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
  };

  render() {
    return (
      <StickyBox className={styles.stickyBox}>
        <div className={styles.nav}>{this.props.children}</div>
      </StickyBox>
    );
  }
}
