import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import classnames from 'classnames';
import CapacitorRipple from '../capacitor/Ripple';

import { getSDK } from '../../utils/dom';
import * as livestreamActions from '../../actions/livestreamEvents';
import * as notificationActions from '../../actions/notifications';
import LoadingIndicator from '../common/LoadingIndicator';

import { hasValidTicketForEvent } from '../../selectors/livestreamEvents';
import { selectEasyConnectPartnerId, selectEasyConnectSdkOriginUrl } from '../../selectors/client';
import { HAVE_TICKET, EASY_CONNECT_ERROR } from '../../lib/notifications';

import styles from './EasyConnectButton.css';

class EasyConnectButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    eventId: PropTypes.string.isRequired,
    eventSlug: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onOrderComplete: PropTypes.func.isRequired,
    isAuth: PropTypes.bool.isRequired,

    easyConnectSdkUrlOrigin: PropTypes.string.isRequired,
    easyConnectPartnerId: PropTypes.string.isRequired,

    loadEventJWT: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,

    hidden: PropTypes.bool,
  };

  state = {
    loading: true,
  };

  async componentDidMount() {
    const origin = this.props.easyConnectSdkUrlOrigin;
    const SDK_URL_MODULE = `${origin}/webshop/v1/webshop.esm.js`;
    const SDK_GLOBAL = 'ECWebshop';
    await getSDK(SDK_URL_MODULE, SDK_GLOBAL, undefined, undefined, undefined, {
      type: 'module',
    });
    // Disabling linter because this is an async cdm
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ loading: false });
  }

  onWindowMessage = event => {
    if (event.origin !== this.props.easyConnectSdkUrlOrigin) {
      return;
    }
    this.getSidebar().addEventListener('reporting', this.onSidebarCallback);
  };

  onSidebarCallback = async event => {
    const detail = event.detail;
    switch (detail.eventName) {
      case 'CLOSED': {
        window.removeEventListener('message', this.onWindowMessage);
        this.getSidebar().removeEventListener('reporting', this.onSidebarCallback);
        break;
      }
      case 'CHARGED': {
        const eventProperties = detail.eventProperties;
        const order = {
          id: eventProperties.order_id,
          platform: 'tg',

          ticketType: 'tg.paid',
          ticketId: eventProperties.ticket_id,

          productType: 'cf.LivestreamEvent',
          productId: eventProperties.product_id,
        };

        this.setState({ loading: true });
        this.props.onOrderComplete(order, event);
        // At this point button should be unmounted

        break;
      }
      default:
        break;
    }
  };

  onClick = async event => {
    const { loading } = this.state;
    const { isAuth, eventId, eventSlug, easyConnectPartnerId } = this.props;
    const { onClick, loadEventJWT } = this.props;

    if (loading) {
      return;
    }

    if (onClick) {
      onClick(event);
    }

    if (!isAuth) {
      // In this case redirect to login from the HOC and the link,
      // The HOC is connected on the onClick handler above
      return;
    }

    window.addEventListener('message', this.onWindowMessage);
    this.setState({ loading: true });
    try {
      const response = await loadEventJWT(eventSlug);

      const { jwt, user } = response.normalized;

      // Check again if user has a ticket
      if (hasValidTicketForEvent(user.tickets, eventId)) {
        this.props.addNotification(HAVE_TICKET);
        return;
      }

      this.setState({ loading: false });
      this.getSidebar().open({
        externalId: eventId,
        partnerId: easyConnectPartnerId,
        authToken: jwt,
      });
    } catch (e) {
      console.warn(e); // eslint-disable-line no-console
      this.setState({ loading: false });
      this.props.addNotification(EASY_CONNECT_ERROR);
    }
    this.setState({ loading: false });
  };

  getSidebar = () => document.querySelector('ec-sidebar-wrapper');

  render() {
    const { loading } = this.state;
    const { hidden, className } = this.props;
    const classNames = classnames('c-btn c-btn--is-inverted', className, {
      [styles.isLoading]: loading,
      [styles.isHidden]: hidden,
    });
    return (
      <React.Fragment>
        <button
          className={classNames}
          onClick={this.onClick}
          disabled={loading}
          data-test="livestream-event-hero.easy-connect-btn"
        >
          <CapacitorRipple />
          <FormattedMessage id="livestream-event.buy.button-text" defaultMessage="Buy Ticket" />
          <LoadingIndicator isLoading={loading} className="hideText" />
        </button>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    easyConnectSdkUrlOrigin: selectEasyConnectSdkOriginUrl(state),
    easyConnectPartnerId: selectEasyConnectPartnerId(state),
  }),
  {
    loadEventJWT: livestreamActions.loadEventJWT,
    addNotification: notificationActions.addNotification,
  }
)(EasyConnectButton);
