import { defineMessages } from 'react-intl';

import { VIDEO_TYPE_RECORDING, VIDEO_TYPE_ALBUM } from './constants';

const messages = defineMessages({
  videoGroupTitle: {
    id: 'discover.video-group.title',
    defaultMessage: 'Video Shorts – Artists Recommend',
  },
});

export const allVideos = [
  // Discover
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/29839903-thumbnail.jpg',
    id: 29839903,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Anna Prohaska – Why 'Dido and Aeneas' is the perfect opera",
      de: "Anna Prohaska – weshalb 'Dido und Aeneas' die perfekte Oper für mich ist",
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/29066952-thumbnail.jpg',
    id: 29066952,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Carolin Widmann – Time stands still in Schubert's Fantasy for Violin and Piano",
      de: 'Carolin Widmann über Momente der Unendlichkeit in Schuberts Fantasie für Violine und Klavier',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/18079303-thumbnail.jpg',
    id: 18079303,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Moritz Eggert – Stravinsky's Concerto for two Pianos",
      de: 'Moritz Eggert über Strawinskis Konzert für zwei Klaviere',
    },
  },
  {
    imageUrl:
      'https://idagio-images.global.ssl.fastly.net/assets/web/the-melody-at-night-with-you-thumbnail.jpg',
    id: 'the-melody-at-night-with-you',
    type: VIDEO_TYPE_ALBUM,
    shouldShowOnDiscover: true,
    title: {
      en: 'Khatia Buniatishvili – Constantly captivating playing from Keith Jarrett',
      de: 'Khatia Buniatishvili über die stets fesselnden Interpretationen von Keith Jarrett',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/15015085-thumbnail.jpg',
    id: 15015085,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Artyom Dervoed – The Prince's Toys Suite by Nikita Koshkin",
      de: "Artyom Dervoed über 'Das Spielzeug des Prinzen' von Nikita Koshkin ",
    },
  },
  {
    imageUrl:
      'https://idagio-images.global.ssl.fastly.net/assets/web/satie-C6EEA533-D358-4D54-AAF4-7094AD1889A7-thumbnail.jpg',
    id: 'satie-C6EEA533-D358-4D54-AAF4-7094AD1889A7',
    type: VIDEO_TYPE_ALBUM,
    shouldShowOnDiscover: true,
    title: {
      en: 'Olga Scheps – The music of Erik Satie',
      de: 'Olga Scheps über den Komponisten Erik Satie',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/22852326-thumbnail.jpg',
    id: 22852326,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Valentin Erben – Elisabeth Leonskaja plays Schubert's last piano sonata",
      de: 'Valentin Erben über Elisabeth Leonskaja mit Schuberts letzter Klaviersonate',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/25879679-thumbnail.jpg',
    id: 25879679,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Anna Vinnitskaya – Philippe Herreweghe conducts the 'St Matthew Passion'",
      de: 'Anna Vinnitskaya über die Matthäuspassion von Philippe Herreweghe mit dem Collegium Vocale Gent',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/25920420-thumbnail.jpg',
    id: 25920420,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: 'Zubin Mehta – Learning Mahler from the Vienna Philharmonic',
      de: 'Zubin Mehta – Wie ich über Mahler von den Wiener Philharmonikern lernte',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/25198762-thumbnail.jpg',
    id: 25198762,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Berlin Philharmonic Cellist Stephan Koncz – Tchaikovsky's Fifth Symphony conducted by Evgeny Mravinsky",
      de: 'Stephan Koncz (Berliner Philharmoniker, Cello) über Evgeny Mravinsky mit Tschaikowskis 5. Sinfonie',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/12232837-thumbnail.jpg',
    id: 12232837,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Khatia Buniatishvili – Rachmaninoff performing Schumann’s 'Carnaval'",
      de: 'Khatia Buniatishvili über den Pianisten Rachmaninoff mit Schumanns Carnaval',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/26825402-thumbnail.jpg',
    id: 26825402,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Zubin Mehta – Recording the 'Alpine Symphony' with the L.A. Philharmonic",
      de: 'Zubin Mehta über die Aufnahme der Alpensinfonie mit dem L.A. Philharmonic',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/19434777-thumbnail.jpg',
    id: 19434777,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Gregor Sigl (Artemis Quartet) – Ravel's Piano Concerto in G",
      de: 'Gregor Sigl (Artemis Quartett) über Ravels Klavierkonzert in G-Dur M 83',
    },
  },
  {
    imageUrl:
      'https://idagio-images.global.ssl.fastly.net/assets/web/eight-seasons-astor-piazzolla-four-seasons-of-buenos-aires-vivaldi-four-seasons-thumbnail.jpg',
    id: 'eight-seasons-astor-piazzolla-four-seasons-of-buenos-aires-vivaldi-four-seasons',
    type: VIDEO_TYPE_ALBUM,
    shouldShowOnDiscover: true,
    title: {
      en: "Suyoen Kim – Gidon Kremer and Kremerata Baltica's 'Eight Seasons'",
      de: 'Suyoen Kim über die acht Jahreszeiten von der Kremerata Baltica',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/11101317-thumbnail.jpg',
    id: 11101317,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Anna Vinnitskaya – Rachmaninoff's Third Symphony performed in the spirit of the composer",
      de: 'Anna Vinnitskaya über eine Interpretation von Rachmaninoffs 3. Sinfonie im Sinne des Komponisten',
    },
  },
  {
    imageUrl:
      'https://idagio-images.global.ssl.fastly.net/assets/web/glenn-gould-plays-mozart-the-piano-sonatas-no-10-recordings-of-1958-1970-fantasias-k-397-k-475-fantasia-fugue-k-394-piano-concerto-no-24-k-491-thumbnail.jpg',
    id: 'glenn-gould-plays-mozart-the-piano-sonatas-no-10-recordings-of-1958-1970-fantasias-k-397-k-475-fantasia-fugue-k-394-piano-concerto-no-24-k-491',
    type: VIDEO_TYPE_ALBUM,
    shouldShowOnDiscover: true,
    title: {
      en: "Composer Moritz Eggert – Glenn Gould plays Mozart's Piano Sonatas",
      de: 'Moritz Eggert über Glenn Gould mit Mozarts Klaviersonaten',
    },
  },
  {
    imageUrl:
      'https://idagio-images.global.ssl.fastly.net/assets/web/bach-partitas-nos-1-5-and-6-E64A4629-7313-454C-948E-64037A46082D-thumbnail.jpg',
    id: 'bach-partitas-nos-1-5-and-6-E64A4629-7313-454C-948E-64037A46082D',
    type: VIDEO_TYPE_ALBUM,
    shouldShowOnDiscover: true,
    title: {
      en: "Pianist Olga Scheps – Murray Perahia plays J.S. Bach's Partitas",
      de: 'Olga Scheps über Murray Perahia mit J.S. Bachs Partiten',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/27763168-thumbnail.jpg',
    id: 27763168,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: 'Berlin Philharmonic Concermaster Noah Bendix-Balgley – Bartók plays Bartók',
      de: 'Noah Bendix-Balgley (Berliner Philharmoniker, Konzertmeister) – Bartók spielt Bartók',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/22955680-thumbnail.jpg',
    id: 22955680,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Vienna Philharmonic Concertmaster Volkhard Steude – Bernstein's Mahler",
      de: 'Volkhard Steude (Wiener Philharmoniker, Konzertmeister) über Bernsteins Mahler',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/18367961-thumbnail.jpg',
    id: 18367961,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: 'Vienna Philharmonic Flautist Walter Auer – Mozart Piano Concerto No. 21',
      de: 'Walter Auer (Wiener Philharmoniker, Flöte) über Mozarts Klavierkonzert KV 467',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/19426781-thumbnail.jpg',
    id: 19426781,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Vienna Philharmonic Violinist Alexander Steinberger – Karajan's 'Music of the Spheres'",
      de: 'Alexander Steinberger (Wiener Philharmoniker, Violine) über Karajans Sphärenklänge',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/16393126-thumbnail.jpg',
    id: 16393126,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: true,
    title: {
      en: "Composer Moritz Eggert – Charles Ives's Fourth Symphony",
      de: 'Moritz Eggert über Charles Ives 4. Sinfonie',
    },
  },
  // Remaining
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/24027975-thumbnail.jpg',
    id: 24027975,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: false,
    title: {
      en: "Berlin Philharmonic Concertmaster Noah Bendix-Balgley – Rubinstein in Brahms's Piano Quintet",
      de: "Noah Bendix-Balgley (Berliner Philharmoniker, Konzertmeister) über Rubinstein in Brahms' Klavierquintett",
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/22680407-thumbnail.jpg',
    id: 22680407,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: false,
    title: {
      en: "Berlin Philharmonic Concertmaster Noah Bendix-Balgley – Khachaturian's Violin Concerto",
      de: 'Noah Bendix-Balgley (Berliner Philharmoniker, Konzertmeister) über Khachaturians Violinkonzert',
    },
  },
  {
    imageUrl:
      'https://idagio-images.global.ssl.fastly.net/assets/web/kreisler-plays-kreisler-BC2DD2CD-718A-4601-BAF7-F1788F532F21-thumbnail.jpg',
    id: 'kreisler-plays-kreisler-BC2DD2CD-718A-4601-BAF7-F1788F532F21',
    type: VIDEO_TYPE_ALBUM,
    shouldShowOnDiscover: false,
    title: {
      en: 'Berlin Philharmonic Concert Master Noah Bendix-Balgley – Kreisler plays Kreisler',
      de: 'Noah Bendix-Balgley (Berliner Philharmoniker, Konzertmeister) – Kreisler spielt Kreisler',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/30523225-thumbnail.jpg',
    id: 30523225,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: false,
    title: {
      en: "Berlin Philharmonic Concert Master Noah Bendix-Balgley – My favourite 'La Bohème'",
      de: 'Noah Bendix-Balgley (Berliner Philharmoniker, Konzertmeister) – meine Lieblings-"Bohème"',
    },
  },
  {
    imageUrl:
      'https://idagio-images.global.ssl.fastly.net/assets/web/bach-j-s-cello-suites-nos-1-6-0e3e6fad-9d6b-4e6f-9e24-2b791f084615-thumbnail.jpg',
    id: 'bach-j-s-cello-suites-nos-1-6-0e3e6fad-9d6b-4e6f-9e24-2b791f084615',
    type: VIDEO_TYPE_ALBUM,
    shouldShowOnDiscover: false,
    title: {
      en: 'Berlin Philharmonic Cellist Stephan Koncz – Revelatory Bach on the viola da gamba',
      de: 'Stephan Koncz (Berliner Philharmoniker, Cello) über Paolo Pandolfo mit Bachs Cellosuiten',
    },
  },
  {
    imageUrl:
      'https://idagio-images.global.ssl.fastly.net/assets/web/brahms-2-piano-trios-klaviertrios-thumbnail.jpg',
    id: 'brahms-2-piano-trios-klaviertrios',
    type: VIDEO_TYPE_ALBUM,
    shouldShowOnDiscover: false,
    title: {
      en: "Gregor Sigl (Artemis Quartet) –  The string sextet version of Brahms's Piano Trios",
      de: 'Gregor Sigl (Artemis Quartett) über Brahms Klaviertrios für Streichsextett',
    },
  },
  {
    imageUrl:
      'https://idagio-images.global.ssl.fastly.net/assets/web/debussy-suite-bergamasque-pour-le-piano-estampes-etc-thumbnail.jpg',
    id: 'debussy-suite-bergamasque-pour-le-piano-estampes-etc',
    type: VIDEO_TYPE_ALBUM,
    shouldShowOnDiscover: false,
    title: {
      en: "Gregor Sigl (Artemis Quartet) – The reference recording of 'Clair de Lune'",
      de: 'Gregor Sigl (Artemis Quartett) über Clair de Lune',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/21947287-thumbnail.jpg',
    id: 21947287,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: false,
    title: {
      en: "Gregor Sigl (Artemis Quartet) – Mahler's Fifth Symphony live in Birmingham",
      de: 'Gregor Sigl (Artemis Quartet) über Mahlers 5. Sinfonie live in Birmingham',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/23860349-thumbnail.jpg',
    id: 23860349,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: false,
    title: {
      en: 'Berlin Philharmonic Cellist Stephan Koncz – Funky Sounds by Bartók',
      de: 'Stephan Koncz (Berliner Philharmoniker, Cello) über Bartóks Musik für Saiteninstrumente, Schlagzeug und Celesta',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/23860349-thumbnail.jpg',
    id: 'harnoncourt-conducts-johann-strauss-ii',
    type: VIDEO_TYPE_ALBUM,
    shouldShowOnDiscover: false,
    title: {
      en: '',
      de: '',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/23860349-thumbnail.jpg',
    id: 21799916,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: false,
    title: {
      en: '',
      de: '',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/23860349-thumbnail.jpg',
    id: 20110362,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: false,
    title: {
      en: '',
      de: '',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/23860349-thumbnail.jpg',
    id: 22576776,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: false,
    title: {
      en: '',
      de: '',
    },
  },
  {
    imageUrl: 'https://idagio-images.global.ssl.fastly.net/assets/web/23860349-thumbnail.jpg',
    id: 22576756,
    type: VIDEO_TYPE_RECORDING,
    shouldShowOnDiscover: false,
    title: {
      en: '',
      de: '',
    },
  },
];

export const albumVideos = allVideos.filter(({ type }) => type === VIDEO_TYPE_ALBUM);

export const recordingVideos = allVideos.filter(({ type }) => type === VIDEO_TYPE_RECORDING);

export const discoverVideos = allVideos.filter(({ shouldShowOnDiscover }) => shouldShowOnDiscover);

export const videoGroup = {
  id: 'video-group',
  items: discoverVideos,
  name: messages.videoGroupTitle,
  tags: null,
  type: 'video-group',
};
