// @flow
// $FlowFixMe
import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { requestPasswordReset as requestPasswordResetActionCreator } from '../actions/auth';
import { addNotification as addNotificationActionCreator } from '../actions/notifications';
import LoadingIndicator from '../components/common/LoadingIndicator';

import styles from './ForgotPassword.css';
import { CHROME_CLEAN_LINKS, CAPTCHA_ACTION_REQUEST_PW_RESET } from '../constants';
import chromeComponent from '../hoc/chromeComponent';
import protectedComponent from '../hoc/protectedComponent';

import AccountEmailLabel from '../components/messages/AccountEmailLabel';
import CaptchaForm from '../components/common/CaptchaForm';
import { selectCaptchaType } from '../selectors/client';
import LoginLink from '../components/common/LoginLink';
import { withRouter } from 'react-router';

type OwnProps = {};

type MapStateToProps = {
  captchaType: string,
  presetEmail: string,
};

type DispatchProps = {
  requestPasswordReset: Function,
};

type Props = OwnProps & MapStateToProps & DispatchProps;

const ForgotPassword = ({ requestPasswordReset, captchaType, presetEmail }: Props) => {
  const [email, setEmail] = useState(presetEmail);
  const [isLoading, setIsLoading] = useState(false);

  const submitRequestPasswordReset = async captchaToken => {
    try {
      await requestPasswordReset(email, captchaToken, captchaType);
      setEmail('');
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
  };

  const onClose = () => {
    setIsLoading(false);
  };

  const Header = () => (
    <div className={styles.header}>
      <h1 className="fz--beta">
        <FormattedMessage id="forgot-password.header" defaultMessage="Forgot your password?" />
      </h1>
      <p>
        <FormattedMessage
          id="forgot-password.description"
          defaultMessage="Please enter the email address of your account. You will receive an email with a link to reset your password."
        />
      </p>
      <LoginLink
        text={<FormattedMessage id="forgot-password.login-link" defaultMessage="Back to Login" />}
      />
    </div>
  );

  const viewClasses = classnames(styles.view, 'u-page-container');
  const classesBtn = classnames('c-btn', 'c-btn--is-purple', styles.btn);

  const isButtonDisabled = !email || isLoading;

  return (
    <div className={viewClasses}>
      <Header />
      <CaptchaForm
        captchaAction={CAPTCHA_ACTION_REQUEST_PW_RESET}
        className={styles.form}
        submit={submitRequestPasswordReset}
        onSubmit={onSubmit}
        onClose={onClose}
      >
        <div>
          <div className="c-form-field">
            <label htmlFor="email" className="c-form-field__label">
              <AccountEmailLabel />
            </label>
            <input
              type="email"
              name="email"
              disabled={isLoading}
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="c-form-field__input"
              id="email"
              data-test="forgot-password-form.email-input"
              autoFocus
            />
          </div>
          <button
            className={classesBtn}
            data-test="forgot-password-form.submit-btn"
            type="submit"
            disabled={isButtonDisabled}
          >
            <LoadingIndicator isLoading={isLoading} className="hideText" />
            <FormattedMessage id="forgot-password.submit-btn" defaultMessage="Request link" />
          </button>
        </div>
      </CaptchaForm>
    </div>
  );
};

function mapStateToProps(
  state: Object,
  ownProps: OwnProps & { location: Object }
): MapStateToProps {
  const { location } = ownProps;

  return {
    captchaType: selectCaptchaType(state),
    presetEmail: location.query && location.query.email,
  };
}

const dispatchProps: DispatchProps = {
  requestPasswordReset: requestPasswordResetActionCreator,
  addNotification: addNotificationActionCreator,
};

export default compose(
  protectedComponent({ anonymousOnly: true }),
  chromeComponent({ type: CHROME_CLEAN_LINKS }),
  connect(mapStateToProps, dispatchProps),
  withRouter
)(ForgotPassword);
