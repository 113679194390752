// @flow

import classNames from 'classnames';
import React from 'react';
import styles from './HeroSliderDot.css';

type OwnProps = {
  onClick: Function,
  isActive: boolean,
  index: number,
};

const HeroSliderDot = ({ index, isActive, onClick }: OwnProps) => {
  const className = classNames(styles.dot, {
    [styles.isActive]: isActive,
  });
  return (
    <button className={className} onClick={e => onClick(e, index)}>
      <div className={styles.dotInner} />
    </button>
  );
};

export default HeroSliderDot;
