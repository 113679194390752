import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import { IMAGES_BASE } from '../../constants';

import { loadRecordingsByFilter } from '../../actions/recording';
import * as facetActions from '../../actions/facet';

import { selectProfile } from '../../selectors/profile';

import { selectRecordingsFilterParams } from '../../selectors/filteredList';
import FilteredRecordingList from '../common/FilteredRecordingList';
import { selectActiveRecordingFacets } from '../../selectors/facet';
import dataComponent from '../../hoc/dataComponent';
import { compose } from 'redux';
import Head from '../chrome/Head';
import metaInjector from '../../hoc/metaInjector';
import { injectIntl, intlShape } from 'react-intl';
import { messages } from './messages';

class ProfileRecordings extends Component {
  static propTypes = {
    artistName: PropTypes.string.isRequired,
    profileSlug: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    profileIsEnsemble: PropTypes.bool.isRequired,
    profileIsPerformer: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    filterParams: PropTypes.object.isRequired,
    activeFacets: PropTypes.array.isRequired,
    intl: intlShape,
  };

  static getCanonicalRoute(slug) {
    return `/profiles/${slug}/recordings`;
  }

  render() {
    const {
      artistName,
      profileSlug,
      profileId,
      profileIsEnsemble,
      profileIsPerformer,
      location,
      params,
      filterParams,
      activeFacets,
      intl,
    } = this.props;

    const { formatMessage } = intl;

    return (
      <div>
        <Head
          canonicalRoute={ProfileRecordings.getCanonicalRoute(profileSlug)}
          title={formatMessage(messages.profileRecordingsMetaTitle, { name: artistName })}
          description={formatMessage(messages.profileRecordingsMetaDescription, {
            name: artistName,
          })}
          keywords={formatMessage(messages.profileRecordingsMetaKeywords, { name: artistName })}
        />
        <FilteredRecordingList
          location={location}
          params={params}
          contextType="profile"
          contextFacet={profileIsEnsemble ? 'Ensemble' : 'Artist'}
          contextId={profileId}
          handleMultipleComposers={profileIsPerformer}
          filterParams={filterParams}
          activeFacets={activeFacets}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { slug } = ownProps.params;
  const profile = selectProfile(state, slug);
  const profileIsEnsemble = includes(profile.functions, 'ensemble');
  const location = ownProps.location;
  const filterParams = selectRecordingsFilterParams(location.query, profile.id, 'artists');
  const activeFacets = selectActiveRecordingFacets(state, filterParams, 'artists');

  return {
    filterParams,
    artistName: profile.name,
    profileSlug: profile.slug,
    profileId: profile.id.toString(),
    profileIsPerformer: !!profile.numRecordingsAsPerformer,
    profileIsEnsemble,
    location,
    activeFacets,
  };
}

function fetchData(store, { slug, cursor }, location) {
  const state = store.getState();
  const profile = selectProfile(state, slug);
  const filterParams = selectRecordingsFilterParams(location.query, profile.id, 'artists');
  return [
    store.dispatch(loadRecordingsByFilter(filterParams, cursor, __CAPACITOR__ ? 20 : 50)),
    store.dispatch(facetActions.loadTopRecordingFacets(filterParams)),
  ];
}

function getMetaInformation(props) {
  const { artistName, profileId, intl } = props;
  return {
    'og:description': intl.formatMessage(messages.profileRecordingsOgDescription, {
      name: artistName,
    }),
    'og:image': `${IMAGES_BASE}/artists/${profileId}/main.jpg?auto=format&dpr=1&fit=fill&fill=blur&w=1200&h=630`,
  };
}

export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, {}),
  injectIntl,
  metaInjector(getMetaInformation)
)(ProfileRecordings);
