import classNames from 'classnames';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router';

import styles from './style.css';
import typography from '../common/typography.css';
import {
  LiveCompareComposerShape,
  LiveComparePieceShape,
  LiveCompareWorkShape,
} from '../../../shapes/liveCompare';

const messages = defineMessages({
  sortByYear: {
    id: 'live-compare.sort.year',
    defaultMessage: 'Sorted by year',
  },
  recordingPosition: {
    id: 'live-compare.recording-position',
    defaultMessage: '{current}/{total} recordings',
  },
});

function toHumanReadableIndex(index) {
  return index + 1;
}

class WorkComposerSection extends React.PureComponent {
  static propTypes = {
    work: LiveCompareWorkShape,
    piece: LiveComparePieceShape,
    composer: LiveCompareComposerShape,
    intl: intlShape,
    activeRecordingIndex: PropTypes.number,
    isSwitching: PropTypes.bool.isRequired,
    recordingsCount: PropTypes.number,
  };

  render() {
    const { composer, work, piece, intl, activeRecordingIndex, isSwitching, recordingsCount } =
      this.props;
    const { formatMessage } = intl;

    return (
      <div className={styles.Section}>
        <div className={styles.ComposerAndWork}>
          <Link to={`/profiles/${composer.id}`} onlyActiveOnIndex>
            <span className={classNames(typography.Normal, typography.Bolder, styles.ComposerName)}>
              {composer.name}
            </span>
          </Link>
          <Link to={`/works/${work.id}`} onlyActiveOnIndex>
            <span className={typography.Base}>{work.title}</span>
          </Link>
          <Link to={`/works/${work.id}`} onlyActiveOnIndex>
            <span className={typography.Base}>{piece.title}</span>
          </Link>
        </div>
        <div className={styles.RecordingPosition}>
          <span className={classNames(typography.SmallText)}>
            {formatMessage(messages.recordingPosition, {
              current: isSwitching ? '—' : toHumanReadableIndex(activeRecordingIndex),
              total: recordingsCount,
            })}
          </span>
        </div>
      </div>
    );
  }
}

export default injectIntl(WorkComposerSection);
