import React from 'react';
import { motion } from 'framer-motion';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import CapacitorRipple from '../capacitor/Ripple';
import IconLabel from '../util/IconLabel';
import styles from './CourseSessionItem.css';

const CourseSessionItem = props => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { title, hasEnded, children } = props;
  const randomId = React.useRef(Math.random().toString(26).substring(2));

  const onClick = event => {
    event.preventDefault();
    setIsExpanded(ie => !ie);
  };

  return (
    <div className={styles.wrapper}>
      <button
        className={classnames(styles.trigger, { [styles.hasEnded]: hasEnded })}
        onClick={onClick}
        aria-expanded={isExpanded}
        aria-controls={randomId.current}
      >
        <CapacitorRipple />
        {title}{' '}
        <motion.div animate={{ scaleY: isExpanded ? -1 : 1 }}>
          <IconLabel name="chevron-down" size="default" />
        </motion.div>
      </button>
      <motion.div
        className={styles.content}
        id={randomId.current}
        role="region"
        animate={{
          height: isExpanded ? 'auto' : 0,
        }}
        transition={{
          duration: 0.3,
        }}
      >
        <div className={styles.contentInner}>{children}</div>
      </motion.div>
    </div>
  );
};

CourseSessionItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  hasEnded: PropTypes.bool,
  children: PropTypes.node,
};

export default CourseSessionItem;
