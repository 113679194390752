import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { selectClientCountry } from '../../selectors/client';

function getCurrency(country) {
  let currency = 'EUR';

  if (country === 'GB') {
    currency = 'GBP';
  }

  if (country === 'US' || country === 'KR') {
    currency = 'USD';
  }
  return currency;
}

const PriceValuesShape = PropTypes.shape({
  EUR: PropTypes.number,
  GBP: PropTypes.number,
  USD: PropTypes.number,
});

const PriceShape = PropTypes.shape({
  isFixed: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
  fixedValues: PriceValuesShape,
  variableValues: PropTypes.shape({
    min: PriceValuesShape,
    max: PriceValuesShape,
  }),
});

class Price extends Component {
  static propTypes = {
    price: PriceShape.isRequired,
    country: PropTypes.string.isRequired,
  };

  static defaultProps = {
    country: '--',
    price: null,
  };

  renderFree = () => {
    return (
      <FormattedMessage id="livestream-event.free-of-charge" defaultMessage="Free of charge" />
    );
  };

  renderFixedPrice = (values, currency) => {
    const value = values[currency];
    if (!value) {
      return null;
    }

    return <FormattedNumber currency={currency} style="currency" value={value} />;
  };

  renderVariablePrice = (minValues, maxValues, currency) => {
    const minValue = minValues[currency];
    const maxValue = maxValues[currency];
    if (!minValue && !maxValue) {
      return null;
    }

    const minPrice = <FormattedNumber currency={currency} style="currency" value={minValue} />;

    const maxPrice = <FormattedNumber currency={currency} style="currency" value={maxValue} />;

    return (
      <FormattedMessage
        id="livestream-event.variable-price"
        defaultMessage="{minPrice} - {maxPrice}"
        values={{
          minPrice,
          maxPrice,
        }}
      />
    );
  };

  render() {
    const { price, country } = this.props;
    const { isFree, isFixed } = price;
    const currency = getCurrency(country);

    if (isFree) {
      return this.renderFree();
    }

    if (isFixed) {
      return this.renderFixedPrice(price.fixedValues, currency);
    }

    const { min, max } = price.variableValues;
    return this.renderVariablePrice(min, max, currency);
  }
}

function mapStateToProps(state) {
  return {
    country: selectClientCountry(state),
  };
}

export default connect(mapStateToProps)(Price);
