// @flow
import React from 'react';
import { Link } from 'react-router';
import { injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import styles from './Hotlinks.css';
import type { Hotlink } from '../../shapes/types';
import { NavigationPlugin } from '../../capacitor-connector';
import classNames from 'classnames';
import CapacitorRipple from '../capacitor/Ripple';
import * as analyticsActions from '../../actions/analytics';
import { connect } from 'react-redux';

declare var __CAPACITOR__: boolean;

type OwnProps = {
  items: Array<Hotlink>,
  trackingSource: string,
};

type DispatchProps = {
  analyticsTrack: Function,
};

type Props = OwnProps & DispatchProps & { intl: IntlShape };

type ItemProps = {
  item: Hotlink,
  trackingSource: string,
  intl: IntlShape,
} & DispatchProps;

const HotlinkItem = ({ item, intl, analyticsTrack, trackingSource }: ItemProps) => {
  const trackHotlinkClick = () => {
    analyticsTrack('Clicked Hotlink', {
      url: item.trackingUrl,
      source: trackingSource,
    });
  };

  const handleExternalLinkClick = e => {
    trackHotlinkClick();
    if (__CAPACITOR__) {
      NavigationPlugin.androidShouldOpenUrl({ url: e.currentTarget.href });
      e.preventDefault();
    }
  };

  const content = (
    <React.Fragment>
      <CapacitorRipple />
      <div className={classNames(styles.background, styles[`background--${item.id}`])} />
      <div className={styles.title}>{intl.formatMessage(item.title)}</div>
    </React.Fragment>
  );

  if (item.url.startsWith('http')) {
    return (
      <a href={item.url} className={styles.item} onClick={handleExternalLinkClick}>
        {content}
      </a>
    );
  }

  return (
    <Link to={item.url} className={styles.item} onClick={trackHotlinkClick}>
      {content}
    </Link>
  );
};

const Hotlinks = ({ items, analyticsTrack, trackingSource, intl }: Props) => {
  return (
    <div className={styles.container}>
      {items.map((item, index) => {
        return (
          <HotlinkItem
            key={index}
            item={item}
            intl={intl}
            analyticsTrack={analyticsTrack}
            trackingSource={trackingSource}
          />
        );
      })}
    </div>
  );
};

const dispatchProps: DispatchProps = {
  analyticsTrack: analyticsActions.track,
};

export default connect(null, dispatchProps)(injectIntl(Hotlinks));
