import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import FilteredList from '../common/FilteredList';

import { selectProfile } from '../../selectors/profile';

import * as Shapes from '../../shapes';

import { selectPlaylistsFilterParams, selectSortOrder } from '../../selectors/filteredList';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import dataComponent from '../../hoc/dataComponent';
import { compose } from 'redux';
import Head from '../chrome/Head';
import { messages } from './messages';
import stylesFilteredList from '../common/FilteredList.css';
import { loadPlaylistsByFilter } from '../../actions/playlist';
import {
  selectFilteredAndSortedPlaylists,
  selectFilteredAndSortedPlaylistsLoading,
} from '../../selectors/playlist';
import { selectPlaylistActiveFacets, selectPlaylistFilterTopFacets } from '../../selectors/facet';
import * as analyticsActions from '../../actions/analytics';
import PlaylistListItem from '../common/PlaylistListItem';
import { ENTITY_TYPE_PLAYLIST } from '../../constants';

class ProfilePlaylists extends PureComponent {
  static propTypes = {
    profile: Shapes.Profile.isRequired,
    profileSlug: PropTypes.string.isRequired,
    artistName: PropTypes.string.isRequired,
    playlists: PropTypes.arrayOf(Shapes.Playlist).isRequired,
    topFacets: PropTypes.object,
    location: PropTypes.object,
    activeFacets: PropTypes.array,
    filterParams: PropTypes.object,
    nextCursor: PropTypes.string,
    loadMore: PropTypes.func,
    sort: PropTypes.string.isRequired,
    currentCursor: PropTypes.string,
    prevCursor: PropTypes.string,
    playlistsAreLoading: PropTypes.bool.isRequired,
    loadAllFacets: PropTypes.func.isRequired,

    trackPlayPausePressed: PropTypes.func.isRequired,
    analyticsTrack: PropTypes.func.isRequired,

    intl: intlShape,
  };

  onItemClick = ({ content }) => {
    this.props.analyticsTrack('Selected Playlist from Profile', {
      id: content.id,
      profileId: this.props.profile.id,
    });
  };

  static getCanonicalRoute(slug) {
    return `/profiles/${slug}/playlists`;
  }

  getEmptyListMessage = () => {
    return (
      <FormattedMessage
        id="filtered-list.no-playlists"
        defaultMessage="No playlists match these criteria."
      />
    );
  };

  renderItem = playlist => {
    return (
      <li className={stylesFilteredList.item} key={playlist.id}>
        <PlaylistListItem
          playlist={playlist}
          onClick={this.onItemClick}
          beforeTogglePlay={this.beforeTogglePlay}
        />
      </li>
    );
  };

  render() {
    const {
      profileSlug,
      artistName,
      playlists,
      location,
      filterParams,
      topFacets,
      activeFacets,
      nextCursor,
      sort,
      prevCursor,
      currentCursor,
      playlistsAreLoading,
      loadAllFacets,
      intl,
      loadMore,
    } = this.props;

    const { formatMessage } = intl;

    return (
      <div>
        <Head
          canonicalRoute={ProfilePlaylists.getCanonicalRoute(profileSlug)}
          title={formatMessage(messages.profilePlaylistsMetaTitle, {
            name: artistName,
          })}
          description={formatMessage(messages.profilePlaylistsMetaTitle, {
            name: artistName,
          })}
          keywords={formatMessage(messages.profilePlaylistsMetaKeywords, {
            name: artistName,
          })}
        />
        <FilteredList
          contextType="profile"
          contextFacet="Composer"
          location={location}
          items={playlists}
          filterType="playlists"
          shouldRenderSortAndFilter={false}
          shouldRenderPlayAllButton={false}
          topFacets={topFacets}
          activeFacets={activeFacets}
          filterParams={filterParams}
          listIsEmpty={!playlists || playlists.length === 0}
          ignoredFacets={['artist_id']}
          nextCursor={nextCursor}
          prevCursor={prevCursor}
          loadMore={loadMore}
          sort={sort}
          currentCursor={currentCursor}
          isLoading={playlistsAreLoading}
          emptyListMessage={this.getEmptyListMessage()}
          renderFilteredListItem={this.renderItem}
          loadAllFacets={loadAllFacets}
          gridLayout
        />
      </div>
    );
  }

  beforeTogglePlay = (item, playing) => {
    this.props.trackPlayPausePressed({ contextType: 'profilePlaylists' }, playing);
  };
}

function mapStateToProps(state, ownProps) {
  const { slug, cursor } = ownProps.params;
  const profile = selectProfile(state, slug);
  const query = ownProps.location.query;
  const filterParams = selectPlaylistsFilterParams(query, profile.id);

  return {
    profile,
    profileSlug: profile.slug,
    artistName: profile.name,
    playlists: selectFilteredAndSortedPlaylists(state, filterParams),
    location: ownProps.location,
    filterParams,
    sort: selectSortOrder(query, ENTITY_TYPE_PLAYLIST),
    currentCursor: cursor,

    topFacets: selectPlaylistFilterTopFacets(state),
    activeFacets: selectPlaylistActiveFacets(state),

    playlistsAreLoading: selectFilteredAndSortedPlaylistsLoading(state),
    nextCursor: null,
    prevCursor: null,
  };
}

function fetchData(store, { slug, cursor }, location) {
  const state = store.getState();
  const profile = selectProfile(state, slug);
  const { query } = location;
  const filterParams = selectPlaylistsFilterParams(query, profile.id);

  return [store.dispatch(loadPlaylistsByFilter(filterParams, cursor))];
}

const noop = () => {};
export default compose(
  dataComponent(fetchData),
  connect(mapStateToProps, {
    loadMore: loadPlaylistsByFilter,
    trackPlayPausePressed: analyticsActions.trackPlayPausePressed,
    analyticsTrack: analyticsActions.track,
    loadAllFacets: noop,
  }),
  injectIntl
)(ProfilePlaylists);
