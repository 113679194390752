/* @flow */
declare var __CLIENT__: boolean;
declare var __CAPACITOR__: boolean;

// Imports
import AppChildrenOrError from '../components/common/AppChildrenOrError';
import * as React from 'react';
import type { Connector } from 'react-redux'; // eslint-disable-line
import { connect } from 'react-redux';
import { compose } from 'redux';
import classnames from 'classnames';
import { StripeProvider } from 'react-stripe-elements';
import { last } from 'lodash';
import ReactModal from 'react-modal';

import Head from '../components/chrome/Head';
import AppHeader from '../components/chrome/appheader/';
import PlayerBar from '../components/player/PlayerBar';
import Notifications from '../components/notifications/Notifications';
import Sidebar from '../components/common/Sidebar';
import OfflineScreen from '../components/capacitor/OfflineScreen';

import * as clientActions from '../actions/client';
import { selectModalOpen } from '../selectors/modals';
import { selectIsGeoBlockedCountry, selectIsInApp, selectIsConnected } from '../selectors/client';
import { selectFeatureFlags } from '../selectors/features';
import { selectIsOnLandingPage } from '../selectors/routing';
import ModalContainer from '../components/modals/ModalContainer';
import { setViewportHeight } from '../utils/dom';
import { CHROMES_HIDE_SIDEBAR } from '../constants';
import { FeatureContext } from '../components/util/Feature';
import { EventChannelPlugin } from '../capacitor-connector';

import type { ThunkAction } from '../actions/types';
import type { FeatureFlags } from '../reducers/client/features';

type OwnProps = {
  children: React.ChildrenArray<React.Element<any>>,
  routes: [{ hideUi: boolean }],
};

type MapStateToProps = {
  chrome: string,
  playerbarVisible: boolean,
  modalOpen: boolean,
  isGeoBlockedCountry: boolean,
  featureFlags: FeatureFlags,
  isInApp: boolean,
  children: React.ChildrenArray<React.Element<any>>,
  hideUi: boolean,
  isConnected: boolean,
};

type DispatchProps = {
  onKeyDown: KeyboardEventHandler,
  loadImageAnnotations: () => ThunkAction,
};

type Props = MapStateToProps & DispatchProps;
type State = {| stripe: ?Object |};

class App extends React.PureComponent<Props, State> {
  state = {
    stripe: null,
  };

  componentWillMount() {
    if (__CLIENT__) {
      setViewportHeight();
      // Stripe.js can't be loaded on the server. The stripe object is needed in
      // state to be passed to stripe elements and on window for direct calls.
      window.stripe = window.Stripe(window.__stripe_key__); // eslint-disable-line
      this.setState({ stripe: window.stripe }); // eslint-disable-line
    }
  }

  componentDidMount() {
    const { onKeyDown } = this.props;
    document.addEventListener('keydown', onKeyDown);

    if (!this.props.isGeoBlockedCountry) {
      this.props.loadImageAnnotations();
    }

    if (__CAPACITOR__) {
      EventChannelPlugin.webAppSendsIsMounted();
    }

    ReactModal.setAppElement(document.getElementById('root'));
  }

  render() {
    const { playerbarVisible, modalOpen, chrome, children, isInApp, isConnected, hideUi } =
      this.props;

    const chromeHidesSidebar = CHROMES_HIDE_SIDEBAR.includes(chrome);
    const showSidebar = !chromeHidesSidebar && !isInApp && !hideUi;
    const showPlayerbar = playerbarVisible && !isInApp;

    const className = classnames(`app-chrome-${chrome}`, 'is-logged-in', {
      'playerbar-visible': showPlayerbar,
      'sidebar-visible': showSidebar,
      'is-in-app': isInApp,
      'is-capacitor': !!__CAPACITOR__,
    });

    if (!isConnected && __CAPACITOR__) {
      return (
        <section className="content" id="app-content">
          <OfflineScreen />
          <Notifications />
        </section>
      );
    }

    return (
      <FeatureContext.Provider value={this.props.featureFlags}>
        <StripeProvider stripe={this.state.stripe}>
          <div className={className}>
            <Head />
            {!isInApp && !hideUi && <AppHeader />}
            {showSidebar && <Sidebar />}
            <section className="content" id="app-content" data-test="app-content">
              <AppChildrenOrError>{children}</AppChildrenOrError>
            </section>
            {!modalOpen && <Notifications />}
            {!isInApp && !hideUi && <PlayerBar />}
            <ModalContainer />
            <ec-sidebar-wrapper custom-landing-page salesChannel="idagio" is-in-app={isInApp} />
          </div>
        </StripeProvider>
      </FeatureContext.Provider>
    );
  }
}

function mapStateToProps(state: Object, ownProps: OwnProps): MapStateToProps {
  // TODO properly type state
  const clientUi = state.client.ui;

  return {
    modalOpen: selectModalOpen(state),
    chrome: clientUi.chrome,
    playerbarVisible: !clientUi.playerbarHidden,
    shortcutsHelpIsVisible: clientUi.shortcutsHelpIsVisible,
    children: ownProps.children,
    isGeoBlockedCountry: selectIsGeoBlockedCountry(state),
    isLandingPage: selectIsOnLandingPage(state),
    featureFlags: selectFeatureFlags(state),
    isInApp: selectIsInApp(state),
    isConnected: selectIsConnected(state),
    hideUi: last(ownProps.routes).hideUi,
  };
}

const dispatchProps: DispatchProps = {
  onKeyDown: clientActions.onKeyDown,
  loadImageAnnotations: clientActions.loadImageAnnotations,
};

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, dispatchProps);

export default compose(connector)(App);

export { App as PureApp };
