// @flow
// $FlowFixMe
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { motion, useAnimation, useMotionValue } from 'framer-motion';

import HeroSliderItem from './HeroSliderItem';
import HeroSliderItemBackground from './HeroSliderItemBackground';
import HeroSliderControls from './HeroSliderControls';
import HeroSliderDot from './HeroSliderDot';

import styles from './DiscoverHeroSlider.css';
import { DEFAULT_SLIDER_TRANSITION } from '../../constants';

type OwnProps = {
  items: Array<Object>,
  onItemClick: Function,
  beforeTogglePlay: Function,
  isLivestreamEventGroup: boolean,
  groupId: string,
};

type Props = OwnProps;

const DiscoverHeroSlider = ({
  items,
  isLivestreamEventGroup,
  onItemClick,
  beforeTogglePlay,
  groupId,
}: Props) => {
  const sliderRef = useRef();
  const cycleDirectionRef = useRef(1);
  const intervalRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(1200);
  const [allowClick, setAllowClick] = useState(true);
  const [isAutoSliding, setIsAutoSliding] = useState(true);

  const controls = useAnimation();
  const x = useMotionValue(0);

  const getLeftDragConstraint = () => {
    // Make sure we can only drag until the right edge of the last slide lines
    // up with the right edge of the slider container.
    return -containerWidth * (items.length - 1);
  };

  const canDrag = items.length > 1;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    debounce(() => {
      const newContainerWidth = sliderRef.current.clientWidth;
      setContainerWidth(newContainerWidth);
      const snapTo = -activeIndex * newContainerWidth;
      controls.start({
        x: snapTo,
        transition: {
          ...DEFAULT_SLIDER_TRANSITION,
          duration: 0.1,
        },
      });
    }, 200),
    [activeIndex]
  );

  useEffect(() => {
    setContainerWidth(sliderRef.current.clientWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const goToSlide = useCallback(
    targetIndex => {
      setActiveIndex(oldActiveIndex => {
        if (targetIndex < 0 || targetIndex > items.length - 1) {
          return oldActiveIndex;
        }
        const snapTo = -targetIndex * containerWidth;
        controls.start({
          x: snapTo,
          transition: DEFAULT_SLIDER_TRANSITION,
        });
        return targetIndex;
      });
    },
    [containerWidth, controls, items]
  );

  const goToNextSlide = useCallback(() => {
    setActiveIndex(oldActiveIndex => {
      // Throw the slider to the next item, but make sure to not exceed the total item number
      const targetIndex = Math.min(oldActiveIndex + 1, items.length - 1);
      const snapTo = -targetIndex * containerWidth;
      controls.start({
        x: snapTo,
        transition: DEFAULT_SLIDER_TRANSITION,
      });
      return targetIndex;
    });
  }, [containerWidth, controls, items]);

  const goToPrevSlide = useCallback(() => {
    setActiveIndex(oldActiveIndex => {
      // Throw the slider to the previous item, but make sure not to go below zero
      const targetIndex = Math.max(oldActiveIndex - 1, 0);
      const snapTo = -targetIndex * containerWidth;
      controls.start({
        x: snapTo,
        transition: DEFAULT_SLIDER_TRANSITION,
      });
      return targetIndex;
    });
  }, [containerWidth, controls]);

  // Go to the last slide, then go backwards again to the first slide, until
  // user interacts with slider
  const cycleSlide = useCallback(() => {
    if (
      activeIndex + cycleDirectionRef.current >= items.length ||
      activeIndex + cycleDirectionRef.current < 0
    ) {
      cycleDirectionRef.current *= -1;
    }
    if (cycleDirectionRef.current > 0) {
      goToNextSlide();
    } else {
      goToPrevSlide();
    }
  }, [activeIndex, items, goToPrevSlide, goToNextSlide]);

  const cancelAutoSlide = () => {
    setIsAutoSliding(false);
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    if (isAutoSliding) {
      intervalRef.current = setInterval(cycleSlide, 5000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [cycleSlide, isAutoSliding]);

  const handleNextClick = () => {
    cancelAutoSlide();
    goToNextSlide();
  };

  const handlePrevClick = () => {
    cancelAutoSlide();
    goToPrevSlide();
  };

  const handleDragStart = () => {
    cancelAutoSlide();
    setAllowClick(false);
  };

  const handleDragEnd = (e, info) => {
    setTimeout(() => setAllowClick(true), 200);
    const offset = info.offset.x;
    if (offset < 0) {
      goToNextSlide();
    } else {
      goToPrevSlide();
    }
  };

  const handleDotClick = (e, index) => {
    cancelAutoSlide();
    goToSlide(index);
  };

  return (
    <div className={styles.group}>
      <div className={styles.container} ref={sliderRef}>
        {items.map((item, index) => (
          <HeroSliderItemBackground
            key={index}
            isActive={index === activeIndex}
            item={item}
            isLivestreamEventGroup={isLivestreamEventGroup}
          />
        ))}
        <HeroSliderControls
          activeIndex={activeIndex}
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick}
          itemCount={items.length}
        />
        <motion.ul
          animate={controls}
          style={{ x }}
          drag="x"
          dragConstraints={{
            right: 0,
            left: getLeftDragConstraint(),
          }}
          className={styles.slider}
          dragElastic={0.1}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          dragListener={canDrag}
        >
          {items.map((item, index) => {
            const isActive = index === activeIndex;
            return (
              <motion.li
                key={index}
                className={styles.slide}
                style={allowClick ? {} : { pointerEvents: 'none' }}
                aria-disabled={!isActive}
                animate={{
                  scale: isActive ? 1 : 0.75,
                  opacity: isActive ? 1 : 0,
                }}
                initial={{
                  scale: isActive ? 1 : 0.75,
                  opacity: isActive ? 1 : 0,
                }}
                transition={{
                  duration: 0.5,
                  ease: 'easeOut',
                }}
              >
                <HeroSliderItem
                  isActive={isActive}
                  item={item}
                  onClick={onItemClick}
                  beforeTogglePlay={beforeTogglePlay}
                  cancelAutoSlide={cancelAutoSlide}
                  isLivestreamEventGroup={isLivestreamEventGroup}
                  groupId={groupId}
                />
              </motion.li>
            );
          })}
        </motion.ul>
        <div className={styles.dots}>
          {items.map((item, index) => {
            return (
              <HeroSliderDot
                key={index}
                index={index}
                isActive={index === activeIndex}
                onClick={handleDotClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DiscoverHeroSlider;
