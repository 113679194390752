// @flow
import React from 'react';
import classnames from 'classnames';

import IconLabel from '../util/IconLabel';
import styles from './DownloadIndicator.css';

type OwnProps = {
  trackIsDownloaded?: boolean,
  trackIsDownloading?: boolean,
  className?: string,
};

type Props = OwnProps;

const DownloadIndicator = ({ trackIsDownloaded, trackIsDownloading, className }: Props) => {
  if (trackIsDownloaded) {
    return (
      <div className={classnames(className, styles.downloadIndicator)}>
        <IconLabel
          name="download-circle-block"
          title="Downloaded"
          size="small"
          className={styles.downloadIcon}
        />
      </div>
    );
  }
  if (trackIsDownloading) {
    return (
      <div className={classnames(className, styles.downloadIndicator)}>
        <div className={styles.spinner} />
      </div>
    );
  }
  return null;
};

export default DownloadIndicator;
