import { livestreamEventStatusDecorator } from './livestreamEvents.js';

export const selectMyEvents = state => {
  const events = state.entities.myEvents || {};
  return Object.values(events).map(livestreamEventStatusDecorator);
};

export const selectMyEventsIsLoading = state => state.lists.myEvents.loading;

export const selectNextMyEventsCursor = state => {
  const { cursor } = state.lists.myEvents.meta;
  return cursor ? cursor.next : null;
};

export const selectPrevMyEventsCursor = state => {
  const { cursor } = state.lists.myEvents.meta;
  return cursor ? cursor.prev : null;
};
