import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import styles from './ProfileBanner.css';

class ProfileBanner extends PureComponent {
  static propTypes = {
    bannerImageUrl: PropTypes.string,
    bannerImageLink: PropTypes.string,
  };

  renderImage = bannerImageUrl => {
    const imageStyle = {
      backgroundImage: `url("${bannerImageUrl}")`,
    };

    return (
      <div className={styles.container}>
        <div className={styles.image} style={imageStyle} role="img" />
      </div>
    );
  };

  render() {
    const { bannerImageUrl, bannerImageLink } = this.props;

    if (!bannerImageUrl) {
      return null;
    }

    if (bannerImageLink) {
      return (
        <Link onlyActiveOnIndex={false} to={bannerImageLink}>
          {this.renderImage(bannerImageUrl)}
        </Link>
      );
    }

    return this.renderImage(bannerImageUrl);
  }
}

export default ProfileBanner;
