// @flow

export type DownloadStatus = 'not_downloaded' | 'download_running' | 'downloaded';

export type DownloadUpdateAction = {
  type: 'DOWNLOAD_UPDATE',
  entityId: string,
  downloadStatus: DownloadStatus,
  downloadedTrackIds: string[],
};

export function downloadUpdateAction({
  entityId,
  downloadStatus,
  downloadedTrackIds,
}: {
  entityId: string,
  downloadStatus: DownloadStatus,
  downloadedTrackIds: string[],
}): DownloadUpdateAction {
  return {
    type: 'DOWNLOAD_UPDATE',
    entityId,
    downloadStatus,
    downloadedTrackIds,
  };
}
