// @flow
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Price from '../common/Price';
import {
  selectStripeDiscountCouponId,
  selectStripeDiscountEndDate,
  selectStripeDiscountPercentOff,
} from '../../selectors/subscription';
import { connect } from 'react-redux';

type MapStateToProps = {
  couponId: string,
  percentOff: number,
  endDate: Date,
};

const SubscriptionDiscountStatus = ({ couponId, percentOff, endDate }: MapStateToProps) => {
  if (!endDate) {
    return (
      <FormattedMessage
        id="settings.subs.discount-status.for-life"
        defaultMessage="100% discount active for life"
      />
    );
  }

  // Special case 99cent funnel
  // If coupon_id === '99ct-3mo' then we want to display a
  // hardcoded string such as "$0.99/mo discount ends at .."
  // instead of the above template.
  // https://github.com/IDAGIO/idagio-web/issues/3884
  if (couponId === '99ct-3mo') {
    return (
      <FormattedMessage
        id="settings.subs.discount-status.99cent-discount-with-end-date"
        defaultMessage="{price}/month discount until {discountEnd}"
        values={{
          price: <Price price={0.99} />,
          discountEnd: (
            <FormattedDate year="numeric" month="2-digit" day="2-digit" value={endDate} />
          ),
        }}
      />
    );
  }

  return (
    <FormattedMessage
      id="settings.subs.discount-status.with-end-date"
      defaultMessage="{percentOff}% discount until {discountEnd}"
      values={{
        percentOff,
        discountEnd: <FormattedDate year="numeric" month="2-digit" day="2-digit" value={endDate} />,
      }}
    />
  );
};

function mapStateToProps(state: Object): MapStateToProps {
  return {
    couponId: selectStripeDiscountCouponId(state),
    percentOff: selectStripeDiscountPercentOff(state),
    endDate: selectStripeDiscountEndDate(state),
  };
}

export default connect(mapStateToProps)(SubscriptionDiscountStatus);
